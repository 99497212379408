import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArrowUpSvg from "../../../assets/images/icon/consulting_location_detail_arrow_up.svg";
import ArrowDownSvg from "../../../assets/images/icon/consulting_location_detail_arrow_down.svg";
import { requestStartupConsultation } from "~/sagas/api/common";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { getUserSetting } from "~/sagas/api/user";
import { useSelector } from "react-redux";

const ConsultingModal = React.forwardRef(
  ({ isOpen, onClose, brand, consultingLocation, coord }, ref) => {
    const nameInput = useRef();
    const phoneNumberInput = useRef();
    const agreeInfomationSaveInput = useRef();
    const [nameValidation, setNameValidation] = useState(false);
    const [phoneValidation, setPhoneValidation] = useState(false);
    const [agreeValidation, setAgreeValidation] = useState(false);
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);

    const [locationDetailOpen, setLocationDetailOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [inputs, setInputs] = useState({
      brandIdVal: brand.id ?? -1,
      userName: "",
      phoneNumber: "",
      inquireTime: "",
      favoriteArea: consultingLocation,
      agreeInfomationSave: false,
      source: "여기에 창업하기 버튼",
    });
    const { userInfo } = useSelector(({ common }) => ({
      userInfo: common.auth.userInfo,
    }));

    useEffect(() => {
      if (userInfo.name) {
        onChangeField({
          target: {
            name: "userName",
            value: userInfo.name,
          },
        });
      }
    }, [userInfo]);

    // eslint-disable-next-line
    const { userName, phoneNumber, inquireTime, favoriteArea } = inputs;
    const onChangeField = (e) => {
      const { name, value } = e.target;

      // console.log(userName.trim().length);
      // console.log(value, userName);

      if (name === "userName") {
        if (value.trim() === "" || value.trim().length < 3) {
          setNameValidation(true);
        } else {
          setNameValidation(false);
        }
      } else if (name === "phoneNumber") {
        if (/^\d{2,3}-?\d{3,4}-?\d{4}$/.test(value)) {
          setPhoneValidation(false);
        } else {
          setPhoneValidation(true);
        }
      }

      setInputs({ ...inputs, [name]: value });
    };

    useEffect(() => {
      if (coord != null && locationDetailOpen) {
        var mapDiv = document.getElementById("consulting-map"); // 'map'으로 선언해도 동일

        var map = new window.naver.maps.Map(mapDiv, {
          center: new window.naver.maps.LatLng(coord.y, coord.x),
        });
        map.setOptions({
          //지도 인터랙션 끄기
          draggable: false,
          pinchZoom: false,
          scrollWheel: false,
          keyboardShortcuts: false,
          disableDoubleTapZoom: true,
          disableDoubleClickZoom: true,
          disableTwoFingerTapZoom: true,
        });
      }
    }, [locationDetailOpen]);

    const onChangeCheckbox = () => {
      setAgreeCheckbox(!agreeCheckbox);
      setInputs({ ...inputs, agreeInfomationSave: !agreeCheckbox });
    };

    const closeClick = () => {
      //초기화
      setInputs({
        brandIdVal: brand.id,
        userName: "",
        phoneNumber: "",
        areaOfInterest: "",
        desiredTimeForConsultation: "",
        agreeInfomationSave: false,
        source: "여기에 창업하기 버튼",
      });
      setNameValidation(false);
      setPhoneValidation(false);
      setAgreeValidation(false);
      setAgreeCheckbox(false);
    };

    useEffect(() => {
      if (agreeCheckbox) {
        setAgreeValidation(false);
      }
    }, [agreeCheckbox]);

    const saveClick = () => {
      if (userName.trim() === "" || userName.trim().length < 3) {
        setNameValidation(true);
        nameInput.current.focus();
        return;
      } else if (!/^\d{2,3}-?\d{3,4}-?\d{4}$/.test(phoneNumber.trim())) {
        setPhoneValidation(true);
        phoneNumberInput.current.focus();
        return;
      } else if (agreeCheckbox === false) {
        setAgreeValidation(true);
        agreeInfomationSaveInput.current.focus();
        return;
      }

      requestStartupConsultation(inputs, localStorage.getItem("jwtToken")).then(
        (response) => {
          enqueueSnackbar("저장 되었습니다.", {
            className: "snackbar success",
            autoHideDuration: 5000,
            action: actionCloseChangePasswordPopup,
          });

          closeClick();
          onClose();
        }
      );
    };

    // customized
    const actionCloseChangePasswordPopup = (key) => (
      <Button
        onClick={() => {
          onClose();
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    );

    return (
      <Container>
        <Title>여기에 창업하기</Title>
        <SubTitle>
          아래 정보를 입력해 주시면
          <br />
          브랜드 담당자가 친절하게 상담해 드립니다.
        </SubTitle>

        <InputContainer style={{ marginTop: "40px" }}>
          <TextInput
            type="text"
            name="userName"
            onChange={onChangeField}
            ref={nameInput}
            defaultValue={userInfo.name}
          />
          <InputName>이름</InputName>

          {nameValidation ? (
            <InputValidation>
              이름은 2자 이상 공백 없이 한글/영문만 입력해 주세요.
            </InputValidation>
          ) : null}
        </InputContainer>
        <InputContainer style={{ marginTop: "18px" }}>
          <TextInput
            type="text"
            name="phoneNumber"
            onChange={onChangeField}
            ref={phoneNumberInput}
          />
          <InputName>휴대전화</InputName>
          {phoneValidation ? (
            <InputValidation>휴대전화를 8자 이상 입력해 주세요</InputValidation>
          ) : null}
        </InputContainer>
        <InputContainer style={{ marginTop: "18px" }}>
          <TextInput type="text" defaultValue={brand.name} />
          <InputName>관심 브랜드</InputName>
        </InputContainer>
        <InputContainer style={{ marginTop: "18px" }}>
          <TextInput
            type="text"
            name="favoriteArea"
            onChange={onChangeField}
            defaultValue={consultingLocation}
          />
          <InputName>관심 지역</InputName>
        </InputContainer>

        <LocationDetailContainer>
          <LocationDetailHeader
            onClick={() => setLocationDetailOpen(!locationDetailOpen)}
          >
            위치 상세보기
            {locationDetailOpen ? (
              <LocationDetailArrow src={ArrowUpSvg} />
            ) : (
              <LocationDetailArrow src={ArrowDownSvg} />
            )}
          </LocationDetailHeader>
          {locationDetailOpen && (
            <LocationDetailBody>
              <LocationDetailMap id="consulting-map" />
            </LocationDetailBody>
          )}
        </LocationDetailContainer>

        <InputContainer style={{ marginTop: "24px" }}>
          <TextInput type="text" name="inquireTime" onChange={onChangeField} />
          <InputName>상담희망 시간</InputName>
        </InputContainer>
        <AcceptContainer>
          <label className="checkbox check-primary">
            <input
              type="checkbox"
              name="agreeInfomationSave"
              defaultChecked={agreeCheckbox}
              onClick={() => onChangeCheckbox()}
              ref={agreeInfomationSaveInput}
            />
            <i></i>
            <span>이용약관과 개인정보처리방침에 동의합니다.</span>
          </label>

          {agreeValidation ? (
            <InputValidation>
              개인정보 수집 및 이용에 동의해주세요.
            </InputValidation>
          ) : null}
        </AcceptContainer>
        <ConsultingButton onClick={() => saveClick()}>
          상담 신청하기
        </ConsultingButton>
      </Container>
    );
  }
);

const Container = styled.div`
  background: #fff;
  padding: 22px 22px 24px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ed703e;
`;

const SubTitle = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const InputContainer = styled.div`
  position: relative;
`;

const TextInput = styled.input`
  display: block;
  width: 340px;
  height: 56px;
  padding: 10px 7px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #747474;
  box-sizing: border-box;

  &:focus {
    color: #333333;
    border: 2px solid #333333;
  }

  &:focus.brand-detail__inquire-name-title {
    color: #333333;
  }
`;

const InputName = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #747474;
  position: absolute;
  top: -10px;
  left: 6px;
  padding: 2px;
  background: #fff;
`;

const InputValidation = styled.div`
  color: #ff662a;
  font-size: 12px;
  font-weight: 400;
`;

const LocationDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const LocationDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #747474;

  cursor: pointer;
  padding: 0 6px;
`;

const LocationDetailArrow = styled.img`
  width: 12px;
  height: 12px;
`;

const LocationDetailBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 167px;
  width: 100%;
  padding: 8px;
  background: rgba(101, 101, 101, 0.03);

  margin-top: 12px;
`;

const LocationDetailMap = styled.div`
  width: 200px;
  height: 140px;
  border-radius: 8px;
`;

const AcceptContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px 0 33px;
`;

const ConsultingButton = styled.button`
  background-color: #ff662a;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.075);
  }
  &.inactive {
    background-color: #dcdfe2;
  }
`;

export default ConsultingModal;
