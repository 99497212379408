import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { changeFilterFolding, filterForm} from '~/sagas/actions/filterAction';
import BrandScreen from '../../screen/brand/BrandScreen';
import MapScreen from '../../screen/maps/MapScreen';
import Header from '../layout/Header';
import Filters from './filter/Filters';
import cn from 'classnames';

const FilterContainer = () => {
  const { form } = useSelector(({ filter }) => ({
    form: filter.form,
  }));
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));

  const dispatch = useDispatch();

  const filterFoldingEvent = useCallback(
    () => {
    dispatch(
      changeFilterFolding()
      ); 

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, folding]);

  const onClickDelete = useCallback(
    (e) => {
      e.preventDefault();
      const { id, value } = e.target;

      switch (id) {
        case 'reset':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              selectedCategory: form.selectedCategory.map((i) => ({
                ...i,
                checked: false,
              })),
              selectedTheme: form.selectedTheme.map((i) => ({
                ...i,
                checked: false,
              })),
              selectedBadge: form.selectedBadge.map((i) => ({
                ...i,
                checked: false,
              })),
            }),
          );
          break;
        case 'badge':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              selectedBadge: form.selectedBadge.map((i) =>
                i.id === Number(value)
                  ? {
                      ...i,
                      checked: !i.checked,
                    }
                  : i,
              ),
            }),
          );
          break;
        case 'category':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              selectedCategory: form.selectedCategory.map((i) =>
                i.id === Number(value)
                  ? {
                      ...i,
                      checked: !i.checked,
                    }
                  : i,
              ),
            }),
          );
          break;
        case 'theme':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              selectedTheme: form.selectedTheme.map((i) =>
                i.id === Number(value)
                  ? {
                      ...i,
                      checked: !i.checked,
                    }
                  : i,
              ),
            }),
          );
          break;

        case 'cost':
          //console.log(id);
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              fromStartUpCost: 0,
              toStartUpCost: 0,
            }),
          );
          break;
        default:
          break;
      }
    },
    [dispatch, form],
  );
  const onClickRange = useCallback((name, newValue, checked) => {
    
      switch (name) {
        case 'rangeStartupCost':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              fromStartUpCost: newValue[0],
              toStartUpCost: newValue[1],
              isCheckedStartUpCost: checked,
            }),
          );
          break;
        case 'rangeSale':
          dispatch(
            filterForm({
              ...form,
              pageIndex: 0,
              more: false,
              fromMonth: newValue[0],
              toMonth: newValue[1],
              isCheckedMonth: checked,
            }),
          );
          break;
        case 'rangeArea':
          dispatch(
            filterForm({
              ...form,
              more: false,
              fromArea: newValue[0],
              toArea: newValue[1],
              isCheckedArea: checked,
            }),
          );
          break;

        default:
          break;
      }
    },
    [dispatch, form],
  );

  const onChangeCategory = useCallback((e, id) => {
      const { checked } = e.target;

          /*let trueList = form.selectedCategory.map(i => {
            if ( i.id == id ) {
              i.checked = true;
            } else {
              i.checked = false;
            }


            return i;
          });*/

      // if ( trueList.length > 0 ) {
        dispatch(
          filterForm({
            ...form,
            pageIndex: 0,
            more: false,
            selectedCategory: form.selectedCategory.map((ele) => {
                if(ele.id == id){
                    ele.checked = !ele.checked;
                }
                return ele
            })
            
          }),
        );
      // }
      
    },

    [dispatch, form],
  );

  const onChangeTheme = useCallback(
    (e, type) => {
      const { value, checked } = e.target;
      //console.log(value);

      if (type === 'all') {
        dispatch(
          filterForm({
            ...form,
            pageIndex: 0,
            more: false,
            selectedTheme: checked
              ? form.selectedTheme.map((i) => ({
                  ...i,
                  checked: true,
                }))
              : form.selectedTheme.map((i) => ({
                  ...i,
                  checked: false,
                })),
          }),
        );
      } else {
        dispatch(
          filterForm({
            ...form,
            pageIndex: 0,
            more: false,
            selectedTheme: form.selectedTheme.map((i) =>
              i.id === Number(value)
                ? {
                    ...i,
                    checked: !i.checked,
                  }
                : i,
            ),
          }),
        );
      }
    },
    [dispatch, form],
  );

  const onChangeBadge = useCallback(
    (e, type) => {
      const { value, checked } = e.target;
      // console.log(value);

      if (type === 'all') {
        dispatch(
          filterForm({
            ...form,
            pageIndex: 0,
            more: false,
            selectedBadge: checked
              ? form.selectedBadge.map((i) => ({
                  ...i,
                  checked: true,
                }))
              : form.selectedBadge.map((i) => ({
                  ...i,
                  checked: false,
                })),
          }),
        );
      } else {
        dispatch(
          filterForm({
            ...form,
            pageIndex: 0,
            more: false,
            selectedBadge: form.selectedBadge.map((i) =>
              i.id === Number(value)
                ? {
                    ...i,
                    checked: !i.checked,
                  }
                : i,
            ),
          }),
        );
      }
    },
    [dispatch, form],
  );

  const onSubmit = (e) => {
    e.preventDefault();
  };


  return (
    <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
      <Header />
      <div id="container">
            <Filters
              folding={folding}
              onSubmit={onSubmit}
              onClickDelete={onClickDelete}
              changeEvent={{
                onChangeCategory: onChangeCategory,
                onChangeTheme: onChangeTheme,
                onChangeBadge: onChangeBadge,
                onClickRange: onClickRange,
                filterFoldingEvent: filterFoldingEvent,
              }}
            />
            <Switch>
              <Route path="/brand" component={BrandScreen} exact/>
              <Route path='/map' component={MapScreen} exact/>
              <Route path="/map/:brandId/:branchId/:categoryId/:latitude/:longitude" component={MapScreen} />
            </Switch>
      </div>
      
    </div>
  );
};

export default React.memo(FilterContainer);
