import React, { useEffect, useState } from "react";
import { formattedNum } from "~/common/NumberUtil";
import { amountUnit } from "../../App";
import ManagerComment from "./ManagerComment";

function FranchiseAdHistory({ adHistoryList, franchiseStartDate, logo, comment, hideComment }) {
  
  // console.log(adHistoryList);

  const [adHistory, setAdHistory] = useState(null);

  useEffect(() => {
    adHistoryList.sort( (a,b) => b.year - a.year);

    if ( adHistoryList && adHistoryList.length > 0 ) {
      setAdHistory(adHistoryList[0]);
    }
  }, []);

  if (adHistoryList < 1) return null;

  return (
    <div className="brand-detail__franchise-ad-history">
      {
        !hideComment &&
        <span className="brand-detail__title">가맹점 광고/판촉 내역</span>
      }

      <span className="brand-detail__sub-title">
        { adHistory && 
          <div>{adHistory.year}년 정보공개서 기준</div>
        }
      </span>
    
      <div className="brand-detail__table-group">
        <div>
          <span>광고비</span>
          <span>{adHistory && adHistory.ad ? `${formattedNum(adHistory.ad*amountUnit)}원` : '정보없음'}</span>
        </div>
        <div>
          <span>판촉비</span>
          <span>{adHistory && adHistory.promo ? `${formattedNum(adHistory.promo*amountUnit)}원` : '정보없음'}</span>
        </div>
      </div>

      {
        !hideComment && comment && comment.affiliateAdvertisementPromotionDetailsComment &&
        <ManagerComment key="affiliateAdvertisementPromotionDetailsComment" logo={logo} comment={comment.affiliateAdvertisementPromotionDetailsComment} name={comment.userName} modifyAt={comment.modifyAt}/>
      }
      

    </div>
  );
}

export default React.memo(FranchiseAdHistory);
