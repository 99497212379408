import { takeLatest } from 'redux-saga/effects';
import * as api from './api/home';
import createRequestSaga from './modules/createRequestSaga';
import * as type from './types/homeType';

const getBrandListAtHomeSaga = createRequestSaga(type.GET_BRAND_LIST_AT_HOME, api.getBrandListAtHome);

export default function* homeSaga() {
  yield takeLatest(type.GET_BRAND_LIST_AT_HOME, getBrandListAtHomeSaga);
}
