import React, { useState } from 'react';
import { Button, Slider } from '@material-ui/core';

const SelectSales = (props) => {
  const [fromTo, setFromTo] = useState(props.value);
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 50000000,
      label: '5천',
    },
    {
      value: 100000000,
      label: '1억',
    },
    {
      value: 200000000,
      label: '2억',
    },
    {
      value: 300000000,
      label: '3억',
    },
    {
      value: 400000000,
      label: '4억',
    },
    {
      value: 500000000,
      label: '최대',
    },
  ];

  const valueChange = (event, value) => {
    setFromTo(value);
  };

  const onClickReset = () => {
    setFromTo([0, 500000000]);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="slider">
          <Slider
            marks={marks}
            className="primary"
            value={fromTo}
            onChange={valueChange}
            name="rangeStartupCost"
            // valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={50000000}
            min={0}
            max={500000000}
          />
        </div>
      </div>

      <div className="modal-footer">
        <div className="align-between">
          <button id="cost" className="btn-reset" onClick={onClickReset}>
            초기화
          </button>
          <Button variant="outlined" className="btn-modal-ok" onClick={(e) => props.handleConfirm('rangeStartupCost', fromTo, true)}>
            적용
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectSales;
