import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_brand.scss';
import { Button, CircularProgress, Modal} from '@material-ui/core';
import { getBrandInfo, modifyBrandInfo } from '~/sagas/api/admin/brand_management';
import { useHistory } from 'react-router';
import { loginCorp } from '~/sagas/actions/commonAction';
import { useSnackbar } from 'notistack';
import { unstable_batchedUpdates } from 'react-dom';
import { finishLoading, startLoading } from '~/sagas/actions/loadingAction';

function BrandManagementInfo () {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const {selectedCategory, hiddenCategory} = useSelector(({filter}) => ({
        form: filter.form,
        selectedCategory: filter.form.selectedCategory,
        hiddenCategory: filter.form.hiddenCategory
    }), shallowEqual);

    const { corpUserInfo } = useSelector(({ common }) => ({
        corpUserInfo: common.auth.corpUserInfo,
    }));


    ////가맹점 관리
    const [mainImage, setMainImage] = useState(''); // 메인 이미지
    const [logoImage, setLogoImage] = useState(''); // 로고 이미지
    const [selectedParent, setSelectedParent] = useState('선택하세요'); //선택된 부모 카테고리
    const [selectedChild, setSelectedChild] = useState(0); //선택된 자식 카테고리
    const [distance, setDistance] = useState(false); // 출점 거리

    const [distinctParentList, setDistinctParentList] = useState(null); //중복제거된 부모 카테고리 리스트
    const [childList, setChildList] = useState(''); //자식 카테고리 전체

    const [fileUploading, setFileUploading] = useState(false); //엑셀 수행중 체크.
    
    const formElement = useRef(null);
    const logoFileElement = useRef(null);
    const mainFileElement = useRef(null);

    
    // customized
    const actionCloseChangePasswordPopup = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    ), [closeSnackbar]);

    //가맹점 관리
    // 부모 카테고리 변경
    const changeParentCategory = useCallback((e) => {
        setSelectedParent(e.target.value);

        //부모 카테고리 변경 시 자식 첫번째 세팅.
        let firstCategory = childList && childList.filter((v)  => v.category === e.target.value)[0];
        setSelectedChild(firstCategory ? firstCategory.id : 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParent]);

    // 자식 카테고리 변경
    const changeChildCategory = useCallback((e) => {
        setSelectedChild(e.target.value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChild]);

    //가맹점 관리 저장하기
    const saveBrandInfo = () => {

        
        //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

        if ( corpUserInfo && corpUserInfo.selectedBrand ) {


            unstable_batchedUpdates(async () => {
                setFileUploading(true); //파일 업로드 버튼 로딩 시작

                let data = new FormData(formElement.current);
                data.set("brandId", corpUserInfo.selectedBrand.brandId);
                data.set("distance", distance);
                data.set("categoryId", selectedChild);

                try {
                    await modifyBrandInfo(data, localStorage.getItem("jwtTokenAdmin")).then(res => {
                    
                        if ( res.status === 200 ) {
                            dispatch(
                                loginCorp({
                                    ...corpUserInfo,
                                    brandList: corpUserInfo.brandList.map(item => {
                                        if ( item.brandId === corpUserInfo.selectedBrand.brandId) {
                                            item.mainImage = mainImage;
                                            item.logoImage = logoImage;
                                            item.distance = distance;
                                            item.categoryId = selectedChild;
                                            return item;
                
                                        } else {
                                            return item;
                                        }
                                    })
                                })
                            );

                            logoFileElement.current.value = "";
                            mainFileElement.current.value = "";

                            enqueueSnackbar("저장 되었습니다.", {
                                className:"snackbar success",
                                autoHideDuration: 3000,
                                action:actionCloseChangePasswordPopup
                            });
                        }
                        
                    }).catch(error => {
                        console.log(error);
                    });

                } catch (e) {
                    console.log(e);
                    enqueueSnackbar("통신에 문제가 발생되었습니다. 관리자에게 문의하세요.", {
                        className: "snackbar warning",
                        autoHideDuration: 3000,
                        action: actionCloseChangePasswordPopup
                    });
                }

                setFileUploading(false); //파일 업로드 버튼 로딩 종료

                
            });

            

        }

        
    };

    const onChangeDistance = (e) => {
        setDistance(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onClickModifyLogo = () => {
        logoFileElement.current.click();
    };

    const onClickModifyMain = () => {
        mainFileElement.current.click();
    };

    //최초 조회시 부모 1번째와 자식 첫번째 세팅
    useEffect(() => {
        //console.log('브랜드 바뀜');
        //부모 카테고리 목록 중복제거
        dispatch(startLoading());
        
        const categoryList0 = selectedCategory && selectedCategory.map(e=> e.category);
        const categoryList1 = hiddenCategory && hiddenCategory.map(e=> e.category);
        const categoryList = categoryList0.concat(categoryList1); //전체 카테고리 합침.
        let distinctedCategory = categoryList && categoryList.filter((v,i,a)=>a.indexOf(v)===i); //중복제거
        let childCategories = selectedCategory.concat(hiddenCategory);

        //카테고리 id 내림차순 정렬 0 1 2 3 4
        childCategories = childCategories.sort((a,b) => {
            if ( a.id > b.id ) {
                return 1;
            } else if ( a.id < b.id ) {
                return -1;
            } else {
                return 0;
            }
        });

        distinctedCategory = distinctedCategory.map( item => {
            if ( item === "전체" ) {
                return "선택하세요";
            } else {
                return item;
            }
        });
        childCategories = childCategories.map( item => {
            if ( item.id === 0 ) {
                item.category = "선택하세요";
                item.name = "선택하세요";
                return item;
            } else {
                return item;
            }
        });

        let firstCategory = [];
        if ( categoryList && categoryList.length > 0 ) {
            firstCategory = childCategories[0];
        }

        setDistinctParentList(distinctedCategory); //전체 부모(전체,외식,서비스,도소매)
        setChildList(childCategories); //전체 자식([{id:1,cateogry:외식,name:기타 외국식}, {id:2,cateogry:외식,name:기타 외식}])

        try {
            // const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

            if ( corpUserInfo && corpUserInfo.selectedBrand ) {
                //브랜드 기본 정보
                getBrandInfo(corpUserInfo.selectedBrand.brandId, localStorage.getItem("jwtTokenAdmin"))
                .then(response => {
    
                    if ( response.status === 200 ) {
                        const data = response.data;
                        if ( data ) {
                            setLogoImage(data.logoImageUrlThumb);
                            setMainImage(data.mainImageUrlThumb);
                            setSelectedParent(data.category.category);
                            setSelectedChild(data.category.id);
                            setDistance(data.storeLimitDistance);
                        } else {
                            //첫번째 부모의 첫번째 자식
                            setSelectedParent(firstCategory ? firstCategory.category : '전체');
                            setSelectedChild(firstCategory ? firstCategory.id : 0);
                        }
                    }
                    
                    dispatch(finishLoading());
                    
                }).catch( error => {
                    console.log(error);
                    dispatch(finishLoading());
                });
            }
            
        } catch (e) {
            console.log(e);
        }

    }, [corpUserInfo]);
    
    return (
        <div className="general-tab__container">
            <form onSubmit={onSubmit} id="brandImageUpload" method="POST" encType="multipart/form-data" ref={formElement}>
                <div className="main-group">
                    <span className="title">브랜드 로고 이미지</span>
                    <span className="detail">최소 100px X 100px 사이즈의 이미지 파일을 업로드 해주세요.</span>
                    <div className="display-image__container logo">
                        <img className="logo" src={logoImage ? logoImage : "/placeholder_logo.png"} alt="logo"/>
                    </div>
                    <button className="logo-btn" onClick={onClickModifyLogo}>로고 이미지 선택</button>
                    <div className="selected-file-group">
                        <span>선택 된 파일 : </span>
                        <input ref={logoFileElement} type="file" name="logoFile" accept="image/png,image/jpeg" />
                    </div>
                </div>
                <div className="main-group">
                    <span className="title">브랜드 대표 이미지</span>
                    <span className="detail">최소 276px X 183px 사이즈의 이미지 파일을 업로드 해주세요.</span>
                    <div className="display-image__container main">
                        <img className="main" src={mainImage ? mainImage : "/placeholder_brand.png"} alt="main"/>
                    </div>
                    <button className="logo-btn" onClick={onClickModifyMain}>대표 이미지 선택</button>
                    <div className="selected-file-group">
                        <span>선택 된 파일 : </span>
                        <input ref={mainFileElement} type="file" name="mainFile" accept="image/png,image/jpeg" />
                    </div>
                </div>
            
                <div className="main-group">
                    <span className="title">카테고리</span>
                    <span className="detail">브랜드 성격에 맞는 카테고리를 선택해주세요.</span>
                    <div className="field">
                        <select name="" value={selectedParent} className="select outlined mgr20" onChange={changeParentCategory}>
                            {distinctParentList && distinctParentList.map( (e, idx) => <option key={idx} value={e}> {e}</option> )}
                        </select>
                        <select name="" value={selectedChild} className="select outlined" onChange={changeChildCategory} >
                            {childList && 
                            childList
                                .filter((f) => f.category === selectedParent)
                                .map( (e) => <option key={e.id} value={e.id}> {e.name}</option> )}
                        </select>
                    </div>
                </div>
                <div className="main-group">
                    <span className="title">출점 제한거리</span>
                    <span className="detail">가맹점과 가맹점간의 제한거리를 m단위로 입력해주세요.</span>
                    <div className="field">
                        <input type="number" defaultValue={distance} onChange={onChangeDistance} name="distance" className="input-box" placeholder="출점 제한거리"/>
                        <span className="title">제한거리</span>
                        <span className="inner-unit">M</span>
                    </div>
                </div>
                <button className="save-btn" onClick={saveBrandInfo}>저장하기</button>
            </form>
            <Modal
                open={fileUploading}
                className="admin-branch__modal"
            >
                <div>
                    <div className="circular-progress-box">
                        <CircularProgress className="circular-progress" size={20} />
                        <div className="upload-text">업로드 중 입니다.</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default React.memo(BrandManagementInfo);