import "~/assets/scss/join_complete.scss";
import cn from "classnames";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { login } from "~/sagas/actions/commonAction";
import UserSetting from "../login/UserSetting";
import { selectUser } from "~/sagas/api/user";
import Footer from "~/components/layout/Footer";

import styled from "styled-components";
import JoinCompleteStep from "./joinCompleteStep";

function JoinComplete() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  async function getUser() {
    //console.log('getUser')
    let res = await selectUser(localStorage.getItem("jwtToken"));
    //   console.log(res.data);
    dispatch(login(res.data));
  }

  const confirmEvent = () => {
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      window.scrollTo(0, 0);
      history.replace("/map");
    }, 1000);
  };

  // useEffect(() => {
  //     // console.log(localStorage.getItem("jwtToken"));
  //     // console.log(userInfo.id);

  //     if ( userInfo.id === '' || localStorage.getItem("jwtToken") === null) {
  //         localStorage.removeItem("jwtToken");
  //         history.replace("/map");
  //     } else {
  //         getUser();
  //     }

  //   // eslint-disable-next-line
  // }, []);

  if (!userInfo) return <div>로딩중</div>;

  return (
    <>
      <Container>
        <HeaderContainer>환영합니다! {userInfo.name}님</HeaderContainer>

        {/* <UserSetting confirmEvent={confirmEvent} viewType="COMPLETE" /> */}
        <JoinCompleteStep />
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div``;

const HeaderContainer = styled.div`
  width: 100%;
  height: 120px;
  background: #fd8724;

  padding-top: 40px;
  padding-left: 80px;
  font-weight: 600;
  font-size: 14px;

  color: white;
`;

export default JoinComplete;
