

import React from 'react';
import { Link } from 'react-router-dom';

const AdminHeader = () => {

  return (
    <div id="header">
      <header>
        <div className="inner">
          <h1 className="logo">
            <Link to="/corp/brand">
              <i className='ttoksa other'>똑똑한사장로고</i>
            </Link>
          </h1>
        </div>
      </header>
    </div>
  );
};

export default React.memo(AdminHeader);
