export default function InquiryTable({
  list,
  onClickRow,
  pageType,
  brandList,
}) {
  return (
    <div className="grid-list">
      <div className="grid-container">
        <div className="grid-header">
          <span className="name">이름</span>
          <span className="area">관심지역</span>
          <span className="brand">브랜드명</span>
          <span className="contract">계약가능성</span>
          <span className="page">유입페이지</span>
          <span className="date">문의 날짜</span>
        </div>
        {list &&
          list.map((item) => {
            let brand = brandList.find((ele) => ele.brandId == item.brandIdVal);
            return (
              <div
                key={item.id}
                className="grid-row"
                onClick={() => onClickRow(pageType, item, brand)}
              >
                <span className="name">{item.userName}</span>
                <span className="area">{item.areaOfInterest}</span>
                <span className="brand">{brand.name}</span>
                <span className="contract">{item.contractPossibility}</span>
                <span className="page">{item.source}</span>
                <span className="date">
                  {item.registerAt.substring(0, 19).replace("T", " ")}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
