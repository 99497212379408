import React, { useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UnitNormalList from '~/components/listitems/UnitNormalList';
import { filterForm } from '~/sagas/actions/filterAction';
import { getBrandList } from '~/sagas/actions/listAction';

function BrandListMoreBtn() {

  const dispatch = useDispatch();
  const { BrandList, hasNext, form } = useSelector(({ list, filter }) => ({
      BrandList: list.BrandList.list,
      hasNext: list.BrandList.hasNext,
      form: filter.form,
  }), shallowEqual);

  const onClickMore = useCallback((e) => {
      dispatch(
          filterForm({
            ...form,
            pageIndex: form.pageIndex + 1,
            more: true,
          })
      );
      
    }, [dispatch, form]);

  useEffect(()=> {
    //console.log('More Btn');
    
    return (()=> {
      //console.log('More Btn return');
    });
  }, []);

  if ( !hasNext) {
    return null;
  }

  return (
      <>
        {hasNext && form.selectedCategory.filter((i) => i.checked).length != 0 && (
          <div className="alignC bottom-btn-container">
            <button onClick={onClickMore} className="btn btn-primary size-l">
              더보기
            </button>
          </div>
        )} 
      </>
  );

}

export default React.memo(BrandListMoreBtn);