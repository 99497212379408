import client from "./client";

//로그인 유저 check
export const checkUser = (body) => client.post(
    `/api/checkUser`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        }
    }
);

//로그인 유저 정보 조회
export const selectUser = (tokenVal) => client.get(
    `/api/user`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 정보 조회
export const getUserSetting = (tokenVal) => client.get(
    `/api/getUserSetting`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 정보 저장
export const addUserSetting = (body, tokenVal) => client.post(
    `/api/addUserSetting`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 비밀번호 정보 저장
export const updateUser = (id, body, tokenVal) => client.post(
    `/api/users/${id}`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);
//마이 페이지 비밀번호 정보 저장
export const updateUserMarketing = (id, body, tokenVal) => client.post(
    `/api/usersMarketing/${id}`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 유저 삭제
export const removeUser = (id, tokenVal) => client.delete(
    `/api/users/${id}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);


/////////////////////////////
//기업고객
//////////////////////////
//로그인 기업 고객 정보 조회
export const selectCorpUser = (tokenVal) => client.get(
    `/admin/corp/user`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);


//로그인 유저 check
export const checkCorpUser = (body) => client.post(
    `/admin/corp/checkUser`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        }
    }
);


//마이 페이지 비밀번호 정보 저장
export const updateCorpUser = (id, body, tokenVal) => client.post(
    `/admin/corp/users/${id}`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 정보 저장
export const modifyCorpUserMyPage = (id, body, tokenVal) => client.post(
    `/admin/corp/modifyCorpUserMyPage/${id}`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//마이 페이지 유저 삭제
export const removeCorpUser = (id, tokenVal) => client.delete(
    `/admin/corp/users/${id}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);