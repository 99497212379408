import client from "../client";

//지점 관리 정보 조회
export const getBranchList = (brandId, tokenVal) =>
  client.get(`/admin/${brandId}/branch/list`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//지점 삭제 버튼
export const deleteBranchData = (id, tokenVal) =>
  client.delete(`/admin/branch/list/${id}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//지점 전체 삭제
export const deleteBranchAll = (brandId, tokenVal) =>
  client.delete(`/admin/${brandId}/branch`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//지점 추가 버튼
export const addBranchData = (body, tokenVal) =>
  client.post(`/admin/branch/list/add`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });
export const modifyBranchData = (body, tokenVal) =>
  client.post(`/admin/branch/list/modify`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//엑셀로 일괄 등록(브랜드)
export const uploadHqBrandInfomationByExcel = (year, body) =>
  client.post(`/admin/uploadHqBrandInfomationByExcel/${year}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

//엑셀로 일괄 등록(지점)
export const uploadBranchExcelFile = (brandId, body, tokenVal) =>
  client.post(`/admin/uploadBranchExcelFile/${brandId}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//엑셀 파일 다운로드
export const downloadExcelFileAPI = (fileName, tokenVal) =>
  client.get(`/admin/static/files/${fileName}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//지역 정보 조회
export const getRegionList = () =>
  client.get(`/admin/region/list`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

export const setRegionActive = (regionId, body, tokenVal) =>
  client.post(`/admin/region/active/${regionId}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

//잘못된 GPS 지점 수
export const countBranchGpsUpdateList = () =>
  client.post(`/admin/countBranchGpsUpdateList`);

//잘못된 GPS 업데이트 수행
export const updateBranchGps = () => client.post(`/admin/updateBranchGps`);
