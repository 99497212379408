import React, { useState } from "react";
import styled from "styled-components";
import Header from "~/components/layout/Header";
import TitleBGPng from "~/assets/images/introduce/introduce_title_bg.png";
import FirstImagePng from "~/assets/images/introduce/introduce_first_image.png";
import SecondImagePng from "~/assets/images/introduce/introduce_second_image.png";
import ThirdImagePng from "~/assets/images/introduce/introduce_third_image.png";
import PricingBGPng from "~/assets/images/introduce/introduce_pricing_bg.png";

import { ReactComponent as SimpleIconSvg } from "~/assets/images/introduce/introduce_price_icon_simple.svg";
import { ReactComponent as BasicIconSvg } from "~/assets/images/introduce/introduce_price_icon_basic.svg";
import { ReactComponent as PremiumIconSvg } from "~/assets/images/introduce/introduce_price_icon_premium.svg";

import OrangeCheckPng from "~/assets/images/introduce/introduce_icon_check_orange.png";
import WhiteCheckPng from "~/assets/images/introduce/introduce_icon_check_white.png";

import ProcessFirstIconSvg from "~/assets/images/introduce/introduce_process_icon_first.svg";
import ProcessSecondIconSvg from "~/assets/images/introduce/introduce_process_icon_second.svg";
import ProcessThirdIconSvg from "~/assets/images/introduce/introduce_process_icon_third.svg";

import ProcessFirstArrowSvg from "~/assets/images/introduce/introduce_process_arrow_first.svg";
import ProcessSecondArrowSvg from "~/assets/images/introduce/introduce_process_arrow_second.svg";

import FaqUpArrowSvg from "~/assets/images/introduce/introduce_faq_arrow_up.svg";
import FaqDownArrowSvg from "~/assets/images/introduce/introduce_faq_arrow_down.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

const faqList = [
  {
    question: "보고서는 결제 후 언제 받아 볼 수 있을까요?",
    answer:
      "똑똑한 보고서는 내부 창업 전문가가 직접 작성합니다. 이에 따라 작성과 퇴고에 평균 일주일이 소요될 수 있습니다.",
  },
  {
    question: "카카오톡 상담은 불안해요 다른 방법은 없을까요?",
    answer:
      "고객님께 보다 편리한 상담 시스템을 제공하기 위해 여러 방면을 고려하고 있습니다. 카카오 상담은 빠른 응대와 커뮤니케이션을 위한 선택입니다. 이 외 메일 상담은 help@ttoksa.kr을 통해 진행할 수 있습니다.",
  },
  {
    question: "접수 할 때 어떤 내용이 필요할까요?",
    answer: `원하는 창업 지역, 업종, 브랜드를 필수 정보로 수집합니다. 그 외 접수하신 보고서 상품에 따라 보유 예산, 기대 수익 등을 수집할 수 있습니다
    
    예) 강남구 삼성동, 치킨, BBQ`,
  },
  {
    question: "똑똑한 보고서의 데이터는 정말 신뢰할 수 있나요?",
    answer:
      "네 신뢰할 수 있습니다. (주)똑똑한 사장은 한국 외식산업협회 출신의 베테랑 전문가가 승인한 데이터를 기반으로 운영되며 똑똑한 보고서 역시 마찬가지입니다.",
  },
];

const priceList = [
  {
    icon: (color) => <SimpleIconSvg color={color} />,
    title: "심플 레포트",
    subTitle: "모르면 안되는 기본 정보",
    price: "3만 4천원",
    page: 20,
    benefitList: ["희망 상권 분석", "희망 업종 분석", "창업 시 예상 매출 분석"],
  },
  {
    icon: (color) => <BasicIconSvg color={color} />,
    title: "베이직 레포트",
    subTitle: "알짜배기 창업 정보",
    price: "7만원",
    page: 30,
    benefitList: [
      "심플 레포트",
      "희망 브랜드 가맹 본부 분석",
      "희망 브랜드 평판 분석",
      "창업시 예상 손익 분석",
    ],
  },
  {
    icon: (color) => <PremiumIconSvg color={color} />,
    title: "프리미엄 레포트",
    subTitle: "퍼스널 성공 전략",
    price: "14만원",
    page: 50,
    benefitList: [
      "베이직 레포트",
      "경쟁 브랜드 3사 비교 분석",
      "AI 추천 브랜드 제안",
    ],
  },
];

export default function IntroduceScreen() {
  const [selectedPrice, setSelectedPrice] = useState(1);
  const [selectedFaq, setSelectedFaq] = useState(0);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Container>
        <Header />
        <TitleSection>
          <ServiceName>똑똑한 사장</ServiceName>
          <IntroduceTitle>
            똑똑한
            <br />
            보고서
          </IntroduceTitle>
          <IntroduceSubTitle>
            프랜차이즈 창업 전문가와 AI의 분석 결과가 담긴
            <br />
            프라이빗 프랜차이즈 창업 리포트를 확인하세요
          </IntroduceSubTitle>
        </TitleSection>
        <FirstPointSection>
          <CheckPointLabel>Check Point 1</CheckPointLabel>
          <CheckPointTitle>
            의뢰 직후
            <br />
            220만 데이터 분석
          </CheckPointTitle>
          <CheckPointInfo>
            모아보기 불편한 시중 브랜드, 매출, 실적 정보
            <br />
            일일이 본사에 문의할 필요 없어요
            <br />
            빅데이터 기반 안심 창업이 가능하도록 똑똑한 보고서에 담길 데이터를
            정제해요
          </CheckPointInfo>
          <FirstPointImage src={FirstImagePng} />
        </FirstPointSection>
        <SecondPointSection>
          <CheckPointLabel>Check Point 2</CheckPointLabel>
          <CheckPointTitle>
            어떤 브랜드로 얼만큼?
            <br />
            미래 전망 레포팅
          </CheckPointTitle>
          <CheckPointInfo>
            의뢰한 브랜드와 상권의 비용 대비 수익률, 미래 전망까지
            <br />
            프랜차이즈 창업 전문가가 분석합니다
          </CheckPointInfo>
          <SecondPointImage src={SecondImagePng} />
        </SecondPointSection>
        <ThirdPointSection>
          <CheckPointLabel>Check Point 3</CheckPointLabel>
          <CheckPointTitle>브랜드 & 가맹본사 비교</CheckPointTitle>
          <CheckPointInfo>
            똑똑한 보고서에는 선택한 브랜드보다 더 수익 높은 브랜드도 추천해요
            <br />
            브랜드별 경쟁력에 가맹본사 평판 비교도 볼 수 있어요
            <br />
            <span style={{ fontSize: "16px" }}>
              *프리미엄 레포트에 한해 제공됩니다
            </span>
          </CheckPointInfo>
          <ThirdPointImage src={ThirdImagePng} />
        </ThirdPointSection>
        <PricePlanSection>
          <PricePlanSubTitle>PRICING</PricePlanSubTitle>
          <PricePlanTitle>똑똑한 사장이 되는 가장 빠른 지름길!</PricePlanTitle>
          <PricePlanInfo>
            모든 상품은 프랜차이즈 전문가의 컨설팅 의견이 담겨있으며
            <br />
            페이퍼 콘텐츠로 제공됩니다
          </PricePlanInfo>
          <PriceListContainer>
            {priceList.map((priceItem, index) => (
              <PriceItem
                className={selectedPrice == index ? "focused" : ""}
                onMouseEnter={() => setSelectedPrice(index)}
              >
                <PriceTitleContainer>
                  {priceItem.icon(selectedPrice == index ? "white" : "#ECEBFF")}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <PriceItemSubTitle>{priceItem.subTitle}</PriceItemSubTitle>
                    <PriceItemTitle>{priceItem.title}</PriceItemTitle>
                  </div>
                </PriceTitleContainer>
                <Price>{priceItem.price}</Price>
                <Page>{priceItem.page}페이지</Page>
                <PriceBenefitList>
                  {priceItem.benefitList.map((benefit) => (
                    <PriceBenefitItem>
                      <BenefitCheck
                        src={
                          selectedPrice == index
                            ? WhiteCheckPng
                            : OrangeCheckPng
                        }
                      />
                      {benefit}
                    </PriceBenefitItem>
                  ))}
                </PriceBenefitList>
                <CtaButton
                  onClick={() => {
                    logEvent(analytics, "introduct-click-report");
                    window.open(
                      "http://pf.kakao.com/_Xxmavxj/chat",
                      "target=_blank"
                    );
                  }}
                >
                  지금 신청하기
                </CtaButton>
              </PriceItem>
            ))}
          </PriceListContainer>
          <PriceAlert>
            신뢰도 높은 분석을 위한 자체 데이터가 충분하지 않으면 전문가 검토 후
            신청이 반려될 수 있습니다
          </PriceAlert>
        </PricePlanSection>
        <ProcessSection>
          <ProcessTitle>어떤 절차로 진행되나요?</ProcessTitle>
          <ProcessSubTitle>
            모든 과정은 전담 매니저가 배정되며 상담 이력과 보고서 전달까지 밀착
            관리합니다
          </ProcessSubTitle>
          <ProcessListContainer>
            <ProcessItem>
              <ProcessIcon src={ProcessFirstIconSvg} />
              <ProcessName>접수 및 상세 상담</ProcessName>
              <ProcessContent>
                원하는 보고서를 선택해
                <br />
                창업 희망 업종과 지역, 브랜드를
                <br />
                접수 받습니다
              </ProcessContent>
            </ProcessItem>
            <ProcessItem>
              <ProcessIcon src={ProcessSecondIconSvg} />
              <ProcessName>구매 안내 및 결제</ProcessName>
              <ProcessContent>
                내부 보유 데이터를 검토하여
                <br />
                높은 정확도로 분석이 가능한 경우
                <br />
                구매 절차와 결제 방법을 안내합니다
              </ProcessContent>
            </ProcessItem>
            <ProcessItem>
              <ProcessIcon src={ProcessThirdIconSvg} />
              <ProcessName>똑똑한 보고서 전달</ProcessName>
              <ProcessContent>
                창업 전문단이 레포트를 직접 작성합
                <br />
                니다. 자체 보유 AI의 신뢰도 검사 직
                <br />후 보고서가 전달됩니다.
              </ProcessContent>
            </ProcessItem>
            <ProcessArrow src={ProcessFirstArrowSvg} />
            <ProcessArrow src={ProcessSecondArrowSvg} />
          </ProcessListContainer>
        </ProcessSection>
        <FaqSection>
          <FaqSubTitle>FAQ'S</FaqSubTitle>
          <FaqTitle>자주 묻는 질문</FaqTitle>
          <FaqListContainer>
            {faqList.map((ele, index) => (
              <FaqItem
                className={selectedFaq === index ? "selected" : ""}
                onClick={() => setSelectedFaq(index)}
              >
                <FaqTitleArea>
                  <FaqItemTitle>{ele.question}</FaqItemTitle>
                  <FaqArrow
                    src={
                      selectedFaq === index ? FaqUpArrowSvg : FaqDownArrowSvg
                    }
                  />
                </FaqTitleArea>
                {selectedFaq === index && (
                  <FaqItemContent>{ele.answer}</FaqItemContent>
                )}
              </FaqItem>
            ))}
          </FaqListContainer>
        </FaqSection>
      </Container>
    </div>
  );
}

const Container = styled.div`
  padding-top: 79px;
  max-width: 1280px;
  min-width: 1280px;
  margin: 0 auto;
  background: linear-gradient(180deg, #323030 0%, #aaa 100%);
`;

const TitleSection = styled.div`
  height: 832px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 236px;

  position: relative;
  ::before {
    content: "";
    background: url(${TitleBGPng});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.4;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

const ServiceName = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;

  color: #ed703e;
  position: relative;
`;

const IntroduceTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 86px;
  line-height: 110%;

  color: #ffffff;
  position: relative;
`;

const IntroduceSubTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin-top: 30px;

  color: #ffffff;
  position: relative;
`;

const FirstPointSection = styled.div`
  height: 832px;
  background: #ed703e;

  padding: 75px 77px 0px;
`;

const FirstPointImage = styled.img`
  margin-top: 46px;
  width: 100%;
  height: 380px;
`;

const SecondPointSection = styled.div`
  height: 832px;

  padding: 97px 77px 0;
`;
const SecondPointImage = styled.img`
  margin-top: 36px;
  width: 100%;
  height: 380px;
`;

const ThirdPointSection = styled.div`
  height: 832px;
  background: #ed703e;

  padding: 106px 77px 0;
`;
const ThirdPointImage = styled.img`
  margin-top: 36px;
  width: 100%;
  height: 380px;
`;

const CheckPointLabel = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  color: #ffffff;
`;

const CheckPointTitle = styled.div`
  margin-top: 6px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 120%;

  color: #ffffff;
`;

const CheckPointInfo = styled.div`
  margin-top: 12px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;

  color: #ffffff;
`;

const PricePlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 1083px;

  padding-top: 80px;

  background: url(${PricingBGPng});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const PricePlanSubTitle = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  letter-spacing: 0.1em;

  color: #ed703e;
`;

const PricePlanTitle = styled.div`
  margin-top: 27px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;

  text-align: center;

  color: #000000;
`;

const PricePlanInfo = styled.div`
  margin-top: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  text-align: center;

  color: #323030;
`;

const PriceListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 99px 19px 0px;
  width: calc(100% - 38px);
`;

const PriceItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 573px;

  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border-radius: 24px;

  background: #ffffff;

  &.focused {
    background: #ed703e;

    margin-top: -59px;
  }
  padding: 46px 40px 36px;
`;

const PriceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const PriceIcon = styled.img`
  width: 72px;
  height: 72px;
`;

const PriceItemSubTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  color: #6f6c90;

  .focused & {
    color: white;
  }
`;

const PriceItemTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  color: #ed703e;
  .focused & {
    color: white;
  }
`;

const Price = styled.div`
  margin-top: 23px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  color: #ed703e;
  .focused & {
    color: white;
  }
`;

const Page = styled.div`
  margin-top: 17px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ed703e;
  .focused & {
    color: white;
  }
`;

const PriceBenefitList = styled.div`
  margin-top: 21px;
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const PriceBenefitItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 14px;

  font-weight: 400;
  font-size: 18px;
  color: #ed703e;
  .focused & {
    color: rgba(217, 219, 233, 1);
  }
`;

const BenefitCheck = styled.img`
  width: 26px;
  height: 26px;
`;

const CtaButton = styled.button`
  width: 308px;
  height: 72px;
  border-radius: 96px;

  font-size: 18px;
  font-family: "Noto Sans";
  font-weight: 500;
  text-align: center;

  background: #ed703e;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  .focused & {
    font-weight: 700;
    background: #fff;
    color: #ed703e;
  }
`;

const PriceAlert = styled.div`
  margin-top: 46px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: rgba(68, 67, 67, 0.55);
`;

const ProcessSection = styled.div`
  background-color: white;
  padding-top: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProcessTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-align: center;

  color: #000000;
`;

const ProcessSubTitle = styled.div`
  margin-top: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22.3012px;

  text-align: center;

  color: #4f4f4f;
`;

const ProcessListContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 109.73px;
  display: flex;
  flex-direction: row;
  gap: 104.16px;

  position: relative;
`;

const ProcessItem = styled.div`
  width: 305.9px;
  height: 343.81px;
  background: #fafafa;
  border-radius: 27.8764px;

  padding: 36.24px 0 0 39.88px;
`;

const ProcessIcon = styled.img`
  width: 103.22px;
  height: 103.22px;
`;

const ProcessName = styled.div`
  margin-top: 43.67px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;

  color: #000000;
`;

const ProcessContent = styled.div`
  margin-top: 29.47px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14.8674px;

  color: #4f4f4f;
`;

const ProcessArrow = styled.img`
  position: absolute;

  :nth-of-type(1) {
    left: 232.21px;
    top: 69.69px;
  }
  :nth-of-type(2) {
    right: 290.34px;
    top: 184px;
  }
`;

const FaqSection = styled.div`
  background-color: white;
  padding-top: 120px;
  padding-bottom: 120px;
`;

const FaqSubTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #e36f2d;
`;

const FaqTitle = styled.div`
  margin-top: 16px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;

  text-align: center;
  letter-spacing: -0.015em;

  color: #ed703e;
`;

const FaqListContainer = styled.div`
  margin: 48px 77px 0;
`;

const FaqItem = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 40px 32px;
  border-bottom: 1px solid #e7eaee;

  &.selected {
    background: #f6f7f9;
    border-radius: 6px;
    padding: 32px;
    margin-bottom: 24px;
    border: 0;
  }
`;

const FaqTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .selected & {
    padding: 8px 0;
  }
`;

const FaqItemTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: -0.015em;

  color: #07090d;
`;
const FaqItemContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: -0.03em;

  color: #3c4353;
  white-space: pre-line;
  margin-top: 16px;
`;

const FaqArrow = styled.img`
  width: 24px;
  height: 24px;
`;
