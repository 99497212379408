import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_brand.scss';
import { Box, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from "prop-types";
import AdminHeader from './AdminHeader';
import BrandManagementInfo from './BrandManagementInfo';
import BrandManagementComments from './BrandManagementComments';
import BrandManagementCustom from './BrandManagementCustom';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
};
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function BrandManagement () {
    const { app } = useSelector(({ common }) => ({
      app: common.app,
    }));

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const handleChangeIndex = (index) => {
        setValue(index);
    };


     
    
    return (
        <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
            <AdminHeader/>
            <div className="main-container">
                <div className="admin-brand__container">
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" aria-label="tabs">
                        <Tab label="가맹점 관리" {...a11yProps(0)} />
                        <Tab label="가맹사업 현황/본사 정보" {...a11yProps(1)} />
                        <Tab label="브랜드 정보" {...a11yProps(2)} />
                    </Tabs>
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            {/* 브랜드관리 - 가맹점 관리 탭 */}
                            <BrandManagementInfo />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            {/* 브랜드관리 - 가맹사업 현황/본사 정보 탭 */}
                            <BrandManagementComments />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            {/* 브랜드관리 - 브랜드 정보 탭 */}
                            <BrandManagementCustom />
                        </TabPanel>
                        
                    </SwipeableViews>
                </div>
            </div>
        </div>
    );
}

export default React.memo(BrandManagement);