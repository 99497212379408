import { createAction, handleActions } from 'redux-actions';
import * as type from '../types/commonType';

export const login = createAction(type.LOGIN, (form) => form);
export const loginCorp = createAction(type.LOGIN_CORP, (form) => form);
export const logout = createAction(type.LOGOUT);
export const app = createAction(type.APP);
export const popup = createAction(type.POPUP);
export const getUserInfo = createAction(type.GET_USERINFO);

const initialState = {
  auth: {
    accessToken: null,
    expiredAt: null,
    userInfo: {
      technicianId: '',
      name: '',
      birthdate: '',
      phoneNumber: '',
      email: '',
      dealerCode: '',
      dealerName: '',
      createdAt: '2021-01-05T16:57:29.255Z',
      pushAgree: false,
      emailAgree: false,
      brands: []
    },
    corpUserInfo: {
      id: '',
      name: '',
      email: '',
      rank: '',
      phoneNumber: '',
      receiveAlarmTalk: '',
      useService: '',
      userInfoAgree: '',
      markettingAgree: '',
      brandList: [],
      selectedBrand: null,
    }
  },
  app: {
    dimmed: {
      isActive: false,
    },
    class: null,
  },
  popup: {
    login: false, //로그인 화면
      loginUserType: true, //로그인 true:예비 창업자, false:기업고객
      loginView: false, //false: 최초화면 <-> true: 이메일 로그인 화면
    join : false, //회원가입 화면
      joinUserType: true, //회원가입 예비 창업자:true, 기업고객: false
      joinView: true, //최초화면: true, 입력필드: false
    comparison: false, //비교하기 브랜드
    favorite: false, //관심 브랜드
    findPassword: false, //비밀번호 찾기(일반 유저)
    findPasswordAdmin: false, //비밀번호 찾기(기업 고객)
    loginMessage: false,  //로그인 메시지 팝업
    searchBrand: false, //브랜드 검색(회원가입 창에서)
    usingRules: false, //이용약관
    userInfo: false, //개인정보처리방침
    marketing: false, //마케팅 정보 수신
  },
  error: null,
};

const common = handleActions(
  {
    [type.LOGIN]: (state, { payload }) => ({
      ...state,
      auth: {
        ...state.auth,
        userInfo: {
          ...state.auth.userInfo,
          ...payload,
        }
      },
    }),[type.LOGIN_CORP]: (state, { payload }) => ({
      ...state,
      auth: {
        ...state.auth,
        corpUserInfo: {
          ...state.auth.corpUserInfo,
          ...payload,
        }
      },
    }),[type.APP]: (state, { payload }) => ({
      ...state,
      app: {
        ...state.app,
        ...payload,
      },
    }),[type.POPUP]: (state, { payload }) => ({
      ...state,
      popup: {
        ...state.popup,
        ...payload,
      },
    }),
    [type.GET_USERINFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      auth: {
        ...state.auth,
        userInfo: payload,
      },
    }),
    [type.GET_USERINFO_FAILURE]: (state, { payload }) => ({
      ...state,
      auth: {
        ...state.auth,
        userInfo: {
          ...state.auth.userInfo,
          error: payload,
        },
      },
    }),
  },
  initialState,
);

export default common;
