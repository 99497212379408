import {
  Tab,
  Tabs,
  useTheme,
  Typography,
  Box,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { formattedNum } from "../../common/NumberUtil.js";
import {Bar} from "react-chartjs-2";
import cn from "classnames";
import {amountUnit} from "~/App";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const initButton = {
  sales: true,
  propit: false,
  netIncome: false,
  asset: false,
  capital: false,
  debt: false,
  percentage: false,
  si: false, //손익,
  js: false,
};

const initChartData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      //backgroundColor:['#FF662A','#FE662A','#FD662A','#FA662A'],
      barThickness: 12,
      data: [12, 19, 3, 5, 2, 3]
    },
  ],
};

const initChartOptions = {
  maintainAspectRatio: true,
  scales: {
    x: {
      grid:{
        display:false
      },
      // title: {
      //   text: '축 이름'
      // },
      ticks: {
        major: {
          enabled: true
        },
        color: (context) => '#FF662A',
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 400
            };
          }
        },
      }
    },
    y: {
      ticks: {
        align:'right',
        major: {
          enabled: true
        },
        color: (context) => {
          return "#B3B3B3";
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 100
            };
          }
        },
        callback: function(tickValue, index, ticks) {
          //console.log(tickValue);
          return tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
        // index, dataset, point, nearest(defalut), x, y
        mode: 'index',
        intersect: true,
        titleColor: '#333333',
        titleFont: {weight: 500},
        bodyColor: '#333333',
        bodyFont: {weight: 400},
        backgroundColor: '#ffffff',
        borderColor: '#d3dfe2',
        borderWidth: 1,
        usePointStyle: true,
        callbacks: {
          title: function(data) {
            //console.log(data);
            return data[0].label;
          },
          label: function(context) { 
            //console.log(context);
              var label = context.dataset.label || '';

              if (label) {
                  label += ' ';
              }
              if (context.parsed.y !== null) {
                  if ( context.datasetIndex === 3 ) {
                    label += context.parsed.y + '%';
                  } else {
                    const tickValue = context.parsed.y;
                    label += tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
                  }                  
              }
              return label;
          },
          labelColor: function(context) { //바디 젤 앞 이미지
            //console.log(context.datasetIndex);
            return {
                //borderColor: 'rgb(0, 0, 255)',
                backgroundColor: context.datasetIndex === 0 ? "rgb(108, 209, 130)" 
                                  : context.datasetIndex === 1 ? "rgb(220, 223, 226)"
                                  : context.datasetIndex === 2 ? "rgb(25, 58, 115)"
                                  : context.datasetIndex === 3 ? "#FF662A"
                                  : "#FF662A",
                borderWidth: 2,
                borderDash: [2, 2],
                borderRadius: 2,
            };
          },
          labelPointStyle: function(context) { //바디 젤 앞 이미지 모양
            return {
              pointStyle: 'circle',
              rotation: 0
            };
          }
        }
  
    }
  },
};

function SalesOfHeadQuaterItem({ salesOfHeadQuater, franchiseStartDate }) {
  //console.log(salesOfHeadQuater);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [data, setData] = useState(initChartData);
  const [options, setOptions] = useState(initChartOptions);
  const [button, setButton] = useState(initButton);

  let tempSalesOfHeadQuaterList = []; 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleDatas = (type) => {
    setButton({
        sales: type === "SALES" ? true : false,
        propit: type === "PROPIT" ? true : false,
        netIncome: type === "NET_INCOME" ? true : false,
        asset: type === "ASSET" ? true : false,
        capital: type === "CAPITAL" ? true : false,
        debt: type === "DEBT" ? true : false,
        percentage: type === "PERCENTAGE" ? true : false,
        si: type === "SI" ? true : false,
        jm: type === "JM" ? true : false
      });

    const yearArr = salesOfHeadQuater.map(i => i.year);

    //// start 무조건 10년 간의 내용 보여주게 처리 하려고 추가. 10년치와 0값 포함 10개
    const minYear = yearArr.reduce(( a, b ) => b < a ? b : a);

    const date = new Date();
    let nowYear = date.getFullYear();
    const tenYearArr = [];
    for(let i=0; i<10; i++ ) {
      if ( nowYear < minYear ) {
        tenYearArr.push(nowYear);
      }
      if ( !minYear ) {
        tenYearArr.push(nowYear);
      }
      nowYear--;
    }

    const emptyYears = tenYearArr.sort((a,b) => a-b);
    const emptyZeros = tenYearArr.map((a) => null);
    ////end

    const year = franchiseStartDate && franchiseStartDate.substring(0,4);

    setData({
      labels: emptyYears.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i, idx) => i.year === Number(year) ? [i.year, '사업개시']: i.year)),
      datasets: 
        type === "SALES" ? [
          {
            label : '매출',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.sales*amountUnit))
          }
        ] :
        type === "PROPIT" ? [
          {
            label : '영업이익',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.operatingProfit*amountUnit))
          }
        ] :
        type === "NET_INCOME" ? [
          {
            label : '당기순이익',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.netIncome*amountUnit))
          }
        ] :
        type === "ASSET" ? [
          {
            label : '자산',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.asset*amountUnit))
          }
        ] :
        type === "CAPITAL" ? [
          {
            label : '자본',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.capital*amountUnit))
          }
        ] :
        type === "DEBT" ? [
          {
            label : '부채',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.debt*amountUnit))
          }
        ] :
        type === "PERCENTAGE" ? [
          {
            label : '부채비율(부채/자본)',
            backgroundColor:'#FF662A',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => Math.round(((i.debt / i.capital) * 10000)) / 100))
          }
        ] :
        type === "SI" ? [
          {
            label : '매출',
            backgroundColor:'rgb(108, 209, 130)',
            barThickness: 12,
            type: 'bar',
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.sales*amountUnit))
          },
          {
            label : '영업이익',
            backgroundColor:'rgb(220, 223, 226)',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.operatingProfit*amountUnit))
          },
          {
            label : '당기순이익',
            backgroundColor:'rgb(25, 58, 115)',
            barThickness: 12,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.netIncome*amountUnit))
          }
        ] :
        type === "JM" ? [
          {
            label : '자산',
            backgroundColor:'rgb(108, 209, 130)',
            barThickness: 12,
            type: 'bar',
            order: 2,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.asset*amountUnit))
          },
          {
            label : '자본',
            backgroundColor:'rgb(220, 223, 226)',
            barThickness: 12,
            order: 2,
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.capital*amountUnit))
          },
          {
            label : '부채',
            backgroundColor:'rgb(25, 58, 115)',
            barThickness: 12,
            order: 3, 
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => i.debt*amountUnit))
          },
          {
            yAxisID: 'percentageScale',
            label : '부채비율(부채/자본)',
            backgroundColor:'#FF662A',
            borderColor: '#FF662A',
            borderWidth: 2,
            type: 'line',
            order: 1,//낮은 게 위쪽
            data: emptyZeros.concat(salesOfHeadQuater.sort((a,b) => a.year-b.year).map((i) => Math.round(((i.debt / i.capital) * 10000)) / 100))
          }
        ] : []
    });

    setOptions({
      ...initChartOptions,
      scales: {
        ...initChartOptions.scales,
        x: {
          grid:{
            display:false
          },
          ticks: {
            major: {
              enabled: true
            },
            color: (context) => {
              if ( emptyYears && emptyYears.filter( i => context.tick.label === i ).length > 0 ) {
                return '#E5E5E5';
              } else {
                return '#B3B3B3';
              }
            },
            font: function(context) {
              return {weight: 400};
            },
          }
        },
        y: {
          ticks: {
            major: {
              enabled: true
            },
            color: (context) => {
              return "#B3B3B3";
            },
            font: function(context) {
              if (context.tick && context.tick.major) {
                return {
                  weight: 100
                };
              }
            },
            callback: function(tickValue, index, ticks) {
              //console.log(ticks);
              return tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
            }
          }
        },
        percentageScale : { //custom 스케일
          type: 'linear', //linear, logarithmic, category, time, timeseries
          position: 'right', // `axis` is determined by the position as `'y'`
          grid:{
            display:false
          },
          ticks: {
            color: (context) => {
              return "#B3B3B3";
            },
            callback: function(tickValue, index, ticks) {
              return ticks[ticks.length-1].value === 1 ? null : tickValue + '%';
            }
          }
        }
      }
    });
  };

  if (salesOfHeadQuater != null && salesOfHeadQuater.length > 3) {

  salesOfHeadQuater.sort((a,b) => b.year-a.year);
    for (let i = 0; i < 3; i++) {
      const ok = salesOfHeadQuater[i];
      tempSalesOfHeadQuaterList.push(ok);
    }
  } else {
    tempSalesOfHeadQuaterList = salesOfHeadQuater;
  }

  useEffect(() => {
    handleDatas("SI");

    // eslint-disable-next-line
  }, [salesOfHeadQuater]);


  

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="tabs"
      >
        <Tab label="차트" {...a11yProps(0)} />
        <Tab label="재무제표" {...a11yProps(1)} />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className="brand-detail__head-quater-chart">
            <div className="brand-detail__head-quater-chart-button-group">
              <button className={cn({"active":button.si})} onClick={() => handleDatas('SI')}>손익계산서</button>
              <button className={cn({"active":button.jm})} onClick={() => handleDatas('JM')}>재무상태표</button>
              <button className={cn({"active":button.sales})} onClick={() => handleDatas("SALES")}>매출액</button>
              <button className={cn({"active":button.propit})} onClick={() => handleDatas("PROPIT")}>영업이익</button>
              <button className={cn({"active":button.netIncome})} onClick={() => handleDatas('NET_INCOME')}>당기순이익</button>
              <button className={cn({"active":button.asset})} onClick={() => handleDatas('ASSET')}>자산</button>
              <button className={cn({"active":button.capital})} onClick={() => handleDatas('CAPITAL')}>자본</button>
              <button className={cn({"active":button.debt})} onClick={() => handleDatas('DEBT')}>부채</button>
              <button className={cn({"active":button.percentage})} onClick={() => handleDatas('PERCENTAGE')}>부채비율(부채/자본)</button>
            </div>
            <div className="brand-detail__head-quater-chart-content">
              <Bar height={100} data={data} options={options} />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="brand-detail__financial-statements">
            <span className="brand-detail__title">재무재표 최근 3개년</span>
            <div>
              <span>년도</span>
              <span>매출액</span>
              <span>영업이익</span>
              <span>당기순이익</span>
              <span>자산</span>
              <span>자본</span>
              <span>부채</span>
              <span>부채비율</span>
            </div>

            {tempSalesOfHeadQuaterList.map((currSales) => (
              <HeadQuaterBS key={currSales.id} currSales={currSales} />
            ))}

            </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

export default SalesOfHeadQuaterItem;

function HeadQuaterBS (props) {
  const currSales = props.currSales;

  const sales = currSales.sales ? formattedNum(currSales.sales * amountUnit)  + "원": "";
  const operatingProfit = currSales.operatingProfit ? formattedNum(currSales.operatingProfit * amountUnit)  + "원": "";
  const netIncome = currSales.netIncome ? formattedNum(currSales.netIncome * amountUnit)  + "원": "";
  const asset = currSales.asset ? formattedNum(currSales.asset * amountUnit)  + "원": "";
  const capital = currSales.capital ? formattedNum(currSales.capital * amountUnit)  + "원": "";
  const debt = currSales.debt ? formattedNum(currSales.debt * amountUnit)  + "원": "";
  const debtRatio = currSales.capital ? Math.round(((currSales.debt / (currSales.capital == 0 ? 1 : currSales.capital)) * 10000)) / 100 + "%" : "";


  return (
    <div >
              <span>{currSales.year}</span>
              <span>{sales}</span>
              <span>{operatingProfit}</span>
              <span>{netIncome}</span>
              <span>{asset}</span>
              <span>{capital}</span>
              <span>{debt}</span>
              <span>{debtRatio}</span>
            </div>
  );
}
