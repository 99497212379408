import classNames from "classnames";
import React, { useEffect, useState } from "react";


function MapSearchResultText({text, searchKeyword} ) {

    const [array, setArray] = useState(null);

    useEffect(()=> {
        // console.log(text, searchKeyword, text.split(searchKeyword), text.split(searchKeyword).length);

        if ( text.split(searchKeyword).join('') !== '' ) {
            const len = text.split(searchKeyword).length;
            let ar = text.split(searchKeyword);
            let resultArr = [];

            for ( let i = 0 ; i< ar.length; i++ ) {
                const data = ar[i];
                const nextData = ar[i+1];
                if ( data === '') {
                    resultArr.push(searchKeyword);
                } else if ( data !== '' && i+1 !== len && (nextData && '' !== nextData)) {
                    resultArr.push(data);
                    resultArr.push(searchKeyword);
                } else {
                    resultArr.push(data);
                }
            }

            setArray(resultArr);
            
        } else {
            setArray([text]);
        }       
    }, [text, searchKeyword]);

    return (
        <>
        { array &&
            array.map((item, idx) => <span key={idx} className={classNames({"keyword-color": item === searchKeyword})}>{item}</span>)
        }
        </>
    );
}

export default React.memo(MapSearchResultText);