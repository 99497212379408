import React from "react";
import cn from "classnames";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseApp } from "~/App";
import IconPng from "../../../assets/images/icon/icon_login_google.png";

function GoogleLogin({ hide, callbackFunc }) {
  const onClickLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(firebaseApp);
    signInWithPopup(auth, provider)
      .then((result) => {
        if (result && result.user) {
          const { user } = result;
          const { displayName, email, uid } = user;
          callbackFunc(
            { profileObj: { name: displayName, email, id: uid } },
            "google"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <button
      className={cn("login__btn sns__google", { hide: !hide })}
      onClick={onClickLogin}
    >
      <img src={IconPng} />
      구글 아이디로 시작하기
      <div className="empty" />
    </button>
  );
}

export default React.memo(GoogleLogin);
