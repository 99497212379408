import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import "~/assets/scss/using_rules_popup.scss";
import { popup } from "~/sagas/actions/commonAction";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import styled from "styled-components";

function UsingRulesPopup() {
  const [notionBlockMap, setNotionBlockMap] = useState({});

  const dispatch = useDispatch();
  const { usingRules } = useSelector(
    ({ common }) => ({
      usingRules: common.popup.usingRules,
    }),
    shallowEqual
  );

  const closePopup = useCallback(() => {
    dispatch(
      popup({
        usingRules: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!usingRules) return;
    fetch(
      "https://notion-api.splitbee.io/v1/page/90ffbc5214cc4ad98293fb2a9140227d"
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setNotionBlockMap(res);
      });
  }, [usingRules]);

  return (
    <Modal open={usingRules}>
      <div className="using-rules-popup__container">
        <div className="using-rules-popup__title-group">
          <div className="using-rules-popup__title">이용약관</div>
          <button className="using-rules-popup__close-btn" onClick={closePopup}>
            close
          </button>
        </div>

        <NotionContainer>
          {Object.keys(notionBlockMap).length > 0 && (
            <NotionRenderer blockMap={notionBlockMap} fullPage={true} />
          )}
        </NotionContainer>

        {/*              <div className="using-rules-popup__content-group">*/}
        {/*                <h1>제 1창 총칙</h1>*/}
        {/*                <h2>제 1조 (목적)</h2>*/}
        {/*                <div className="using-rules-popup__content-content">*/}
        {/*                본 약관은 (주)마이프랜차이즈(이하 “회사”라 함)가 운영하는 마이프차 서비스(이하 “서비스”라 함)의 이용과 관련하여 회사와 이용자 및 이용자 간의 권리, 의무 및 책임사항, 서비스의 이용조건 및 절차, 기타 필요*/}
        {/*한 사항을 규정함을 목적으로 합니다. 마이프차 서비스라 함은 회사가 제공하는 “마이프차” 브랜드를 사용하는 서비스를 말합니다.*/}
        {/*                </div>*/}
        {/*                <h2>제 2조 (정의)</h2> */}
        {/*                <h3>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</h3>*/}
        {/*                <div>1. 이용자 : 마이프차에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 이용하는 자를 말하며 회원을 포함합니다.</div>*/}
        {/*                <div>2. 서비스 : 구현되는 단말기(PC, 모바일, 태블릿 PC등의 각종 유무선 장치를 포함)와 상관없이 이용자가 이용할 수 있는 마이프차 서비스를 의미합니다.</div>*/}
        {/*                <div>3. 회원 : 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</div>*/}
        {/*                <div>4. 브랜드 : 프랜차이즈 가맹본부(본사)의 가맹사업을 식별하는 사업 단위를 의미합니다.</div>*/}
        {/*                <div>5. 기업 고객 : 프랜차이즈 가맹본부(본사)에 소속된 직원으로서 마이프차 내 해당 프랜차이즈 브랜드 정보를 관리하기 위해 회원 등록을 한 자를 의미합니다.</div>*/}
        {/*                <div>6. 자료 : 회사가 수집한 각종 정보, 콘텐츠로서 서비스 상에 게시된 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일, 링크, 다운로드, 광고 등을 포함하여 본 서비스에 게시물 형태*/}
        {/*     로 포함되어 있거나, 본 서비스를 통해 배포, 전송되거나, 본 서비스로부터 접근되는 정보를 의미합니다.</div>*/}
        {/*                <div>7. 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 회원 본인의 문자와 숫자의 조합을 의미하며 회원이 설정한 이메일 주소 등이 포함됩니다.</div>*/}
        {/*                <div>8. 비밀번호 : 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자나 숫자의 조합을 말합니다.</div>*/}
        {/*                <div>9. 상담 내용 : 서비스에서 제공되는 정보에 대해 회사와 회원 간의 상담 내용을 의미합니다.</div>*/}
        {/*                <br/>*/}
        {/*                <h3>② 이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 관계법령 및 일반관례에 따릅니다.</h3>*/}

        {/*                <h2>제3조 (약관의 명시, 효력 및 변경)</h2>*/}
        {/*                */}
        {/*                <h3>① 회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 회사가 제공하는 서비스에 공지합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 이용자는 회사가 제공하는 서비스를*/}
        {/*      사용할 경우 이용약관에 동의한 것으로 간주합니다.</h3>*/}
        {/*                <h3>② 회사는 [약관의 규제에 관한 법률], [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</h3>*/}
        {/*                <h3>③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 이용자가 알기 쉽도록 표시하여 공지합니다.</h3>*/}
        {/*                <h3>④ 회사가 약관을 개정할 경우에는 변경된 약관은 공지된 시점부터 그 효력이 발생하며, 이용자는 약관이 변경된 후에도 본 서비스를 계속 이용함으로써 변경 후의 약관에 대해 동의를 한 것으로 간주됩니다.</h3>*/}
        {/*                <h3>⑤ 이용자는 개정약관에 동의하지 않을 경우 제8조 1항에 따라 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</h3>*/}
        {/*                <h3>⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계 */}
        {/*          법령 및 상관례에 따릅니다.</h3>*/}
        {/*              </div>*/}
      </div>
    </Modal>
  );
}

const NotionContainer = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding-bottom: 20px;

  .notion-page-header {
    display: none;
  }

  main {
    margin-top: 30px;
  }
`;

export default React.memo(UsingRulesPopup);
