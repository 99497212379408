import "react-app-polyfill/ie9";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

// REDUX
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer, { rootSaga } from "./sagas";
import createSagaMiddleware from "redux-saga";
// import { createLogger } from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
// const logger = createLogger();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
sagaMiddleware.run(rootSaga);

export default store;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>

      <SnackbarProvider maxSnack={3} 
            autoHideDuration={7000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
          >
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
