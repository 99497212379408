import { Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PopupStartUpConsultationApplication from './PopupStartUpConsultationApplication';

function WaitingBranchCard({
  brand,
  displayAddress,
  eventDetail,
  branch
  // open,
  // setOpen,
  // visibleOpen,
}) {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="brand-detail__recruit-content-group">
        <h1 className="brand-detail__recruit-content-area">{displayAddress}</h1>
        {eventDetail != null ? (
          <div className="brand-detail__recruit-content-event-group">
            <div className="brand-detail__recruit-content-event-badge">이벤트</div>
            <div className="brand-detail__recruit-content-event-text">{eventDetail}</div>
          </div>
        ) : null}
        <div className="brand-detail__recruit-content-btn-group">
          <Link className="brand-detail__recruit-content-btn-map" to={`/map/${brand.id}/${branch.id}/${brand.category.id}/${branch.latitude}/${branch.longitude}`} >
          <button>지도보기</button>
          </Link>
          <button className="brand-detail__recruit-content-btn-inquire" onClick={handleOpen}>가맹문의</button>
        </div>

        
      </div>
      <Modal open={open}>
        <PopupStartUpConsultationApplication brand={brand} close={handleClose} buttonName="가맹문의" />
      </Modal>
    </>
  );
}

export default WaitingBranchCard;
