import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_login.scss';
import { checkCorpUser, selectCorpUser } from '~/sagas/api/user';
import { loginCorpUserWithEmail } from '~/sagas/api/join';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { loginCorp, popup } from '~/sagas/actions/commonAction';
import AdminLoginHeader from './AdminLoginHeader';
import { Modal } from '@material-ui/core';

function AdminLogin () {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const { app } = useSelector(({ common }) => ({
      app: common.app,
    }));
    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    });
    const [msg, setMsg] = useState(false);

    const openJoinScreen = useCallback(() => {

        dispatch(
            popup({
                login: false,
                join: true,
                joinUserType: false,
                joinView: false
            }),
        );
    }, [dispatch]);

    const openFindPassword = useCallback(() => {
        dispatch(
            popup({
                findPasswordAdmin: true,
            }),
        );
    }, [dispatch]);

    const onChangeField = useCallback((e) => {
        const {name, value} = e.target;
        setInputs({...inputs, [name]:value});
    }, [inputs]);
    
    
    const openMsgPopup2 = useCallback(() => {
        setMsg(2);
    }, []);
    
    const openMsgPopup = useCallback(() => {
        setMsg(1);
    }, []);

    const closeMsgPopup = useCallback(() => {
        setMsg(0);
    }, []);

    const loginEvent = useCallback(async () => {
        const {email, password} = inputs;

        try {
            const res = await checkCorpUser({email:email, password:password});

            if ( res.status === 200) { //통신 정상

                if ( res.data ) { 
                    let jwtToken = await loginCorpUserWithEmail(email);

                    if (jwtToken.status === 200) {
                        
                        if ( jwtToken.data === false ) {
                            openMsgPopup();
                        } else {
                            let res = await selectCorpUser(jwtToken.data);

                            if ( res.status === 200 ) {

                                if ( !res.data.brandList || res.data.brandList.length === 0 ) {
                                    openMsgPopup2();
                                } else {
                                    localStorage.setItem("jwtTokenAdmin", jwtToken.data);
                                    
                                    res.data.brandList[0].isActive = true; //첫번째 선택 된 상태로 보냄.
        
                                    dispatch(loginCorp({...res.data, selectedBrand:res.data.brandList ? res.data.brandList[0] : null}));
                                    
                                    history.push("/corp/brand");

                                }

                            }

                        }

                    } else {
                        enqueueSnackbar("통신 문제가 발생했습니다. 관리자에게 문의해 주세요.", {
                            className:"snackbar warning",
                            autoHideDuration: 1000
                        });
                    }
                } else { // 로그인 정보 없음.
                    enqueueSnackbar("ID 혹은 비밀번호를 확인해 주세요.", {
                        className:"snackbar warning",
                        autoHideDuration: 1000
                    });
                }
            } else {
                enqueueSnackbar("회원이 아닙니다. 가입 후 로그인 해주세요.", {
                    className:"snackbar warning",
                    autoHideDuration: 1000
                });
            }
        } catch (e) {
            console.log(e);
            enqueueSnackbar("통신 문제가 발생했습니다. 관리자에게 문의해 주세요.", {
                className:"snackbar warning",
                autoHideDuration: 1000
            });
        }
    }, [dispatch, enqueueSnackbar, history, inputs]);

    const  loginEventByKeyboard = (e) => {
        if ( e.key === 'Enter' ) {
            loginEvent();
        }
    };

    return (
        <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
            <AdminLoginHeader />
            <div className={cn("admin-login__write_view")}>
                <div className="admin-login-title">
                    기업 고객 로그인
                </div>
                <div className="admin-login__input">
                    <div className="admin-login__email">
                        <input type="text" name="email" onChange={onChangeField}  onKeyPress={loginEventByKeyboard} className="admin-login__email-input-box" />
                        <span className="admin-login__email-title"> 이메일 주소</span>
                    </div>
                    <div className="admin-login__password">
                        <input type="password" name="password" onChange={onChangeField} onKeyPress={loginEventByKeyboard} className="admin-login__password-input-box" />
                        <span className="admin-login__password-title">비밀번호</span>
                    </div>
                </div>

                <button className="admin-login__btn" onClick={loginEvent}>로그인</button>

                <div className="admin-login__find-password">        
                    <span>비밀번호를 잊으셨나요?</span>
                    <button href="#" onClick={openFindPassword}>비밀번호 찾기</button>
                </div>
                <div className={cn("admin-login__corp-sign-up")}>
                    <span>기업 고객 지금 바로 무료가입</span>
                    <span onClick={openJoinScreen}>회원가입</span>
                </div>
            </div> 
            <Modal
                        open={msg}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">로그인 불가</div>
                            <button className="password-change__close-btn" onClick={closeMsgPopup}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="password-change__content-content">
                                {msg === 1 ? <>
                                    승인되지 않은 계정입니다.<br/>
                                    현재 똑사 관리자가 검토중입니다.<br />
                                    자세한 내용은 담당자에게 문의해주세요.
                                </>
                                : <>
                                    권한이 할당된 브랜드가 없습니다.<br/>
                                    자세한 내용은 담당자에게 문의해주세요.
                                </>
                                }
                            </div>
                            <div className="password-change__content-btn-group">
                                <div className="password-change__content-btn" onClick={closeMsgPopup}>확인</div>
                            </div>       
                        </div>
                    </div>
                </Modal>
        </div>
    );
}

export default AdminLogin;