import { takeLatest } from 'redux-saga/effects';
import * as api from './api/filter';
import createRequestSaga from './modules/createRequestSaga';
import * as type from './types/filterType';

const getBadgeFilterSaga = createRequestSaga(type.GET_FILTER_BADGE, api.getFilterListBadge);
const getThemeFilterSaga = createRequestSaga(type.GET_FILTER_THEME, api.getFilterListTheme);
const getCategoryFilterSaga = createRequestSaga(type.GET_FILTER_CATEGORY, api.getFilterListCategory);
const getSearchBrandSaga = createRequestSaga(type.GET_SEARCH_BRAND, api.getSearchBrand);

export default function* filterSaga() {
  yield takeLatest(type.GET_FILTER_BADGE, getBadgeFilterSaga);
  yield takeLatest(type.GET_FILTER_THEME, getThemeFilterSaga);
  yield takeLatest(type.GET_FILTER_CATEGORY, getCategoryFilterSaga);
  yield takeLatest(type.GET_SEARCH_BRAND, getSearchBrandSaga);
}
