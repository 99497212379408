import axios from "axios";
// SHOW_API_LOG
import { API_HOST, API_ROOT_PATH } from "../../constants";
// const url = `${API_HOST}${API_ROOT_PATH}${uri}`;
// SHOW_API_LOG && console.debug(`[${method}]`, uri);

const client = axios.create();
client.defaults.baseURL = `${API_HOST}${API_ROOT_PATH}`; // production
// client.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
client.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default client;
