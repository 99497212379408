import React, { useState, useEffect } from "react";
import cn from "classnames";
import "~/assets/scss/map.scss";
import { formattedNum, numberWithCommas } from "~/common/NumberUtil";
import { Link } from "react-router-dom";
import PopupStartUpConsultationApplication from "../brand/PopupStartUpConsultationApplication";
import { Box, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { amountUnit, analytics } from "~/App";
import { getBranchSales } from "~/sagas/api/brand";
import styled from "styled-components";
import AlertSvg from "assets/images/icon/priceplan_alert.svg";
import TipBGSvg from "assets/images/image_thirdlayer_tip.svg";
import { popup } from "~/sagas/actions/commonAction";
import PredictionResult from "~/components/PredictionResult";
import { logEvent } from "firebase/analytics";

function MapThirdBranchPopup({
  branch,
  branchDetailPopup,
  handleThirdBranchDetailPopup,
}) {
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));
  const [salesOfBranch, setSalesOfBranch] = useState(false); //최근 년도 한개 값

  useEffect(() => {
    if (branch && branch.salesOfBranchList) {
      let list = branch.salesOfBranchList;
      list.sort((a, b) => b.year - a.year); //년도 내림차순

      if (list && list.length > 0) {
        setSalesOfBranch(list[0]);
      }
    }
  }, [branch]);

  return (
    <div
      className={cn("map__branch-info-popup", { active: branchDetailPopup })}
    >
      <div className="map__branch__title-group">
        <div className="map__branch__title">지점 정보</div>
        <button
          className="map__branch__close-btn"
          onClick={handleThirdBranchDetailPopup}
        >
          close
        </button>
      </div>

      <div className="map__branch__content-group">
        <div className="map__branch-monthly-sales">
          <span>추정 월평균 매출액</span>
          <div>
            {branch && branch.monthlyEstimatedSales ? (
              <span>{formattedNum(branch.monthlyEstimatedSales) + "원"}</span>
            ) : (
              <span>정보없음</span>
            )}
          </div>
        </div>
        <div className="map__branch-monthly-sales">
          <span>
            <b>{branch && branch.region && branch.region.name}</b>지역 가맹점
            추정월평균 매출액
          </span>
          <div>
            {branch && branch.sameBrandSameAreaMonthlyEstimatedSales ? (
              <span>
                {formattedNum(branch.sameBrandSameAreaMonthlyEstimatedSales) +
                  "원"}
              </span>
            ) : (
              <span>정보없음</span>
            )}
          </div>
        </div>
        <div className="map__branch-monthly_sales33">
          <span>
            3.3m<sup>2</sup>(1평)당 추정 월평균 매출액
          </span>
          <div>
            {branch && branch.monthlyEstimatedSales && branch.area ? (
              <span>
                {formattedNum(
                  branch.monthlyEstimatedSales / (branch.area / 3.3)
                )}
                원
              </span>
            ) : (
              <span>정보없음</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function MapThirdLayer({
  brand,
  branch,
  isActive,
  branchDetailPopup,
  handleFirstLayer,
  handleSecondLayer,
  handleThirdLayer,
  handleThirdBranchDetailPopup,
}) {
  // console.log(brand);
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));
  const [open, setOpen] = useState(false); //창업상담 팝업
  const [salesOfBrand, setSalesOfBrand] = useState(false); //최근 년도 한개 값
  const [totalBranchCnt, setTotalBranchCnt] = useState(0); // 전체 매장수
  const [sameAreaBranchCnt, setSameAreaBranchCnt] = useState(0); // 지역내 매장수

  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));
  const [predictionData, setPredictionData] = useState(null);

  const handleOpen = () => {
    logEvent(analytics, "lefttab-click-inquiry");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (brand && brand.salesOfBrandList) {
      let list = brand.salesOfBrandList;
      list.sort((a, b) => b.year - a.year); //년도 내림차순

      if (list && list.length > 0) {
        setSalesOfBrand(list[0]);
      }
    }

    // console.log(brand);

    if (
      brand &&
      (brand.directRegionOfBrandList || brand.franchiseRegionOfBrandList) &&
      branch &&
      branch.region
    ) {
      // 예상매출
      getBranchSales(brand.id, branch.id)
        .then((res) => {
          if (res.status == 200) {
            if (
              res.data &&
              res.data.brandId &&
              res.data.branchId &&
              res.data.minValue &&
              res.data.maxValue &&
              res.data.year
            ) {
              setPredictionData(res.data);
            } else {
              setPredictionData(null);
            }
          }
        })
        .catch((err) => console.log(err));

      let cnt = 0;
      let totalCnt = 0;

      if (brand.directRegionOfBrandList) {
        const directList = brand.directRegionOfBrandList;

        directList.sort((a, b) => b.year - a.year);
        if (directList && directList.length > 0) {
          const directRegionCnt = directList[0];
          //console.log(directRegionCnt);
          totalCnt = directRegionCnt.total ? directRegionCnt.total : 0;

          switch (branch.region.name) {
            case "서울":
              cnt += directRegionCnt.seoul ? directRegionCnt.seoul : 0;
              break;
            case "부산":
              cnt += directRegionCnt.busan ? directRegionCnt.busan : 0;
              break;
            case "대구":
              cnt += directRegionCnt.daegu ? directRegionCnt.daegu : 0;
              break;
            case "인천":
              cnt += directRegionCnt.incheon ? directRegionCnt.incheon : 0;
              break;
            case "광주":
              cnt += directRegionCnt.gwangju ? directRegionCnt.gwangju : 0;
              break;
            case "대전":
              cnt += directRegionCnt.daejeon ? directRegionCnt.daejeon : 0;
              break;
            case "울산":
              cnt += directRegionCnt.ulsan ? directRegionCnt.ulsan : 0;
              break;
            case "세종":
              cnt += directRegionCnt.sejong ? directRegionCnt.sejong : 0;
              break;
            case "경기":
              cnt += directRegionCnt.gyeonggi ? directRegionCnt.gyeonggi : 0;
              break;
            case "강원":
              cnt += directRegionCnt.gangwon ? directRegionCnt.gangwon : 0;
              break;
            case "충북":
              cnt += directRegionCnt.chungbuk ? directRegionCnt.chungbuk : 0;
              break;
            case "충남":
              cnt += directRegionCnt.chungnam ? directRegionCnt.chungnam : 0;
              break;
            case "전북":
              cnt += directRegionCnt.jeonbuk ? directRegionCnt.jeonbuk : 0;
              break;
            case "전남":
              cnt += directRegionCnt.jeonnam ? directRegionCnt.jeonnam : 0;
              break;
            case "경북":
              cnt += directRegionCnt.gyeongbuk ? directRegionCnt.gyeongbuk : 0;
              break;
            case "경남":
              cnt += directRegionCnt.gyeongnam ? directRegionCnt.gyeongnam : 0;
              break;
            case "제주":
              cnt += directRegionCnt.jeju ? directRegionCnt.jeju : 0;
              break;
          }
        }
      }

      if (brand.franchiseRegionOfBrandList) {
        const franchise = brand.franchiseRegionOfBrandList;
        franchise.sort((a, b) => b.year - a.year);
        if (franchise && franchise.length > 0) {
          const franchiseRegionCnt = franchise[0];
          //console.log(franchiseRegionCnt);
          totalCnt += franchiseRegionCnt.total ? franchiseRegionCnt.total : 0;

          switch (branch.region.name) {
            case "서울":
              cnt += franchiseRegionCnt.seoul ? franchiseRegionCnt.seoul : 0;
              break;
            case "부산":
              cnt += franchiseRegionCnt.busan ? franchiseRegionCnt.busan : 0;
              break;
            case "대구":
              cnt += franchiseRegionCnt.daegu ? franchiseRegionCnt.daegu : 0;
              break;
            case "인천":
              cnt += franchiseRegionCnt.incheon
                ? franchiseRegionCnt.incheon
                : 0;
              break;
            case "광주":
              cnt += franchiseRegionCnt.gwangju
                ? franchiseRegionCnt.gwangju
                : 0;
              break;
            case "대전":
              cnt += franchiseRegionCnt.daejeon
                ? franchiseRegionCnt.daejeon
                : 0;
              break;
            case "울산":
              cnt += franchiseRegionCnt.ulsan ? franchiseRegionCnt.ulsan : 0;
              break;
            case "세종":
              cnt += franchiseRegionCnt.sejong ? franchiseRegionCnt.sejong : 0;
              break;
            case "경기":
              cnt += franchiseRegionCnt.gyeonggi
                ? franchiseRegionCnt.gyeonggi
                : 0;
              break;
            case "강원":
              cnt += franchiseRegionCnt.gangwon
                ? franchiseRegionCnt.gangwon
                : 0;
              break;
            case "충북":
              cnt += franchiseRegionCnt.chungbuk
                ? franchiseRegionCnt.chungbuk
                : 0;
              break;
            case "충남":
              cnt += franchiseRegionCnt.chungnam
                ? franchiseRegionCnt.chungnam
                : 0;
              break;
            case "전북":
              cnt += franchiseRegionCnt.jeonbuk
                ? franchiseRegionCnt.jeonbuk
                : 0;
              break;
            case "전남":
              cnt += franchiseRegionCnt.jeonnam
                ? franchiseRegionCnt.jeonnam
                : 0;
              break;
            case "경북":
              cnt += franchiseRegionCnt.gyeongbuk
                ? franchiseRegionCnt.gyeongbuk
                : 0;
              break;
            case "경남":
              cnt += franchiseRegionCnt.gyeongnam
                ? franchiseRegionCnt.gyeongnam
                : 0;
              break;
            case "제주":
              cnt += franchiseRegionCnt.jeju ? franchiseRegionCnt.jeju : 0;
              break;
          }
        }
      }

      setTotalBranchCnt(totalCnt);
      setSameAreaBranchCnt(cnt);
    }
  }, [brand, branch]);

  return (
    <div className={cn("mapAsideContainer-3dep", { active: isActive })}>
      <div className={cn("map__branch-info")}>
        <div className="map__branch-logo__container">
          <img
            src={
              brand && brand.logoImageUrlThumb
                ? brand.logoImageUrlThumb
                : "/placeholder_logo.png"
            }
            alt="LOGO"
            className="map__branch-logo"
          />
        </div>

        <div className="map__barnch-detail">
          <span>{brand && brand.category && brand.category.name}</span>
          <span>{branch && branch.name}</span>
        </div>
        <div className="map__badge-theme">
          {brand &&
            brand.brandBadge &&
            brand.brandBadge.map((item) => (
              <div key={item.badge.id} className="map__badge-image">
                {item.badge.name}
              </div>
            ))}
          {brand &&
            brand.themeBrand &&
            brand.themeBrand.map((item) => (
              <div key={item.theme.id} className="map__theme-image">
                {item.theme.shortName}
              </div>
            ))}
        </div>

        <div className="map__branch-costs">
          <div className="map__start-up-cost">
            <span>창업비용</span>
            <div>
              {brand && brand.startUpCost ? (
                <>
                  <span>
                    {brand &&
                      brand.startUpCost &&
                      formattedNum(brand.startUpCost.total * amountUnit)}
                    원
                  </span>
                  {brand && brand.startUpCost.baseArea != 0 && (
                    <span>
                      {brand.startUpCost &&
                        parseInt(brand.startUpCost.baseArea * 3.3, 10)}
                      m<sup>2</sup>({brand.startUpCost.baseArea}
                      평) 기준
                    </span>
                  )}
                  <Link
                    to={`/brand/${brand && brand.id}`}
                    target="_blank"
                    className="detail"
                  >
                    자세히 보기
                  </Link>
                </>
              ) : (
                <span>정보없음</span>
              )}
            </div>
          </div>
          {/* <div className="map__monthly_sales">
            <span>월평균 매출액</span>
            <div>
              <span>{salesOfBrand ? formattedNum(salesOfBrand.avgMonthlySales) + '원' : '정보없음'}</span>
            </div>
          </div>
          <div className="map__monthly_sales33">
            <span>3.3m<sup>2</sup>(1평)당 월평균 매출액</span>
            <div>
              <span>{salesOfBrand ? formattedNum(salesOfBrand.avgMonthlySalesArea) + '만원' : '정보없음'}</span>
            </div>
          </div> */}
          <div className="map__branch-total-cnt">
            <span>전체 매장</span>
            <div>
              {totalBranchCnt ? (
                <span>{formattedNum(totalBranchCnt)}개</span>
              ) : (
                <span>정보없음</span>
              )}
            </div>
          </div>
          <div className="map__branch-total-cnt">
            <span>
              <b>{branch && branch.region && branch.region.name}</b>지역내
              매장수
            </span>
            <div>
              {sameAreaBranchCnt != null ? (
                <span>{sameAreaBranchCnt}개</span>
              ) : (
                <span>정보없음</span>
              )}
            </div>
          </div>
          <PredictionContainer>
            <PredictionTitle>지점 추정 매출</PredictionTitle>
            <PredictionResult
              isLogin={userInfo.email}
              predictionData={predictionData}
            />

            <PredictionAlertContainer>
              {userInfo.email && (
                <>
                  <img src={AlertSvg} alt="alert icon" />
                  지점 정보와 매출 추이, 평단가 등의 데이터를 똑사 AI가
                  계산했어요. 실제 현황과 다를 수 있으니 참고 용도로
                  활용해주세요
                </>
              )}
            </PredictionAlertContainer>
          </PredictionContainer>
        </div>

        {/* <div className="map__button-check-group">
          <button className="map__button map__button--sales-check" onClick={handleThirdBranchDetailPopup}>매출액 확인</button>
          <button className="map__button map__button--share-check">공유</button>
        </div> */}

        <Link to={`/brand/${brand && brand.id}`} target="_blank">
          <button className="map__button map__button--brand-intro">
            브랜드 소개
          </button>
        </Link>
        <button
          className="map__button map__button--franchise-application"
          onClick={handleOpen}
        >
          창업상담 신청
        </button>
      </div>

      <Modal open={open}>
        <Box>
          <PopupStartUpConsultationApplication
            brand={brand}
            buttonName="맵에서 상담 신청하기"
            close={handleClose}
          />
        </Box>
      </Modal>

      <Modal open={branchDetailPopup}>
        <Box>
          <MapThirdBranchPopup
            branch={branch}
            branchDetailPopup={branchDetailPopup}
            handleThirdBranchDetailPopup={handleThirdBranchDetailPopup}
          />
        </Box>
      </Modal>
    </div>
  );
}

const PredictionContainer = styled.div`
  margin-top: 8px;
`;

const PredictionTitle = styled.div`
  font-size: 12px;
  color: #747474;
`;

const PredictionAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 6px;
  margin-top: 9px;

  font-weight: 500;
  font-size: 10px;
  color: #bebcbc;

  min-height: 30px;
`;

export default React.memo(MapThirdLayer);
