// import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import '~/assets/scss/noMatch.scss';

function NoMatch() {
  // let location = useLocation();

  return (
    <div className="no-match__container">
      <div className="no-match__section">
        {/* No match for <code>{location.pathname}</code> */}
        <div className="no-match__alram">알림</div>
        <div className="no-match__title">404 Page Not Found</div>
        <div className="no-match__content">
          페이지 주소가 정확하지 않거나<br/>
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.<br />
          이 오류가 계속 발생 할 경우 관리자에게 문의하십시오.
        </div>
        <Link to="/map" className="no-match__go-home">홈으로 돌아가기</Link>
      </div>
    </div>
  );
}

export default NoMatch;
