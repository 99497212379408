import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getRegionList,
  setRegionActive,
} from "~/sagas/api/admin/branch_management";

export default function SalesActiveScreen() {
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getRegionList().then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRegionList(data); //목록
      }
    });
  };

  const onCheck = (id, checked) => {
    setRegionActive(
      id,
      { active: checked },
      localStorage.getItem("jwtTokenAdmin")
    ).then((response) => {
      if (response.status === 200) {
        getData();
      }
    });
  };

  return (
    <Container>
      <ul>
        {regionList.map((ele) => (
          <RegionItem key={ele.id}>
            <FormGroup>
              <FormControlLabel
                id={ele.id}
                control={<Checkbox checked={ele.active} />}
                label={ele.name}
                onChange={(e) => {
                  onCheck(ele.id, e.target.checked);
                }}
              />
            </FormGroup>
          </RegionItem>
        ))}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 24px;
`;

const RegionItem = styled.li`
  display: flex;
  flex-direction: row;
`;
