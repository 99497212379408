// import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CategoryList from '~/components/listitems/CategoryList';
import SelectArea from '~/components/SelectArea';
import SelectCost from '~/components/SelectCost';
import SelectSales from '~/components/SelectSales';
import SelectTheme from '~/components/SelectTheme';
import { filterForm } from '~/sagas/actions/filterAction';
import PopOver from '../Popover';

const Filters = ({ folding, onSubmit, onClickDelete, changeEvent }) => {
  const dispatch = useDispatch();
  const { theme, badge, category, form } = useSelector(({ filter }) => ({
    theme: filter.form.selectedTheme,
    badge: filter.form.selectedBadge,
    category: filter.form.selectedCategory,
    form: filter.form,
  }), shallowEqual);


  // const closePopup = () => {

  // };

  const getThemeTitle = useCallback(() => {
    const checkedList = theme.filter(i=>i.checked);

    if ( checkedList && checkedList.length > 0 ) {
      if ( checkedList.length === 1 ) {
        return checkedList[0].name;
      } else {
        return checkedList[0].name + ' 외 ' + (checkedList.length-1);
      }
    } else {
      return "선택하세요."

    }
  }, [theme]);

  const getThemeContainerStyle = useCallback(() => {
    const checkedList = theme.filter(i=>i.checked);

    if ( checkedList && checkedList.length > 0 ) {
      return true
    } else {
      return false

    }
  }, [theme]);

  const getBadgeTitle = useCallback(() => {
    const checkedList = badge.filter(i=>i.checked);

    if ( checkedList && checkedList.length > 0 ) {
      if ( checkedList.length === 1 ) {
        return checkedList[0].name;
      } else {
        return checkedList[0].name + ' 외 ' + (checkedList.length-1);
      }
    } else {
      return "선택하세요."

    }
  }, [badge]);


  const getBadgeContainerStyle = useCallback(() => {
    const checkedList = badge.filter(i=>i.checked);

    if ( checkedList && checkedList.length > 0 ) {
      return true
    } else {
      return false

    }
  }, [badge]);

  const getStartupCostContainerStyle = useCallback(() => {
    if ( form.isCheckedStartUpCost ) {
      return true
    } else {
      return false

    }
  }, [form]);
  const getSalesContainerStyle = useCallback(() => {
    if ( form.isCheckedMonth ) {
      return true
    } else {
      return false

    }
  }, [form]);
  const getAreaContainerStyle = useCallback(() => {
    if ( form.isCheckedArea ) {
      return true
    } else {
      return false

    }
  }, [form]);
  
  const resetThemeList = () => {
    dispatch(
      filterForm({
        ...form,
        selectedTheme: form.selectedTheme.map((i) => ({
          ...i,
          checked: false,
        })),
      }),
    );
  };
  const resetBadgeList = () => {
    dispatch(
      filterForm({
        ...form,
        selectedBadge: form.selectedBadge.map((i) => ({
          ...i,
          checked: false,
        })),
      }),
    );
  };
  
  const onStartupCostChange = useCallback((e, newValue) => {
    changeEvent.onClickRange('rangeStartupCost', newValue, (newValue[0] === 0 && newValue[1] === 500000000) ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onSalesChange = useCallback((e, newValue) => {
    changeEvent.onClickRange('rangeSale', newValue, (newValue[0] === 0 && newValue[1] === 500000000) ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onAreaChange = useCallback((e, newValue) => {
    changeEvent.onClickRange('rangeArea', newValue, (newValue[0] === 0 && newValue[1] === 156) ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);
  
  return (
    <div className={cn("filter-container", {expanded: folding.foldingStatus})}>
      <form onSubmit={onSubmit}>
        <div className="sectors-container">
          <div className="section">
            <div className="inner">
              <div className="row">
                <CategoryList data={category} onChange={changeEvent.onChangeCategory} />
              </div>

              <div className="row">
                <dl className={cn("desc-primary")}>
                  <dt>테마</dt>
                  <dd className={cn({added: getThemeContainerStyle()})}>
                    <PopOver type="select" 
                            title={getThemeTitle()}
                            buttonStyle="select outlined" 
                            containerClass="selector-container"
                            // onClickConfirm={closePopup}
                            >
                      <SelectTheme item={theme} 
                      onChange={changeEvent.onChangeTheme}
                      onClickReset={resetThemeList}
                       />
                    </PopOver>
                  </dd>
                </dl>
                <dl className={cn("desc-primary")}>
                  <dt>뱃지</dt>
                  <dd className={cn({added: getBadgeContainerStyle()})}>
                    <PopOver type="select" 
                            title={getBadgeTitle()}
                            buttonStyle="select outlined" 
                            containerClass="selector-container"
                            // onClickConfirm={closePopup}
                            >
                      <SelectTheme item={badge} 
                      onChange={changeEvent.onChangeBadge}
                      onClickReset={resetBadgeList}
                       />
                    </PopOver>
                  </dd>
                </dl>
                <dl className="desc-primary">
                  <dt>필터</dt>
                  <dd className={cn({added: getStartupCostContainerStyle()})}>
                    <PopOver
                      onClickConfirm={changeEvent.onClickRange}
                      title="창업비용"
                      value=""
                      buttonStyle="select outlined"
                      containerClass="range-container"
                    >
                      <SelectCost value={[form.fromStartUpCost, form.toStartUpCost]}
                                  onConfirm={onStartupCostChange}
                                  />
                    </PopOver>
                  </dd>
                  <dd className={cn({added: getSalesContainerStyle()})}>
                    <PopOver
                      onClickConfirm={changeEvent.onClickRange}
                      title="매출액"
                      value=""
                      buttonStyle="select outlined"
                      containerClass="range-container"
                    >
                      <SelectSales value={[form.fromMonth, form.toMonth]} 
                                  onConfirm={onSalesChange}
                                  />
                    </PopOver>
                  </dd>
                  <dd className={cn({added: getAreaContainerStyle()})}>
                    <PopOver
                      onClickConfirm={changeEvent.onClickRange}
                      title="매장면적"
                      value=""
                      buttonStyle="select outlined"
                      containerClass="range-container"
                    >
                      <SelectArea value={[form.fromArea, form.toArea]} 
                                  onConfirm={onAreaChange}
                                  />
                    </PopOver>
                  </dd>
                </dl>
              </div>
            </div>
            {/* <CategoryList /> */}
            {/* <Filter /> */}
          </div>
        </div>

      </form>

      <div className={cn("filter__folding-arrow", { active: folding.showOnFolding})} onClick={changeEvent.filterFoldingEvent}>
        <div className={cn({"btn--arrow-down":!folding.foldingStatus, "btn--arrow-up":folding.foldingStatus})}>
            전체접기
        </div>
      </div>
    </div>
  );
};

export default React.memo(Filters);
