import React from "react";
import ManagerComment from "./ManagerComment";
import FranchiseIncreaseTrendItem from "./FranchiseIncreaseTrendItem";

function FranchiseIncreaseTrend({ increaseHistoryList, franchiseStartDate, logo, comment, hideComment }) {
  if (increaseHistoryList.length < 1) return null;

  //console.log(salesOfHeadQuater);

  return (
    <div className="brand-detail__franchise-increase-trend">
      {
        !hideComment &&
        <span className="brand-detail__title">가맹점 증감추이</span>
      }

      {/* <span className="brand-detail__sub-title">
        {salesOfHeadQuater != null && salesOfHeadQuater.length > 0 ? (
              <div>{salesOfHeadQuater[0].year}년 정보공개서 기준</div>
            ) : null}
      </span> */}
    
      <FranchiseIncreaseTrendItem increaseHistoryList={increaseHistoryList} franchiseStartDate={franchiseStartDate}/>

      {
        !hideComment && comment && comment.affiliateStoreGrowthTrendComment &&
        <ManagerComment key="affiliateStoreGrowthTrendComment" logo={logo} comment={comment.affiliateStoreGrowthTrendComment} name={comment.userName} modifyAt={comment.modifyAt}/>
      }
      

    </div>
  );
}

export default React.memo(FranchiseIncreaseTrend);
