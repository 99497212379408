import React from "react";
import cn from "classnames";
import { Popover } from "@material-ui/core";
import { useSelector } from "react-redux";

function MapSecondLayerItem({ branch, checkedBranchItem, highlightBranchItem, handleThirdLayer, moveToCenterAtMap }) {

  const onClick = (branchId) => {
    checkedBranchItem(branchId);
    handleThirdLayer();

    moveToCenterAtMap(branch.latitude, branch.longitude); //move to center at map
  };

  return (
    <div id={`item${branch.id}`} onMouseEnter={() => highlightBranchItem(branch.id)} className={cn("map__list-item", { checked: branch.checked })} onClick={() => onClick(branch.id)}>
      <div className="map__item_location">
        <i>marker</i>
        <div className="map__branch_area">{branch.address}</div>
      </div>
      <div className="map__branch_name">{branch.name}</div>
    </div>
  );
}

function MapSecondLayer({ brandList, branchList, isActive, checkedBranchItem, highlightBranchItem, handleFirstLayer, handleSecondLayer, handleThirdLayer, moveToCenterAtMap, sortBranch, sortingBranchEvent, recruitType, sortingBranchRecruitEvent }) {
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRecruit, setAnchorElRecruit] = React.useState(null);

  const openDropdownEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropdownEvent = (value) => {
    handelClose();
    sortingBranchEvent(value);
  };
  const handelClose = () => {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const openRecruitDropdownEvent = (event) => {
    setAnchorElRecruit(event.currentTarget);
  };

  const closeRecruitDropdownEvent = (value) => {
    handelRecruitClose();
    sortingBranchRecruitEvent(value);
  };
  const handelRecruitClose = () => {
    setAnchorElRecruit(null);
  }
  const openRecruit = Boolean(anchorElRecruit);
  const idRecruit = openRecruit ? 'simple-popover2' : undefined;

  return (
    <div className={cn("mapAsideContainer-2dep", { "active": isActive })}>

      <div className="map__branch-list">
        <div className="map__title">
          <span>지점</span>
          <div className="map__order-group">

            <button onClick={openRecruitDropdownEvent} className="map__order-group-select-box no-icon">
              {
                recruitType === "ALL" ? "전체" :
                  recruitType === "OPERATING" ? "운영중" :
                    recruitType === "DUE_OPEN" ? "오픈예정" :
                      recruitType === "CLOSE_DOWN" ? "폐점" :
                        recruitType === "RECRUITING" ? "모집중"
                          : "매장 넓은 순"
              }
            </button>

            <button onClick={openDropdownEvent} className="map__order-group-select-box">
              {
                sortBranch === "AREA_BIG" ? "매장 넓은 순" :
                  sortBranch === "AREA_SMALL" ? "매장 좁은 순" :
                    sortBranch === "NAME_DESC" ? "지점명 내림차순" :
                      sortBranch === "NAME_ASC" ? "지점명 오름차순" :
                        sortBranch === "OPEN_PERIOD_PAST" ? "개업일 빠른순" :
                          sortBranch === "OPEN_PERIOD_CURRENT" ? "개업일 늦은순" :
                            "매장 넓은 순"
              }
            </button>

            <Popover
              id={idRecruit}
              open={openRecruit}
              anchorEl={anchorElRecruit}
              onClose={handelRecruitClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div id="map__order-group-select-box-container">
                <span className={cn("map__order-group-select-box-item", { active: recruitType === "ALL" })} onClick={() => closeRecruitDropdownEvent("ALL")}>전체</span>
                <span className={cn("map__order-group-select-box-item", { active: recruitType === "OPERATING" })} onClick={() => closeRecruitDropdownEvent("OPERATING")}>운영중</span>
                <span className={cn("map__order-group-select-box-item", { active: recruitType === "DUE_OPEN" })} onClick={() => closeRecruitDropdownEvent("DUE_OPEN")}>오픈예정</span>
                <span className={cn("map__order-group-select-box-item", { active: recruitType === "CLOSE_DOWN" })} onClick={() => closeRecruitDropdownEvent("CLOSE_DOWN")}>폐점</span>
                <span className={cn("map__order-group-select-box-item", { active: recruitType === "RECRUITING" })} onClick={() => closeRecruitDropdownEvent("RECRUITING")}>모집중</span>
              </div>
            </Popover>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handelClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div id="map__order-group-select-box-container">
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "AREA_BIG" })} onClick={() => closeDropdownEvent('AREA_BIG')}>매장 넓은 순</span>
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "AREA_SMALL" })} onClick={() => closeDropdownEvent('AREA_SMALL')}>매장 좁은 순</span>
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "NAME_ASC" })} onClick={() => closeDropdownEvent('NAME_ASC')}>지점명 오름차순</span>
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "NAME_DESC" })} onClick={() => closeDropdownEvent('NAME_DESC')}>지점명 내림차순</span>
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "OPEN_PERIOD_PAST" })} onClick={() => closeDropdownEvent('OPEN_PERIOD_PAST')}>개업일 빠른순</span>
                <span className={cn("map__order-group-select-box-item", { active: sortBranch === "OPEN_PERIOD_CURRENT" })} onClick={() => closeDropdownEvent('OPEN_PERIOD_CURRENT')}>개업일 늦은순</span>
              </div>
            </Popover>
          </div>
        </div>

        <div className="map__list-container">
          <div className="map__list-item-container">

            {branchList && branchList.filter(branch => {
              if (recruitType === 'ALL') {
                return true;
              } else if (recruitType === "OPERATING" && branch.isRecruit === 'OPERATING') {
                return true;
              } else if (recruitType === "DUE_OPEN" && branch.isRecruit === 'DUE_OPEN') {
                return true;
              } else if (recruitType === "CLOSE_DOWN" && branch.isRecruit === 'CLOSE_DOWN') {
                return true;
              } else if (recruitType === "RECRUITING" && branch.isRecruit === 'RECRUITING') {
                return true;
              } else {
                return false;
              }
            }).map((branch) =>
              <MapSecondLayerItem key={branch.id} branch={branch} checkedBranchItem={checkedBranchItem} highlightBranchItem={highlightBranchItem} handleThirdLayer={handleThirdLayer} moveToCenterAtMap={moveToCenterAtMap} />
            )}


          </div>
        </div>

      </div>
    </div>
  );
};

export default React.memo(MapSecondLayer);
