import React from 'react';
import {Modal} from '@material-ui/core';
import {useSelector} from 'react-redux';
import styled from "styled-components";

function Loading() {
    const {open} = useSelector(({loading}) => ({
        open: loading.open
    }));

    return (
        <Modal
            open={open}
        >
            <Container>
                <section>
                    <span className="loader"></span>
                </section>
                AI가 예상 매출을 계산하고 있습니다
            </Container>
        </Modal>
    );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  gap: 16px;

  color: white;
  font-family: "Noto Sans";
`;


export default React.memo(Loading);
