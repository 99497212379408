import { takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./api/list";
import * as type from "./types/listType";
import createRequestSaga from "./modules/createRequestSaga";

const getBrandListSaga = createRequestSaga(type.GET_BRAND_LIST, api.getList);
const getThemeListSaga = createRequestSaga(type.GET_THEME_LIST, api.getList);

export default function* listSaga() {
  yield takeLatest(type.GET_BRAND_LIST, getBrandListSaga);
  yield takeEvery(type.GET_THEME_LIST, getThemeListSaga);
}
