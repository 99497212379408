const paymentBank = [
  {
    bankName: "경남은행",
    id: 39,
  },
  {
    bankName: "광주은행",
    id: 34,
  },
  {
    bankName: "단위농협(지역농축협)",
    id: 12,
  },
  {
    bankName: "부산은행",
    id: 32,
  },
  {
    bankName: "새마을금고",
    id: 45,
  },
  {
    bankName: "산림조합",
    id: 64,
  },
  {
    bankName: "신한은행",
    id: 88,
  },
  {
    bankName: "신협",
    id: 48,
  },
  {
    bankName: "씨티은행",
    id: 27,
  },
  {
    bankName: "우리은행",
    id: 20,
  },
  {
    bankName: "우체국예금보험",
    id: 71,
  },
  {
    bankName: "저축은행중앙회",
    id: 50,
  },
  {
    bankName: "전북은행",
    id: 37,
  },
  {
    bankName: "제주은행",
    id: 35,
  },
  {
    bankName: "카카오뱅크",
    id: 90,
  },
  {
    bankName: "토스뱅크",
    id: 92,
  },
  {
    bankName: "하나은행",
    id: 81,
  },
  {
    bankName: "홍콩상하이은행",
    id: 54,
  },
  {
    bankName: "IBK기업은행",
    id: "03",
  },
  {
    bankName: "KB국민은행",
    id: "06",
  },
  {
    bankName: "DGB대구은행",
    id: 31,
  },
  {
    bankName: "KDB산업은행",
    id: "02",
  },
  {
    bankName: "NH농협은행",
    id: 11,
  },
  {
    bankName: "SC제일은행",
    id: 23,
  },
  {
    bankName: "Sh수협은행",
    id: "07",
  },
];

export const getBankName = (id) => {
  const bank = paymentBank.find((item) => item.id == id);
  return bank ? bank.bankName : "";
};
