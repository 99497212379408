import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "~/assets/scss/default.scss";
import FilterContainer from "./components/container/FilterContainer";
import NoMatch from "./NoMatch";
import MenuWithScreen from "./screen/home/MenuWithScreen";
import LoginScreen from "./screen/login/LoginScreen";
import MyPage from "./screen/login/MyPage";
import LoginMessagePopup from "./screen/popup/LoginMessagePopup";
import FindPasswordModal from "./screen/popup/FindPasswordModal";
import FindPasswordAdminModal from "./screen/popup/FindPasswordAdminModal";
import JoinScreen from "./screen/join/JoinScreen";
import UsingRulesPopup from "./screen/popup/UsingRulesPopup";
import UserInfoAcceptRule from "./screen/popup/UserInfoAcceptRule";
import MarketingInfoRecieve from "./screen/popup/MarketingInfoRecieve";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { login, loginCorp, popup } from "~/sagas/actions/commonAction";
import JoinComplete from "./screen/join/JoinComplete";
import {
  getFilterListBadge,
  getFilterListCategory,
  getFilterListTheme,
} from "./sagas/actions/filterAction";
import { selectCorpUser, selectUser } from "./sagas/api/user";
import ComparisonBrand from "./screen/popup/ComparisonBrand";
import BrandManagement from "./screen/admin/BrandManagement";
import BranchManagement from "./screen/admin/BranchManagement";
import FranchiseManagement from "./screen/admin/FranchiseManagement";
import AdminLogin from "./screen/admin/AdminLogin";
import AdminMyPage from "./screen/admin/AdminMyPage";
import { useHistory } from "react-router";
import cn from "classnames";
import NaverLoginCallback from "./screen/login/sns/NaverLoginCallback";
import AddBrandAllByExcel from "./screen/superAdmin/AddBrandAllByExcel";
import Loading from "./screen/popup/Loading";
import RouteScreen from "./screen/home/RouteScreen";
import useSmartlook from "./common/useSmartlook";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PrivacyScreen from "./screen/term/PrivacyScreen";
import IntroduceScreen from "./screen/introduce";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import PaymentScreen from "./screen/payment";
import PaymentSuccessScreen from "./screen/payment/PaymentSuccess";
import PaymentFailedScreen from "./screen/payment/PaymentFailed";
import useQuery from "./common/useQuery";
import SettingScreen from "./screen/admin/setting/SettingScreen";

const firebaseConfig = {
  apiKey: "AIzaSyDoVFZepRKf1QNLM5RRyQ10_UfihMytyJE",
  authDomain: "ttoksa-d0dab.firebaseapp.com",
  projectId: "ttoksa-d0dab",
  storageBucket: "ttoksa-d0dab.appspot.com",
  messagingSenderId: "754152445159",
  appId: "1:754152445159:web:6ae090d813f956987b3e42",
  measurementId: "G-JXD5TJ3G6C",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

export const amountUnit = 1000; //금액 단위

const App = () => {
  const history = useHistory();
  const searchParams = useQuery();
  const dispatch = useDispatch();
  const { auth } = useSelector(({ common }) => ({
    auth: common.auth,
  }));

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState(null);

  useSmartlook();

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        signInAnonymously(firebaseAuth);
      }
    });
    if (searchParams.get("dev") == "toss") {
      localStorage.setItem(
        "jwtToken",
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiLrsJXspIDtmIEiLCJpZCI6MzEsImV4cCI6MTY5ODMzOTk5MiwiZW1haWwiOiJqaC5wYXJrQHBpY21lLmtyIiwidXNlcm5hbWUiOiLrsJXspIDtmIEifQ.tDV_fXdfiEHOBYkxaSJcy28TwcWN8nqhbRgjVzSIm7zKd5XiEadDqrXYNJB-5Alpdxb1OZ343e-b7REiBj8Xbg"
      );
      history.location.pathname = "/";
    }
  }, []);

  const openToast = (msg) => {
    setMessage(msg);
    setToast(true);
    openChatting();
  };

  const closeToast = () => {
    setToast(false);
  };

  const openFavoriteBrand = (e) => {
    //로그인 여부 확인 후
    if (!auth.userInfo || !auth.userInfo.email) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();
    } else {
      //로그인 되어 있으면
      dispatch(
        popup({
          comparison: true,
        })
      );
    }
  };
  const openChatting = () => {
    console.log("특정 카카오톡 링크로 이동");
  };

  const getUser = useCallback(async () => {
    let res = await selectUser(localStorage.getItem("jwtToken"));
    // console.log(res.data);
    dispatch(login(res.data));
  }, [dispatch]);

  const getCorpUser = useCallback(async () => {
    let res = await selectCorpUser(localStorage.getItem("jwtTokenAdmin"));
    // console.log(res.data);
    dispatch(
      loginCorp({
        ...res.data,
        selectedBrand: res.data.brandList ? res.data.brandList[0] : null,
      })
    );
  }, [dispatch]);

  //로그인 팝업 띄우기
  const openLoginMessagePopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: true,
      })
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    dispatch(getFilterListCategory());
    dispatch(getFilterListTheme());
    dispatch(getFilterListBadge());
  }, [dispatch]);

  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const currentEpochTime = () => {
    return Math.floor(Date.now() / 1000) + 60 * 60;
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token == "test") return;
    if (token && token != "null") {
      const jwtPayload = parseJwt(token); //header, payload, signature 중 payload

      //토큰시간이 만료 되었으면 localStorage에서 삭제
      if (jwtPayload.exp < currentEpochTime()) {
        localStorage.removeItem("jwtToken");
      } else {
        getUser();
      }
    }

    const token2 = localStorage.getItem("jwtTokenAdmin");

    if (token2 && token2 != "null") {
      const jwtPayload = parseJwt(token2); //header, payload, signature 중 payload

      //토큰시간이 만료 되었으면 localStorage에서 삭제
      if (jwtPayload.exp < currentEpochTime()) {
        localStorage.removeItem("jwtTokenAdmin");
      } else {
        getCorpUser();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path="/joincomplete" component={JoinComplete} exact />
        <Route path="/mypage" component={MyPage} exact />
        {/* <Route path="/" component={HomeScreen} exact /> */}
        <Route path="/" component={RouteScreen} exact />
        <Route path="/brand" component={FilterContainer} exact />
        <Route path="/map" component={FilterContainer} />
        <Route path="/brand/:brandId" component={MenuWithScreen} />
        <Route path="/corp/login" component={AdminLogin} />
        <Route path="/corp/mypage" component={AdminMyPage} />
        <Route path="/corp/brand" component={BrandManagement} />
        <Route path="/corp/branch" component={BranchManagement} />
        <Route path="/corp/franchise" component={FranchiseManagement} />
        <Route path="/setting" component={SettingScreen} />
        <Route path="/naver/login" component={NaverLoginCallback} />
        <Route path="/super/addBrand" component={AddBrandAllByExcel} />
        <Route path="/term/privacy" component={PrivacyScreen} />
        <Route path="/introduce" component={IntroduceScreen} />

        <Route path="/payment/success" component={PaymentSuccessScreen} />
        <Route path="/payment/failed" component={PaymentFailedScreen} />
        <Route path="/payment" component={PaymentScreen} />
        <Route path="*" component={NoMatch} />
      </Switch>

      <LoginScreen />
      <JoinScreen />
      <ComparisonBrand />
      <LoginMessagePopup />
      <FindPasswordModal />
      <FindPasswordAdminModal />
      <UsingRulesPopup />
      <UserInfoAcceptRule />
      <MarketingInfoRecieve />
      <Loading />

      {history.location.pathname != "/joincomplete" &&
        history.location.pathname != "/term/privacy" && (
          <>
            <div
              className={cn("main__floating-chatting", {
                hide: history.location.pathname.indexOf("/corp") != -1,
              })}
              onClick={() =>
                window.open(
                  "http://pf.kakao.com/_Xxmavxj/chat",
                  "target=_blank"
                )
              }
            >
              chatting
            </div>
            {history.location.pathname != "/introduce" && (
              <div
                className={cn("main__floating-compare", {
                  hide: history.location.pathname.indexOf("/corp") != -1,
                })}
                onClick={openFavoriteBrand}
              >
                comparison
              </div>
            )}
          </>
        )}

      <Snackbar
        id="snackbar__chatting"
        className="chatting"
        open={toast}
        message={message}
        autoHideDuration={3000}
        onClose={closeToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeToast} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default App;
