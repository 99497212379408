import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import NaverMap from "./NaverMap";

// const NaverAPIMap = /*React.forwardRef((props, ref)*/ () => {
  // return (
  //   <></>
    // <NaverMap
    //   id="map"
    //   {...props.options}
    //   defaultZoom={10}
    //   zoom={props.zoom}
    //   naverRef={(node) => {
    //     ref.current = node;
    //   }}
    // >
    //   {props.children}
    // </NaverMap>
//   );
// };

function NaverAPIMap({params}) {

  const { selectedTheme, selectedBadge, selectedCategory, form,
    fromMonth, toMonth, isCheckedMonth, fromStartUpCost, toStartUpCost, isCheckedStartUpCost, 
    fromArea, toArea, isCheckedArea, selectedOrder
  } = useSelector(({ filter }) => ({
    theme: filter.theme,
    badge: filter.badge,
    category: filter.category,
    selectedTheme: filter.form.selectedTheme,
    selectedBadge: filter.form.selectedBadge,
    selectedCategory: filter.form.selectedCategory,
    form: filter.form,
    fromMonth: filter.form.fromMonth, 
    toMonth: filter.form.toMonth, 
    isCheckedMonth: filter.form.isCheckedMonth, 
    fromStartUpCost: filter.form.fromStartUpCost, 
    toStartUpCost: filter.form.toStartUpCost, 
    isCheckedStartUpCost: filter.form.isCheckedStartUpCost, 
    fromArea: filter.form.fromArea, 
    toArea: filter.form.toArea, 
    isCheckedArea: filter.form.isCheckedArea, 
    selectedOrder: filter.form.selectedOrder, 
  }), shallowEqual);

  if ( selectedBadge && selectedBadge.length === 0) return null;

  return <NaverMap params={params} form={form}/>
}

export default NaverAPIMap;
