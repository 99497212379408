import client from "./client";


const config = {
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    }
};

////////////
//(일반 유저)
////////////

//이메일 회원가입
export const joinUserWithEmail = (body) => client.post(
    `/api/oauth/join/email`,
    JSON.stringify(body),
    config
);

//sns 회원가입
export const joinUserWithSns = (social, body) => client.post(
    `/api/oauth/join/${social}`,
    JSON.stringify(body),
    config
);

//이메일 로그인
export const loginUserWithEmail = (email) => client.post(
    `/api/login/${email}`,
    null,
    config
);

//sns 로그인
export const loginUserWithSns = (social, body) => client.post(
    `/api/oauth/login/${social}`,
    JSON.stringify(body),
    config
);//(일반 유저)




////////////
//(기업 고객)
////////////


//이메일 회원가입
export const joinCorpUserWithEmail = (body) => client.post(
    `/join/corp/email`,
    body,
    config
);

//이메일 로그인
export const loginCorpUserWithEmail = (email) => client.post(
    `/admin/login/corp/${email}`,
    null,
    config
);

//기업고객 브랜드 추가 목록 정보
export const getJoinBrandList = (email) => client.get(
    `/api/join/brands`,
    config
);
