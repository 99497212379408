import { Modal } from "@material-ui/core";
import React from "react";
import "assets/scss/prediction_loading.scss";
import styled from "styled-components";

export default function PredictionLoading({ isOpen }) {
  return (
    <Modal open={isOpen}>
      <Container>
        <section>
          <span className="loader"></span>
        </section>
        데이터 분석중...
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  gap: 16px;

  color: white;
  font-family: "Noto Sans";
`;
