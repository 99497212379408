import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

const serializedCategories = [
  { category: "외식", name: "피자", id: 0 },
  { category: "외식", name: "치킨", id: 0 },
  { category: "외식", name: "분식", id: 0 },
  { category: "외식", name: "일식", id: 0 },
  { category: "외식", name: "주점", id: 0 },
  { category: "외식", name: "중식", id: 0 },
  { category: "외식", name: "패스트푸드", id: 0 },
  { category: "외식", name: "한식", id: 0 },
  { category: "외식", name: "기타 외국식", id: 0 },
  { category: "외식", name: "기타 외식", id: 0 },
  { category: "카페 간식", name: "커피", id: 0 },
  { category: "카페 간식", name: "아이스크림/빙수", id: 0 },
  { category: "카페 간식", name: "음료 (커피 외)", id: 0 },
  { category: "카페 간식", name: "제과제빵", id: 0 },
  { category: "서비스", name: "교육 (교과)", id: 0 },
  { category: "서비스", name: "교육 (외국어)", id: 0 },
  { category: "서비스", name: "반려동물 관련", id: 0 },
  { category: "서비스", name: "배달", id: 0 },
  { category: "서비스", name: "부동산 중개", id: 0 },
  { category: "서비스", name: "숙박", id: 0 },
  { category: "서비스", name: "운송", id: 0 },
  { category: "서비스", name: "유아 관련 (교육 외)", id: 0 },
  { category: "서비스", name: "이미용", id: 0 },
  { category: "서비스", name: "이사", id: 0 },
  { category: "서비스", name: "인력 파견", id: 0 },
  { category: "서비스", name: "임대", id: 0 },
  { category: "서비스", name: "자동차 관련", id: 0 },
  { category: "서비스", name: "기타 교육", id: 0 },
  { category: "서비스", name: "기타 서비스", id: 0 },
  { category: "도소매", name: "편의점", id: 0 },
  { category: "도소매", name: "(건강)식품", id: 0 },
  { category: "도소매", name: "농수산물", id: 0 },
  { category: "도소매", name: "안경", id: 0 },
  { category: "도소매", name: "약국", id: 0 },
  { category: "도소매", name: "의류 / 패션", id: 0 },
  { category: "도소매", name: "종합소매점", id: 0 },
  { category: "도소매", name: "화장품", id: 0 },
  { category: "도소매", name: "기타도소매", id: 0 },
  { category: "여가", name: "PC방", id: 0 },
  { category: "여가", name: "스포츠 관련", id: 0 },
  { category: "여가", name: "오락", id: 0 },
];

export default function FavoriteCategoryStep({
  onClickNext,
  favoriteCategories,
}) {
  const history = useHistory();
  const { category } = useSelector(({ filter }) => ({
    category: filter.category,
  }));

  const [categories, setCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] =
    useState(favoriteCategories);

  useEffect(() => {
    if (category) {
      setCategories(
        serializedCategories.map((ele) => {
          let tmpCategory = category.find(
            (serverCategory) => serverCategory.name == ele.name
          );
          if (tmpCategory) {
            return { ...ele, id: tmpCategory.id };
          }
        })
      );
    }
  }, [category]);

  const selectCategory = (id) => {
    if (selectedCategories.includes(id)) {
      let index = selectedCategories.indexOf(id);
      let tmpList = [...selectedCategories];
      tmpList.splice(index, 1);
      setSelectedCategories(tmpList);
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  const onClickSkip = () => {
    logEvent(analytics, "join-skip");
    history.replace("/map");
  };

  return (
    <Container>
      <TitleContainer>
        <Title>관심 있는 업종을 선택하세요</Title>
        <SubTitle>똑사에서 맞춤 창업 정보를 제공할게요</SubTitle>
      </TitleContainer>
      <CategorySelectContainer>
        <CategoryTitle style={{ margin: 0 }}>🥘 외식</CategoryTitle>
        <CategoryListContainer>
          {categories
            .filter((ele) => ele.category == "외식")
            .map((ele) => (
              <CategoryItemContainer
                key={ele.id}
                onClick={() => selectCategory(ele.id)}
                isSelected={selectedCategories.includes(ele.id)}
              >
                {ele.name}
              </CategoryItemContainer>
            ))}
        </CategoryListContainer>
        <CategoryTitle>☕ 카페 간식</CategoryTitle>
        <CategoryListContainer>
          {categories
            .filter((ele) => ele.category == "카페 간식")
            .map((ele) => (
              <CategoryItemContainer
                key={ele.id}
                onClick={() => selectCategory(ele.id)}
                isSelected={selectedCategories.includes(ele.id)}
              >
                {ele.name}
              </CategoryItemContainer>
            ))}
        </CategoryListContainer>
        <CategoryTitle>💆 서비스</CategoryTitle>
        <CategoryListContainer>
          {categories
            .filter((ele) => ele.category == "서비스")
            .map((ele) => (
              <CategoryItemContainer
                key={ele.id}
                onClick={() => selectCategory(ele.id)}
                isSelected={selectedCategories.includes(ele.id)}
              >
                {ele.name}
              </CategoryItemContainer>
            ))}
        </CategoryListContainer>
        <CategoryTitle>🏪 도소매</CategoryTitle>
        <CategoryListContainer>
          {categories
            .filter((ele) => ele.category == "도소매")
            .map((ele) => (
              <CategoryItemContainer
                key={ele.id}
                onClick={() => selectCategory(ele.id)}
                isSelected={selectedCategories.includes(ele.id)}
              >
                {ele.name}
              </CategoryItemContainer>
            ))}
        </CategoryListContainer>
        <CategoryTitle>⚽ 여가</CategoryTitle>
        <CategoryListContainer>
          {categories
            .filter((ele) => ele.category == "여가")
            .map((ele) => (
              <CategoryItemContainer
                key={ele.id}
                onClick={() => selectCategory(ele.id)}
                isSelected={selectedCategories.includes(ele.id)}
              >
                {ele.name}
              </CategoryItemContainer>
            ))}
        </CategoryListContainer>
      </CategorySelectContainer>
      <NextButton
        disabled={selectedCategories.length == 0}
        onClick={() => onClickNext(selectedCategories)}
      >
        다음
      </NextButton>
      <SkipButtonContainer>
        <SkipButton onClick={onClickSkip}>다음에 하기</SkipButton>
      </SkipButtonContainer>
    </Container>
  );
}

const Container = styled.div``;

const TitleContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  color: #000000;
`;

const SubTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #323030bf;

  margin-top: 7px;
`;

const CategorySelectContainer = styled.div`
  width: 322px;
  max-width: 322px;
  margin: 24px 11px 0;
`;

const CategoryTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: rgba(50, 48, 48, 0.75);

  margin-top: 16px;
`;

const CategoryListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3px;
  gap: 3px;
`;

const CategoryItemContainer = styled.div`
  padding: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;

  letter-spacing: 0.5px;

  color: #000000;

  cursor: pointer;

  border: ${(props) =>
    props.isSelected
      ? "1px solid #FEC581"
      : "1px solid rgba(101, 101, 101, 0.25)"};
  border-radius: 4px;
`;

const NextButton = styled.button`
  width: calc(100% - 32px);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled ? "rgba(101, 101, 101, 0.25)" : "#ed703e"};
  border-radius: 4px;
  color: white;

  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const SkipButtonContainer = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SkipButton = styled.button`
  color: #999999;
`;
