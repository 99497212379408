import { Button, CircularProgress, Modal } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { uploadHqBrandInfomationByExcel } from "~/sagas/api/admin/branch_management";
import '~/assets/scss/super_admin_excel_bulk_upload.scss';

function AddBrandAllByExcel() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [year, setYear] = useState(null);
    const [excelUploading, setExcelUploading] = useState(false); //엑셀 수행중 체크.
    const excelUploadInput = useRef(null);
    const yearElement = useRef(null);
    const excelFileElement = useRef(null);


    const onChangeYear = (e) => {
        let value = e.target.value
        
        if ( value ) {
            value = parseInt(value, 10);
        }
        setYear(value);
    };
    
    const closeConfirm = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    ), [closeSnackbar]);

    const onSubmit = (e) => {
        e.preventDefault();
      };

    const selectExcelFile = () => {

        if ( !(year && `${year}`.length === 4) ) {
            enqueueSnackbar(`4자리 년도를 입력해 주세요.`, {
                className: "snackbar warning",
                autoHideDuration: 10000,
                action: closeConfirm
            });
            yearElement.current.focus();
            return ;
        }
        if ( (excelUploadInput.current.value === undefined || excelUploadInput.current.value === "") ) {
            enqueueSnackbar(`파일을 먼저 선택하세요.`, {
                className: "snackbar warning",
                autoHideDuration: 10000,
                action: closeConfirm
            });
            excelUploadInput.current.focus();
            return ;
        }

        unstable_batchedUpdates(async () => {
            setExcelUploading(true); //엑셀 업로드 버튼 로딩 시작

            const data = new FormData(excelFileElement.current);
            // console.log(data);
    
            try {
                console.log(year);
                await uploadHqBrandInfomationByExcel(year, data).then(res => {
                    // console.log(res);
    
                    if (res.status === 200) {
                        enqueueSnackbar(`저장 완료되었습니다.`, {
                            className: "snackbar success",
                            autoHideDuration: 3000,
                            action: closeConfirm
                        });
                        
                    } else if (res.status === 206) {
                        enqueueSnackbar(`입력 권한이 없는 사업자번호(${res.data})가 포함되어 있습니다.`, {
                            className: "snackbar warning",
                            autoHideDuration: 10000,
                            action: closeConfirm
                        });
                    } else if (res.status === 204) {
                        //선택한 파일 없음. 정상.
                    }
                });
    
            } catch (e) {
                // console.log(e);
                enqueueSnackbar("통신에 문제가 발생되었습니다. 관리자에게 문의하세요.", {
                    className: "snackbar warning",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });
            }
    
            setExcelUploading(false); //엑셀 업로드 버튼 로딩 종료
        });
    };

    return (
        <div className="super-admin__excel-bulk-upload__container">
            
            <form onSubmit={onSubmit} method="POST" encType="multipart/form-data" ref={excelFileElement}>
                <div className="title">
                    파일 업로드 화면
                </div>
                <div>
                    <input ref={yearElement} type="number" name="year" placeholder="년도를 입력하세요. ex) 2021, 2022" onChange={onChangeYear}/>
                </div>
                <div>
                    <input ref={excelUploadInput} type="file" name="excelUpload" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /* style={{ display: "none" }} */  />
                </div>
                <div>
                    <button onClick={selectExcelFile} className="file-upload-btn">업로드</button>
                </div>
            </form>
            <Modal
                    open={excelUploading}
                    className="admin-branch__modal"
                >
                    <div>
                        <div className="circular-progress-box">
                            <CircularProgress className="circular-progress" size={20} />
                            <div className="upload-text">업로드 중 입니다.</div>
                        </div>
                    </div>
                </Modal>
        </div>
        
    )
}

export default React.memo(AddBrandAllByExcel);