import React from "react";
import styled from "styled-components";
import CelebrationEmojiSvg from "../../../assets/images/main/joincomplete_end_emoji.svg";

export default function StepComplete({ onClickNext }) {
  return (
    <Container>
      <CelebrationEmoji src={CelebrationEmojiSvg} />
      <CelebrationTitle>관심사 설정 완료!</CelebrationTitle>
      <CelebrationSubTitle>
        이제 관심 창업 정보를 똑사가 추천해드릴게요
        <br />
        유용한 정보를 쉽게 얻게 될거에요
      </CelebrationSubTitle>
      <NextButton onClick={onClickNext}>확인</NextButton>
    </Container>
  );
}

const Container = styled.div`
  padding: 8px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CelebrationEmoji = styled.img`
  width: 86px;
  height: 120px;
`;

const CelebrationTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #ed703e;
  margin-top: 20px;
`;

const CelebrationSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-top: 20px;
  text-align: center;
`;

const NextButton = styled.button`
  width: 256px;
  height: 38px;
  display: flex;
  margin: 30px 52px 10px;
  align-items: center;
  justify-content: center;
  background: #ed703e;
  border-radius: 4px;
  color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
