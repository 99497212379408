import React, { useState } from "react";
import BudgetStep from "./BudgetStep";
import FavoriteCategoryStep from "./FavoriteCategoryStep";
import StartUpTimeStep from "./StartUpTimeStep";
import StepComplete from "./StepComplete";
import UserPersonalStep from "./UserPersonalStep";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { addUserSetting } from "~/sagas/api/user";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

const STEPS = {
  FAVORITE_BRAND: 0,
  BUDGET: 1,
  STARTUP_TIME: 2,
  USER_PERSONAL: 3,
  COMPLETE: 4,
};

export default function JoinCompleteStep() {
  const history = useHistory();

  const [step, setStep] = useState(STEPS.FAVORITE_BRAND);

  const [favoriteCategories, setFavoriteCategories] = useState([]);
  const [budget, setBudget] = useState([0, 500000000]);
  const [times, setTimes] = useState(-1);
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(-1);
  const [parentLocation, setParentLocation] = useState("");
  const [childLocation, setChildLocation] = useState("");

  const onCompleteInitUser = () => {
    logEvent(analytics, "join-complete");
    if (localStorage.getItem("jwtToken") == "test") {
      history.replace("/map");
      return;
    }
    // eslint-disable-next-line
    const response = addUserSetting(
      {
        categoryList: favoriteCategories,
        sex: gender,
        age: age,
        startUpBudgetMin: budget[0],
        startUpBudgetMax: budget[1],
        startUpType: times,
        selectedParent: parentLocation,
        selectedChild: childLocation,
      },
      localStorage.getItem("jwtToken")
    )
      .then((res) => {
        history.replace("/map");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container>
      <BoxContainer>
        {step != 4 && (
          <StepContainer>
            {[0, 1, 2, 3].map((ele) => (
              <Step key={ele} isCurrentStep={step >= ele}></Step>
            ))}
          </StepContainer>
        )}
        {step === STEPS.FAVORITE_BRAND ? (
          <FavoriteCategoryStep
            favoriteCategories={favoriteCategories}
            onClickNext={(data) => {
              setFavoriteCategories(data);
              setStep(STEPS.BUDGET);
            }}
          />
        ) : step === STEPS.BUDGET ? (
          <BudgetStep
            budget={budget}
            onClickPrevious={() => setStep(STEPS.FAVORITE_BRAND)}
            onClickNext={(data) => {
              setBudget(data);
              setStep(STEPS.STARTUP_TIME);
            }}
          />
        ) : step === STEPS.STARTUP_TIME ? (
          <StartUpTimeStep
            times={times}
            parentLocation={parentLocation}
            childLocation={childLocation}
            onClickPrevious={() => setStep(STEPS.BUDGET)}
            onClickNext={(times, parentLocation, childLocation) => {
              setTimes(times);
              setParentLocation(parentLocation);
              setChildLocation(childLocation);
              setStep(STEPS.USER_PERSONAL);
            }}
          />
        ) : step === STEPS.USER_PERSONAL ? (
          <UserPersonalStep
            onClickPrevious={() => setStep(STEPS.STARTUP_TIME)}
            onClickNext={(gender, age) => {
              setGender(gender);
              setAge(age);
              setStep(STEPS.COMPLETE);
            }}
          />
        ) : (
          <StepComplete
            onClickNext={() => {
              onCompleteInitUser();
            }}
          />
        )}
      </BoxContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 62px;
`;

const BoxContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(101, 101, 101, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  padding: 28px 28px 32px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4.31px;
`;

const Step = styled.div`
  width: 47.46px;
  height: 10px;
  background: ${(props) => (props.isCurrentStep ? "#ed703e" : "#d9d9d9")};
  border-radius: 8px;
`;
