import React, { useEffect, useRef, useState } from "react";
import StartUpCostOfBrand from "./StartUpCostOfBrand";
import BrandOfHeadQuater from "./BrandOfHeadQuater";
import { Link } from "react-router-dom";
import UnitNormalList from "~/components/listitems/UnitNormalList";
import Slider from "react-slick";
import client from "~/sagas/api/client";
import FranchiseIncreaseTrend from "./FranchiseIncreaseTrend";
import FranchiseContractStatus from "./FranchiseContractStatus";
import FranchiseAdHistory from "./FranchiseAdHistory";
import FranchiseAvgMonthlySales from "./FranchiseAvgMonthlySales";

function BrandStatusOfHeadQuaterTab({ brand, brandId, localStorageBrands }) {
  const sliderRef = useRef(null);
  const sliderRefB = useRef(null); //~의 브랜드
  const [list, setList] = useState(null);

  const settingsB = {
    dots: false,
    draggable: false, 
    arrows: false,
    infinite: brand.brandsOfHeadQuater.length > 3 ? true : false,
    centerMode: false,
    autoplaySpeed: 3000,
    autoplay:false,
    variableWidth: false,
    slidesToShow: 3,
    appendDots: (dots) => (
      <div className="slick-dots">
        <ul className="paging-container">{dots}</ul>
      </div>
    ),
  };

  const settings = {
    dots: false,
    draggable: true, 
    arrows: false,
    infinite: true,
    centerMode: false,
    autoplaySpeed: 3000,
    autoplay:false,
    variableWidth: false,
    appendDots: (dots) => (
      <div className="slick-dots">
        <ul className="paging-container">{dots}</ul>
      </div>
    ),
  };
  
  const division = (data, n) => {
    let arr = data;
    let len = arr != null ? arr.length : 0;
    let cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    let tmp = [];

    for(let i = 0 ; i< cnt; i++ ) {
      tmp.push(arr.splice(0, n));
    }

    //console.log(tmp);

    return tmp;
  };
  const onClickPrevB = (e) => {
      sliderRefB.current.slickPrev();
  };
  const onClickNextB = (e) => {
      sliderRefB.current.slickNext();
  };

  const onClickPrev = (e) => {
    sliderRef.current.slickPrev();
  };
  const onClickNext = (e) => {
    sliderRef.current.slickNext();
  };

  useEffect( () => {
    client.get("/api/brands/recommand/" + brandId).then((response) => {
      setList(division(response.data, 3));
    });
  }, [brandId]);

  return (
    <div className="brand-detail__brand-status-of-head-quater">

      {/* 가맹점 증감추이 */}
      {brand.increaseHistoryList != null && brand.increaseHistoryList != null ? <FranchiseIncreaseTrend increaseHistoryList={brand.increaseHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus}/> : null}

      {/* 가맹점 계약현황 */}
      {brand.contractHistoryList != null && brand.contractHistoryList != null ? <FranchiseContractStatus contractHistoryList={brand.contractHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus}/> : null}

      {/* 가맹점 월평균 매출 */}
      {brand.salesOfBrandList != null ? <FranchiseAvgMonthlySales salesOfBrandList={brand.salesOfBrandList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus}/> : null}
            
      {/* 가맹점 창업비용 */}
      {brand.startUpCost != null ? <StartUpCostOfBrand startUpCost={brand.startUpCost} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus}/> : null}
      
      {/* 가맹점 광고/판촉 내역 */}
      {brand.adHistoryList != null && brand.adHistoryList != null ? <FranchiseAdHistory adHistoryList={brand.adHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus}/> : null}

      {/* 가맹점 운영비용 20210914 주석 처리 */}
      {/* {brand.franchiseOperationCost != null ? <FranchiseOperationCostOfBrand franchiseOperationCost={brand.franchiseOperationCost} startUpCost={brand.startUpCost}/> : null} */}

      {/* 브랜드 그룹의 자식 브랜드 */}
      <div className="brand-detail__brans-of-head-quater">
        { brand.brandsOfHeadQuater && brand.brandsOfHeadQuater.length > 0 ? (
          <>
            <div className="brand-detail__brans-of-head-quater-title-group">
              <div className="brand-detail__brans-of-head-quater-title-group-left">
                <span>{brand.headQuater.name}의 브랜드</span>
              </div>
              { brand.brandsOfHeadQuater.length > 3 ?
                <div className="brand-detail__brans-of-head-quater-title-group-right">
                  <button className="brand-detail__prev-arrow" onClick={onClickPrevB}>&lt;</button>
                  <button className="brand-detail__next-arrow" onClick={onClickNextB}>&gt;</button>
                </div>
                : null 
              }
            </div>
            <div className="brand-detail__brans-of-head-quater-brands">
              <Slider ref={sliderRefB}  { ...settingsB}>
                { brand.brandsOfHeadQuater.map((curr) => 
                    <Link key={curr.id} to={`/brand/${curr.id}`} target="_blank">
                      <BrandOfHeadQuater  currBrandOfHeadQuater={curr} brand={brand} />
                    </Link>
                )}
              </Slider>
            </div>
          </>
        ) : null}
        
      </div>


      {list != null && list.length > 0 ? (
        <>
          <div className="inner">
            <div className="brand-detail__what-franchise-like-title-group">
              <div className="brand-detail__what-franchise-like-title-group-left">
                <span>이런 프랜차이즈 어때요?</span>
              </div>
              <div className="brand-detail__what-franchise-like-title-group-right">
                <button className="brand-detail__prev-arrow" onClick={onClickPrev}>&lt;</button>
                <button className="brand-detail__next-arrow" onClick={onClickNext}>&gt;</button>
              </div>
            </div>
        
            <div className="brand-detail__what-franchise-like-body-group">
              <Slider ref={sliderRef} {...settings}>
                  {list && 
                    list.map((i, idx) => <UnitNormalList key={idx} items={i} />)
                    }     
              </Slider>
            </div>
          </div>


        </>
      ) : null}

      {localStorageBrands != null && localStorageBrands.length > 0 ? (
        <>
          <div className="inner">
            <div className="brand-detail__seen-recently-title-group">
              <div className="brand-detail__seen-recently-title-group-left">
                <span>최근 본 브랜드</span>
              </div>
            </div>
            <div className="brand-detail__seen-recently-title-group">
              <UnitNormalList items={localStorageBrands} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}


export default React.memo(BrandStatusOfHeadQuaterTab);