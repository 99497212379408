import { createAction, handleActions } from 'redux-actions';
import * as type from '../types/homeType';

export const getBrandListAtHome = createAction(type.GET_BRAND_LIST_AT_HOME);

const initialState = {
  brandListAtHome: null,
  
  error: {
    GET_BRAND_LIST_AT_HOME: false,
  },
};

const list = handleActions(
  {
    [type.GET_BRAND_LIST_AT_HOME_SUCCESS]: (state, { req, payload }) => ({
      brandListAtHome: payload,
    }),
    [type.GET_BRAND_LIST_AT_HOME_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_BRAND_LIST_AT_HOME: payload,
      },
    }),

  },
  initialState,
);

export default list;
