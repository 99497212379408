import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formattedNum } from '~/common/NumberUtil';
import { login, popup } from '~/sagas/actions/commonAction';
import { addBrandsWithComparison, addBrandsWithInterests, removeBrandsWithComparison, removeBrandsWithInterests } from '~/sagas/api/favorite';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { amountUnit } from '~/App';

const UnitNormalListItem = ({ item, postEventAdd, postEventDelete }) => {
  // console.log(item);
  const { 
    id, branch, salesOfBrandList, startUpCost, brandBadge, themeBrand, name, category, mainImageUrlThumb, logoImageUrlThumb
  ,directRegionOfBrandList
  ,franchiseRegionOfBrandList
 } = item;
  // console.log(item);
  // const url = `/brand?themeId=${id}`;
  const [favoriteState, setFavoriteState] = useState(false);
  const [comparisonState, setComparisonState] = useState(false);
  const [salesOfBrand, setSalesOfBrand] = useState(false); //최근 년도 한개 값
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const { auth } = useSelector(({ common }) => ({
    auth: common.auth,
  }));

  const restoreDeletedBrand = useCallback(async () => {
   
    await addBrandsWithInterests(id, localStorage.getItem("jwtToken"));
    dispatch(
      login({
        ...auth.userInfo,
        brandOfInterestList: auth.userInfo.brandOfInterestList ? auth.userInfo.brandOfInterestList.concat(id) : [id]
      })
    );

    addedFavoriteToast();


    if ( postEventDelete ) {
      postEventDelete(id);
    }
    

    //eslint-disable-next-line
  }, [id]);

  // customized
  const actionTrue = useCallback(key => (
    <>
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    </>
  ), [closeSnackbar]);

  const actionFalse = useCallback(key => (
    <>
        <Button onClick={() => { restoreDeletedBrand(); closeSnackbar(key); }}>
            실행취소
        </Button>
    </>
  ), [closeSnackbar, restoreDeletedBrand]);

  const addedFavoriteToast = useCallback(() => {
        enqueueSnackbar("관심 브랜드 목록에 추가 되었습니다.", {
          className:"snackbar favorite-add",
          action: actionTrue,
        });
  }, [enqueueSnackbar, actionTrue]);

  const deletedFavoriteToast = useCallback(() => {
        enqueueSnackbar("관심 브랜드 목록에서 삭제 되었습니다.", {
          className:"snackbar favorite-delete",
          action: actionFalse,
        });
}, [enqueueSnackbar, actionFalse]);




  //로그인 팝업 띄우기
  const openLoginMessagePopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: true,
      }),
    );
  }, [dispatch]);



  //비교하기 등록/삭제 기능
  const onClickComparison = useCallback(async (e) => {
    //로그인 여부 확인 후 
    if ( !auth.userInfo || !auth.userInfo.email ) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();
    
    } else {//로그인 되어 있으면
      
      if ( comparisonState ) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithComparison(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            comparisonTargetList: auth.userInfo.comparisonTargetList.filter(i => i !== id )
          })
        );

        
      } else {
        setComparisonState(prev => !prev);

        const res = await addBrandsWithComparison(id, localStorage.getItem("jwtToken"));
        // console.log(res);
        if ( res.data.STATUS === "FAIL") { //최대치 4개 찼을 때
            enqueueSnackbar(res.data.MSG, {
              className:"snackbar warning",
              autoHideDuration: 3000
          });
          setComparisonState(prev => !prev);

        } else {
          dispatch(
            login({
              ...auth.userInfo,
              comparisonTargetList: auth.userInfo.comparisonTargetList.concat(id)
            })
          );
        }
        

      }
    }

  }, [dispatch, id, openLoginMessagePopup, auth, comparisonState]);



  //관심 브랜드 등록/삭제 기능
  const onClickFavorite = useCallback( async (e) => {
    //로그인 여부 확인 후 
    if ( !auth.userInfo || !auth.userInfo.email ) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();

    } else {//로그인 되어 있으면
      //관심 브랜드 등록/삭제 기능
      setFavoriteState(prev => !prev);

      if ( favoriteState ) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithInterests(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            brandOfInterestList: auth.userInfo.brandOfInterestList.filter( i => i !== id )
          })
        );

        if ( postEventDelete ) {
          postEventDelete(id);
        }

        
        
        deletedFavoriteToast();

      } else {
        await addBrandsWithInterests(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            brandOfInterestList: auth.userInfo.brandOfInterestList.concat(id)
          })
        );

        if ( postEventAdd ) {
          postEventAdd(id);
        }

        addedFavoriteToast();

      }
    }
    
  }, [addedFavoriteToast, deletedFavoriteToast, dispatch, id, openLoginMessagePopup, postEventAdd, postEventDelete, auth, favoriteState]);


  const [branchTotal, setBranchTotal] = useState(0);

  useEffect(() => {
    // console.log(auth.userInfo);
    let branchTotalCnt = 0;
    branchTotalCnt = directRegionOfBrandList && directRegionOfBrandList.length > 0 ? directRegionOfBrandList[0].total : 0;
    branchTotalCnt += franchiseRegionOfBrandList && franchiseRegionOfBrandList.length > 0 ? franchiseRegionOfBrandList[0].total : 0;
    setBranchTotal(branchTotalCnt);

    setComparisonState(auth.userInfo.comparisonTargetList && auth.userInfo.comparisonTargetList.indexOf(id) !== -1 ? true : false);
    setFavoriteState(auth.userInfo.brandOfInterestList && auth.userInfo.brandOfInterestList.indexOf(id) !== -1 ? true : false);
    if ( salesOfBrandList ) {
      let list = salesOfBrandList;
      list.sort((a, b) => b.year - a.year);//년도 내림차순

      if ( list && list.length > 0 ) {
        setSalesOfBrand(list[0]);
      }

    }
    
      // eslint-disable-next-line
  }, [auth]);

  return (

    
      <div className="item unit-type-normal">
        <Link to={`/brand/${id}`} className="thumb" target="_blank">
          <img src={mainImageUrlThumb ? mainImageUrlThumb : "/placeholder_brand.png"} alt={name} />
          <div className="badge">
            {brandBadge && brandBadge.length !== 0 && brandBadge.map((i, idx) => /* idx < 3 &&*/ <span key={`badge-${idx}`}>{i.badge.name}</span>)}
            {themeBrand && themeBrand.length !== 0 && themeBrand.map((i, idx) => /*idx < 3 &&*/ <span key={`theme-${idx}`}>{i.theme.shortName}</span>)}
          </div>
        </Link>

        <div className="brand">
          <div className="thumb">
            <img src={logoImageUrlThumb ? logoImageUrlThumb : "/placeholder_logo.png" } alt="" />
          </div>
          <div className="info-group">
            <div className="info">
              <div className="cate">{category && category.name}</div>
              <div className="util">
                <label onClick={onClickComparison}>
                  <i className={cn("comparison", {on: comparisonState})}></i>
                  <span>비교</span>
                </label>

                <label onClick={onClickFavorite}>
                  <i className={cn("favorite", {on: favoriteState})}></i>
                </label>
              </div>
              </div>
            <div className="name">{name}</div>
          </div>
        </div>

        <div className="detail">
          <div className="p num">
            <span>매장</span>
            <strong>{branchTotal ? formattedNum(branchTotal) + '개' : '정보없음'}</strong>
          </div>
          <div className="p sales">
            <span>월평균 매출액</span>
            <strong>{salesOfBrand && salesOfBrand.avgMonthlySales ? formattedNum(amountUnit * salesOfBrand.avgMonthlySales) + '원' : '정보없음'}</strong>
            {salesOfBrand && salesOfBrand.avgMonthlySalesArea ?
            <span className="sub">(3.3㎡당 {salesOfBrand && formattedNum(amountUnit * salesOfBrand.avgMonthlySalesArea)}원)</span>
            : null }
          </div>
          <div className="p startup-cost">
            <span>창업비용</span>
            <strong>{startUpCost && startUpCost.total ? formattedNum(amountUnit * startUpCost.total) + '원' : '정보없음'}</strong>
            {startUpCost && startUpCost.squareMeter ?
            <span className="sub">({startUpCost && startUpCost.squareMeter}㎡ 기준)</span>
            : null}
          </div>
        </div>
      </div>
  );
};

const UnitNormalList = function UnitNormalList({ items, postEventAdd, postEventDelete }) {


  return (
    <>
          {
            items ? items.map((i) => (
              <UnitNormalListItem key={`brand-item-${i.id}`} item={i} postEventAdd={postEventAdd} postEventDelete={postEventDelete}/> 
            ))
            : <div>no item</div>
          }
    </>
  )
}

export default React.memo(UnitNormalList);

UnitNormalList.defaultProps = {
  items: null,
};
