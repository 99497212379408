import React from "react";
import cn from "classnames";
import MapFirstLayer from "../../../screen/maps/MapFirstLayer";
import MapSecondLayer from "../../../screen/maps/MapSecondLayer";
import MapThirdLayer from "~/screen/maps/MapThirdLayer";
import { useSelector } from "react-redux";

const MapAsideContainer = ({
  currentBrand,
  currentBranch,
  brandList,
  branchList,
  addressMapCenter,
  getMapBranchList,
  checkedBrandItem,
  checkedBranchItem,
  highlightBranchItem,
  handleAsideLayer,
  handleFirstLayer,
  handleSecondLayer,
  handleThirdLayer,
  isActive,
  moveToCenterAtMap,
  handleThirdBranchDetailPopup,
  sortBrand,
  sortBranch,
  sortingBrandEvent,
  sortingBranchEvent,
  initialize,
  recruitType,
  sortingBranchRecruitEvent,
  callGetAddress,
  showMarkerZoomToast,
}) => {
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));

  const isActiveFirst = isActive.all && isActive.firstLayer;
  const isActiveSecond = isActive.all && isActive.secondLayer;
  const isActiveThird = isActive.all && isActive.thirdLayer;

  //
  const onClickBrandOne = (brandId) => {
    getMapBranchList(brandId); //브랜치 목록
    checkedBrandItem(brandId); //브랜드 선택값 변경
    handleSecondLayer(); //2번째 레이어 펼침
  };

  return (
    <aside id="aside" className={cn({ active: isActive.firstLayer })}>
      <MapFirstLayer
        brandList={brandList}
        branchList={branchList}
        brand={currentBrand}
        branch={currentBranch}
        addressMapCenter={addressMapCenter}
        isActive={isActiveFirst}
        onClickBrandOne={onClickBrandOne}
        handleFirstLayer={handleFirstLayer}
        moveToCenterAtMap={moveToCenterAtMap}
        sortBrand={sortBrand}
        sortingBrandEvent={sortingBrandEvent}
        initialize={initialize}
        callGetAddress={callGetAddress}
        showMarkerZoomToast={showMarkerZoomToast}
      />
      <MapSecondLayer
        brandList={brandList}
        branchList={branchList}
        brand={currentBrand}
        branch={currentBranch}
        isActive={isActiveSecond}
        checkedBranchItem={checkedBranchItem}
        highlightBranchItem={highlightBranchItem}
        handleFirstLayer={handleFirstLayer}
        handleSecondLayer={handleSecondLayer}
        handleThirdLayer={handleThirdLayer}
        moveToCenterAtMap={moveToCenterAtMap}
        sortBranch={sortBranch}
        sortingBranchEvent={sortingBranchEvent}
        recruitType={recruitType}
        sortingBranchRecruitEvent={sortingBranchRecruitEvent}
      />
      <MapThirdLayer
        brandList={brandList}
        branchList={branchList}
        brand={currentBrand}
        branch={currentBranch}
        isActive={isActiveThird}
        branchDetailPopup={isActive.branchDetailPopup}
        handleFirstLayer={handleFirstLayer}
        handleSecondLayer={handleSecondLayer}
        handleThirdLayer={handleThirdLayer}
        handleThirdBranchDetailPopup={handleThirdBranchDetailPopup}
      />

      <div
        className={cn("map__2dep-folding-arrow", {
          active: isActive.secondLayer && !isActive.thirdLayer,
        })}
        onClick={handleFirstLayer}
      >
        <button className={cn("map__2dep-btn-close")}>close</button>
      </div>

      <div
        className={cn("map__3dep-folding-arrow", {
          active: isActive.thirdLayer,
        })}
        onClick={handleSecondLayer}
      >
        <button className={cn("map__3dep-btn-close")}>close</button>
      </div>

      <div className={cn("map__folding-arrow")} onClick={handleAsideLayer}>
        <div
          className={cn({
            "btn--arrow-left": isActive.all,
            "btn--arrow-right": !isActive.all,
          })}
        >
          전체접기
        </div>
      </div>
    </aside>
  );
};

export default React.memo(MapAsideContainer);
