import { Slider } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import "./CustomMui.css";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 50000000,
    label: "5천",
  },
  {
    value: 100000000,
    label: "1억",
  },
  {
    value: 200000000,
    label: "2억",
  },
  {
    value: 300000000,
    label: "3억",
  },
  {
    value: 400000000,
    label: "4억",
  },
  {
    value: 500000000,
    label: "최대",
  },
];

export default function BudgetStep({ budget, onClickPrevious, onClickNext }) {
  const [startUpBudget, setStartUpBudget] = useState(budget); //창업 예산

  const onChangeBudget = (e, newValue) => {
    setStartUpBudget(newValue);
  };
  return (
    <Container>
      <TitleContainer>
        <Title>생각해두신 창업 예산을 선택하세요</Title>
      </TitleContainer>
      <BudgetContainer>
        <BudgetSubTitle>창업 예산</BudgetSubTitle>
        <BudgetSelectContainer>
          <Slider
            marks={marks}
            className="primary"
            value={startUpBudget}
            name="rangeStartupCostBudget"
            onChange={onChangeBudget}
            // valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={50000000}
            min={0}
            max={500000000}
          />
        </BudgetSelectContainer>
      </BudgetContainer>
      <UtilContainer>
        <PreviousButton onClick={onClickPrevious}>뒤로</PreviousButton>
        <NextButton onClick={() => onClickNext(startUpBudget)}>다음</NextButton>
      </UtilContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const TitleContainer = styled.div`
  margin-top: 29px;
  text-align: center;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  color: #000000;
`;

const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 24px 12px 0;
`;

const BudgetSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;

  margin-left: 6.75px;

  color: rgba(50, 48, 48, 0.78);
`;

const BudgetSelectContainer = styled.div`
  width: 320px;
  margin-top: 5px;
`;

const UtilContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-self: flex-end;
  gap: 16px;
  width: calc(100% - 32px);
  margin: auto auto 0;
`;

const PreviousButton = styled.button`
  width: 100px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(101, 101, 101, 0.25);
  border-radius: 4px;
  color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const NextButton = styled.button`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ed703e;
  border-radius: 4px;
  color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
