import { Button } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import cn from 'classnames';
import React, { useCallback } from 'react';

const PopOver = ({ children, type, title, buttonStyle, containerClass, value, onClickConfirm }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const onClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = useCallback((name, newValue, checked) => {
      let isSet = false;
      if ( name === "rangeStartupCost" ) {
        isSet = (newValue[0] === 0 && newValue[1] === 500000000) ? false : true;
      } else if ( name === "rangeSale" ) {
        isSet = (newValue[0] === 0 && newValue[1] === 500000000) ? false : true;
      } else if ( name === "rangeArea" ) {
        isSet = (newValue[0] === 0 && newValue[1] === 156) ? false : true;
      }

      onClickConfirm(name, newValue, isSet);
      setAnchorEl(null);
    },
    [onClickConfirm],
  );

  return (
    <>
      <Button onClick={onClick} variant="outlined" color="default" className={buttonStyle}>
        {value ? value : title}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={cn('modal', containerClass)}>
          {type !== 'select' && (
            <div className="modal-header">
              {title}
              {/* <button onClick={onClose} className="modal-close"></button> */}
            </div>
          )}
          {React.cloneElement(children, {
            handleConfirm: handleConfirm,
          })}
        </div>
      </Popover>
    </>
  );
};

export default PopOver;
