import React from "react";
import styled from "styled-components";
import MapImagePng from "../../assets/images/onboarding/onboarding_start_map.png";
import CloseSvg from "../../assets/images/icon/icon_onboarding_close.svg";

const OnBoardingStart = React.forwardRef(
  ({ onClickNext, onClickSkip, onClickSkipNextTime }, ref) => {
    return (
      <Container ref={ref}>
        <ModalWrapper>
          <CloseButton onClick={onClickSkip}>
            <img src={CloseSvg} alt="close" />
          </CloseButton>
          <MapImage src={MapImagePng} />
          <Title>
            돈이 보이는 안심 창업
            <br />
            <TitleAttention>똑똑한사장</TitleAttention>에서 시작하세요
          </Title>
          <StartButton onClick={onClickNext}>지금 시작하기</StartButton>
          {localStorage.getItem("onboarding_shown") ? (
            <SkipNextTimeButton onClick={onClickSkipNextTime}>
              다시 보지 않기
            </SkipNextTimeButton>
          ) : (
            <SkipNextTimeButton onClick={onClickSkip}>
              튜토리얼 건너뛰기
            </SkipNextTimeButton>
          )}
        </ModalWrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -35px;
  right: 0px;
`;

const ModalWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 36px 70px;

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const MapImage = styled.img`
  width: 228px;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #1c1c1c;

  text-align: center;
  margin-top: 20px;
`;

const TitleAttention = styled.span`
  font-weight: 500;
  color: #ed703e;
`;

const StartButton = styled.button`
  width: 256px;
  height: 36px;
  background: #ed703e;
  border-radius: 4px;

  margin-top: 30px;
  color: white;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const SkipNextTimeButton = styled.button`
  position: absolute;
  bottom: -40px;
  padding: 10px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #f1f1f1;
`;

export default OnBoardingStart;
