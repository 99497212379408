import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import commonSaga from "./commonSaga";
import listSaga from "./listSaga";
import filterSaga from "./filterSaga";

import loading from "./actions/loadingAction";
import common from "./actions/commonAction";
import filter from "./actions/filterAction";
import list from "./actions/listAction";
import home from "./actions/homeAction";
import avgMonthlySalesAction from "./actions/avgMonthlySalesAction";
import startUpCostAction from "./actions/startUpCostAction";
import homeSaga from "./homeSaga";

const rootReducer = combineReducers({
  loading,
  common,
  filter,
  list,
  home,
  avgMonthlySalesAction,
  startUpCostAction,
});

export function* rootSaga() {
  yield all([commonSaga(), listSaga(), filterSaga(), homeSaga()]);
}

export default rootReducer;
