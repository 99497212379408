import React, { useRef, useCallback } from "react";
import cn from "classnames";
import "~/assets/scss/join.scss";
import { popup } from "~/sagas/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import CorpUserJoin from "./CorpUserJoin";

const getTextLength = function (str) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length === 6) {
      len++;
    }
    len++;
  }
  return len;
};

function JoinScreen() {
  const history = useHistory();
  const fileInput = useRef(null);

  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { join } = useSelector(({ common }) => ({
    join: common.popup.join,
  }));
  const { joinUserType } = useSelector(({ common }) => ({
    joinUserType: common.popup.joinUserType,
  }));
  const { joinView } = useSelector(({ common }) => ({
    joinView: common.popup.joinView,
  }));

  const openHomeScreen = useCallback(() => {
    dispatch(
      popup({
        login: false,
        join: false,
      })
    );
  }, [dispatch]);

  const setUserType = useCallback(() => {
    dispatch(
      popup({
        joinUserType: true,
        joinView: true,
      })
    );
  }, [dispatch]);

  const setCorpType = useCallback(() => {
    dispatch(
      popup({
        joinUserType: false,
        joinView: false,
      })
    );
  }, [dispatch]);

  const openJoinInEmail = useCallback(() => {
    dispatch(
      popup({
        joinView: false,
      })
    );
  }, [dispatch]);

  const setIsUserType = useCallback(
    (val) => {
      dispatch(
        popup({
          joinUserType: val,
        })
      );
    },
    [dispatch]
  );

  const setJoinView = useCallback(
    (val) => {
      dispatch(
        popup({
          joinView: val,
        })
      );
    },
    [dispatch]
  );

  //사업자 등록증
  // eslint-disable-next-line no-unused-vars
  const changeBusinessFile = (e) => {
    //console.log(e.target);
  };

  //이용약관
  const openUsingRules = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        popup({
          usingRules: true,
        })
      );
    },
    [dispatch]
  );
  //개인정보처리방침
  const openUserInfo = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        popup({
          userInfo: true,
        })
      );
    },
    [dispatch]
  );
  //마케팅 정보 수신
  const openMarketing = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        popup({
          marketing: true,
        })
      );
    },
    [dispatch]
  );

  return (
    <Modal
      open={join}
      className={cn("join-modal", { email: !joinView, on: !joinUserType })}
    >
      <div className={cn("join__container")}>
        <div
          className={cn("join-section", {
            email: !joinView,
            on: !joinUserType,
          })}
        >
          <div className="join-inner-section">
            <div className="join__title-group">
              <div className="join__title">회원가입</div>
              <button className="join__close-btn" onClick={openHomeScreen}>
                close
              </button>
            </div>
            <div className={cn("join", { on: !joinUserType })}>
              <div className="join__menu">
                {/* <button className={cn({"on": joinUserType})} onClick={setUserType}>예비 창업자</button> */}
                <button
                  className={cn({ on: !joinUserType })}
                  onClick={setCorpType}
                >
                  기업 고객
                </button>
              </div>

              {/* 회원가입 첫번째 화면 */}
              {/* <SnsJoin joinView={joinView} isUserType={joinUserType} openJoinInEmail={openJoinInEmail} /> */}

              {/* 회원가입 두번째 화면 */}
              {/* <UserJoin joinView={joinView} isUserType={joinUserType} setIsUserType={setIsUserType} setJoinView={setJoinView} getTextLength={getTextLength}
                                    openUsingRules={openUsingRules} openUserInfo={openUserInfo} openMarketing={openMarketing}/> */}

              {/* 기업 고객 화면 */}
              <CorpUserJoin
                joinView={joinView}
                isUserType={joinUserType}
                setIsUserType={setIsUserType}
                setJoinView={setJoinView}
                getTextLength={getTextLength}
                openUsingRules={openUsingRules}
                openUserInfo={openUserInfo}
                openMarketing={openMarketing}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(JoinScreen);
