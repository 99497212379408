import React, { useEffect, useState } from "react";
import { formattedNum } from "../../common/NumberUtil.js";
import ManagerComment from "./ManagerComment.js";

function EmployeesOfBrand({ employeesOfBrandList, logo, comment, hideComment }) {
  const [employeesOfBrand, setEmployeesOfBrand] = useState(null);

  useEffect(() => {
    if ( employeesOfBrandList ) {
      setEmployeesOfBrand(employeesOfBrandList[0]);
    }
  }, []);

  if ( !employeesOfBrand ) return null;

  return (
    <div className="brand-detail__employees-head-quater">
      {
        !hideComment &&
        <span className="brand-detail__title">본사 임직원 수</span>
      }
      <span className="brand-detail__sub-title">{employeesOfBrand.year}년 정보공개서 기준</span>

      <div className="brand-detail__table-group">
        <div>
          <span>임원</span>
          <span> {employeesOfBrand.numberOfExecutives ? `${formattedNum(employeesOfBrand.numberOfExecutives)}명` : '정보없음'}</span>
        </div>
        <div>
          <span>직원</span>
          <span> {employeesOfBrand.numberOfEmployees ? `${formattedNum(employeesOfBrand.numberOfEmployees)}명` : '정보없음'}</span>
        </div>
      </div>
      <div className="brand-detail__table_total">
        <span>전체</span>
        <span> {(employeesOfBrand.numberOfExecutives || employeesOfBrand.numberOfEmployees) ? `${formattedNum(employeesOfBrand.numberOfExecutives + employeesOfBrand.numberOfEmployees)}명` : '정보없음'}</span>
      </div>

      {
        !hideComment && comment && comment.numberOfHeadOfficeEmployeesComment &&
        <ManagerComment key="numberOfHeadOfficeEmployeesComment" logo={logo} comment={comment.numberOfHeadOfficeEmployeesComment} name={comment.userName} modifyAt={comment.modifyAt}/>
      }
      
    </div>
  );
}

export default EmployeesOfBrand;
