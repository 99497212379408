import React, { useState } from "react";
import cn from "classnames";
import { naverAuthorize } from "~/sagas/api/naver_login";
import { useHistory } from "react-router";
import { Modal } from "@material-ui/core";
import IconPng from "../../../assets/images/icon/icon_login_naver.png";

function NaverLogin({ hide, callbackFunc }) {
  const history = useHistory();
  const [html, setHtml] = useState(null);

  window.naverLoginOpenerCallback = (response, snsType) => {
    window.naver_login_popup_login.close();
    callbackFunc(response, snsType);
  };

  const callLogin = () => {
    try {
      naverAuthorize()
        .then((response) => {
          console.log(response);

          if (response.status === 200) {
            const naverLoginHtml = response.data;

            try {
              let doc = document.createElement("html");
              doc.innerHTML = response.data;
              const url = doc.querySelector("input#url").defaultValue;

              console.log(url);

              localStorage.setItem("naver_login_html", naverLoginHtml);
              localStorage.setItem("naver_api_type", "LOGIN");
              // console.log(response.data);
              window.naver_login_popup_login = window.open(
                url,
                "naver_login_popup",
                "width: 500, height: 600, top=100,left=100,status=no ,location=no, directoryies=no, resizable=no, scrollbars=yes, titlebar=no"
              );
              // window.document.location.href = url;

              // console.log(window.naver_login_popup.closed);
              // callbackFunc(response, 'naver');

              // console.log(naverLoginHtml);
              if (naverLoginHtml) {
                setHtml(naverLoginHtml);
              }
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e2) {
      console.log(e2);
    }
  };

  return (
    //         <LoginNaver
    //             clientId="MLzeOHNaghVMLcW2N7Xj"
    //             onSuccess={(response) => callbackFunc(response, 'naver')}
    //             callbackUrl="http://localhost:3000/login"
    //             onFailure={(error) => console.error(error)}
    //             render={(props) =>
    //                 <button className={cn("login__btn sns__naver", {"hide": !hide})}
    //                         onClick={props.onClick}
    <>
      <button
        className={cn("login__btn sns__naver", { hide: !hide })}
        onClick={callLogin}
      >
        <img src={IconPng} />
        네이버 아이디로 시작하기
        <div className="empty" />
      </button>
    </>
  );
}

export default React.memo(NaverLogin);
