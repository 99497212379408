export const CHANGE_FILTER_FOLDING = 'filter/CHANGE_FILTER_FOLDING';
export const CHANGE_FILTER_FOLDING_INIT = 'filter/CHANGE_FILTER_FOLDING_INIT';
export const CHANGE_FILTER_FOLDING_BTN_HIDE = 'filter/CHANGE_FILTER_FOLDING_BTN_HIDE';
export const GET_FILTER_BADGE = 'filter/GET_FILTER_BADGE';
export const GET_FILTER_BADGE_SUCCESS = 'filter/GET_FILTER_BADGE_SUCCESS';
export const GET_FILTER_BADGE_FAILURE = 'filter/GET_FILTER_BADGE_FAILURE';

export const GET_FILTER_CATEGORY = 'filter/GET_FILTER_CATEGORY';
export const GET_FILTER_CATEGORY_SUCCESS = 'filter/GET_FILTER_CATEGORY_SUCCESS';
export const GET_FILTER_CATEGORY_FAILURE = 'filter/GET_FILTER_CATEGORY_FAILURE';
export const CHANGE_FILTER_CATEGORY_SHOW_ON_FILTER = 'filter/CHANGE_FILTER_CATEGORY_SHOW_ON_FILTER';

export const GET_FILTER_THEME = 'filter/GET_FILTER_THEME';
export const GET_FILTER_THEME_SUCCESS = 'filter/GET_FILTER_THEME_SUCCESS';
export const GET_FILTER_THEME_FAILURE = 'filter/GET_FILTER_THEME_FAILURE';

export const GET_SEARCH_BRAND = 'filter/GET_SEARCH_BRAND';
export const GET_SEARCH_BRAND_SUCCESS = 'filter/GET_SEARCH_BRAND_SUCCESS';
export const GET_SEARCH_BRAND_FAILURE = 'filter/GET_SEARCH_BRAND_FAILURE';

export const FILTER_FORM = 'FILTER_FORM';
export const AUTOCOMPLETE_INIT = 'AUTOCOMPLETE_INIT';
export const BRAND_SEARCH_FORM = 'BRAND_SEARCH_FORM';
