import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';


const SelectTheme = ({ item, onChange, onClickReset, handleConfirm}) => {

  const resetEvent = () => {
    onClickReset();
    // handleConfirm();
  }
  return (
    <div className="checkbox-group">
      <div className="select-list">
        {item &&
          item.map((i, idx) => (
            <FormControlLabel
              color="blue"
              onChange={onChange}
              key={i.id}
              value={i.id}
              checked={i.checked}
              className="dropdown checkbox custom"
              control={<Checkbox name="theme" />}
              label={i.name}
            />
          ))}
      </div>
      <div className="modal-footer theme-and-badge">
        <div className="align-between">
          <button id="cost" className="btn-reset" onClick={resetEvent}>
            초기화
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectTheme);
