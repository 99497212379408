import React, { useState } from "react";
import styled from "styled-components";
const initSex = [
  {
    id: 1,
    name: "남성",
    value: "MALE",
    checked: false,
  },
  {
    id: 2,
    name: "여성",
    value: "FEMALE",
    checked: false,
  },
];
const initAge = [
  {
    id: 1,
    name: "18~24세",
    value: 1824,
    checked: false,
  },
  {
    id: 2,
    name: "25~34세",
    value: 2534,
    checked: false,
  },
  {
    id: 3,
    name: "35~44세",
    value: 3544,
    checked: false,
  },
  {
    id: 4,
    name: "45~54세",
    value: 4554,
    checked: false,
  },
  {
    id: 5,
    name: "55~64세",
    value: 5564,
    checked: false,
  },
  {
    id: 6,
    name: "64세 이상",
    value: 6499,
    checked: false,
  },
];

export default function UserPersonalStep({ onClickPrevious, onClickNext }) {
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(-1);
  return (
    <Container>
      <TitleContainer>
        <Title>
          성별과 연령을 알려주시면
          <br />
          창업 혜택 제공에 도움이 돼요
        </Title>
      </TitleContainer>
      <UserPersonalContainer>
        <PersonalSubTitle>성별</PersonalSubTitle>
        <PersonalList>
          {initSex.map((ele) => (
            <PersonalItem
              key={ele.id}
              isSelected={gender == ele.value}
              onClick={() => setGender(ele.value)}
            >
              {ele.name}
            </PersonalItem>
          ))}
        </PersonalList>
        <PersonalSubTitle style={{ marginTop: "16px" }}>
          연령대
        </PersonalSubTitle>
        <PersonalList>
          {initAge.map((ele) => (
            <PersonalItem
              key={ele.id}
              isSelected={age == ele.value}
              onClick={() => setAge(ele.value)}
            >
              {ele.name}
            </PersonalItem>
          ))}
        </PersonalList>
      </UserPersonalContainer>
      <UtilContainer>
        <PreviousButton onClick={onClickPrevious}>뒤로</PreviousButton>
        <NextButton
          disabled={gender == "" || age == -1}
          onClick={() => onClickNext(gender, age)}
        >
          다음
        </NextButton>
      </UtilContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const TitleContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #000000;
`;

const UserPersonalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 24px 0 0;
`;

const PersonalSubTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(50, 48, 48, 0.78);
`;

const PersonalList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  row-gap: 6px;
  margin-top: 3px;

  max-width: 344px;
  flex-wrap: wrap;
`;

const PersonalItem = styled.div`
  padding: 0px 10px;

  border: ${(props) =>
    props.isSelected
      ? "1px solid #fec581"
      : "1px solid rgba(101, 101, 101, 0.25);"};
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: #000000;
  cursor: pointer;
`;

const UtilContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-self: flex-end;
  gap: 16px;
  width: calc(100% - 32px);
  margin: auto auto 0;
`;

const PreviousButton = styled.button`
  width: 100px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(101, 101, 101, 0.25);
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const NextButton = styled.button`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled ? "rgba(101, 101, 101, 0.25)" : "#ed703e"};
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
