import React, { useCallback, useEffect, useState } from "react";
import PopupStartUpConsultationApplication from "./PopupStartUpConsultationApplication";
import { formattedNum } from "../../common/NumberUtil.js";
import { Box, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { login, popup } from "~/sagas/actions/commonAction";
import { addBrandsWithComparison, addBrandsWithInterests, removeBrandsWithComparison, removeBrandsWithInterests } from "~/sagas/api/favorite";
import cn from "classnames";
import { amountUnit } from "~/App";

function BrandDetailRight({ brand }) {
  const { id, logoImageUrlThumb, category, name, salesOfBrandList, directRegionOfBrandList, franchiseRegionOfBrandList } = brand;
  const [open, setOpen] = useState(false);
  const [favoriteState, setFavoriteState] = useState(false);
  const [comparisonState, setComparisonState] = useState(false);
  const [salesOfBrand, setSalesOfBrand] = useState(false); //최근 년도 한개 값
  const [totalBranchCnt, setTotalBranchCnt] = useState(0); // 전체 매장수

  const dispatch = useDispatch();
  const { auth } = useSelector(({ common }) => ({
    auth: common.auth,
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  //로그인 팝업 띄우기
  const openLoginMessagePopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: true,
      }),
    );
  }, [dispatch]);

  //비교하기 등록/삭제 기능
  const onClickComparison = useCallback(async (e) => {
    //로그인 여부 확인 후 
    if (!auth.userInfo || !auth.userInfo.email) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();

    } else {//로그인 되어 있으면
      //비교하기 등록/삭제 기능
      setComparisonState(prev => !prev);

      if (comparisonState) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithComparison(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            comparisonTargetList: auth.userInfo.comparisonTargetList.filter(i => i !== id)
          })
        );

      } else {
        await addBrandsWithComparison(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            comparisonTargetList: auth.userInfo.comparisonTargetList.concat(id)
          })
        );

      }
    }

  }, [dispatch, id, openLoginMessagePopup, auth, comparisonState]);



  //관심 브랜드 등록/삭제 기능
  const onClickFavorite = useCallback(async (e) => {
    //로그인 여부 확인 후 
    if (!auth.userInfo || !auth.userInfo.email) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();

    } else {//로그인 되어 있으면
      //관심 브랜드 등록/삭제 기능
      setFavoriteState(prev => !prev);

      if (favoriteState) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithInterests(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            brandOfInterestList: auth.userInfo.brandOfInterestList.filter(i => i !== id)
          })
        );

      } else {
        await addBrandsWithInterests(id, localStorage.getItem("jwtToken"));
        dispatch(
          login({
            ...auth.userInfo,
            brandOfInterestList: auth.userInfo.brandOfInterestList.concat(id)
          })
        );

      }
    }

  }, [dispatch, id, openLoginMessagePopup, auth, favoriteState]);


  useEffect(() => {
    // console.log(auth.userInfo);

    setComparisonState(auth.userInfo.comparisonTargetList && auth.userInfo.comparisonTargetList.indexOf(id) !== -1 ? true : false);
    setFavoriteState(auth.userInfo.brandOfInterestList && auth.userInfo.brandOfInterestList.indexOf(id) !== -1 ? true : false);
    if (salesOfBrandList) {
      let list = salesOfBrandList;
      list.sort((a, b) => b.year - a.year);//년도 내림차순

      if (list && list.length > 0) {
        setSalesOfBrand(list[0]);
      }
    }

    if ( directRegionOfBrandList || franchiseRegionOfBrandList  ) {
      let totalCnt = 0;

      if ( directRegionOfBrandList ) {

        const directList = directRegionOfBrandList;

        directList.sort( (a,b) => b.year- a.year);
        if ( directList && directList.length > 0 ) {
          const directRegionCnt = directList[0]; //가장 큰 year
          totalCnt = directRegionCnt.total ? directRegionCnt.total : 0 ;

        }
      }

      if ( franchiseRegionOfBrandList ) {
        const franchise = franchiseRegionOfBrandList;
        franchise.sort( (a,b) => b.year- a.year);
        if ( franchise && franchise.length > 0 ) {
          const franchiseRegionCnt = franchise[0];
          totalCnt += franchiseRegionCnt.total ? franchiseRegionCnt.total : 0 ;

        }
      }

      setTotalBranchCnt(totalCnt);
    }

    // eslint-disable-next-line
  }, [auth]);

  return (
    <div className="brand-detail__right-container">

      <div className="brand-detail__brand-info">
        <div className="brand-detail__brand-logo__container">
          <img src={brand.logoImageUrlThumb ? brand.logoImageUrlThumb : "/placeholder_logo.png"} alt="LOGO" className="brand-detail__brand-logo" />
        </div>
        
        <div className="brand-detail__barnch-detail" >
          <span>{brand && brand.category && brand.category.name}</span>
          <span>{brand && brand.name}</span>
        </div>
        <div className="brand-detail__badge-theme">
          {
            brand && brand.brandBadge &&
            brand.brandBadge.map((item, idx) =>
              <div key={idx} className="brand-detail__badge-image">{item.badge.name}</div>
            )
          }
          {
            brand && brand.themeBrand &&
            brand.themeBrand.map((item, idx) =>
              <div key={idx} className="brand-detail__theme-image">{item.theme.shortName}</div>
            )
          }
        </div>

        <div className="brand-detail__branch-costs">
          <div className="brand-detail__start-up-cost">
            <span>창업비용</span>
            <div>
              <span>{brand && brand.startUpCost && brand.startUpCost.total ? `${formattedNum(amountUnit * brand.startUpCost.total)}원` : "정보없음"}</span>
              { brand && brand.startUpCost && brand.startUpCost.baseArea ?
                <span>{brand && brand.startUpCost && parseInt(brand.startUpCost.baseArea*3.3, 10)}m<sup>2</sup>({brand && brand.startUpCost && brand.startUpCost.baseArea}평) 기준</span>
                : <span>정보없음</span>
              }
            </div>
          </div>
          <div className="brand-detail__monthly_sales">
            <span>월평균 매출액</span>
            <div>
              { salesOfBrand && salesOfBrand.avgMonthlySales ?
               <span>{salesOfBrand && formattedNum(amountUnit * salesOfBrand.avgMonthlySales)}원</span>
               : <span>정보없음</span>
              }
            </div>
          </div>
          <div className="brand-detail__monthly_sales33">
            <span>3.3m<sup>2</sup>(1평)당 월평균 매출액</span>
            <div>
              { salesOfBrand && salesOfBrand.avgMonthlySalesArea ?
              <span>{salesOfBrand && formattedNum(amountUnit * salesOfBrand.avgMonthlySalesArea)}원</span>
                : <span>정보없음</span>
              }
            </div>
          </div>
          <div className="brand-detail__branch-total-cnt">
            <span>매장</span>
            <div>
            {totalBranchCnt ?
              <span>{formattedNum(totalBranchCnt)}개</span>
              : <span>정보없음</span>
              }
            </div>
          </div>
        </div>
        {/* <div className="brand-detail__button-check-group">
          <button className={cn("brand-detail__button brand-detail__button--sales", {on: favoriteState})} onClick={onClickFavorite}>1,083</button>
          <button className={cn("brand-detail__button brand-detail__button--compare", {on: comparisonState})} onClick={onClickComparison}>비교</button>
          <button className="brand-detail__button brand-detail__button--share">공유</button>
        </div> */}
        <div className="brand-detail__button-check-group">
          <button className={cn("brand-detail__button brand-detail__button--sales", { on: favoriteState })} onClick={onClickFavorite}>관심 브랜드</button>
          <button className={cn("brand-detail__button brand-detail__button--compare", { on: comparisonState })} onClick={onClickComparison}>비교</button>
        </div>

        <button className="brand-detail__button brand-detail__button--franchise-application" onClick={handleOpen}>창업상담 신청</button>
      </div>

      <Modal
        open={open}
      >
        <Box>
          <PopupStartUpConsultationApplication
            brand={brand}
            buttonName="상담 신청하기"
            close={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default React.memo(BrandDetailRight);
