import React, { useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UnitNormalList from '~/components/listitems/UnitNormalList';
import { filterForm } from '~/sagas/actions/filterAction';
import { getBrandList } from '~/sagas/actions/listAction';

function BrandListContainer() {

  const dispatch = useDispatch();
  const { BrandList, form } = useSelector(({ list, filter }) => ({
      BrandList: list.BrandList.list,
      form: filter.form,
  }), shallowEqual);

  useEffect(()=> {
    //console.log('brandlistContainer');

    if ( form.selectedCategory.filter((i) => i.checked).length != 0) {
      dispatch(
          getBrandList(
            {
              ...form,
              selectedCategory: form.selectedCategory
                .filter((i) => i.checked)
                .map((i) => i.id)
                .join(),
              selectedBadge: form.selectedBadge
                .filter((i) => i.checked)
                .map((i) => i.id)
                .join(),
              selectedTheme: form.selectedTheme
                .filter((i) => i.checked)
                .map((i) => i.id)
                .join(),
            },
            form.more,
          ),
        );
    }
  }, [dispatch, form]);

  

  if ( form.selectedCategory.filter((i) => i.checked).length == 0 ) {
    return <div className="brand__list-check-category">업종을 선택해 주세요</div>;
  }

  // console.log(!BrandList);
  if ( !BrandList || BrandList.length == 0 ) {
    return <div className="brand__list-check-category">데이타가 없습니다</div>;
  }


  return (
        <div className="brand__list-container active">{BrandList ? <UnitNormalList items={BrandList} /> : '로딩중'}</div>

      
  );
}

export default React.memo(BrandListContainer);