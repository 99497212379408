import { Button } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import styled from "styled-components";
import HighLightSvg from "../../assets/images/onboarding/onboarding_prediction_hightlight.svg";
import BubbleSvg from "../../assets/images/onboarding/onboarding_prediction_bubble.svg";

const OnBoardingPrediction = React.forwardRef(({ onClickNext }, ref) => {
  return (
    <Container ref={ref}>
      <TopContainer></TopContainer>
      <MiddleContainer>
        <MiddleBlock />
        <HighLight>
          <img src={HighLightSvg} alt="" />
          <PredictionButtonContainer>
            <Button
              variant="contained"
              onClick={onClickNext}
              className={cn("btn-color", { on: false })}
              style={{
                color: "white",
                backgroundColor: "#ff662a",
                borderRadius: "20px",
              }}
            >
              매출예측
            </Button>
          </PredictionButtonContainer>
        </HighLight>
      </MiddleContainer>
      <BottomContainer />

      <BubbleContainer style={{ backgroundImage: `url(${BubbleSvg})` }}>
        <BubbleTitle>
          이미 똑똑한 사장 <span style={{ fontWeight: 400 }}>from 똑사</span>
        </BubbleTitle>
        <BubbleContents>
          환영합니다 👋 똑사 활용 첫걸음을 시작할게요!
          <br />
          <span style={{ fontWeight: 600, color: "#ed703e" }}>
            매출 예측을 클릭하세요
          </span>
        </BubbleContents>
      </BubbleContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 82px;
`;

const MiddleBlock = styled.div`
  flex: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BottomContainer = styled.div`
  width: 100%;
  min-height: 156px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HighLight = styled.div`
  width: 134px;
  height: 82px;

  margin-right: -5px;
`;

const PredictionButtonContainer = styled.div`
  position: fixed;
  right: 19px;
  bottom: 177px;
  z-index: 103;
`;

const BubbleContainer = styled.div`
  position: fixed;
  right: 140px;
  bottom: 165px;

  width: 416px;

  height: 125px;
  filter: drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25));

  padding: 24px 32px;
`;

const BubbleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #686868;
`;

const BubbleContents = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 8px;
`;

export default OnBoardingPrediction;
