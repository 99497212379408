import React from "react";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "~/common/NumberUtil";

function CoinListItem({ coinData }) {
  const history = useHistory();

  function onClickPayment() {
    history.push(`/payment?coinId=${coinData.id}`);
  }

  return (
    <button onClick={onClickPayment}>
      {numberWithCommas(coinData.price)}원
    </button>
  );
}

export default CoinListItem;
