export const SHOW_API_LOG = true;

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

const API_HOST_DEFS = {
  DEV: "http://localhost:8080/", // [개발]
  // DEV: "http://3.35.208.58:8080", // [실서버] // TODO 각 파일마다 중복인 것 -> 공통 /api/로 만들고, 각화면에서는 공통 참조
  PROD: "https://ttoksa.kr/", // [실서버] // TODO 각 파일마다 중복인 것 -> 공통 /api/로 만들고, 각화면에서는 공통 참조
};

export const API_HOST = IS_PRODUCTION ? API_HOST_DEFS.PROD : API_HOST_DEFS.DEV;
export const API_ROOT_PATH = "";
export const RESPONSE_DELAY = 0;

//http://13.125.182.28/13.125.182.28:8080/admin/corp/user

// 80 -> nginx -> http://13.125.182.28

// route
// 80 -> nginx -> http://13.125.182.28/ -> React -> static /build
// 80 -> nginx -> http://13.125.182.28/api/ -> Spring -> localhost:8080/admin/corp/user
