import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import "~/assets/scss/admin_franchise.scss";
import { Box, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import AdminHeader from "./AdminHeader";
import { useHistory } from "react-router";
import {
  getAllCosultList,
  getCosultList,
} from "~/sagas/api/admin/franchise_management";
import FranchiseManagementDetail from "./FranchiseManagementDetail";
import InquiryTable from "~/components/admin/InquiryTable";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function FranchiseManagement() {
  const history = useHistory();
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const { corpUserInfo } = useSelector(({ common }) => ({
    corpUserInfo: common.auth.corpUserInfo,
  }));
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [consultList, setConsultList] = useState({
    receipt: [],
    ing: [],
    consult_complete: [],
    keep: [],
    contract_complete: [],
  });

  const [details, setDetails] = useState({
    statusTitle: "",
    page: "LIST", // LIST, DETAIL
    startupConsultId: "",
    brand: null,
  });

  useEffect(() => {
    if (!corpUserInfo) return;
    getList();
  }, [corpUserInfo]);

  const getList = () => {
    //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

    if (!corpUserInfo) {
      return;
    }

    if (corpUserInfo.level === "ADMINISTRATOR") {
      getAllCosultList(
        corpUserInfo.selectedBrand.brandId,
        localStorage.getItem("jwtTokenAdmin")
      ).then((req) => {
        if (req.status === 200) {
          if (req.data) {
            setConsultList({
              receipt: req.data.filter((i) => i.consultType === "RECEIPT"),
              ing: req.data.filter((i) => i.consultType === "ING"),
              consult_complete: req.data.filter(
                (i) => i.consultType === "CONSULT_COMPLETE"
              ),
              keep: req.data.filter((i) => i.consultType === "KEEP"),
              contract_complete: req.data.filter(
                (i) => i.consultType === "CONTRACT_COMPLETE"
              ),
            });
          }
        }
      });
    } else if (corpUserInfo.selectedBrand) {
      getCosultList(
        corpUserInfo.selectedBrand.brandId,
        localStorage.getItem("jwtTokenAdmin")
      ).then((req) => {
        console.log(req);
        if (req.status === 200) {
          if (req.data) {
            setConsultList({
              receipt: req.data.filter((i) => i.consultType === "RECEIPT"),
              ing: req.data.filter((i) => i.consultType === "ING"),
              consult_complete: req.data.filter(
                (i) => i.consultType === "CONSULT_COMPLETE"
              ),
              keep: req.data.filter((i) => i.consultType === "KEEP"),
              contract_complete: req.data.filter(
                (i) => i.consultType === "CONTRACT_COMPLETE"
              ),
            });
          }
        }
      });
    }
  };

  const callList = useCallback(getList, [details]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onClickRow = useCallback(
    (title, consult, brand) => {
      setDetails({
        statusTitle: title,
        page: "DETAIL",
        startupConsultId: consult.id,
        brand,
      });
    },
    [details]
  );

  const onClickGetListAndGoList = useCallback(() => {
    callList();

    onClickGoList();
  }, [callList]);

  const onClickGoList = useCallback(() => {
    setDetails({
      statusTitle: "",
      page: "LIST",
    });
  }, [details]);

  useEffect(() => {
    callList();
  }, [details, corpUserInfo, history]);

  return (
    <div
      id="wrapper"
      className={cn(app.class, { dimmed: app.dimmed.isActive })}
    >
      <AdminHeader />
      <div className="main-container">
        <div className="admin-franchise__container">
          <div className={cn("list", { active: details.page === "LIST" })}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              aria-label="tabs"
            >
              <Tab
                label={`접수(${consultList.receipt.length})`}
                {...a11yProps(0)}
              />
              <Tab
                label={`상담중(${consultList.ing.length})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`상담완료(${consultList.consult_complete.length})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`보관(${consultList.keep.length})`}
                {...a11yProps(3)}
              />
              <Tab
                label={`계약완료(${consultList.contract_complete.length})`}
                {...a11yProps(4)}
              />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <InquiryTable
                  list={consultList.receipt}
                  onClickRow={onClickRow}
                  pageType={"RECEIPT"}
                  brandList={corpUserInfo.brandList}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <InquiryTable
                  list={consultList.ing}
                  onClickRow={onClickRow}
                  pageType={"ING"}
                  brandList={corpUserInfo.brandList}
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <InquiryTable
                  list={consultList.consult_complete}
                  onClickRow={onClickRow}
                  pageType={"CONSULT_COMPLETE"}
                  brandList={corpUserInfo.brandList}
                />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <InquiryTable
                  list={consultList.keep}
                  onClickRow={onClickRow}
                  pageType={"KEEP"}
                  brandList={corpUserInfo.brandList}
                />
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <InquiryTable
                  list={consultList.contract_complete}
                  onClickRow={onClickRow}
                  pageType={"CONTRACT_COMPLETE"}
                  brandList={corpUserInfo.brandList}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
          <div className={cn("detail", { active: details.page === "DETAIL" })}>
            <FranchiseManagementDetail
              onClickRow={onClickRow}
              details={details}
              onClickGoList={onClickGoList}
              onClickGetListAndGoList={onClickGetListAndGoList}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(FranchiseManagement);
