import "~/assets/scss/coin.scss";
import React, { useEffect, useState } from "react";
import { coinText } from "./Coin";
import { useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "~/App";

function MyCoin() {
  const [coinCnt, setCoinCnt] = useState(null);
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  useEffect(() => {
    if (!userInfo.id) return;
    const queryRef = query(
      collection(firestore, "wallet"),
      where("userId", "==", userInfo.id)
    );

    getDocs(queryRef)
      .then((querySnapshot) => {
        setCoinCnt(querySnapshot.empty ? 0 : querySnapshot.docs[0].data().coin);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, []);

  return (
    <div className="my-coin-cnt">
      <i className="coin-img">{coinText}이미지</i>
      <span className="coin-label">현재 보유 중인 {coinText}</span>
      {coinCnt ? (
        <span className="coin-cnt color">{coinCnt} 개</span>
      ) : (
        <span className="coin-cnt">없음</span>
      )}
    </div>
  );
}

export default React.memo(MyCoin);
