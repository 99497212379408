import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NaverMap from '~/components/container/map/NaverMap';
import { app } from '~/sagas/actions/commonAction';
import '~/assets/scss/map.scss';
import NaverAPIMap from '~/components/container/map/NaverAPIMap';

const MapScreen = ({match}) => {
  const dispatch = useDispatch();
  const { fully } = useSelector(({ common }) => ({
    fully: common.app.class,
  }));

  useEffect(() => {
    dispatch(
      app({
        class: 'full-size',
      }),
    );
  }, [dispatch]);
  return fully && <NaverAPIMap params={match.params}/>;
};

export default React.memo(MapScreen);
