import React from 'react';
import { useSelector } from 'react-redux';
import '~/assets/scss/home.scss';
import cn from 'classnames'
import BrandDetail from '../brand/BrandDetail';
import { Route } from 'react-router-dom';

const MenuWithScreen = () => {
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));


  return (
    <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
      {/* <Header /> */}
      <div id="container">
        <Route path="/brand/:brandId" component={BrandDetail} />
      </div>
    </div>
  );
};

export default MenuWithScreen;
