import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames'
import "~/assets/scss/admin_my_page.scss";
import AdminHeader from './AdminHeader';
import { modifyCorpUserMyPage, removeCorpUser, updateCorpUser } from "~/sagas/api/user";
import { useSnackbar } from 'notistack';
import { loginCorp } from '~/sagas/actions/commonAction';
import { Button, CircularProgress, Modal} from '@material-ui/core';
import { useHistory } from 'react-router';

function AdminMyPage () {

    const history = useHistory();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [corpName, setCorpName] = useState('');
    const [corpRank, setCorpRank] = useState('');
    const [corpEmail, setCorpEmail] = useState('');
    const [corpPhoneNumber, setCorpPhoneNumber] = useState('');
    const [corpCheckbox, setCorpCheckbox] = useState({
        receiveAlarmTalk: false,
        useService: false,
        userInfoAgree: false,
        markettingAgree: false
    });
    const inputCorpName = useRef(null);
    const inputCorpRank = useRef(null);
    const inputCorpPhoneNumber = useRef(null);
    const inputCorpEmail= useRef(null);

    const [inputs, setInputs] = useState({
        password1: "",
        password2: ""
    });

    const [changePassword, setChangePassword] = useState(false); //비밀번호 변경 팝업
    const [secession, setSecession] = useState(false); //탈퇴 팝업
    const [secessionComplete, setSecessionComplete] = useState(false); //탈퇴 완료 팝업
    const [loading, setLoading] = useState(false);


    const changePasswordInput1 = useRef();
    const changePasswordInput2 = useRef();
    

    const dispatch = useDispatch();
    const { app } = useSelector(({ common }) => ({
      app: common.app,
    }));
    const { corpUserInfo } = useSelector(({ common }) => ({
      corpUserInfo: common.auth.corpUserInfo,
    }));

    //이름 입력
    const changeCorpName = (e) => {
        setCorpName(e.target.value);
    };
    //직급 입력
    const changeCorpRank = (e) => {
        setCorpRank(e.target.value);
    };
    //폰 번호 입력
    const changeCorpPhoneNumber= (e) => {
        setCorpPhoneNumber(e.target.value);
    };
    //이메일 입력
    const changeCorpEmail = (e) => {
        setCorpEmail(e.target.value);
    };

    // customized
    const actionCloseChangePasswordPopup = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    ), [closeSnackbar]);


    const onChangeField = useCallback((e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    
    }, [inputs]);

    const modifyUser = useCallback(async () => {
        await updateCorpUser(corpUserInfo.id, {password1:inputs.password1}, localStorage.getItem("jwtTokenAdmin"));
    }, [corpUserInfo, inputs]);

    const closeChangePasswordPopup = () => {
        setChangePassword(false);
    };

    const handleCheck = useCallback((name) => {
        let value;

        if (name === "receiveAlarmTalk") {
            value = !corpCheckbox.receiveAlarmTalk;
        } else if (name === "useService") {
            value = !corpCheckbox.useService;
        } else if (name === "userInfoAgree") {
            value = !corpCheckbox.userInfoAgree;
        } else if (name === "markettingAgree") {
            value = !corpCheckbox.markettingAgree;
        }

        setCorpCheckbox({
            ...corpCheckbox,
            [name]: value
        });

    }, [corpCheckbox]);


    const saveChangePassword = useCallback(async () => {
        const {password1, password2} = inputs;

        if ( password1.trim().length < 6) {
            enqueueSnackbar("6자리 이상 입력해 주세요.", {
                className:"snackbar warning",
                autoHideDuration: 3000
            });
        } else if ( password1.trim() !== password2.trim() ) {
            enqueueSnackbar("비밀번호가 서로 다릅니다. 다시 입력 해주세요.", {
                className:"snackbar warning",
                autoHideDuration: 3000
            });
        } else {
            setLoading(true);
        
            await modifyUser();

            setLoading(false);

            closeChangePasswordPopup(); 

            enqueueSnackbar("비밀번호가 변경되었습니다.", {
                className:"snackbar success",
                autoHideDuration: 3000,
                action: actionCloseChangePasswordPopup
            });

            // setInputs({
            //     password1: "",
            //     password2: ""
            // }); //비밀번호 초기화
        }

    }, [actionCloseChangePasswordPopup, enqueueSnackbar, modifyUser, inputs]);

    const snackbar = (msg) => {
        enqueueSnackbar(msg, {
            className:"snackbar warning",
            autoHideDuration: 1000
          });
    }


    const getTextLength = function(str) {
        var len = 0;
        for (var i = 0; i < str.length; i++) {
            if (escape(str.charAt(i)).length === 6) {
                len++;
            }
            len++;
        }
        return len;
    }

    //회원가입 할때 api 참고( FIXME)
    const modifyCorpUserMyPageEvent = () => {

        //유효성 검사 후
        if ( corpName.trim() === "" || getTextLength(corpName) < 2) {
            snackbar("담당자 이름을 확인해 주세요.");
            inputCorpName.current.focus();
            return;
        }
        if ( corpRank.trim() === "" || getTextLength(corpRank) < 2) {
            snackbar("직급을 확인해 주세요.");
            inputCorpRank.current.focus();
            return;
        }
        
        if ( /^\d{2,3}-?\d{3,4}-?\d{4}$/.test(corpPhoneNumber) === false ) {
            snackbar("휴대전화를 8자 이상 입력해 주세요.");
            inputCorpPhoneNumber.current.focus();
            return;
        }

        if ( /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(corpEmail.trim()) === false ) {
            snackbar("이메일 정보를 확인해 주세요.");
            inputCorpEmail.current.focus();
            return;
        }
        

        modifyCorpUserMyPage(corpUserInfo.id, {
            name:corpName,
            email:corpEmail,
            rank:corpRank,
            phoneNumber:corpPhoneNumber,
            receiveAlarmTalk:corpCheckbox.receiveAlarmTalk
        }, localStorage.getItem("jwtTokenAdmin")).then( data => {

                if ( data.status === 200 ) {
                    dispatch(
                        loginCorp({
                            ...corpUserInfo, 
                            name:corpName,
                            email:corpEmail,
                            rank:corpRank,
                            phoneNumber:corpPhoneNumber,
                            receiveAlarmTalk:corpCheckbox.receiveAlarmTalk
                        })
                    );

                    enqueueSnackbar("저장 되었습니다.", {
                        className:"snackbar success",
                        autoHideDuration: 3000
                    });
                }
                
            }
        );

        
 
     };
 

    const openSecessionPopup = () => {
        setSecession(true);
    };

     const deleteUser = useCallback(async () => {
         await removeCorpUser(corpUserInfo.id, localStorage.getItem("jwtTokenAdmin"));
         localStorage.removeItem("jwtTokenAdmin");
         dispatch(loginCorp({
            id: "",
            name: "",
            email: "",
            rank: '',
            phoneNumber: '',
            receiveAlarmTalk: '',
            useService: '',
            userInfoAgree: '',
            markettingAgree: ''
           }));
     }, [dispatch, corpUserInfo]);

     const closeSecessionPopup = () => {
        setSecession(false);
    };

    const openChangePasswordPopup = () => {
        setChangePassword(true);
    };

    const openSecessionCompletePopup = useCallback(async () => {
        await deleteUser();
        setSecession(false);
        setSecessionComplete(true);
    }, [deleteUser]);

    const goHome = () => {
        history.replace("/corp/login");
    };

    useEffect(() => {
        

        setCorpName(corpUserInfo.name);
        setCorpRank(corpUserInfo.rank);
        setCorpEmail(corpUserInfo.email);
        setCorpPhoneNumber(corpUserInfo.phoneNumber);
        setCorpCheckbox({
            ...corpCheckbox,
            receiveAlarmTalk:corpUserInfo.receiveAlarmTalk
        });

    }, [corpUserInfo, history]);

    return (
        <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
            <AdminHeader />
            <div className="admin-my-page">
                <div className="join__input">
                    <div className="admin-title">계정 관리</div>
                    <div className="join__input-group">
                        <input type="text" className="join__input-box" defaultValue={corpName} onChange={changeCorpName} ref={inputCorpName} />
                        <span className="join__input-title">담당자 이름</span>
                    </div>
                    <div className="join__input-group">
                        <input type="text" className="join__input-box" defaultValue={corpRank} onChange={changeCorpRank} ref={inputCorpRank}/>
                        <span className="join__input-title">직급</span>
                    </div>
                    <div className="join__input-group ">
                        <input type="text" className="join__input-box" defaultValue={corpPhoneNumber} onChange={changeCorpPhoneNumber} ref={inputCorpPhoneNumber}/>
                        <span className="join__input-title">휴대전화 번호</span>
                    </div> 
                    <div className="join__input-group">
                        <input type="text" className="join__input-box" defaultValue={corpEmail} onChange={changeCorpEmail} ref={inputCorpEmail} placeholder="이메일 주소는 아이디로 이용합니다." disabled/>
                        <span className="join__input-title">이메일 주소</span>
                    </div>
                </div>

                <span className="sub-title">비밀번호 변경</span>
                <div className="divider"></div>

                <button className="my-page__change-btn" onClick={openChangePasswordPopup}>변경하기</button>

                <span className="sub-title">알림 동의</span>
                <div className="divider"></div>
                <div className="join__agree-check-box margin-min">
                    <div className="join__agree-check-row">
                        <label className="checkbox check-primary">
                            <input type="checkbox" name="receiveAlarmTalk" onChange={()=>handleCheck("receiveAlarmTalk")} defaultChecked={corpCheckbox.receiveAlarmTalk}/>
                            <i></i>
                            <span>창업 문의 접수, 서비스 공지사항 알림톡 받기</span>
                        </label>
                    </div>
                </div>

                <button className="my-page__save-btn" onClick={modifyCorpUserMyPageEvent}>저장</button>
                <button className="delete-user" onClick={openSecessionPopup}>탈퇴하기</button>
                <div className="height80"></div>
            </div>

            <Modal
                        open={changePassword}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">비밀번호 변경하기</div>
                            <button className="password-change__close-btn" onClick={closeChangePasswordPopup}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="main-group">
                                <div className="group">
                                    <input type="password" name="password1" className="input-box" onChange={onChangeField} ref={changePasswordInput1}/>
                                    <span className="input-title">새 비밀번호</span>
                                </div>
                                <div className="group">
                                    <input type="password" name="password2" className="input-box" onChange={onChangeField} ref={changePasswordInput2}/>
                                    <span className="input-title">새 비밀번호 확인</span>
                                </div>
                            </div>                
                            <div className="btn-and-progress-group">

                            <Button
                                className="password-change__content-btn"
                                disabled={loading}
                                onClick={saveChangePassword}
                                >
                                {
                                    loading ? 
                                        <div className="circular-progress-box">
                                            <CircularProgress className="circular-progress" size={20}/>
                                        </div>
                                        : "비밀번호 변경하기"
                                }
                                </Button>
                                {/* <div className="password-change__content-btn" onClick={saveChangePassword}>비밀번호 변경하기</div> */}
                                
                            </div>
                            
                        </div>
                    </div>
                </Modal>

                <Modal
                        open={secession}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">탈퇴하기</div>
                            <button className="password-change__close-btn" onClick={closeSecessionPopup}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="password-change__content-content">
                                 탈퇴하시겠습니까?
                            </div>
                            <div className="password-change__content-btn-group">
                                <div className="password-change__content-btn grey" onClick={closeSecessionPopup}>취소</div>
                                <div className="password-change__content-btn" onClick={openSecessionCompletePopup}>탈퇴하기</div>
                            </div>       
                        </div>
                    </div>
                </Modal>

                <Modal
                        open={secessionComplete}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">탈퇴 완료</div>
                            <button className="password-change__close-btn" onClick={goHome}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="password-change__content-content">
                                정상적으로 탈퇴 처리되었습니다
                            </div>                
                            <div className="password-change__content-btn" onClick={goHome}>확인</div>
                        </div>
                    </div>
                </Modal>
        </div>
    );
}

export default AdminMyPage;