import React from "react";
import ManagerComment from "./ManagerComment";
import SalesOfHeadQuaterItem from "./SalesOfHeadQuaterItem";

function SalesOfHeadQuater({ salesOfHeadQuater, franchiseStartDate, logo, comment, hideComment }) {
  if (salesOfHeadQuater.length < 1) return null;

  salesOfHeadQuater.sort((a, b) => b.year - a.year);//년도 오름차순
  // console.log(salesOfHeadQuater);

  return (
    <div className="brand-detail__head-quater-sales">
      {
        !hideComment &&
        <span className="brand-detail__title">본사 매출정보</span>
      }

      <span className="brand-detail__sub-title">
        {salesOfHeadQuater != null && salesOfHeadQuater.length > 0 ? (
              <div>{salesOfHeadQuater[0].year}년 정보공개서 기준</div>
            ) : null}
      </span>
    
      <SalesOfHeadQuaterItem salesOfHeadQuater={salesOfHeadQuater} franchiseStartDate={franchiseStartDate}/>

      {
        !hideComment && comment && comment.headquartersFinancialSituationComment &&
        <ManagerComment key="headquartersFinancialSituationComment" logo={logo} comment={comment.headquartersFinancialSituationComment} name={comment.userName} modifyAt={comment.modifyAt}/>
      }
      

    </div>
  );
}

export default SalesOfHeadQuater;
