import React, { useCallback } from "react";
import "~/assets/scss/footer.scss";
import { popup } from "~/sagas/actions/commonAction";
import { useDispatch } from "react-redux";

const Footer = () => {
  const dispatch = useDispatch();
  const openUsingRules = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        popup({
          usingRules: true,
        })
      );
    },
    [dispatch]
  );
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__main-cc-parent">
          <div className="footer__main-group">
            <span>똑똑한사장(주)</span>
            <div className="footer__sub-group">
              <div className="footer__address">
                <span>Address</span>
                <span>
                  서울특별시 동대문구 한천로2길 16, 105호(장안동, 덕양빌딩)
                </span>
              </div>
              <div className="footer__representative">
                <span>대표자</span>
                <span>김형래</span>
              </div>
              <div className="footer__business-number">
                <span>사업자등록번호</span>
                <span>566-87-02265</span>
              </div>
              <div className="footer__business-number">
                <span>통신판매업신고번호</span>
                <span>2023-서울동대문-1481</span>
              </div>
            </div>
          </div>
          <div className="footer__cc-group">
            <span>고객센터</span>
            <a href="tel::070-7954-1454">070-7954-1454</a>
          </div>
        </div>
        <div className="footer__divider-group"></div>
        <div className="footer__terms-group">
          <div className="footer__terms-info">
            <button onClick={openUsingRules}>이용약관</button>
            <a href="/term/privacy">개인정보처리방침</a>
            <a href="https://habitual-triangle-cc6.notion.site/586f9d8b0621419ea1a5fd9b459f7530">
              회사소개
            </a>
          </div>
          <span className="footer__terms-copyright">
            COPYRIGHT © 2021 똑똑한 사장 ALL RIGHTS RESERVED.
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
