import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import '~/assets/scss/search_brand_popup.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { popup } from '~/sagas/actions/commonAction';
import cn from 'classnames';
import { getJoinBrandList } from '~/sagas/api/join';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';


function SearchBrandPopup( {onClickBrand} ) {

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const [selectBrand, setSelectBrand] = useState(null); //선택한 브랜드
  const [brandAll, setBrandAll] = useState(null); //원본 초기 상태 유지
  const [brandList, setBrandList] = useState(null); //필터링 된 브랜드 목록
  const { searchBrand } = useSelector(({ common }) => ({
    searchBrand: common.popup.searchBrand,
  }), shallowEqual);

  const closeSearchBrand = useCallback(() => {
      setSelectBrand(null);
      setBrandList(brandAll ? brandAll.filter(item => true) : null);
      dispatch(
          popup({
            searchBrand: false,
          }),
      );
  }, [dispatch, selectBrand, brandAll]);

  const debounce = (func, delay)=> {
    let inDebounce;
    if ( inDebounce ) {
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout( func(), delay);
  }

  const onChangeBrandSearch = (e) => {
    const { value } = e.target;
    debounce( () => {
      const list = brandAll && brandAll.filter( item => value !== '' && (item.name.indexOf(value) !== -1 || (item.headQuaterName && item.headQuaterName.indexOf(value) !== -1 )));
      setBrandList(list);
      setSelectBrand(null);

    }, 100);
  };

  const onClickSelectBrandRow = (brand) => {
    setSelectBrand(brand);
  };

  // customized
  const actionTrue = useCallback(key => (
    <>
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    </>
  ), [closeSnackbar]);

  const okSelectButton = () => {

    if ( selectBrand ) {
      onClickBrand(selectBrand);
      closeSearchBrand();
    } else {
      enqueueSnackbar("브랜드를 선택하세요.", {
        className:"snackbar favorite-delete",
        action: actionTrue,
      });
    }
  }

  useEffect(() => {
    
    getJoinBrandList().then(res=> {
      
      if ( res.status === 200 ) {
        setBrandAll(res.data);
        setBrandList(res.data);
      }
    });

  }, []);

  return (
    <Modal
          open={searchBrand}
      >
          <div className="search-brand__container">
              <div className="search-brand__title-group">
                <div className="search-brand__title">브랜드 검색</div>
                <button className="search-brand__close-btn" onClick={closeSearchBrand}>close</button>
              </div>

              
              <div className="search-brand__content-group">
                <div className="search-brand__content-group-inner">
                  <input type="text" className="search-brand__input-email" placeholder="브랜드 또는 본사 이름 입력" onChange={onChangeBrandSearch}/>
                  
                  
                  {/* 최초 보여지는 문구 */}
                  <div className={cn("search-brand__content-sub-group", {active : false})}>
                    <div className="search-brand__content-content">
                      정보공개서에 등록한 브랜드 이름 또는<br/> 
                      본사 이름으로 검색할 수 있습니다.
                    </div>
                    <div className="search-brand__content-content">
                      브랜드가 검색되지 않을 경우 문의하기 또는<br />
                      02-0000-0000로 연락해주세요.
                    </div>
                  </div>

                  {/* 검색 결과가 없습니다. */}
                  <div className={cn("search-brand__content-sub-group", {active : false})}>
                    <div className="search-brand__content-content">
                      검색 결과가 없습니다.
                    </div>
                  </div>

                  {/* 검색 결과가 있는 경우 */}
                  <div className={cn("search-brand__content-sub-group", {active : true})}>
                    {
                      brandList &&
                      brandList.map(item => (
                        <div key={item.brandId} className={classNames("search-brand__brand-list-box", {selected: selectBrand && selectBrand.brandId === item.brandId})} onClick={() => onClickSelectBrandRow(item)} onDoubleClick={() => {closeSearchBrand(); onClickBrand(item);}}>
                          <div>
                            <span>{item.name}</span>
                            <span>
                              { item.companyRegistrationNumber && item.companyRegistrationNumber.length === 10 ?
                                  `${item.companyRegistrationNumber.substring(0,3)}-${item.companyRegistrationNumber.substring(3,5)}-${item.companyRegistrationNumber.substring(5,10)}`
                                : item.companyRegistrationNumber 
                              }
                            </span>
                          </div>
                          <span>{item.headQuaterName ? item.headQuaterName : <em>&nbsp;</em> }</span>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div className={cn("search-brand__content-group-divider", {active : true})}></div>

                <div className={cn("search-brand__content-group-footer", {active : true})}>
                  <span>관리할 브랜드를 선택해 주세요.</span>
                  <button onClick={okSelectButton}>선택</button>
                </div>

              </div>
          </div>
      </Modal>
  );
}


export default React.memo(SearchBrandPopup);