import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { formattedNum } from "~/common/NumberUtil";
import styled from "styled-components";

const PredictionMarker = ({ data, id }) => {
  return (
    <Container>
      <BrandDetailContainer>
        {data.brand.logoImageUrlThumb ? (
          <BrandLogo
            src={data.brand.logoImageUrlThumb}
            alt={`${data.brand.name}의 로고`}
          />
        ) : (
          <BrandLogoEmpty />
        )}
        <BrandContentsContainer>
          <BrandTitleContainer>
            <BrandTitle>{data.brand.name}</BrandTitle>
            <PredictionPercentageContainer>
              <span>AI</span>
              <span> 정확도 </span>
              <span>
                {data.accuracy}
                <u>% 이하</u>
              </span>
            </PredictionPercentageContainer>
          </BrandTitleContainer>
          <PredictionData>
            월평균매출액 {formattedNum(data.avg * 1000).replaceAll(",", "")}원 /
            평당
            {formattedNum((data.avg * 1000) / data.baseArea).replaceAll(
              ",",
              ""
            )}
            원({data.baseArea}평 기준, 정보공개서)
          </PredictionData>
        </BrandContentsContainer>
      </BrandDetailContainer>
      <UtilContainer>
        <UtilRow>
          <UtilButton id={`btn-${data.brand.id}-detail-${id}`}>
            브랜드 상세보기
          </UtilButton>
          <Divider />
          <UtilButton id={`btn-${data.brand.id}-startup-${id}`}>
            여기에 창업하기
          </UtilButton>
        </UtilRow>
        <div
          style={{
            width: "calc(100% - 16px)",
            margin: "4px 0",
            height: "1px",
            backgroundColor: "rgba(101, 101, 101, 0.1)",
          }}
        />
        <UtilRow>
          <UtilButton
            style={{ color: "#ed703e", fontSize: "16px" }}
            id={`btn-${data.brand.id}-report-${id}`}
          >
            창업 비법 레포트 받기
          </UtilButton>
        </UtilRow>
      </UtilContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 12px 14px 9px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  gap: 9px;

  cursor: default !important;

  min-width: 430px;
`;

const BrandDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const BrandLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
`;

const BrandLogoEmpty = styled.div`
  width: 50px;
  height: 50px;
  background: #d9d9d9;
  border-radius: 6px;
`;

const BrandContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BrandTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

const BrandTitle = styled.div`
  font-weight: 500;
  font-size: 12.6px;

  color: #000000;
`;

const PredictionPercentageContainer = styled.div`
  padding: 0 5px;
  background: #f3f5f8;
  border-radius: 6px;

  font-family: "Noto Sans";
  font-size: 10px;
  span:nth-child(1) {
    font-weight: 700;
    color: #366c82;
  }
  span:nth-child(2) {
    font-weight: 500;
    color: #141414;
  }
  span:nth-child(3) {
    font-weight: 600;
    color: #fd8724;
  }
`;

const PredictionData = styled.div`
  font-weight: 400;
  font-size: 12.6px;

  color: #000000;
`;

const UtilContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(101, 101, 101, 0.1);
  border-radius: 12px;

  padding: 4px;
`;

const UtilRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
`;

const UtilButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;

  color: #323030;
  padding: 8px 0;
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: rgba(101, 101, 101, 0.1);
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (data, id) =>
  renderToStaticMarkup(<PredictionMarker data={data} id={id} />);
