import client from "../client";

export const getAllCosultList = (brandId, tokenVal) =>
  client.post(`/admin/consultation/list`, null, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

export const getCosultList = (brandId, tokenVal) =>
  client.post(`/admin/consultation/${brandId}/list`, null, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

export const getCosultDetail = (consultId, tokenVal) =>
  client.get(`/admin/consultation/list/${consultId}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

export const getCosultListByType = (brandId, type, tokenVal) =>
  client.post(`/admin/consultation/${brandId}/${type}/list`, null, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

export const updateCosultDetail = (contranceId, body, tokenVal) =>
  client.post(`/admin/consultation/list/${contranceId}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });

export const deleteCosultDetail = (contranceId, tokenVal) =>
  client.delete(`/admin/consultation/list/${contranceId}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + tokenVal,
    },
  });
