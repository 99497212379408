import React, { useCallback, useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PopupStartUpConsultationApplication from "../brand/PopupStartUpConsultationApplication";
import { login, popup } from "~/sagas/actions/commonAction";
import { addBrandsWithComparison, addBrandsWithInterests, removeBrandsWithComparison, removeBrandsWithInterests } from "~/sagas/api/favorite";


function MapFranchiseRecruit({branch, brand}) {
    const {id} = brand[0];
    const [favoriteState, setFavoriteState] = useState(false);
    const [comparisonState, setComparisonState] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { auth } = useSelector(({ common }) => ({
      auth: common.auth,
    }));
  
    const handleOpen = (index) => {
      setOpen(true);
    };
  
    const handleClose = (index) => {
      setOpen(false);
    };
    
    //로그인 팝업 띄우기
    const openLoginMessagePopup = useCallback(() => {
      dispatch(
        popup({
          loginMessage: true,
        }),
      );
    }, [dispatch]);
  
    //비교하기 등록/삭제 기능
    const onClickComparison = useCallback(async (e) => {
      //로그인 여부 확인 후 
      if ( !auth.userInfo || !auth.userInfo.email ) {
        //openLoginMessagePopup 호출 정하고
        e.preventDefault();
        openLoginMessagePopup();
      
      } else {//로그인 되어 있으면
        //비교하기 등록/삭제 기능
        setComparisonState(prev => !prev);
  
        if ( comparisonState ) { //이전 상태값이 true
          //false 가 되었고 db 에서 제거.
          await removeBrandsWithComparison(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              comparisonTargetList: auth.userInfo.comparisonTargetList.filter(i => i !== id )
            })
          );
          
        } else {
          await addBrandsWithComparison(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              comparisonTargetList: auth.userInfo.comparisonTargetList.concat(id)
            })
          );
  
        }
      }
  
    }, [dispatch, id, openLoginMessagePopup, auth, comparisonState]);
  
  
    //관심 브랜드 등록/삭제 기능
    const onClickFavorite = useCallback( async (e) => {
      //로그인 여부 확인 후 
      if ( !auth.userInfo || !auth.userInfo.email ) {
        //openLoginMessagePopup 호출 정하고
        e.preventDefault();
        openLoginMessagePopup();
  
      } else {//로그인 되어 있으면
        //관심 브랜드 등록/삭제 기능
        setFavoriteState(prev => !prev);
  
        if ( favoriteState ) { //이전 상태값이 true
          //false 가 되었고 db 에서 제거.
          await removeBrandsWithInterests(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              brandOfInterestList: auth.userInfo.brandOfInterestList.filter( i => i !== id )
            })
          );
  
        } else {
          await addBrandsWithInterests(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              brandOfInterestList: auth.userInfo.brandOfInterestList.concat(id)
            })
          );
  
        }
      }
      
    }, [dispatch, id, openLoginMessagePopup, auth, favoriteState]);
  
    useEffect(() => {
      // console.log(auth.userInfo);
  
      setComparisonState(auth.userInfo.comparisonTargetList && auth.userInfo.comparisonTargetList.indexOf(id) !== -1 ? true : false);
      setFavoriteState(auth.userInfo.brandOfInterestList && auth.userInfo.brandOfInterestList.indexOf(id) !== -1 ? true : false);
      
        // eslint-disable-next-line
    }, [auth]);
  
    return (
      <>
        <div className="map__franchise-title-group">
            <span>이 지역 가맹모집 중 프랜차이즈</span>
            {/* <div>
              <i className="on"></i>
              <i></i>
              <i></i>
            </div> */}
          </div>
  
          <div className="map__franchise-brand-group">
            <div className="map__list-item">
                <img className="map__brand_img" src={brand[0].logoImageUrlThumb ? brand[0].logoImageUrlThumb : "/placeholder_logo.png"} alt="BRAND" />
                <div className="map__item-right">
                    <div className="map__info-and-event">
                      <div className="map__brand_info">
                          <span className="map__brand_category">{brand[0].category && brand[0].category.name}</span>
                          <div className="map__item_location">
                            <i>marker</i>
                            <div className="map__branch_area">{branch.address.split(" ").filter( (i, idx) => idx < 3).join(" ")}</div>
                          </div>
                      </div>
                      <div className="util">
                        <label onClick={onClickComparison}>
                          <i className={cn("comparison", {on: comparisonState})}></i>
                          <span>비교</span>
                        </label>
  
                        
                        <i className={cn("favorite", {on: favoriteState})} onClick={onClickFavorite}></i>
                        
                      </div>
                    </div>
                    <div className="map__brand-name">
                    {brand[0].name}
                    </div>
                </div>
            </div>
            <div className="map__brand-intro-ask">
              <Link to={`/brand/${brand[0].id}`} target="_blank">
              <button className="map__brand-intro">브랜드 소개</button>
              </Link>
              <button className="map__brand-ask" onClick={handleOpen}>가맹문의</button>
            </div>
          </div>
            <Modal
                open={open}
              >
                <PopupStartUpConsultationApplication
                  brand={brand[0]}
                  buttonName="Map"
                  close={handleClose}
                />
            </Modal>
          </>
    );
  }

  export default React.memo(MapFranchiseRecruit);