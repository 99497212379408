import React from "react";

function ManagerComment({logo, comment, name, modifyAt}) {

  return (
    <div className="brand-detail__representative-comment"> 
      <div className="brand-detail__representative-comment-img-group">
        <img src={logo ? logo : "../../placeholder_logo.png"} alt="logo" />
      </div>
      <div>
        <div className="comment-area">
            {comment}
        </div>
        <div>
          <span>{name}</span>
          <span>{modifyAt && modifyAt.substring(0, 10).replace("T", ' ')}</span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ManagerComment);