import cn from "classnames";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getBrandsByKeyword } from "~/sagas/api/brand";
import MapSearchResultText from "~/screen/maps/MapSearchResultText";

let searchResult = [];
let lastValue;

const BrandSelectModal = React.forwardRef(
  ({ isOpen, onClose, hotBrandList, onClickBrand, containerStyle }, ref) => {
    let inDebounce;

    const [searchKeyword, setSearchKeyword] = useState(""); //검색시 키워드
    const [resultBrandList, setResultBrandList] = useState([]); //검색시 brand List에서 검색어에 해당되는 브랜드 목록들

    const debounce = (func, delay) => {
      if (inDebounce) {
        clearTimeout(inDebounce);
      }
      inDebounce = setTimeout(func(), delay);
    };

    //브랜드 검색 키 입력 이벤트
    const onChangeBrandSearch = (e) => {
      const { value } = e.target;
      lastValue = value;
      setSearchKeyword(value);

      debounce(() => {
        if (value) {
          getBrandsByKeyword(value).then((res) => {
            if (res.status === 200) {
              const data = res.data;
              searchResult.push(data);
              if (searchResult.length > 10) {
                searchResult.shift();
              }

              searchResult.map((item) => {
                if (item.keyword === lastValue) {
                  setResultBrandList(item.brandList.slice(0, 3));
                }

                return item;
              });
            }
          });
        } else {
          setResultBrandList([]);
        }
      }, 1000);
    };

    useEffect(() => {
      if (!isOpen) {
        setResultBrandList([]);
        setSearchKeyword("");
      }
    }, [isOpen]);

    return (
      <ModalContainer style={containerStyle} ref={ref}>
        <CloseButton onClick={onClose}>
          <img
            src={
              require("../../../assets/images/icon/icon_map_brandselect_close.svg")
                .default
            }
            alt="close modal"
          />
        </CloseButton>
        <ModalTitle>
          <ModalTitleHighLight>어떤 브랜드</ModalTitleHighLight>의 매출을
          예측할까요?
        </ModalTitle>
        <InputContainer>
          <SearchIcon
            src={
              require("../../../assets/images/icon/icon_map_brandselect_search.svg")
                .default
            }
            alt="search icon"
          />
          <TextInput
            placeholder="브랜드명을 검색하세요"
            value={searchKeyword}
            onChange={onChangeBrandSearch}
          />
          <AutoCompleteContainer
            on={resultBrandList && resultBrandList.length > 0}
          >
            {resultBrandList
              ? resultBrandList.map((item) => (
                  <AutoCompleteItemContainer onClick={() => onClickBrand(item)}>
                    <BrandName>
                      <MapSearchResultText
                        text={item.name}
                        searchKeyword={searchKeyword}
                      />
                      <BrandCategory>{`  · ${item.categoryName}`}</BrandCategory>
                    </BrandName>
                  </AutoCompleteItemContainer>
                ))
              : null}
          </AutoCompleteContainer>
        </InputContainer>

        {resultBrandList.length === 0 && (
          <HotBrandContainer>
            <HotBrandTitle>인기 검색어</HotBrandTitle>
            <HotBrandList>
              {hotBrandList.map((ele) => (
                <HotBrandItemContainer
                  key={ele.id}
                  className={cn({})}
                  onClick={() => onClickBrand(ele)}
                >
                  <HotBrandName>{ele.name}</HotBrandName>
                </HotBrandItemContainer>
              ))}
            </HotBrandList>
          </HotBrandContainer>
        )}
      </ModalContainer>
    );
  }
);

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  padding: 12px 0 36px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  margin-top: 25vh;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-end;
  margin-right: 11.5px;
`;

const ModalTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #1c1c1c;
`;

const ModalTitleHighLight = styled.span`
  color: #ed703e;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 515px;
  min-height: 46px;
  border: 4px solid #ed703e;

  margin: 22px 26px 0;

  position: relative;
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;

  margin-left: 8px;
  margin-right: 10px;
`;

const TextInput = styled.input`
  flex: 1;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: 0;
  margin-right: 8px;

  outline: none;

  ::placeholder {
    color: rgba(68, 67, 67, 0.55);
  }
`;

const HotBrandContainer = styled.div`
  margin: 12px 26px 0;
`;

const HotBrandTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: rgba(68, 67, 67, 0.55);
`;

const HotBrandList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 515px;
  margin-top: 12px;

  column-gap: 16px;
  row-gap: 10px;
`;

const HotBrandItemContainer = styled.div`
  width: 90px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 0;
  background: #ffffff;
  border: 1px solid #ed703e;
  border-radius: 4px;
  cursor: pointer;
`;

const HotBrandName = styled.span`
  max-width: 90px;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ed703e;
  overflow: hidden;
  white-space: nowrap;
`;

const AutoCompleteContainer = styled.div`
  display: ${(props) => (props.on ? "block" : "none")};
  width: 100%;

  overflow-y: auto;
  border-right: 1px solid #ed703e;
  border-left: 1px solid #ed703e;
  border-bottom: 1px solid #ed703e;
  z-index: 106;
  background: #fff;
  padding: 6px 8px;
  left: 0;
  top: 42px;
  position: absolute;
`;

const AutoCompleteItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px 12px;

  border-radius: 4px;
  cursor: pointer;
  :hover {
    background: rgba(101, 101, 101, 0.1);
  }
`;

const BrandName = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #4443438c;
`;

const BrandCategory = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #4443438c;
`;

export default BrandSelectModal;
