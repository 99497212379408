import { NavLink } from "react-router-dom";
import useQuery from "~/common/useQuery";

export default function PaymentFailedScreen() {
  const query = useQuery();
  return (
    <div>
      <h1>결제 실패</h1>
      <div>{`사유: ${query.get("message")}`}</div>
      <NavLink
        to="/"
        style={{
          padding: "0 10px",
          backgroundColor: "#ff662a",
          color: "white",
          borderRadius: "10px",
        }}
      >
        돌아가기
      </NavLink>
      <a
        href="https://developers.tosspayments.com/my/payment-logs"
        style={{
          padding: "0 10px",
          backgroundColor: "#ff662a",
          color: "white",
          borderRadius: "10px",
        }}
      >
        결제내역 확인하기
      </a>
    </div>
  );
}
