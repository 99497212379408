import { createAction, handleActions } from "redux-actions";
import * as type from "../types/startUpCostType";

export const getStartupCost = createAction(type.GET_START_UP_COST);
export const setStartupCost = createAction(type.SET_START_UP_COST, ({from, to, isChecked}) => ({from, to, isChecked}));


const initialState = {
  from: 0,
  to: 500000000,
  isChecked: false,
};

const startUpCostStore = handleActions(
  {
    [type.GET_START_UP_COST]: (state, { req, payload }) => ({
      ...state,
    }),
    [type.SET_START_UP_COST]: (state, { req, payload }) => ({
      ...state,
      from: payload.from,
      to: payload.to,
      isChecked: payload.isChecked,
    }),
  },
  initialState
);

export default startUpCostStore;
