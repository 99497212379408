import TipBGSvg from "~/assets/images/image_thirdlayer_tip.svg";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { popup } from "~/sagas/actions/commonAction";
import { useDispatch } from "react-redux";
import { finishLoading, startLoading } from "~/sagas/actions/loadingAction";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

export default function PredictionResult({ isLogin, predictionData }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoaded(false);
  }, [predictionData, isLogin]);

  const onPressPredictionButton = () => {
    logEvent(analytics, "lefttab-click-prediction");
    dispatch(
      popup({
        login: true,
      })
    );
  };

  const getStringfiedPrediction = (minValue, maxValue) => {
    const number = ((minValue + maxValue) / 2).toFixed(0);

    const billions = Math.floor(number / 100000000); // 억
    const millions = Math.floor((number % 100000000) / 10000); // 만

    let result = "";
    if (billions > 0) {
      result += `${billions}억 `;
    }
    if (millions > 0) {
      result += `${millions.toLocaleString()}만원`;
    }

    return result || "0원"; // 숫자가 0인 경우
  };

  const onClickLoading = () => {
    dispatch(startLoading());
    setTimeout(() => {
      dispatch(finishLoading());
      setIsLoaded(true);
    }, Math.floor(Math.random() * (5001 - 2000)) + 2000);
  };

  if (!isLogin) {
    return (
      <div style={{ position: "relative" }}>
        <PredictionResultContainer
          style={{
            filter: "blur(4px)",
          }}
        >
          <PredictionSubTitle>AI 추정 매출</PredictionSubTitle>
          <PredictionData>월 평균 0,000만원</PredictionData>
        </PredictionResultContainer>
        <PredictionButton onClick={onPressPredictionButton}>
          AI 매출 예측하기
        </PredictionButton>
        <PredictionTipContainer style={{ backgroundImage: `url(${TipBGSvg})` }}>
          <div>로그인하면 볼 수 있어요</div>
          <div>
            <span>똑사 AI가 예측한 매출을 지금 바로 확인해 보세요!</span>
          </div>
        </PredictionTipContainer>
      </div>
    );
  } else {
    if (isLoaded) {
      return (
        <div style={{ position: "relative" }}>
          <PredictionResultContainer>
            <PredictionSubTitle>AI 추정 매출</PredictionSubTitle>
            <PredictionData>
              {predictionData && "월 평균 "}
              <PredictionResultHighlight>
                {predictionData != null
                  ? getStringfiedPrediction(
                      predictionData.minValue,
                      predictionData.maxValue
                    )
                  : "아직 준비중이에요"}
              </PredictionResultHighlight>
            </PredictionData>
          </PredictionResultContainer>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative" }}>
          <PredictionResultContainer>
            {/*    <PredictionSubTitle>AI 추정 매출</PredictionSubTitle>*/}
            {/*    <PredictionData>*/}
            {/*        {predictionData && "월 평균 "}*/}
            {/*        <PredictionResultHighlight>*/}
            {/*            {predictionData != null*/}
            {/*                ? getStringfiedPrediction(predictionData.minValue, predictionData.maxValue)*/}
            {/*                : "아직 준비중이에요"}*/}
            {/*        </PredictionResultHighlight>*/}
            {/*    </PredictionData>*/}

            <PredictionButton onClick={onClickLoading}>
              AI 추정 매출 확인
            </PredictionButton>
          </PredictionResultContainer>
        </div>
      );
    }
  }
}

const PredictionResultContainer = styled.div`
  margin-top: 6px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PredictionSubTitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  text-align: center;

  color: #1c1c1c;
`;
const PredictionData = styled.div`
  margin-top: 4px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  text-align: center;

  color: #1c1c1c;
`;

const PredictionResultHighlight = styled.span`
  color: rgba(237, 112, 62, 1);
`;

const PredictionDate = styled.span`
  color: rgba(156, 156, 157, 1);
  font-size: 12px;
`;

const PredictionButton = styled.button`
  width: 128px;
  height: 38px;
  background: #ed703e;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;

  position: absolute;
  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);
`;

const PredictionTipContainer = styled.div`
  position: absolute;

  top: 62px;
  left: 100px;
  filter: drop-shadow(0px 6px 10px rgba(237, 112, 62, 0.4));
  z-index: 1000;

  padding: 27px 20px 20px;

  background-repeat: round;

  div:nth-child(1) {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    color: #1c1c1c;
  }

  div:nth-child(2) {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #000000;

    span {
      color: rgba(237, 112, 62, 1);
      white-space: nowrap;
    }
  }
`;
