import classNames from "classnames";
import React, { useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import MapFranchiseRecruit from "./MapFranchiseRecruit";

function MapFloatingRecuit ({brandList, options}) {
    const sliderRef = useRef(null);
    const settings = {
      ...options,
    };

    return (
        <div className={classNames("map__franchise-recruit", { "active": (brandList && brandList.flatMap( i => i.branch.filter( (j) => j.isRecruit === "RECRUITING")).length > 0 )  })}>
  
          <Slider ref={sliderRef} {...settings}>
            {
              brandList && 
              brandList.flatMap( i => i.branch.map( j=> j).filter( (j) => j.isRecruit === "RECRUITING"))
                .filter( (i, idx) => idx < 5)
                .map( branch => <MapFranchiseRecruit key={branch.id} branch={branch} brand={brandList.filter(i=>i.branch.filter(j=> j.id === branch.id)[0])} />)
            }
          </Slider>
  
        </div>
    );
  }


  export default React.memo(MapFloatingRecuit);