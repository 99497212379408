import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_brand.scss';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getBrandComments, saveCommentData } from '~/sagas/api/admin/brand_management';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { getBrandDetail } from '~/sagas/api/brand';
import StartUpCostOfBrand from '../brand/StartUpCostOfBrand';
import HeadQuater from '../brand/HeadQuater';
import SalesOfHeadQuater from '../brand/SalesOfHeadQuater';
import Violation from '../brand/Violation';
import FranchiseIncreaseTrend from '../brand/FranchiseIncreaseTrend';
import FranchiseContractStatus from '../brand/FranchiseContractStatus';
import FranchiseAdHistory from '../brand/FranchiseAdHistory';
import FranchiseAvgMonthlySales from '../brand/FranchiseAvgMonthlySales';
import EmployeesOfBrand from '../brand/EmployeesOfBrand';

const franchiseList = [
    {
        franchiseBusinessStatusId : 1,
        id:'affiliateStoreGrowthTrendComment',
        title: "가맹점 증감추이",
        details:"가맹점 증감추이",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'franchiseContractStatusComment',
        title: "가맹점 계약 현황",
        details:"가맹점 계약 현황",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'averageMonthlySalesOfAffiliatesComment',
        title: "가맹점 월평균 매출",
        details:"가맹점 월평균 매출",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'franchiseStartUpCostComment',
        title: "가맹점 창업 비용",
        details:"가맹점 창업 비용",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'affiliateAdvertisementPromotionDetailsComment',
        title: "가맹점 광고/판촉 내역",
        details:"가맹점 광고/판촉 내역",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'corporateInformationComment',
        title: "본사 기업 정보",
        details:"본사 기업 정보",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'headquartersFinancialSituationComment',
        title: "본사 재무 상황",
        details:"",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id:'numberOfHeadOfficeEmployeesComment',
        title: "본사 임직원수",
        details:"",
        isActive: false,
        comment: ''
    }, {
        franchiseBusinessStatusId : 1,
        id: 'violationOfTheLawInTheLastThreeYearsComment',
        title: "최근 3년간 법 위반 사실",
        details:"",
        isActive: false,
        comment: ''
    }
];

function BrandManagementComments () {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();

    const { corpUserInfo } = useSelector(({ common }) => ({
        corpUserInfo: common.auth.corpUserInfo,
    }));

    ////가맹사업 현황/본사 정보
    const [brand, setBrand] = useState(null);
    const [comments, setComments] = useState(franchiseList); //댓글 목록들

    // customized
    const actionCloseChangePasswordPopup = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    ), [closeSnackbar]);

    
    const onChangeComment = (e) => {
        const {name, value} = e.target;
        
        setComments(comments.map(item => {
            if ( item.id === name ) {
                item.comment = value;
                return item;
            } else {
                return item;
            }
        }));
    };

    const callBrandDetail = useCallback(() => {
        //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

        if ( corpUserInfo && corpUserInfo.selectedBrand ) {
            //브랜드 상세 정보 조회
            getBrandDetail(corpUserInfo.selectedBrand.brandId).then((response) => {
                if ( response.status === 200 ) {

                    const data2 = response.data;
                    setBrand(data2);

                    if ( response.data.franchiseBusinessStatus ) {
                        const data = response.data.franchiseBusinessStatus
                        setComments([
                            {
                                franchiseBusinessStatusId : data.id,
                                id:'affiliateStoreGrowthTrendComment',
                                title: "가맹점 증감추이",
                                details:"가맹점 증감추이",
                                isActive: !data.affiliateStoreGrowthTrendComment,
                                comment: data.affiliateStoreGrowthTrendComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'franchiseContractStatusComment',
                                title: "가맹점 계약 현황",
                                details:"가맹점 계약 현황",
                                isActive: !data.franchiseContractStatusComment,
                                comment: data.franchiseContractStatusComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'averageMonthlySalesOfAffiliatesComment',
                                title: "가맹점 월평균 매출",
                                details:"가맹점 월평균 매출",
                                isActive: !data.averageMonthlySalesOfAffiliatesComment,
                                comment: data.averageMonthlySalesOfAffiliatesComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'franchiseStartUpCostComment',
                                title: "가맹점 창업 비용",
                                details:"가맹점 창업 비용",
                                isActive: !data.franchiseStartUpCostComment,
                                comment: data.franchiseStartUpCostComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'affiliateAdvertisementPromotionDetailsComment',
                                title: "가맹점 광고/판촉 내역",
                                details:"가맹점 광고/판촉 내역",
                                isActive: !data.affiliateAdvertisementPromotionDetailsComment,
                                comment: data.affiliateAdvertisementPromotionDetailsComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'corporateInformationComment',
                                title: "본사 기업 정보",
                                details:"본사 기업 정보",
                                isActive: !data.corporateInformationComment,
                                comment: data.corporateInformationComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'headquartersFinancialSituationComment',
                                title: "본사 재무 상황",
                                details:"",
                                isActive: !data.headquartersFinancialSituationComment,
                                comment: data.headquartersFinancialSituationComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id:'numberOfHeadOfficeEmployeesComment',
                                title: "본사 임직원수",
                                details:"",
                                isActive: !data.numberOfHeadOfficeEmployeesComment,
                                comment: data.numberOfHeadOfficeEmployeesComment
                            }, {
                                franchiseBusinessStatusId : data.id,
                                id: 'violationOfTheLawInTheLastThreeYearsComment',
                                title: "최근 3년간 법 위반 사실",
                                details:"",
                                isActive: !data.violationOfTheLawInTheLastThreeYearsComment,
                                comment: data.violationOfTheLawInTheLastThreeYearsComment
                            }
                        ]);
                    }
                    
                }
            });
        }
    }, [corpUserInfo]);

    //수정하기 버튼
    const changeButton = (id) => {
        setComments(comments.map(item => {
            if ( item.id === id ) {
                item.isActive = !item.isActive;
                return item;
            } else {
                return item;
            }
        }));
    };


    
    //댓글 등록하기 버튼
    const saveCommentText = (franchiseBusinessStatusId, id) => {

        //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

        if ( corpUserInfo && corpUserInfo.selectedBrand ) {
            const data = comments.filter(i => i.id === id);
            const fId = data[0].franchiseBusinessStatusId ? data[0].franchiseBusinessStatusId : -99; //댓글 ID 최초 등록은 -99로
            data[0].brandId = corpUserInfo.selectedBrand.brandId;

            saveCommentData(fId, data[0], localStorage.getItem("jwtTokenAdmin")).then(res => {
                
                if ( res.status === 200 ) {
                    enqueueSnackbar("저장 되었습니다.", {
                    className:"snackbar success",
                    autoHideDuration: 3000,
                    action: actionCloseChangePasswordPopup
                });
                }            
            });

            //입력칸/ 버튼 초기화
            setComments(comments.map(item => {
                if ( item.id === id ) {
                    item.isActive = false;
                    return item;
                } else {
                    return item;
                }
            })); 
        }
    };


    //최초 조회시 부모 1번째와 자식 첫번째 세팅
    useEffect(() => {
        
        try {
            // const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

            if ( corpUserInfo && corpUserInfo.selectedBrand ) {
                //가맹사업 현황/본사 정보 댓글
                // getBrandComments(corpUserInfo.selectedBrand.brandId, localStorage.getItem("jwtTokenAdmin"))
                // .then(response => {
                //     console.log(response);
    
                //     if ( response.status === 200 ) {
                //         const data = response.data;

                        
                //     }
                // });
                callBrandDetail();
            }
            
        } catch (e) {
            alert(e);
        }

    }, [callBrandDetail, corpUserInfo]);
    
    return (
        <div className="franchise-tab__container">
            {comments &&
                comments.map(item => (
                    <Accordion key={item.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id={`panel4-header${item.id}`}
                        >
                            <div className="title-group">
                                <span className="title">{item.title}</span>
                                { 
                                    item.comment && 
                                    <span className="icon">작성됨</span> 
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="detail-group">
                                {/* <span className="details">{item.details}</span> */}
                                <div className="data">
                                    {
                                        item.id === 'franchiseStartUpCostComment' ? (brand && brand.startUpCost != null ? <StartUpCostOfBrand startUpCost={brand.startUpCost} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null )
                                        : item.id === 'affiliateStoreGrowthTrendComment' ? (brand && brand.increaseHistoryList != null && brand.increaseHistoryList != null ? <FranchiseIncreaseTrend increaseHistoryList={brand.increaseHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'affiliateAdvertisementPromotionDetailsComment' ? (brand && brand.adHistoryList != null && brand.adHistoryList != null ? <FranchiseAdHistory adHistoryList={brand.adHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'averageMonthlySalesOfAffiliatesComment' ? (brand && brand.salesOfBrandList != null ? <FranchiseAvgMonthlySales salesOfBrandList={brand.salesOfBrandList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'franchiseContractStatusComment' ? (brand && brand.contractHistoryList != null && brand.contractHistoryList != null ? <FranchiseContractStatus contractHistoryList={brand.contractHistoryList} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'corporateInformationComment' ? (brand && brand.headQuater != null ? <HeadQuater headQuater={brand.headQuater} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'headquartersFinancialSituationComment' ? (brand && brand.headQuater != null && brand.headQuater.salesOfHeadQuater != null ? <SalesOfHeadQuater salesOfHeadQuater={brand.headQuater.salesOfHeadQuater} franchiseStartDate={brand.franchiseStartDate} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'numberOfHeadOfficeEmployeesComment' ? (brand && brand.employeesOfBrandList != null ? <EmployeesOfBrand employeesOfBrandList={brand.employeesOfBrandList} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : item.id === 'violationOfTheLawInTheLastThreeYearsComment' ? (brand && brand.violationList != null ? <Violation violationList={brand.violationList} logo={brand.logoImageUrlThumb} comment={brand.franchiseBusinessStatus} hideComment={true}/> : null)
                                        : null
                                    }
                                </div>
                                <div className="comment">
                                    <textarea type="textarea" name={item.id} rows={4} onChange={onChangeComment} disabled={!item.isActive} value={item.comment} />
                                    {
                                        item.isActive 
                                        ? <button onClick={()=> saveCommentText(item.franchiseBusinessStatusId, item.id)}>등록하기</button>
                                        : <button onClick={()=> changeButton(item.id)}>수정하기</button>
                                    }                                                        
                                </div>
                            </div>
                            
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    );
}

export default React.memo(BrandManagementComments);