import { useEffect} from 'react';
import { useHistory } from 'react-router';

const RouteScreen = () => {
  const history = useHistory();
  
  useEffect(() => {
    history.push("/map");
    return () => {};
  }, []);


  return null;
};

export default RouteScreen;
