import { Button, Popover } from "@material-ui/core";
import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { loginCorp } from "~/sagas/actions/commonAction";
import { selectCorpUser } from "~/sagas/api/user";
import { useSnackbar } from "notistack";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  countBranchGpsUpdateList,
  updateBranchGps,
} from "~/sagas/api/admin/branch_management";
import { Autocomplete, TextField } from "@mui/material";

const AdminHeader = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedBrand, setSelectedBrand] = useState("");

  const { corpUserInfo } = useSelector(({ common }) => ({
    corpUserInfo: common.auth.corpUserInfo,
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDropdownEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCountBranchGpsUpdateList = () => {
    countBranchGpsUpdateList().then((res) => {
      enqueueSnackbar(`비정상 ${res.data} 건.`, {
        className: "snackbar success",
        autoHideDuration: 10000,
        action: closeConfirm,
      });
    });
  };

  const handleUpdateBranchGps = () => {
    enqueueSnackbar(`GPS 업데이트 수행 시작`, {
      className: "snackbar success",
      autoHideDuration: 5000,
      action: closeConfirm,
    });
    updateBranchGps().then((res) => {
      enqueueSnackbar(`GPS 업데이트 수행 완료`, {
        className: "snackbar success",
        autoHideDuration: 10000,
        action: closeConfirm,
      });
    });
  };

  const closeConfirm = useCallback(
    (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    ),
    [closeSnackbar]
  );

  const onLogout = () => {
    localStorage.removeItem("jwtTokenAdmin");

    dispatch(
      loginCorp({
        id: "",
        name: "",
        email: "",
        rank: "",
        phoneNumber: "",
        receiveAlarmTalk: "",
        useService: "",
        userInfoAgree: "",
        markettingAgree: "",
        brandList: null,
      })
    );

    handleClose();

    enqueueSnackbar("로그아웃 되었습니다.", {
      className: "snackbar success",
      autoHideDuration: 3000,
      action: closeConfirm,
    });

    history.replace("/corp/login");
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-info-popover" : undefined;

  const changeBrand = () => {
    if (!selectedBrand) return;

    const brandList = corpUserInfo.brandList.map((i) => {
      if (i.brandId === parseInt(selectedBrand.brandId, 10)) {
        i.isActive = true;
        return i;
      } else {
        i.isActive = false;
        return i;
      }
    });

    dispatch(
      loginCorp({
        ...corpUserInfo,
        brandList: brandList,
        selectedBrand: brandList.filter((item) => item.isActive === true)[0],
      })
    );
  };

  useEffect(() => {
    //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

    const token2 = localStorage.getItem("jwtTokenAdmin");

    if (token2 === null || token2 == "null") {
      localStorage.removeItem("jwtTokenAdmin");
      history.replace("/corp/login");
    }

    if (corpUserInfo && corpUserInfo.selectedBrand) {
      setSelectedBrand(corpUserInfo.selectedBrand);
    }
  }, [corpUserInfo]);

  useEffect(() => {
    changeBrand();
  }, [selectedBrand]);
  return (
    <div id="header">
      <header>
        <div className="inner">
          <h1 className="logo">
            <Link to="/corp/brand">
              <i className="ttoksa">똑똑한사장로고</i>
            </Link>
          </h1>
          <div id="nav" className="clfix">
            <nav>
              <NavLink to="/corp/brand" activeClassName="active">
                브랜드관리
              </NavLink>
              {corpUserInfo && corpUserInfo.level === "ADMINISTRATOR" && (
                <NavLink to="/corp/branch" activeClassName="active">
                  지점관리
                </NavLink>
              )}
              <NavLink to="/corp/franchise" activeClassName="active">
                창업문의관리
              </NavLink>
              {corpUserInfo && corpUserInfo.level === "ADMINISTRATOR" && (
                <NavLink to="/setting" activeClassName="active">
                  설정
                </NavLink>
              )}
            </nav>
          </div>

          {corpUserInfo && corpUserInfo.name && (
            <div className={cn("member", { on: corpUserInfo.name })}>
              <div className="item-group">
                <div className={cn("member", { on: corpUserInfo.name })}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField {...params} label="브랜드를 선택하세요" />
                    )}
                    renderOption={(params, option) => (
                      <li {...params} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    options={corpUserInfo.brandList}
                    sx={{ width: 300 }}
                    value={selectedBrand}
                    getOptionLabel={(option) => option.name ?? ""}
                    onChange={(e, value) => {
                      setSelectedBrand(value);
                    }}
                  />

                  {/*<select name="brand" value={selectedBrand} className="select outlined"*/}
                  {/*        onChange={changeBrand}>*/}
                  {/*    {corpUserInfo.brandList &&*/}
                  {/*        corpUserInfo.brandList.map((e) =>*/}
                  {/*            <option key={e.id} value={e.brandId}>{e.name}</option>*/}
                  {/*        )*/}
                  {/*    }*/}
                  {/*</select>*/}
                </div>
              </div>
              <div className="item-group">
                <button onClick={openDropdownEvent} className="username">
                  {corpUserInfo.name} 님
                  {open ? (
                    <KeyboardArrowUpIcon
                      className="folding"
                      sx={{ fontSize: 22 }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className="folding"
                      sx={{ fontSize: 22 }}
                    />
                  )}
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div id="header__order-group-select-box-container">
                    <span className="user-name">{corpUserInfo.name} 님</span>
                    <span className="user-type">기업 고객</span>
                    <span className="user-email">{corpUserInfo.email}</span>
                    <div className="divier"></div>
                    <Link to="/corp/mypage">
                      <span
                        className={cn("header__order-group-select-box-item")}
                      >
                        마이 페이지
                      </span>
                    </Link>

                    <span
                      className="header__order-group-select-box-item"
                      onClick={onLogout}
                    >
                      로그아웃
                    </span>

                    {corpUserInfo &&
                    corpUserInfo.level &&
                    corpUserInfo.level == "ADMINISTRATOR" ? (
                      <>
                        <button
                          className="user-type"
                          onClick={handleCountBranchGpsUpdateList}
                        >
                          지점GPS건수는?
                        </button>
                        <button
                          className="user-type"
                          onClick={handleUpdateBranchGps}
                        >
                          지점GPS업데이트수행
                        </button>
                      </>
                    ) : null}
                  </div>
                </Popover>
              </div>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default AdminHeader;
