import client from "./client";


//인증
export const naverAuthorize = () => client.get(
    `/api/naver/login/authorize`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    }
);



export const naverAuthorizeRedirect = (code, state, error, error_description) => client.get(
    `/api/naver/login/authorize/redirect/${code}/${state}/${error}/${error_description}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    }
);


export const naverProfile = (access_token, token_type) => client.get(
    `/api/naver/login/authorize/profile/${access_token}/${token_type}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    }
);
