import { Button } from "@material-ui/core";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { loginCorp, popup } from "~/sagas/actions/commonAction";
import { joinCorpUserWithEmail } from "~/sagas/api/join";
import { selectCorpUser } from "~/sagas/api/user";
import SearchBrandPopup from "../popup/SearchBrandPopup";
import JoinSelectedBrand from "./JoinSelectedBrand";


function CorpUserJoin({joinView, isUserType, setIsUserType, setJoinView, getTextLength, openUsingRules, openUserInfo, openMarketing}) {

    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [selectedBrands, setSelectedBrands] = useState(null);
    const [corpName, setCorpName] = useState('');
    const [corpRank, setCorpRank] = useState('');
    const [corpEmail, setCorpEmail] = useState('');
    const [corpPhoneNumber, setCorpPhoneNumber] = useState('');
    const [corpPassword, setCorpPassword] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [corpPasswordConfirm, setCorpPasswordConfirm] = useState('');
    const addBrand = useRef(null);
    const inputCorpName = useRef(null);
    const inputCorpRank = useRef(null);
    const formElement = useRef(null);

    const [corpCheckbox, setCorpCheckbox] = useState({
        receiveAlarmTalk: false,
        useService: false,
        userInfoAgree: false,
        markettingAgree: false
    });
    const inputCorpPhoneNumber = useRef(null);
    const inputCorpEmail= useRef(null);
    const inputCorpPassword = useRef(null);

    //이름 입력
    const changeCorpName = (e) => {
        setCorpName(e.target.value);
    };
    //직급 입력
    const changeCorpRank = (e) => {
        setCorpRank(e.target.value);
    };
    //폰 번호 입력
    const changeCorpPhoneNumber= (e) => {
        setCorpPhoneNumber(e.target.value);
    };
    //이메일 입력
    const changeCorpEmail = (e) => {
        setCorpEmail(e.target.value);
    };
    //비밀번호 입력
    const changeCorpPassword = (e) => {
        setCorpPassword(e.target.value);
    };
    //비밀번호 확인 입력
    const changeCorpPasswordConfirm = (e) => {
        setCorpPasswordConfirm(e.target.value);
    };

    const handleCheck = useCallback((name) => {
        let value;

        if (name === "receiveAlarmTalk") {
            value = !corpCheckbox.receiveAlarmTalk;
        } else if (name === "useService") {
            value = !corpCheckbox.useService;
        } else if (name === "userInfoAgree") {
            value = !corpCheckbox.userInfoAgree;
        } else if (name === "markettingAgree") {
            value = !corpCheckbox.markettingAgree;
        }

        setCorpCheckbox({
            ...corpCheckbox,
            [name]: value
        });

    }, [corpCheckbox]);


  // customized
  const actionTrue = useCallback(key => (
    <>
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    </>
  ), [closeSnackbar]);

    const snackbar = useCallback((msg) => {
        enqueueSnackbar(msg, {
            className:"snackbar warning",
            autoHideDuration: 5000,
            action: actionTrue
          });
    }, [enqueueSnackbar, actionTrue])

    const initState = useCallback(() => {
        setIsUserType(true);
        setJoinView(true);
        setCorpCheckbox({
            receiveAlarmTalk: false,
            useService: false,
            userInfoAgree: false,
            markettingAgree: false
        });
        setCorpName('');
        setCorpEmail('');
        setCorpRank('');
        setCorpPhoneNumber('');
        setCorpPassword('');
        setCorpPasswordConfirm('');

    }, [setIsUserType, setJoinView])


    const openHomeScreen = useCallback(() => {

        initState();

        dispatch(
            popup({
                login: false,
                join: false,
            })
        );
    }, [initState, dispatch]);

    //기업고객 - 이메일 회원가입 버튼
    const onClickEmailCorpUser = async () =>  {
        //유효성 검사 후

        if ( !(selectedBrands &&  selectedBrands.length > 0) ) {
            snackbar("한 개 이상의 브랜드를 추가하세요.");
            addBrand.current.focus();
            return;
        }

        const setFileList = selectedBrands.filter(item => item.businessFileUrl); //사업자 등록증 파일 선택 여부 체크

        if ( setFileList.length !== selectedBrands.length ) {
            snackbar("사업자 등록증 파일을 추가해 주세요.");
            addBrand.current.focus();
            return ;
        }

        if ( corpName.trim() === "" || getTextLength(corpName) < 2) {
            snackbar("담당자 이름을 확인해 주세요.");
            inputCorpName.current.focus();
            return;
        }
        if ( corpRank.trim() === "" || getTextLength(corpRank) < 2) {
            snackbar("직급을 확인해 주세요.");
            inputCorpRank.current.focus();
            return;
        }

        if ( /^\d{2,3}-?\d{3,4}-?\d{4}$/.test(corpPhoneNumber) === false ) {
            snackbar("휴대전화를 8자 이상 입력해 주세요.");
            inputCorpPhoneNumber.current.focus();
            return;
        }

        if ( /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(corpEmail.trim()) === false ) {
            snackbar("이메일 정보를 확인해 주세요.");
            inputCorpEmail.current.focus();
            return;
        }
        if ( corpPassword !== corpPasswordConfirm ) {
            snackbar("비밀번호가 서로 다릅니다. 다시 입력 해주세요.");
            inputCorpPassword.current.focus();
            return;
        }
        if ( corpPassword === "" || corpPassword.length < 6) {
            snackbar("비밀번호를 6자리 이상 입력해 주세요.");
            inputCorpPassword.current.focus();
            return;
        }

        if ( corpCheckbox.useService === false ) {
            snackbar("이용약관과 개인정보처리방침에 동의 하셔야 합니다.");
            return;
        }
        if ( corpCheckbox.markettingAgree === false ) {
            snackbar("마케팅 정보 수신에 동의 하셔야 합니다.");
            return;
        }

        //api호출
        try {

            let formData = new FormData(formElement.current);
            formData.set("name", corpName);
            formData.set("email", corpEmail);
            formData.set("rank", corpRank);
            formData.set("phoneNumber", corpPhoneNumber);
            formData.set("password", corpPassword);
            formData.set("receiveAlarmTalk", corpCheckbox.receiveAlarmTalk);
            formData.set("useService", corpCheckbox.useService);
            formData.set("userInfoAgree", corpCheckbox.userInfoAgree);
            formData.set("markettingAgree", corpCheckbox.markettingAgree);


            let jwtToken = await joinCorpUserWithEmail(
                formData
            );
            if (jwtToken.status === 200) {
                localStorage.setItem("jwtTokenAdmin", jwtToken.data);

                let res = await selectCorpUser(jwtToken.data);
                if ( res.status === 200 ) {
                    if ( res.data ) {

                        //res.data.brandList[0].isActive = true; //첫번째 선택 된 상태로 보냄.

                        //dispatch(loginCorp(res.data));
                        enqueueSnackbar("회원가입이 완료 되었습니다. 관리자 승인 후 로그인 가능 합니다.", {
                            className:"snackbar success",
                            autoHideDuration: 10000,
                            action: actionTrue
                        });
                        // history.replace("/corp/login");
                        openHomeScreen();
                        // window.scrollTo(0, 0);
                    } else {
                        console.log('기업 고객 정보가 없습니다.');
                    }
                } else {
                    console.log('통신 에러');
                    snackbar('통신 에러 입니다.');
                }

            } else {
                console.log(jwtToken);
            }
        } catch( e ) {
            console.log(`#############33####### ${e}` );
            snackbar('통신 에러 입니다.');
        }

    };



    //브랜드 검색
    const openSearchBrand = () => {
        dispatch(
            popup({
                searchBrand: true,
            }),
        );
    };


    const openLoginScreen = useCallback(() => {

        initState();

        dispatch(
            popup({
                login: true,
                join: false,
                loginUserType: false, //로그인 true:예비 창업자, false:기업고객
                loginView: true, //false: 최초화면 <-> true: 이메일 로그인 화면
            })
        );
    }, [initState, dispatch]);


    //브랜드 목록에서 브랜드 선택
    const onClickBrand = (brand) => {
        setSelectedBrands(selectedBrands ? (selectedBrands.indexOf(brand) > -1 ? selectedBrands : selectedBrands.concat(brand)) : [brand]);
    };

    //브랜드 목록에서 제거
    const removeBrand = (brandId) => {
        setSelectedBrands(selectedBrands.filter(item=> item.brandId !== brandId ));
    }


    const onSubmit = (e) => {
        e.preventDefault();
    };


    return (
        <div className={classNames("join__join-third-group", { "active" : !joinView, isCorp: !isUserType})}>
            <form onSubmit={onSubmit} id="formUpload" method="POST" encType="multipart/form-data" ref={formElement}>
                <div className="join__select-group mt60">
                    <div className="join__select-group-row">
                        <span className="join__select-title">브랜드 선택</span>
                        <button className="join__select-search" onClick={openSearchBrand} ref={addBrand}>브랜드 추가</button>
                    </div>
                    <div className="join__select-group-divider"></div>

                    { selectedBrands && selectedBrands.length > 0
                        ? selectedBrands.map(item=> <JoinSelectedBrand key={item.brandId} item={item} removeBrand={removeBrand}/> )
                        : <div className="no-selected-brand-data">한 개 이상의 브랜드를 선택하세요.</div>

                    }

                </div>

                <div className={classNames("divider", {"hide": isUserType})}></div>


                <div className={classNames("join body-section", {"hide": isUserType})}>
                    {/* 기업 고객 화면 */}
                    <div className={classNames("join__join-fourth-group", { "active" : !joinView, isCorp: !isUserType})}>
                        <div className="join__corp-title-group">
                            담당자 정보
                        </div>
                        <div className="join__input">
                            <div className="join__input-group">
                                <input type="text" className="join__input-box" onChange={changeCorpName} ref={inputCorpName} />
                                <span className="join__input-title">담당자 이름</span>
                            </div>
                            <div className="join__input-group">
                                <input type="text" className="join__input-box" onChange={changeCorpRank} ref={inputCorpRank}/>
                                <span className="join__input-title">직급</span>
                            </div>
                            <div className="join__input-group margin-bottom-min">
                                <input type="text" className="join__input-box" onChange={changeCorpPhoneNumber} ref={inputCorpPhoneNumber}/>
                                <span className="join__input-title">휴대전화 번호</span>
                            </div>
                        </div>

                        <div className="join__agree-check-box margin-min">
                            <div className="join__agree-check-row">
                                <label className="checkbox check-primary">
                                    <input type="checkbox" name="receiveAlarmTalk" onChange={()=>handleCheck("receiveAlarmTalk")} defaultChecked={corpCheckbox.receiveAlarmTalk}/>
                                    <i></i>
                                    <span>창업 문의 접수, 서비스 공지사항 알림톡 받기</span>
                                </label>
                            </div>
                        </div>

                        <div className="join__input">
                            <div className="join__input-group">
                                <input type="text" className="join__input-box" onChange={changeCorpEmail} ref={inputCorpEmail} placeholder="이메일 주소는 아이디로 이용합니다."/>
                                <span className="join__input-title">이메일 주소</span>
                            </div>
                            <div className="join__input-group">
                                <input type="password" className="join__password-input-box" onChange={changeCorpPassword} ref={inputCorpPassword} placeholder="비밀번호 8~16자 이내로 입력 해 주세요."/>
                                <span className="join__password-title">비밀번호</span>
                            </div>
                            <div className="join__input-group">
                                <input type="password" className="join__password-input-box" onChange={changeCorpPasswordConfirm}/>
                                <span className="join__password-title">비밀번호 확인</span>
                            </div>
                        </div>

                        <div className="join__agree-title-group">
                            <span className="join__agree-title">약관 동의</span>
                        </div>

                        <div className="divider"></div>

                        <div className="join__agree-check-box">
                            <div className="join__agree-check-row">
                                <label className="checkbox check-primary">
                                    <input type="checkbox" name="useService" onChange={()=>handleCheck("useService")} defaultChecked={corpCheckbox.useService} />
                                    <i></i>
                                    <span><b className="join__agree-view-text" onClick={openUsingRules}>이용약관</b>과 <b className="join__agree-view-text" onClick={openUserInfo}>개인정보처리방침</b>에 동의 합니다.</span>
                                </label>
                            </div>
                            <div className="join__agree-check-row">
                                <label className="checkbox check-primary">
                                    <input type="checkbox" name="markettingAgree" onChange={()=>handleCheck("markettingAgree")} defaultChecked={corpCheckbox.markettingAgree} />
                                    <i></i>
                                    <span><b className="join__agree-view-text" onClick={openMarketing}>마케팅 정보 수신</b>에 동의합니다.</span>
                                </label>
                            </div>
                        </div>

                        <button className="join__btn" onClick={onClickEmailCorpUser}>회원가입</button>

                        <div className="join__already-had-account">
                            <span>이미 계정이 있으신가요?</span>
                            <span onClick={openLoginScreen}>로그인</span>
                        </div>
                    </div>
                </div>
            </form>

            <SearchBrandPopup onClickBrand={onClickBrand} />

        </div>
    );
}

export default React.memo(CorpUserJoin);
