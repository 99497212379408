import client from "./client";

//센터 좌표 주소
export const getAddress = (address) => client.get(
    `/api/naver/${address}`
);

//검색 키워드로 좌표 정보 얻기(ㄴㅔ이버 api)
export const getLatLongFromAddress = (address) => client.get(
    `/api/naver/search/${address}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    }
);

//행정동 조회
export const getDongAddress = (address) => client.get(
    `/api/dong/area/${address}/list`
);


//행정동 조회
export const getDongAddressAll = () => client.get(
    `/api/dong/area/list`
);

export const getSalesInCircle = (brandId, regionName, y, x) => client.get(`/api/getSalesInCircle?brandId=${brandId}&regionName=${regionName}&centerY=${y}&centerX=${x}`);
