export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formattedNum(number) {
  const isNegative = number < 0;
  var inputNumber = number < 0 ? -number : number;
  var unitWords = ["", "만", "억", "조", "경"];
  var splitUnit = 10000;
  var splitCount = unitWords.length;
  var resultArray = [];
  var resultString = "";

  for (var i = 0; i < splitCount; i++) {
    var unitResult =
      (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (var j = 0; j < resultArray.length; j++) {
    let value = resultArray[j];
    var unitWord = unitWords[j];
    if (value) {
    } else {
      value = "";
      unitWord = "";
    }

    var space = "";
    if (j > 0 && resultArray[j - 1]) {
      space = " ";
    } else {
      space = "";
    }

    resultString = String(value) + unitWord + space + resultString;
  }

  if (isNegative) {
    resultString = "-" + resultString;
  }

  return numberWithCommas(resultString);
}

export function isNumber(s) {
  s += ""; //문자로 선언(반환)
  s = s.replace(/^\s*|\s*$/g, ""); //좌우 공백 제거
  if (s === "" || isNaN(s)) return false;
  return true;
}

export const appendScript = (scriptToAppend) => {
  const script = window.document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  window.document.body.appendChild(script);
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
