import cn from "classnames";
import AdminHeader from "../AdminHeader";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import SalesActiveScreen from "./SalesActiveScreen";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SettingScreen() {
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div
      id="wrapper"
      className={cn(app.class, { dimmed: app.dimmed.isActive })}
    >
      <AdminHeader />
      <div className="main-container">
        <Container>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="tabs"
          >
            <Tab label="매출 노출 관리" {...a11yProps(0)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* 브랜드관리 - 가맹점 관리 탭 */}
              <SalesActiveScreen />
            </TabPanel>
          </SwipeableViews>
        </Container>
      </div>
    </div>
  );
}

const Container = styled.div`
  width: 1284px;
  margin: auto;
  padding: 20px;
`;
