import React from "react";

function BrandOfHeadQuater({ currBrandOfHeadQuater, brand}) {
  //console.log(currBrandOfHeadQuater, brand);
  //console.log(currBrandOfHeadQuater);

  return (
    <div className="brand-detail__brans-of-head-quater-brand-group">
      <div>
        <div className="brand-detail__brans-of-head-quater-brand-group-img-group">
          <img src={(currBrandOfHeadQuater && currBrandOfHeadQuater.logoImageUrl) ? currBrandOfHeadQuater.logoImageUrl : "/placeholder_logo.png"} alt="logo" />
        </div>
        <span >{currBrandOfHeadQuater.name}</span>
      </div>
      <i></i>
    </div>
  );
    
}

export default BrandOfHeadQuater;
