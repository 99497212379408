import React, { useState, useRef } from "react";
import '~/assets/scss/franchise_inquire_popup.scss';
import { requestStartupConsultation } from "~/sagas/api/common";
import { useSnackbar } from 'notistack';
import { Button } from "@material-ui/core";

function PopupStartUpConsultationApplication({ brand, buttonName, close }) {
  const nameInput = useRef();
  const phoneNumberInput = useRef();
  const agreeInfomationSaveInput = useRef();
  const [nameValidation, setNameValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [agreeValidation, setAgreeValidation] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [inputs, setInputs] = useState({
    brandIdVal: brand.id,
    userName: "",
    phoneNumber: "",
    inquireTime: "",
    favoriteArea: "",
    agreeInfomationSave: false,
    source: buttonName,
  });

  // eslint-disable-next-line
  const { userName, phoneNumber, inquireTime, favoriteArea } = inputs;

  //console.log("#### " + brand.Id);

  const onChangeField = (e) => {
    const { name, value } = e.target;

    // console.log(userName.trim().length);
    // console.log(value, userName);

    if (name === "userName") {
      if (value.trim() === "" || value.trim().length < 3) {
        setNameValidation(true);
      } else {
        setNameValidation(false);
      }
    } else if (name === "phoneNumber") {
      if ( /^\d{2,3}-?\d{3,4}-?\d{4}$/.test(value) ) {
        setPhoneValidation(false);
      } else {
        setPhoneValidation(true);
      }
    }

    setInputs({ ...inputs, [name]: value });

  };
  const onChangeCheckbox = () => {
    const value = !agreeCheckbox;
    setAgreeCheckbox(value);
    setInputs({ ...inputs, agreeInfomationSave: value });
    setAgreeValidation(agreeCheckbox);
  };

  const closeClick = () => {
    //초기화
    setInputs({
      brandIdVal: brand.id,
      userName: "",
      phoneNumber: "",
      areaOfInterest: "",
      desiredTimeForConsultation: "",
      agreeInfomationSave: false,
      source: buttonName,
    });
    setNameValidation(false);
    setPhoneValidation(false);
    setAgreeValidation(false);
    setAgreeCheckbox(false);
  };

  // customized
  const actionCloseChangePasswordPopup = key => (
    <Button onClick={() => { close(); closeSnackbar(key); }}>
        닫기
    </Button>
  );

  const saveClick = async () => {
    if (userName.trim() === "" || userName.trim().length < 3) {
      setNameValidation(true);
      nameInput.current.focus();
      return;
    } else if ( !/^\d{2,3}-?\d{3,4}-?\d{4}$/.test(phoneNumber.trim()) ) {
      setPhoneValidation(true);
      phoneNumberInput.current.focus();
      return;
    } else if (agreeCheckbox === false) {
      setAgreeValidation(true);
      agreeInfomationSaveInput.current.focus();
      return;
    }

    await requestStartupConsultation(inputs, localStorage.getItem("jwtToken")).then((response) => {

      enqueueSnackbar("저장 되었습니다.", {
        className:"snackbar success",
        autoHideDuration: 5000,
        action: actionCloseChangePasswordPopup
      });

      closeClick();
      close();
    });
  };

  return (

      <div className="brand-detail__franchise-inquire-container">
        <div className="brand-detail__title-group">
          <div className="brand-detail__title">창업상담 신청</div>
          <button className="brand-detail__close-btn" onClick={close}>close</button>
        </div>

        <div className="brand-detail__content-group">
          <div className="brand-detail__content-content">아래 정보를 입력해 주시면<br/>브랜드 담당자가 친절하게 상담해 드립니다.</div>

          <div className="brand-detail__input">

            <div className="brand-detail__inquire-name">
                <input type="text"
                      className="brand-detail__inquire-input-box"
                      name="userName"
                      onChange={onChangeField}
                      ref={nameInput} />
                <span className="brand-detail__inquire-name-title">이름</span>

                {nameValidation ? ( <div className="brand-detail__inquire-validation">이름은 2자 이상 공백 없이 한글/영문만 입력해 주세요.</div>
            ) : null}
            </div>

              <div className="brand-detail__inquire-name">
                  <input type="text"
                        className="brand-detail__inquire-input-box"
                        name="phoneNumber"
                        onChange={onChangeField}
                        ref={phoneNumberInput} />
                  <span className="brand-detail__inquire-name-title">휴대전화</span>
                  {phoneValidation ? <div className="brand-detail__inquire-validation">휴대전화를 8자 이상 입력해 주세요</div> : null}
              </div>
            <div className="brand-detail__inquire-name">
                <input type="text" className="brand-detail__inquire-input-box"
                        name="favoriteArea"
                        onChange={onChangeField} />
                <span className="brand-detail__inquire-name-title">관심지역</span>
            </div>

            <div className="brand-detail__inquire-name">
                <input type="text" className="brand-detail__inquire-input-box"
                      name="inquireTime"
                      onChange={onChangeField}/>
                <span className="brand-detail__inquire-name-title">상담희망 시간</span>
            </div>
          </div>

          <div className="brand-detail__private">
            <label className="checkbox check-primary">
              <input type="checkbox"
                  name="agreeInfomationSave"
                  onClick={onChangeCheckbox}
                  defaultChecked={agreeCheckbox}
                  ref={agreeInfomationSaveInput}
                      />
              <i></i>
              <span>이용약관과 개인정보처리방침에 동의합니다.</span>
            </label>

            {agreeValidation ? (
              <div className="brand-detail__inquire-validation">개인정보 수집 및 이용에 동의해주세요.</div>
            ) : null}
          </div>

          <div className="brand-detail__content-btn" onClick={saveClick}>상담 신청하기</div>

        </div>
      </div>
  );
}

      // eslint-disable-next-line
{/* <input
          type="text"
          name="phoneNumber"
          placeholder="휴대전화"
          onChange={onChangeField}
          ref={phoneNumberInput}
        />

        <input type="text" name="areaOfInterest" placeholder="관심지역" onChange={onChangeField} />
        <input
          type="text"
          name="desiredTimeForConsultation"
          placeholder="상담 희망 시간"
          onChange={onChangeField}
        />
        <div className="privateInfomationParent">
          [개인정보수집 및 이용 안내] 1. 수집하는 개인정보 항목: 이름, 휴대전화 번호 2. 개인정보의
          수집 및 이용목적: 가맹문의 접수 및 안내, 향후 가맹 상담을 위한 커뮤니케이션 수단 확보 등 3.
          개인정보의 보유 및 이용기간: 가맹문의 접수 및 안내를 위해 최대 3개월 간 보관 4. 개인정보
          주체는 개인정보 수집 및 이용 동의를 거부할 수 있으나 거부 시 가맹문의 접수가 불가능할 수
          있습니다. [개인정보 제공 안내] 1. 개인정보를 제공받는 자: 창업상담을 신청한 브랜드 본사 2.
          개인정보를 제공받는 자의 개인정보 이용 목적: 가맹문의 내용에 대한 안내 및 가맹상담 3.
          제공하는 개인정보 항목: 이름, 휴대전화 번호 4. 개인정보를 제공받는 자의 개인정보 보유 및
          이용 기간: 가맹문의 안내 및 가맹상담 종료시까지 5. 개인정보 주체는 개인 정보 제공 동의를
          거부할 수 있으나 거부 시 가맹상담이 불가능할 수 있습니다.
        </div>

         */}

export default React.memo(PopupStartUpConsultationApplication);
