import React from "react";
import styled from "styled-components";
import HighLightSvg from "../../assets/images/onboarding/onboarding_end_hightlight.svg";
import BubbleSvg from "../../assets/images/onboarding/onboarding_end_bubble.svg";
import BrandLogoJpg from "../../assets/images/onboarding/onboarding_end_brandlogo.jpg";
import PredictionMarker from "~/components/container/map/PredictionMarker";

const OnBoardingEnd = React.forwardRef(({ onClickNext, map }, ref) => {
  let marker = new window.naver.maps.Marker({
    position: new window.naver.maps.LatLng(37.5230415, 127.0392592),
    map: map,
    icon: {
      // content: contentHtml,
      content: PredictionMarker({
        avg: 24983,
        baseArea: 50,
        branchList: null,
        brand: {
          branch: null,
          branchCnt: null,
          branchViewCnt: null,
          brandBadge: null,
          category: null,
          categoryId: null,
          directRegionOfBrandList: null,
          franchiseRegionOfBrandList: null,
          id: 48906,
          logoImageUrlThumb: BrandLogoJpg,
          mainImageUrlThumb: null,
          name: "컴포즈커피",
          salesOfBrandList: null,
          startUpCost: null,
          themeBrand: null,
        },
      }),
      anchor: new window.naver.maps.Point(240, 0),
    },
    clickable: true,
  });
  let circle = new window.naver.maps.Circle({
    strokeColor: "#ff662a",
    strokeOpacity: 0.1,
    strokeWeight: 1,
    fillColor: "#ff662a",
    fillOpacity: 0.35,
    center: new window.naver.maps.LatLng(37.5230415, 127.0392592),
    radius: 1000, //반경 1km
    zIndex: 100,
    clickable: true,
    map: map,
  });
  let basicMarker = new window.naver.maps.Marker({
    position: new window.naver.maps.LatLng(37.5230415, 127.0392592),
    map: map,
  });

  return (
    <Container ref={ref}>
      <TopContainer></TopContainer>
      <TopContainer
        style={{ minHeight: "230px", height: "auto" }}
      ></TopContainer>
      <MiddleContainer>
        <MiddleBlock />
        <HighLight>
          <img src={HighLightSvg} alt="" />
          <BubbleContainer style={{ backgroundImage: `url(${BubbleSvg})` }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <BubbleTitle>
                이미 똑똑한 사장{" "}
                <span style={{ fontWeight: 400 }}>from 똑사</span>
              </BubbleTitle>
              <BubbleContents>
                <span style={{ fontWeight: 600, color: "#ed703e" }}>
                  짠! 🎉 발품 없이 예상 매출을 확인하셨네요
                </span>
                <br />
                이제 원하는 지역, 업종, 브랜드를 자유롭게 찾아보세요!
                <br />
                똑똑한 창업이 머지 않을 거에요
              </BubbleContents>
              <ButtonContainer
                onClick={() => {
                  marker.setMap(null);
                  circle.setMap(null);
                  basicMarker.setMap(null);
                  onClickNext();
                }}
              >
                <BubbleButton>확인</BubbleButton>
              </ButtonContainer>
            </div>
          </BubbleContainer>
        </HighLight>
        <MiddleBlock />
      </MiddleContainer>
      <BottomContainer />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MiddleBlock = styled.div`
  flex: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BottomContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HighLight = styled.div`
  position: relative;
`;

const BubbleContainer = styled.div`
  position: absolute;
  left: -225px;
  top: 220px;

  width: 479px;

  height: 231px;
  filter: drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25));

  padding: 32px 32px 24px;
`;

const BubbleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #686868;
`;

const BubbleContents = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`;
const BubbleButton = styled.button`
  width: 93px;
  height: 35px;

  background: #fec581;
  border-radius: 50px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ff8a00;
  margin-left: auto;
`;

export default OnBoardingEnd;
