import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { naverAuthorizeRedirect, naverProfile } from "~/sagas/api/naver_login";

function NaverLoginCallback( props ) {
    const { search } = props.location;	// 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환
    const { code, state, error, error_description } = queryObj;

    const [html, setHtml] = useState(null);


    useEffect(() => {
        const naverLoginHtml = localStorage.getItem("naver_login_html");
        const naverAPIType = localStorage.getItem("naver_api_type");
        
        if ( naverLoginHtml ) {
            setHtml(naverLoginHtml);

            naverAuthorizeRedirect(code, state, error, error_description).then(res =>{
                //console.log(res);

                if ( res.status === 200 ) {
                    //console.log(res.data);
                    const access_token = res.data.access_token;
                    const expires_in = res.data.expires_in;
                    const refresh_token = res.data.refresh_token;
                    const token_type = res.data.token_type;

                    naverProfile(access_token, token_type).then( async (res)=> {
                        if ( res.status === 200 ) {
                            // console.log(res.data);

                            const social = "naver";
                            const response = res.data.response;
                            //loginUserWithSns(social, response); 에 호출할때 response로 넘긴다.

                            //console.log(naverAPIType);

                            
                            if ( naverAPIType === "LOGIN" ) {
                                window.opener.naverLoginOpenerCallback(response, social);
                            } else {
                                window.opener.naverJoinOpenerCallback(response, social);
                            }

                            window.self.close();
                            


                            
                        }
                        
                    });
                    

                    
                }
            });
        }

    }, []);

    if ( !html ) return null;

    return (
        <></>
    );
    
}

export default React.memo(NaverLoginCallback);