import React, { useCallback, useState } from "react";
import cn from "classnames";
import "~/assets/scss/login.scss";
import { login, loginCorp, popup } from "~/sagas/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import KakaoLogin from "./sns/KakaoLogin";
import NaverLogin from "./sns/NaverLogin";
import GoogleLogin from "./sns/GoogleLogin";
import { Button, Modal } from "@material-ui/core";
import {
  checkCorpUser,
  checkUser,
  selectCorpUser,
  selectUser,
} from "~/sagas/api/user";
import {
  loginCorpUserWithEmail,
  loginUserWithEmail,
  loginUserWithSns,
} from "~/sagas/api/join";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import CloseSvg from "../../assets/images/icon/icon_login_close.svg";
import LogoPng from "../../assets/images/icon/icon_login_logo.png";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

function LoginScreen() {
  const history = useHistory();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [msg, setMsg] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { loginPopup } = useSelector(({ common }) => ({
    loginPopup: common.popup.login,
  }));
  const { isUserType } = useSelector(({ common }) => ({
    isUserType: common.popup.loginUserType,
  }));
  const { loginView } = useSelector(({ common }) => ({
    loginView: common.popup.loginView,
  }));

  const onChangeField = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const initState = useCallback(() => {
    dispatch(
      popup({
        loginUserType: true,
        loginView: false,
      })
    );
    setInputs({
      email: "",
      password: "",
    });
  }, [dispatch]);

  const loginEvent = useCallback(async () => {
    const { email, password } = inputs;

    if (isUserType) {
      //일반 회원

      try {
        const res = await checkUser({ email: email, password: password });

        if (res.status === 200) {
          //통신 정상

          if (res.data) {
            let jwtToken = await loginUserWithEmail(email);
            if (jwtToken.status === 200) {
              if (jwtToken.data.STATUS === "FAIL") {
                enqueueSnackbar(jwtToken.data.MSG, {
                  className: "snackbar warning",
                  autoHideDuration: 3000,
                  action: closeConfirm,
                });
              } else {
                logEvent(analytics, "login");
                localStorage.setItem("jwtToken", jwtToken.data);
                let res = await selectUser(jwtToken.data);
                dispatch(login(res.data));
                dispatch(
                  popup({
                    login: false,
                  })
                );
              }
            } else {
              enqueueSnackbar(
                "통신 문제가 발생했습니다. 관리자에게 문의해 주세요.",
                {
                  className: "snackbar warning",
                  autoHideDuration: 1000,
                  action: closeConfirm,
                }
              );
            }
          } else {
            // 로그인 정보 없음.
            enqueueSnackbar("ID 혹은 비밀번호를 확인해 주세요.", {
              className: "snackbar warning",
              autoHideDuration: 1000,
              action: closeConfirm,
            });
          }
        } else {
          enqueueSnackbar("회원이 아닙니다. 가입 후 로그인 해주세요.", {
            className: "snackbar warning",
            autoHideDuration: 1000,
            action: closeConfirm,
          });
        }
      } catch (e) {
        enqueueSnackbar("통신 문제가 발생했습니다. 관리자에게 문의해 주세요.", {
          className: "snackbar error",
          autoHideDuration: 1000,
          action: closeConfirm,
        });
      }
    } else {
      //기업 고객
      try {
        const res = await checkCorpUser({ email: email, password: password });

        if (res.status === 200) {
          //통신 정상

          if (res.data) {
            let jwtToken = await loginCorpUserWithEmail(email);

            if (jwtToken.status === 200) {
              if (jwtToken.data === false) {
                // 승인 안된 ID 임.
                openMsgPopup();
              } else {
                let res = await selectCorpUser(jwtToken.data);

                if (res.status === 200) {
                  // console.log(res.data.brandList);

                  if (!res.data.brandList || res.data.brandList.length === 0) {
                    openMsgPopup2();
                  } else {
                    localStorage.setItem("jwtTokenAdmin", jwtToken.data); //정상인 경우에만 세팅
                    res.data.brandList[0].isActive = true; //첫번째 선택 된 상태로 보냄.
                    logEvent(analytics, "login-corp");

                    dispatch(
                      loginCorp({
                        ...res.data,
                        selectedBrand: res.data.brandList
                          ? res.data.brandList[0]
                          : null,
                      })
                    );

                    dispatch(
                      popup({
                        login: false,
                      })
                    );

                    history.push("/corp/brand");
                  }
                }
              }
            } else {
              enqueueSnackbar(
                "통신 문제가 발생했습니다. 관리자에게 문의해 주세요.",
                {
                  className: "snackbar warning",
                  autoHideDuration: 1000,
                  action: closeConfirm,
                }
              );
            }
          } else {
            // 로그인 정보 없음.
            enqueueSnackbar("ID 혹은 비밀번호를 확인해 주세요.", {
              className: "snackbar warning",
              autoHideDuration: 1000,
              action: closeConfirm,
            });
          }
        } else {
          enqueueSnackbar("회원이 아닙니다. 가입 후 로그인 해주세요.", {
            className: "snackbar warning",
            autoHideDuration: 1000,
            action: closeConfirm,
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar("통신 문제가 발생했습니다. 관리자에게 문의해 주세요.", {
          className: "snackbar warning",
          autoHideDuration: 1000,
          action: closeConfirm,
        });
      }
    }
  }, [dispatch, openMsgPopup, enqueueSnackbar, history, isUserType, inputs]);

  const loginEventByKeyEnter = (e) => {
    if (e.key === "Enter") {
      loginEvent();
    }
  };

  const openJoinScreen = useCallback(() => {
    initState();

    dispatch(
      popup({
        login: false,
        join: true,
        joinUserType: false,
        joinView: false,
      })
    );
  }, [initState, dispatch]);

  const goHomeScreen = useCallback(() => {
    initState();

    dispatch(
      popup({
        login: false,
        join: false,
      })
    );
  }, [initState, dispatch]);

  const closeConfirm = useCallback(
    (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    ),
    [closeSnackbar]
  );

  const getUser = async (response, social) => {
    if (social == "kakao") {
      response.profile.kakao_account.profile = {
        nickname: response.profile.properties.nickname,
      };
    }
    try {
      let jwtToken = await loginUserWithSns(social, response);

      if (jwtToken.status === 200) {
        if (jwtToken.data.STATUS && jwtToken.data.STATUS === "FAIL") {
          enqueueSnackbar(jwtToken.data.MSG, {
            className: "snackbar warning",
            autoHideDuration: 3000,
            action: closeConfirm,
          });
        } else {
          logEvent(analytics, "login");

          localStorage.setItem("jwtToken", jwtToken.data.TOKEN);
          let res = await selectUser(jwtToken.data.TOKEN);
          dispatch(login(res.data));

          goHomeScreen();

          if (jwtToken.data.IS_FIRST) {
            //최초 로그인 유저는 마이페이지로 보냄
            logEvent(analytics, "sign_up");
            history.push("/joincomplete");
          }
        }
      }
    } catch (e) {
      console.log(`#################### ${e}`);
      enqueueSnackbar("통신 에러 발생했습니다. 관리자에게 문의해 주세요.", {
        className: "snackbar error",
        autoHideDuration: 3000,
        action: closeConfirm,
      });
    }
  };

  const setUserType = useCallback(() => {
    dispatch(
      popup({
        loginUserType: true,
        loginView: false,
      })
    );

    //eslint-disable-next-line
  }, [dispatch]);

  const setCorpType = useCallback(() => {
    dispatch(
      popup({
        loginUserType: false,
        loginView: true,
      })
    );
    //eslint-disable-next-line
  }, [dispatch]);

  const openLoginEmail = useCallback(() => {
    dispatch(
      popup({
        loginView: true,
      })
    );
    //eslint-disable-next-line
  }, [dispatch]);

  const openFindPassword = useCallback(() => {
    dispatch(
      popup({
        findPassword: true,
      })
    );
  }, [dispatch]);

  const openMsgPopup2 = useCallback(() => {
    setMsg(2);
  }, []);

  const openMsgPopup = useCallback(() => {
    setMsg(1);
  }, []);

  const closeMsgPopup = useCallback(() => {
    setMsg(0);
  }, []);

  return (
    <Modal open={loginPopup}>
      <div className={cn("login__container", { "is-corp": isUserType })}>
        <div className="login-section">
          <div className="login-border-section">
            <button className="login__close-btn" onClick={goHomeScreen}>
              <img src={CloseSvg} />
            </button>

            <div
              className={cn("login", { "border-radius-bottom": !isUserType })}
            >
              <div
                className={cn("login__write_view", {
                  active: !loginView,
                })}
              >
                <img src={LogoPng} className="logo" />
                <div className="login__title">시작하기</div>
                <div className="login__subtitle">
                  간편 소셜 로그인으로 누구보다 빠르게
                  <br />
                  똑똑한 사장이 되어보세요
                </div>
                <GoogleLogin hide={isUserType} callbackFunc={getUser} />
                <NaverLogin hide={isUserType} callbackFunc={getUser} />
                <KakaoLogin hide={isUserType} callbackFunc={getUser} />

                <div className="login_term">
                  소셜 로그인시 원활한 서비스 이용을 위해 똑똑한 사장의 <br />
                  서비스 이용 약관과 개인정보 보호정책에 동의하게 됩니다.
                </div>
                {/* <button
                  onClick={() => {
                    localStorage.setItem("jwtToken", "test");
                    history.push("/joincomplete");
                    dispatch(
                      popup({
                        login: false,
                      })
                    );
                  }}
                >
                  test
                </button> */}
                {/* <button className="login__btn" onClick={openLoginEmail}>이메일로 로그인</button> */}
                {/* <div className={cn("login__user-sign-up")}>
                                    <span>계정이 없으신가요?</span>
                                    <span onClick={openJoinScreen}>회원가입</span>
                                </div> */}
              </div>

              <div className={cn("login__write_view", { active: loginView })}>
                <div className="login__input">
                  <div className="login__email">
                    <input
                      type="text"
                      name="email"
                      onChange={onChangeField}
                      onKeyPress={loginEventByKeyEnter}
                      className="login__email-input-box"
                    />
                    <span className="login__email-title"> 이메일 주소</span>
                  </div>
                  <div className="login__password">
                    <input
                      type="password"
                      name="password"
                      onChange={onChangeField}
                      onKeyPress={loginEventByKeyEnter}
                      className="login__password-input-box"
                    />
                    <span className="login__password-title">비밀번호</span>
                  </div>
                </div>

                <button className="login__btn" onClick={loginEvent}>
                  로그인
                </button>

                <div className="login__find-password">
                  <span>비밀번호를 잊으셨나요?</span>
                  <button href="#" onClick={openFindPassword}>
                    비밀번호 찾기
                  </button>
                </div>
                <div
                  className={cn("login__user-sign-up", {
                    inactive: !isUserType,
                  })}
                >
                  <span>계정이 없으신가요?</span>
                  <span onClick={openJoinScreen}>회원가입</span>
                </div>
                <div
                  className={cn("login__corp-sign-up", {
                    inactive: isUserType,
                  })}
                >
                  <span>기업 고객 지금 바로 무료가입</span>
                  <span onClick={openJoinScreen}>회원가입</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={msg}>
          <div className="password-change__container">
            <div className="password-change__title-group">
              <div className="password-change__title">로그인 불가</div>
              <button
                className="password-change__close-btn"
                onClick={closeMsgPopup}
              >
                close
              </button>
            </div>

            <div className="password-change__content-group">
              <div className="password-change__content-content">
                {msg === 1 ? (
                  <>
                    승인되지 않은 계정입니다.
                    <br />
                    현재 똑사 관리자가 검토중입니다.
                    <br />
                    자세한 내용은 담당자에게 문의해주세요.
                  </>
                ) : (
                  <>
                    권한이 할당된 브랜드가 없습니다.
                    <br />
                    자세한 내용은 담당자에게 문의해주세요.
                  </>
                )}
              </div>
              <div className="password-change__content-btn-group">
                <div
                  className="password-change__content-btn"
                  onClick={closeMsgPopup}
                >
                  확인
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Modal>
  );
}

export default React.memo(LoginScreen);
