import { call, delay, put, race } from 'redux-saga/effects';
import { finishLoading, startLoading } from '../actions/loadingAction';

export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

export default function createRequestSaga(type, request) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return function* (action) {
    //yield put(startLoading(type)); //로딩시작
    try {
      // const response = yield call(request, action.payload);
      const { response } = yield race({
        response: call(request, action.payload),
        timeout: delay(22000),
      });
      if (response) {
        yield put({
          type: SUCCESS,
          req: action.payload,
          payload: response.data,
        });
      } else {
        console.log('Request failure', 'TIMEOUT_ERROR');
        yield put({
          type: FAILURE,
          payload: 'TIMEOUT_ERROR',
          error: true,
        });
      }
    } catch (e) {
      console.log(('Failed', e.message));
      yield put({
        type: FAILURE,
        payload: e.message,
        error: true,
      });
    } finally {
     // yield put(finishLoading(type));
    }
  };
}
