import React from "react";
import LoginKakao from "react-kakao-login";
import cn from "classnames";
import IconPng from "../../../assets/images/icon/icon_login_kakao.png";

function KakaoLogin({ hide, callbackFunc }) {
  return (
    <LoginKakao
      // token="419a57acd98d5949e2fa39b1d7b8243a"
      token="15953feca9eaca6f1ec8386bc44b3456"
      onSuccess={(response) => callbackFunc(response, "kakao")}
      onFail={(error) => console.error(error)}
      onLogout={console.info}
      render={(props) => (
        <button
          className={cn("login__btn sns__kakao", { hide: !hide })}
          onClick={props.onClick}
        >
          <img src={IconPng} />
          카카오 아이디로 시작하기
          <div className="empty" />
        </button>
      )}
    />
  );
}

export default React.memo(KakaoLogin);
