import React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  getCosultDetail,
  updateCosultDetail,
  deleteCosultDetail,
} from "~/sagas/api/admin/franchise_management";
import { useSnackbar } from "notistack";

const initData = {
  status: "",
  contract: "",
  name: "",
  area: "",
  age: "",
  asset: "", //예상 창업 투자금
  phone: "",
  haveSpace: "",
  email: "",
  experience: "",
  time: "",
  context: "",
  registerAt: "",
  updateAt: "",
  updateUser: "",
};

function FranchiseManagementDetail({
  details,
  onClickGoList,
  onClickGetListAndGoList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [consult, setConsult] = useState(initData);

  //상세 조회 API
  const callDetail = useCallback(() => {
    getCosultDetail(
      details.startupConsultId,
      localStorage.getItem("jwtTokenAdmin")
    ).then((req) => {
      if (req.status === 200) {
        if (req.data) {
          const data = req.data;

          setConsult(initData);
          setConsult({
            status: data.consultType,
            contract: data.contractPossibility,
            name: data.userName,
            area: data.areaOfInterest,
            age: data.age,
            asset: data.asset, //예상 창업 투자금
            phone: data.phoneNumber,
            haveSpace: data.haveSpace,
            email: data.email,
            experience: data.experience,
            time: data.desiredTimeForConsultation,
            context: data.context,
            registerAt: data.registerAt,
            updateAt: data.updateAt,
            updateUser: data.updateUser,
          });
        }
      }
    });
  }, [details.startupConsultId]);

  //저장 API
  const callUpdate = useCallback(() => {
    updateCosultDetail(
      details.startupConsultId,
      consult,
      localStorage.getItem("jwtTokenAdmin")
    ).then((req) => {
      enqueueSnackbar("저장 되었습니다.", {
        className: "snackbar success",
        autoHideDuration: 3000,
      });
    });
  }, [enqueueSnackbar, consult, details.startupConsultId]);

  //삭제 API
  const callDelete = useCallback(() => {
    deleteCosultDetail(
      details.startupConsultId,
      localStorage.getItem("jwtTokenAdmin")
    ).then((req) => {
      enqueueSnackbar("삭제 되었습니다.", {
        className: "snackbar success",
        autoHideDuration: 3000,
      });
    });
  }, [enqueueSnackbar, details.startupConsultId]);

  //삭제버튼
  const onClickDelete = useCallback(() => {
    callDelete();

    onClickGetListAndGoList();
  }, [callDelete, onClickGetListAndGoList]);

  //저장버튼
  const onClickSaveAndGoList = useCallback(() => {
    callUpdate();

    onClickGetListAndGoList();
  }, [callUpdate, onClickGetListAndGoList]);

  //상태 변경
  const onChangeStatus = useCallback(
    (e) => {
      setConsult({
        ...consult,
        status: e.target.value,
      });
    },
    [consult]
  );

  //계약 가능성 변경
  const onChangeContract = useCallback(
    (e) => {
      setConsult({
        ...consult,
        contract: e.target.value,
      });
    },
    [consult]
  );

  //필드 입력
  const onChangeField = useCallback(
    (e) => {
      const { name, value } = e.target;
      setConsult({ ...consult, [name]: value });
    },
    [consult]
  );

  useEffect(() => {
    if (details && details.startupConsultId) {
      callDetail();
    }
  }, [callDetail, details, details.startupConsultId]);

  return (
    <div className="admin-franchise-detail__container">
      <span className="title-group">
        창업 문의 상세 (관심 브랜드 : {details.brand?.name ?? "선택 안 함"})
      </span>
      <div className="detail-group">
        <div className="field-group">
          <div className="row-group">
            <span className="title-header">상태</span>
            <select
              name="status"
              value={consult.status}
              onChange={onChangeStatus}
              className="select outlined"
            >
              <option value="RECEIPT">접수</option>
              <option value="ING">상담중</option>
              <option value="CONSULT_COMPLETE">상담완료</option>
              <option value="KEEP">보관중</option>
              <option value="CONTRACT_COMPLETE">계약완료</option>
            </select>
            <span className="title-header">계약 가능성</span>
            <select
              name="contract"
              value={consult.contract}
              onChange={onChangeContract}
              className="select outlined"
            >
              <option value="">선택해주세요</option>
              <option value="상">상</option>
              <option value="중">중</option>
              <option value="하">하</option>
            </select>
          </div>
          <div className="row-group">
            <span className="title-header">이름</span>
            <input
              type="text"
              name="name"
              value={consult.name}
              onChange={onChangeField}
              className="input-box"
            />
            <span className="title-header">관심지역</span>
            <input
              type="text"
              name="area"
              value={consult.area}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
          <div className="row-group">
            <span className="title-header">나이</span>
            <input
              type="text"
              name="age"
              value={consult.age}
              onChange={onChangeField}
              className="input-box"
            />
            <span className="title-header">예상 창업 투자금</span>
            <input
              type="text"
              name="asset"
              value={consult.asset}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
          <div className="row-group">
            <span className="title-header">연락처</span>
            <input
              type="text"
              name="phone"
              value={consult.phone}
              onChange={onChangeField}
              className="input-box"
            />
            <span className="title-header">상가유무</span>
            <input
              type="text"
              name="haveSpace"
              value={consult.haveSpace}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
          <div className="row-group">
            <span className="title-header">이메일</span>
            <input
              type="text"
              name="email"
              value={consult.email}
              onChange={onChangeField}
              className="input-box"
            />
            <span className="title-header">창업경험</span>
            <input
              type="text"
              name="experience"
              value={consult.experience}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
          <div className="row-group">
            <span className="title-header">상담희망시간</span>
            <input
              type="text"
              name="time"
              value={consult.time}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
          <div className="row-group">
            <span className="title-header">문의 내용</span>
            <input
              type="text"
              name="context"
              value={consult.context}
              onChange={onChangeField}
              className="input-box"
            />
          </div>
        </div>
        <div className="added-detail">
          {consult.registerAt && (
            <span className="added-date">
              등록 날짜:{" "}
              {consult.registerAt
                .substring(0, 19)
                .replace("-", "년 ")
                .replace("-", "월")
                .replace("T", "일 ")
                .replace(":", "시 ")
                .replace(":", "분 ") + "초"}
            </span>
          )}
          {consult.updateAt && (
            <span className="update-date">
              마지막 수정:{" "}
              {consult.updateAt
                .substring(0, 19)
                .replace("-", "년 ")
                .replace("-", "월")
                .replace("T", "일 ")
                .replace(":", "시 ")
                .replace(":", "분 ") + "초"}{" "}
              {consult.updateUser}
            </span>
          )}
        </div>
        <div className="button-group">
          <button className="btn btn--cancel" onClick={onClickGoList}>
            취소
          </button>
          <button className="btn btn--delete" onClick={onClickDelete}>
            삭제
          </button>
          <button className="btn btn--save" onClick={onClickSaveAndGoList}>
            저장하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FranchiseManagementDetail);
