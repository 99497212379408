import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import '~/assets/scss/admin_branch_branch_management.scss';
import { getLatLongFromAddress } from '~/sagas/api/map';

function BranchManagementBranchModify( { brand, inputs, onChangeInputFields, regionList, setXY}) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selectAddress, setSelectAddress] = useState("1");

    
    const closeConfirm = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
          닫기
        </Button>
      ), [closeSnackbar]);

    //주소로 좌표 계산
    const onClickGetAxis = async () => {
        let address = "";

        if ( selectAddress === "1" ) {
            const add1 = inputs.address ? inputs.address : "";
            const add2 = inputs.addressDetail ? inputs.addressDetail : "";
            address = add1 + " " + add2;

        } else if ( selectAddress === "2" ) {
            address = inputs.fullAddress;
        }

        if ( address == null || address === "null" || address === undefined || address === "undefined" || address.trim() === "") {
            enqueueSnackbar("주소를 입력 후 시도해 주세요.", {
                className: "snackbar warning",
                autoHideDuration: 5000,
                action: closeConfirm
            });
            return ;
        };

        try {
            await getLatLongFromAddress(address).then(res=> {
                // console.log(res);

                if ( res.status === 200 ) {
                    const data = res.data;

                    if ( data.addresses.length > 0 ) {
                        const address = data.addresses[0];
    
                        console.log(address);
        
                        setXY(address.y, address.x);

                        enqueueSnackbar("위도, 경도 정보가 변경 되었습니다.", {
                            className: "snackbar success",
                            autoHideDuration: 5000,
                            action: closeConfirm
                        });

                    } else {
                        enqueueSnackbar("해당 주소가 유효하지 않습니다.", {
                            className: "snackbar warning",
                            autoHideDuration: 5000,
                            action: closeConfirm
                        });

                    }
                    

                } else {
                    enqueueSnackbar("통신에 실패 하였습니다.", {
                        className: "snackbar warning",
                        autoHideDuration: 5000,
                        action: closeConfirm
                    });
                }
            }).catch( error => {
                console.log(error);
            });
        } catch ( e ) {
            enqueueSnackbar("통신에 실패 하였습니다.", {
                className: "snackbar warning",
                autoHideDuration: 5000,
                action: closeConfirm
            });
        }
        

    };

    const onChangeAddressRadio = (e) => {
        setSelectAddress(e.target.value);
    };

    if ( brand == null || inputs == null ) return null;

    return (
        <div className="branch-management-right-group">

            {/* 신규 등록 / 수정 */}
            {
                inputs &&
                <div className={classNames("main-group", { show: inputs })}>
                    <div className="field">
                        <input type="text" name="brandName" className={classNames("input-box", { disabled: true })} disabled defaultValue={brand.name} />
                        <span className={classNames("title", { disabled: false })} >브랜드 이름</span>
                    </div>
                    <div className="field full-width">
                        <input type="text" name="year" className={classNames("input-box", { disabled: false })} defaultValue={inputs.year} onChange={onChangeInputFields}/>
                        <span className={classNames("title", { disabled: false })} >기준 연도</span>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <select name="type" className={classNames("input-box", { disabled: false })} defaultValue={inputs.type} onChange={onChangeInputFields}>
                                <option value="">선택하세요.</option>
                                <option value="FRANCHISE">가맹점</option>
                                <option value="DIRECT">직영점</option>
                            </select>
                            <span className={classNames("title", { disabled: false })}>가맹구분</span>
                        </div>
                        <div className="field">
                            <select name="region" className={classNames("input-box", { disabled: false })} defaultValue={inputs.region} onChange={onChangeInputFields}>
                                <option value="">선택하세요.</option>
                                {
                                    regionList &&
                                    regionList.map((item2, index) => <option key={index} value={item2.id}>{item2.name}</option>)
                                }
                            </select>
                            <span className={classNames("title", { disabled: false })}>지역</span>
                        </div>
                    </div>
                    <div className="field full-width">
                        <input type="text" name="branchName" className={classNames("input-box", { disabled: false })} defaultValue={inputs.branchName} onChange={onChangeInputFields} />
                        <span className={classNames("title", { disabled: false })}>*지점 이름</span>
                    </div>
                    
                    <div className="address_container">
                        <label className="calc-radio height135">선택
                            <input type="radio" name="address_radio" defaultValue="1" className="address_radio" onChange={onChangeAddressRadio} checked={selectAddress === "1" ? true : false}/>
                        </label>
                        <div className="address_group">
                            <div className="field full-width">
                                <input type="text" name="address" className={classNames("input-box", { disabled: false })} defaultValue={inputs.address} onChange={onChangeInputFields} placeholder="주소를 입력하세요." />
                                <span className={classNames("title", { disabled: false })}>주소(1순위)</span>
                            </div>
                            <div className="field full-width">
                                <input type="text" name="addressDetail" className={classNames("input-box no-margin-bottom", { disabled: false })} defaultValue={inputs.addressDetail} onChange={onChangeInputFields} placeholder="상세주소를 입력하세요.(예:층,호)" />
                                <span className={classNames("title", { disabled: false })}>상세 주소(1순위)</span>
                            </div>
                        </div>
                    </div>

                    <div className="address_container">
                        <label className="calc-radio height56">
                        선택
                            <input type="radio" name="address_radio" defaultValue="2" className="address_radio" onChange={onChangeAddressRadio} checked={selectAddress === "2" ? true : false}/>
                            
                        </label>
                        
                        <div className="address_group">
                            <div className="field full-width">
                                <input type="text" name="fullAddress" className={classNames("input-box no-margin-bottom", { disabled: false })} defaultValue={inputs.fullAddress} onChange={onChangeInputFields} placeholder="주소를 입력하세요." />
                                <span className={classNames("title", { disabled: false })}>전체 주소(2순위)</span>
                            </div>
                        </div>
                    </div>
                    <div className="row-group">
                        <div className="field w440">
                            <input type="number" name="latitude" className={classNames("input-box", { disabled: true })} disabled={true} defaultValue={inputs && inputs.latitude} placeholder="위도" />
                            <span className={classNames("title", {disabled: true})}>위도</span>
                        </div>
                        <div className="field w440">
                            <input type="number" name="longitude" className={classNames("input-box", { disabled: true })} disabled={true} defaultValue={inputs && inputs.longitude} placeholder="경도" />
                            <span className={classNames("title", { disabled: true })}>경도</span>
                        </div>
                        <button className="calc-btn" onClick={onClickGetAxis}>계산</button>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <select name="operationStatus" className={classNames("input-box", { disabled: false })} defaultValue={inputs.operationStatus} onChange={onChangeInputFields} >
                                <option value="">선택하세요</option>
                                <option value="OPERATING">운영중</option>
                                <option value="DUE_OPEN">오픈예정</option>
                                <option value="CLOSE_DOWN">폐점</option>
                                <option value="RECRUITING">모집중</option>
                            </select>
                            <span className={classNames("title", { disabled: false })}>운영상태</span>
                        </div>
                        {<div className="field">
                            <input type="number" name="openPeriod" className={classNames("input-box", {disabled: false})} defaultValue={inputs.openPeriod} onChange={onChangeInputFields} placeholder="개업일"/>
                            <span className={classNames("title", {disabled: false})}>개업일</span>
                        </div>}
                    </div>
                    { inputs && inputs.operationStatus === "RECRUITING" &&
                    <div className="row-group">
                        <div className="field">
                            <input type="text" name="displayAddress" className={classNames("input-box", { disabled: false })} defaultValue={inputs.displayAddress} onChange={onChangeInputFields} placeholder="모집중 주소" />
                            <span className={classNames("title", { disabled: false })}>모집중 주소</span>
                        </div>
                        <div className="field">
                            <input type="text" name="eventDetail" className={classNames("input-box", { disabled: false })} defaultValue={inputs.eventDetail} onChange={onChangeInputFields} placeholder="모집중 이벤트명" />
                            <span className={classNames("title", { disabled: false })}>모집중 이벤트명</span>
                        </div>
                    </div>
                    }
                    <div className="row-group">
                        <div className="field">
                            <input type="number" name="area" className={classNames("input-box", { disabled: false })} defaultValue={inputs.area} onChange={onChangeInputFields} placeholder="면적 (평)" />
                            <span className={classNames("title", { disabled: false })}>면적 (평)</span>
                        </div>
                        <div className="field">
                            <input type="number" name="monthlyEstimatedSales" className={classNames("input-box", { disabled: false })} defaultValue={inputs.monthlyEstimatedSales} onChange={onChangeInputFields} placeholder="추정 월평균 매출액" />
                            <span className={classNames("title", { disabled: false })}>추정 월평균 매출액</span>
                        </div>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <input type="text" name="phoneNumber" className={classNames("input-box", { disabled: false })} defaultValue={inputs.phoneNumber} onChange={onChangeInputFields} placeholder="전화번호" />
                            <span className={classNames("title", { disabled: false })}>전화번호</span>
                        </div>
                    </div>
                    {/* <div className="row-group">
                        <div className="field">
                            <input type="time" name="openTime" className={classNames("input-box", {disabled: false})} placeholder="영업시간"/>
                            <span className={classNames("title", {disabled: false})}>영업 시작 시간</span>
                        </div>
                        <div className="field">
                            <input type="time" name="endTime" className={classNames("input-box", {disabled: false})} placeholder="영업시간"/>
                            <span className={classNames("title", {disabled: false})}>영업 종료 시간</span>
                        </div>
                    </div> */}

                </div>
            }

        </div>
    );
}

export default React.memo(BranchManagementBranchModify);