import React, { useState, useEffect, useCallback } from "react";
// import { isNumber } from "../../common/NumberUtil.js";
import BrandOfHeadQuaterTab from "./BrandOfHeadQuaterTab";
import BrandDetailRight from "./BrandDetailRight";
import BrandStatusOfHeadQuaterTab from "./BrandStatusOfHeadQuaterTab";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import Footer from "~/components/layout/Footer";
import '~/assets/scss/brand_detail.scss';
import WaitingBranchContainer from "./WaitingBranchContainer";
import { getBrandDetail, getIncreaseHistoryList } from "~/sagas/api/brand";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const BrandDetail = ({ match }) => {
  const { brandId } = match.params;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState(null);

  // console.log(brand);
  let localStorageBrands = JSON.parse(window.localStorage.getItem("recentlySeenBrands"));
  // const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  let tabIndex = 1;
  let tabPanelIndex = 1;

  const renderList = useCallback(() => {
    try {
      //if (isNumber(brandId)) { // Long Id 에서 String 사업자 Id로 바뀜
      getBrandDetail(brandId).then((response) => {

        if (response.status === 200) {
          let data = response.data;

          let recentlyBrands;
          if (localStorageBrands != null) {
            recentlyBrands = [data].concat(localStorageBrands.filter((e) => e.id !== data.id));
          } else {
            recentlyBrands = [data];
          }

          if (recentlyBrands != null && recentlyBrands.length > 3) {
            let tempBrands = [];
            for (let i = 0; i < 3; i++) {
              const ok = recentlyBrands[i];
              tempBrands.push(ok);
            }

            window.localStorage.setItem("recentlySeenBrands", JSON.stringify(tempBrands));

          } else {
            window.localStorage.setItem("recentlySeenBrands", JSON.stringify(recentlyBrands));

          }

          setBrand(data);

        }
      });

      //}  // Long Id 에서 String 사업자 Id로 바뀜
    } catch (e) {
      setError(e);
    }

    setLoading(false);

    // eslint-disable-next-line
  }, [brandId]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    // setError(null);
    setBrand(null);
    setLoading(true);
    renderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(new Number(brandId) <= 0);

  if (loading) return <div>로딩중</div>;
  if (error) return <div>에러발생</div>;
  if (!brand) return null; //아무것도 표시 안됨.

  //console.log(brand.franchiseStartDate);
  //console.log(brand.informationDisclosureUpdateDate);
  //console.log(brand);
  //console.log(brand.customSection);


  return (
    <div>
      <div className="brand-detail">
        <div className="brand-detail__left-container">
          {brand.branch != null && brand.branch.length > 0 &&
            <WaitingBranchContainer key={brand.id} brand={brand} />
          }

          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" indicatorColor="primary" aria-label="tabs">
            <Tab label="브랜드 현황" {...a11yProps(0)} />

            {brand.headQuater &&
              <Tab label="본사 정보" {...a11yProps(1)} />
            }
            {brand.customSection != null
              ? brand.customSection.map((currData) => {
                return <Tab key={currData.id} label={currData.title} {...a11yProps(++tabIndex)} />;
              })
              : null}
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* 브랜드 현황 */}
              <BrandStatusOfHeadQuaterTab
                brand={brand}
                brandId={brandId}
                localStorageBrands={localStorageBrands}
              />
            </TabPanel>


            {brand.headQuater &&
              <TabPanel  value={value} index={1} dir={theme.direction}>
                {/* 본사 정보 */}
                <BrandOfHeadQuaterTab
                  brand={brand}
                  brandId={brandId}
                  localStorageBrands={localStorageBrands}
                />
              </TabPanel>
            }

            {brand.customSection != null
              ? brand.customSection.map((currData) => {
                return (
                  <TabPanel key={currData.id} value={value} index={++tabPanelIndex} dir={theme.direction}>
                    <div className="ckeditor-root" dangerouslySetInnerHTML={{ __html: currData.contents }}></div>
                  </TabPanel>
                );
              })
              : null}
          </SwipeableViews>
        </div>

        <BrandDetailRight brand={brand} />
      </div>
      <Footer />
    </div>
  );
};

export default BrandDetail;
