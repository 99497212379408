import React from "react";
import ManagerComment from "./ManagerComment";

function HeadQuater({ headQuater, logo, comment, hideComment }) {
  
  return (
    <div className="brand-detail__head-quater">
      {
        !hideComment &&
        <span className="brand-detail__title">본사 기업정보</span>
      }
      <span className="brand-detail__sub-title">{headQuater.year}년 본사 제공</span>
      <div className="brand-detail__table-group">
        <div>
          <span>상호</span>
          <span>{headQuater.name} {headQuater.foreignCompany ? '(외국계)' : ''}</span>
        </div>
        <div>
          <span>법인설립등기일</span>
          <span>{headQuater.corporationRegistrationDate}</span>
        </div>
        <div>
          <span>사업자등록일</span>
          <span>{headQuater.businessRegistrationDate}</span>
        </div>
        <div>
          <span>대표번호</span>
          <span>{headQuater.phoneNumber}</span>
        </div>
        <div>
          <span>대표팩스번호</span>
          <span>{headQuater.faxNumber}</span>
        </div>
        <div>
          <span>주소</span>
          <span>{headQuater.address}</span>
        </div>
        <div>
          <span>사업자유형</span>
          <span>{headQuater.businessType}</span>
        </div>
        <div>
          <span>법인등록번호</span>
          <span>{headQuater.corporateRegistrationNumber}</span>
        </div>
        <div>
          <span>사업자등록번호</span>
          <span>{headQuater.companyRegistrationNumber}</span>
        </div>

      </div>

      {
        !hideComment && comment && comment.corporateInformationComment &&
        <ManagerComment key="corporateInformationComment" logo={logo} comment={comment.corporateInformationComment} name={comment.userName} modifyAt={comment.modifyAt} />
      }

    </div>
  );
}

export default HeadQuater;
