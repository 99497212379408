import { useEffect } from "react";
import { IS_PRODUCTION } from "~/constants";

export default function useSmartlook() {
  useEffect(() => {
    if (!IS_PRODUCTION) return;
    const scriptElement = document.createElement("script");

    scriptElement.type = "text/javascript";
    scriptElement.innerHTML = `
    window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', '5d371406b460ad029bfb092325897c4f71e58df7', { region: 'eu' });
      smartlook('record', { ips:true });
    `;

    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);
}
