import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import '~/assets/scss/login_message_popup.scss';
import {popup} from '~/sagas/actions/commonAction';

function LoginMessagePopup() {
  
  const dispatch = useDispatch();
  const { loginMessage } = useSelector(({ common }) => ({
    loginMessage: common.popup.loginMessage,
  }), shallowEqual);

  const onClosePopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: false,
      }),
    );
  }, [dispatch]);

  const onLoginPopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: false,
        login: true,
      }),
    );
  }, [dispatch]);

  return (
    <Modal
          open={loginMessage}
      >
          <div className="login-message__container">
              <div className="login-message__title-group">
                <div className="login-message__title">로그인</div>
                <button className="login-message__close-btn" onClick={onClosePopup}>close</button>
              </div>

              <div className="login-message__content-group">
                <div className="login-message__content-content">로그인이 필요한 서비스입니다.<br/>로그인 후 다양한 서비스를 이용해 보세요.</div>                
                <div className="login-message__content-btn" onClick={onLoginPopup}>로그인</div>
              </div>
          </div>
      </Modal>
  );
}


export default React.memo(LoginMessagePopup);