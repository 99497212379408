import React, { useCallback, useState } from "react";
import cn from "classnames";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Popover } from "@material-ui/core";
import { useEffect } from "react";
import { getDongAddress, getDongAddressAll } from "~/sagas/api/map";
import MapFirstSearchBrand from "./MapFirstSearchBrand";
import MapFloatingRecuit from "./MapFloatingRecuit";
import MapFirstLayerItem from "./MapFirstLayerItem";
import { useSelector } from "react-redux";

function MapFirstLayer({
  brandList,
  branchList,
  brand,
  branch,
  addressMapCenter,
  isActive,
  onClickBrandOne,
  handleFirstLayer,
  moveToCenterAtMap,
  sortBrand,
  sortingBrandEvent,
  initialize,
  callGetAddress,
 showMarkerZoomToast
}) {
  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));
  const { form } = useSelector(({ list, filter }) => ({
    form: filter.form,
  }));

  const [isBackgroundLayerActive, setIsBackgroundLayerActive] = useState(false); //브랜드 지역 검색시, 검색 결과 영역
  const [seacrhCloseBtn, setSeacrhCloseBtn] = useState(false); //브랜드 지역 검색시, 창 닫기
  const [searchKeyword, setSearchKeyword] = useState(""); //검색시 키워드
  const [resultBrandList, setResultBrandList] = useState([]); //검색시 brand List에서 검색어에 해당되는 브랜드 목록들
  const [resultAddressAllList, setResultAddressAllList] = useState(null); //전체 행정동 목록들
  const [resultAddressList, setResultAddressList] = useState([]); //검색시 검색어에 해당되는 지역 목록들

  const options = {
    dots: true,
    draggable: true,
    arrows: false,
    infinite: true,
    centerMode: false,
    autoplaySpeed: 3000,
    autoplay: true,
    variableWidth: false,
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onFocusAutoComplete = useCallback(
    (e) => {
      setIsBackgroundLayerActive(false);

      //널일 때만, 조회-세팅
      if (resultAddressAllList == null) {
        getDongAddressAll().then((res) => {
          if (res.status === 200) {
            setResultAddressAllList(res.data);
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isBackgroundLayerActive]
  );

  const onBlurAutoComplete = useCallback(
    (e) => {
      setIsBackgroundLayerActive(false);
      setResultBrandList([]);
      setSearchKeyword("");
      setSeacrhCloseBtn(false);
      setResultAddressList([]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isBackgroundLayerActive, searchKeyword]
  );

  let inDebounce;

  const debounce = (func, delay) => {
    if (inDebounce) {
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout(func(), delay);
  };

  //검색중 ESC 누르면 닫힘.
  const handleKeyDown = (e) => {
    if (e.keyCode === 27 || e.which === 27) {
      onBlurAutoComplete();
    }
  };

  //브랜드 검색 키 입력 이벤트
  const onChangeBrandSearch = (e) => {
    const { value } = e.target;
    setSearchKeyword(value);
    //입력 값 있을 때 창 닫기 보이기
    if (value) {
      setIsBackgroundLayerActive(true);
      setSeacrhCloseBtn(true);
    } else {
      setIsBackgroundLayerActive(false);
      setSeacrhCloseBtn(false);
    }

    debounce(() => {
      //브랜드 목록에서 검색어 관련 추출
      if (brandList) {
        const list = brandList.filter(
          (item) => value !== "" && item.name.indexOf(value) !== -1
        );
        setResultBrandList(list);
      }
      //행정동 전체에서 검색어 관련 추출
      if (resultAddressAllList !== null) {
        const list = resultAddressAllList.filter(
          (item) => value !== "" && item.address.indexOf(value) !== -1
        );
        setResultAddressList(list);
      }
    }, 1000);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDropdownEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropdownEvent = (value) => {
    handleClose();
    sortingBrandEvent(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {}, []);

  return (
    <div className={cn("mapAsideContainer", { active: isActive })}>
      <MapFirstSearchBrand
        isBackgroundLayerActive={isBackgroundLayerActive}
        resultBrandList={resultBrandList}
        resultAddressList={resultAddressList}
        onSubmit={onSubmit}
        onChangeBrandSearch={onChangeBrandSearch}
        handleKeyDown={handleKeyDown}
        onFocusAutoComplete={onFocusAutoComplete}
        onBlurAutoComplete={onBlurAutoComplete}
        searchKeyword={searchKeyword}
        handleFirstLayer={handleFirstLayer}
        moveToCenterAtMap={moveToCenterAtMap}
        initialize={initialize}
        seacrhCloseBtn={seacrhCloseBtn}
        onClickBrandOne={onClickBrandOne}
        callGetAddress={callGetAddress}
      />

      {addressMapCenter && (
        <span
          className={cn("naver__address-by-geocode", {
            inactive: isBackgroundLayerActive,
          })}
        >
          {addressMapCenter}
        </span>
      )}

      <div
        className={cn("map__brand-list", { inactive: isBackgroundLayerActive })}
      >
        <div className="map__title">
          <span>브랜드</span>

          <div className="map__order-group">
            <button
              onClick={openDropdownEvent}
              className="map__order-group-select-box"
            >
              {sortBrand === "BRANCHES"
                ? "매장 많은 순"
                : sortBrand === "AREA"
                ? "매장 넓은 순"
                : "매장 많은 순"}
            </button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div id="map__order-group-select-box-container">
                <span
                  className={cn("map__order-group-select-box-item", {
                    active: sortBrand === "BRANCHES",
                  })}
                  onClick={() => closeDropdownEvent("BRANCHES")}
                >
                  매장 많은 순
                </span>
                <span
                  className={cn("map__order-group-select-box-item", {
                    active: sortBrand === "AREA",
                  })}
                  onClick={() => closeDropdownEvent("AREA")}
                >
                  매장 넓은 순
                </span>
              </div>
            </Popover>
          </div>
        </div>
      </div>

      <div
        className={cn("map__list-container", {
          inactive: isBackgroundLayerActive,
          "exist-recruiting":
            brandList &&
            brandList.flatMap(
              (i) =>
                i.branch
                  .map((j) => j)
                  .filter((j) => j.isRecruit === "RECRUITING").length > 0
            ),
        })}
      >
        <div className="map__list-item-container">
          {brandList &&
            brandList.map((brand) => (
              <MapFirstLayerItem
                key={brand.id}
                brand={brand}
                onClickBrandOne={onClickBrandOne}
              />
            ))}
          {form.selectedCategory.filter((i) => i.checked).length == 0 ? (
            <div className="map__list-no-data">업종을 선택해 주세요</div>
          ) : (
            !brandList ||
            (brandList.length == 0 && (
              <div className="map__list-no-data">{showMarkerZoomToast ? "지도를 확대해주세요" : "데이터가 없습니다"}</div>
            ))
          )}
        </div>
      </div>

      <MapFloatingRecuit brandList={brandList} options={options} />
      <a
        style={{
          position: "fixed",
          bottom: 0,
          right: 25,
          fontSize: "0.8em",
          textDecoration: "underline",
        }}
        href="/term/privacy"
      >
        개인정보처리방침
      </a>
    </div>
  );
}

export default MapFirstLayer; // React.memo();
