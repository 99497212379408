import React, { useState } from 'react';
import { Button, Slider } from '@material-ui/core';

const SelectArea = (props) => {
  const [fromTo, setFromTo] = useState(props.value);
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 16.52,
      label: '5평',
    },
    {
      value: 33.05,
      label: '10평',
    },
    {
      value: 66.11,
      label: '20평',
    },
    {
      value: 99.17,
      label: '30평',
    },
    {
      value: 132.23,
      label: '40평',
    },
    {
      value: 156,
      label: '최대',
    },
  ];

  const valueChange = (event, value) => {
    setFromTo(value);
  };

  const onClickReset = () => {
    setFromTo([0, 156]);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="slider">
          <Slider
            marks={marks}
            className="primary"
            value={fromTo}
            onChange={valueChange}
            name="rangeArea"
            // valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            // step={20}
            min={0}
            max={156}
          />
        </div>
      </div>

      <div className="modal-footer">
        <div className="align-between">
          <button id="cost" className="btn-reset" onClick={onClickReset}>
            초기화
          </button>
          <Button variant="outlined" className="btn-modal-ok" onClick={(e) => props.handleConfirm('rangeArea', fromTo, true)}>
            적용
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectArea;
