import React, { useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { login, popup } from "~/sagas/actions/commonAction";
import { addBrandsWithComparison, addBrandsWithInterests, removeBrandsWithComparison, removeBrandsWithInterests } from "~/sagas/api/favorite";


function MapFirstLayerItem({brand, onClickBrandOne }) {
    const {id} = brand;
    const [favoriteState, setFavoriteState] = useState(false);
    const [comparisonState, setComparisonState] = useState(false);
    
    
    const dispatch = useDispatch();
    const { auth } = useSelector(({ common }) => ({
      auth: common.auth,
    }));
  
    //로그인 팝업 띄우기
    const openLoginMessagePopup = () => {
      dispatch(
        popup({
          loginMessage: true,
        }),
      );
    };
  
    
    //비교하기 등록/삭제 기능
    const onClickComparison = (e) => {
      e.stopPropagation(); //이벤트 버블링 방지
      //로그인 여부 확인 후 
      if ( !auth.userInfo || !auth.userInfo.email ) {
        //openLoginMessagePopup 호출 정하고
        e.preventDefault();
        openLoginMessagePopup();
      
      } else {//로그인 되어 있으면
        //비교하기 등록/삭제 기능
        setComparisonState(prev => !prev);
  
        if ( comparisonState ) { //이전 상태값이 true
          //false 가 되었고 db 에서 제거.
          removeBrandsWithComparison(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              comparisonTargetList: auth.userInfo.comparisonTargetList.filter(i => i !== id )
            })
          );
        } else {
          addBrandsWithComparison(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              comparisonTargetList: auth.userInfo.comparisonTargetList.concat(id)
            })
          );
        }
      }
  
    };
  
  
  
    //관심 브랜드 등록/삭제 기능
    const onClickFavorite = (e) => {
      e.stopPropagation(); //이벤트 버블링 방지
      //로그인 여부 확인 후 
      if ( !auth.userInfo || !auth.userInfo.email ) {
        //openLoginMessagePopup 호출 정하고
        e.preventDefault();
        openLoginMessagePopup();
  
      } else {//로그인 되어 있으면
        //관심 브랜드 등록/삭제 기능
        setFavoriteState(prev => !prev);
  
        if ( favoriteState ) { //이전 상태값이 true
          //false 가 되었고 db 에서 제거.
          removeBrandsWithInterests(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              brandOfInterestList: auth.userInfo.brandOfInterestList.filter( i => i !== id )
            })
          );
        } else {
          addBrandsWithInterests(id, localStorage.getItem("jwtToken"));
          dispatch(
            login({
              ...auth.userInfo,
              brandOfInterestList: auth.userInfo.brandOfInterestList.concat(id)
            })
          );
        }
      }
    };
  
      
  
    useEffect(() => {
      // console.log(auth.userInfo);
  
      setComparisonState(auth.userInfo.comparisonTargetList && auth.userInfo.comparisonTargetList.indexOf(id) !== -1 ? true : false);
      setFavoriteState(auth.userInfo.brandOfInterestList && auth.userInfo.brandOfInterestList.indexOf(id) !== -1 ? true : false);
      
        // eslint-disable-next-line
    }, [auth]);
    
    return (
        <div className={cn("map__list-item", { checked : brand.checked })} id={`item${brand.id}`} onClick={() => onClickBrandOne(brand.id, brand.branch[0].id)}>
            <div className="map__brand_img__container">
              <img src={brand.logoImageUrlThumb ? brand.logoImageUrlThumb : "/placeholder_logo.png"} alt="BRAND" className="map__brand_img" />
            </div>
            
            <div className="map__item-right">
                <div className="map__info-and-event">
                <div className="map__brand_info">
                    <span className="map__brand_category">{brand.category !== null ? brand.category.name : '정보없음' }</span>
                    <span>매장</span>
                    <span>{brand && brand.branchViewCnt}</span>
                </div>
                <div className="util">
                    <label onClick={onClickComparison}>
                      <i className={cn("comparison", {on: comparisonState})}></i>
                      <span>비교</span>
                    </label>
  
                    <i className={cn("favorite", {on: favoriteState})} onClick={onClickFavorite}></i>
  
                </div>
                </div>
                <div className="map__brand-name">
                {brand.name}
                </div>
            </div>
        </div>
    );
  }

export default React.memo(MapFirstLayerItem);