import React, { useEffect, useState } from "react";
import ManagerComment from "./ManagerComment";

function Violation({ violationList, logo, comment, hideComment }) {
  const [violation, setViolation] = useState(null);
  
  useEffect(() => {
    if ( violationList ) {
      setViolation(violationList[0]);
    }
  }, []);

  if ( violation == null ) return null;

  return (

    <div className="brand-detail__violation">
      {
        !hideComment &&
        <span className="brand-detail__title">최근 3년간 법 위반 사실</span>
      }
      <span className="brand-detail__sub-title">{violation && violation.year}년 정보공개서 기준</span>

      <div className="brand-detail__table-group">
        <div>
          <span>공정거래위원회의 시정조치</span>
          <span>민사소송 패소 및 민사상 화해</span>
          <span>형의 선고</span>
        </div>
        <div>
            <span>{violation && violation.correctiveAction && violation.correctiveAction > 0 ? `${violation.correctiveAction} 건` : "정보없음"}</span>
            <span>{violation && violation.civilSuit && violation.civilSuit > 0 ? `${violation.civilSuit} 건` : "정보없음"}</span>
            <span>{violation && violation.sentence && violation.sentence > 0 ? `${violation.sentence} 건` : "정보없음"}</span>
        </div>

        {violation && violation.detail ? <div className="brand-detail__violation-detail">{violation.detail}</div> : null}
      </div>

      {
        !hideComment && comment && comment.violationOfTheLawInTheLastThreeYearsComment &&
        <ManagerComment key="violationOfTheLawInTheLastThreeYearsComment" logo={logo} comment={comment.violationOfTheLawInTheLastThreeYearsComment} name={comment.userName} modifyAt={comment.modifyAt}/>
      }
      
    </div>
  );
}

export default Violation;
