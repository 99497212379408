import React, { useCallback } from "react";
import { Modal } from "@material-ui/core";
import "~/assets/scss/find_password.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { popup } from "~/sagas/actions/commonAction";

function FindPasswordModal() {
  const dispatch = useDispatch();

  const { findPassword } = useSelector(
    ({ common }) => ({
      findPassword: common.popup.findPassword,
    }),
    shallowEqual
  );

  const closeFindPassword = useCallback(() => {
    dispatch(
      popup({
        findPassword: false,
      })
    );
  }, [dispatch]);

  return (
    <Modal open={findPassword}>
      <div className="find-password__container">
        <div className="find-password__title-group">
          <div className="find-password__title">비밀번호 찾기</div>
          <button
            className="find-password__close-btn"
            onClick={closeFindPassword}
          >
            close
          </button>
        </div>

        <div className="find-password__content-group">
          <div className="find-password__content-content">
            아래 카카오톡 채널 상담으로 문의 주시면
            <br />
            신상 정보 확인 과정을 거친 후 <br />
            초기화된 비밀번호를 발송해 드립니다
          </div>
          {/* <input type="text" className="find-password__input-email" placeholder="가입한 이메일 주소"/> */}
          <div
            className="find-password__content-btn"
            onClick={() =>
              window.open("http://pf.kakao.com/_Xxmavxj/chat", "target=_blank")
            }
          >
            카카오톡 채널 상담
          </div>
          {/* 기존 url */}
          {/* <div className="find-password__content-btn" onClick={()=> window.open("http://pf.kakao.com/_wxixlqb/chat", "target=_blank")}>카카오톡 채널 상담</div> */}
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(FindPasswordModal);
