import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import '~/assets/scss/marketing_info_recieve.scss';
import {popup} from '~/sagas/actions/commonAction';

function MarketingInfoRecieve() {
  
  const dispatch = useDispatch();
  const { marketing } = useSelector(({ common }) => ({
    marketing: common.popup.marketing,
  }), shallowEqual);

  
  const closePopup = useCallback(() => {
    dispatch(
      popup({
        marketing: false,
      }),
    );
  }, [dispatch]);

  return (
    <Modal
          open={marketing}
      >
          <div className="marketing-info-recieve__container">
              <div className="marketing-info-recieve__title-group">
                <div className="marketing-info-recieve__title">마케팅 정보 수신 동의</div>
                <button className="marketing-info-recieve__close-btn" onClick={closePopup}>close</button>
              </div>

              <div className="marketing-info-recieve__content-group">
                <div className="marketing-info-recieve__content-content">마이프랜차이즈는 정보통신망 이용 촉진 및 정보보호등에 관한 법률에 따라 사용자에게 마케팅 정보 수신 동의를 받고 있으며 수신 동의 여부를 정기적으로 확인합니다.</div>                

                <h1>1. 마케팅 정보 제공 내용</h1>
                <h2>마이프랜차이즈의 이벤트, 각종 행사등의 정보를 제공합니다.</h2>
                <h1>2. 마케팅 정보 제공 방법</h1>
                <h2>문자(SMS 또는 카카오 알림톡), 푸시, 전화, 이메일 등을 통해 서비스 정보를 제공합니다.</h2>
                <h1>3. 미동의 시 불이익 사항</h1>
                <h2>개인정보보호법 제22조 제5항에 의해 선택적으로 동의할 수 있는 사항의 동의를 거부하더라도 서비스 이용이 제한되지 않습니다. 단, 이벤트, 각종 행사 등의 안내 서비스는 제한됩니다.</h2>
                <h1>4. 마케팅 정보 수신 동의 및 철회</h1>
                <h2>마이프랜차이즈의 마케팅 정보를 받고 싶지 않을 경우 '계정 관리 &gt; 마케팅 정보'에서 마케팅 정보 수신 동의를 철회할 수 있습니다. 향후 마케팅 정보 수신이 필요한 경우 '계정 관리 &gt; 마케팅 정보'에서 마케팅 정보 
수신을 동의할 수 있습니다.</h2>
                <h4>공지 일자: 2020년 8월 14일</h4>
                <h4>시행 일자: 2020년 8월 14일</h4>

              </div>
          </div>
      </Modal>
  );
}

export default React.memo(MarketingInfoRecieve);