import { Button, Modal } from "@material-ui/core";
import cn from "classnames";
import { useSnackbar } from "notistack";
import React, {
  useRef,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
//import { MarkerClustering } from '~/common/MarkerClustering';
import { getAddress, getSalesInCircle } from "~/sagas/api/map";
import client from "../../../sagas/api/client";
//import { htmlMarker1,htmlMarker2,htmlMarker3,htmlMarker4,htmlMarker5,htmlMarker6,htmlMarker7 } from './ClusteringMarker';
import MapAsideContainer from "./MapAsideContainer";
import BrandSelectModal from "./BrandSelectModal";
import PredictionMarker from "./PredictionMarker";
import { useHistory } from "react-router-dom";
import OnBoardingModal from "~/screen/OnBoarding";
import ConsultingModal from "./ConsultingModal";
import Cookies from "js-cookie";
import { popup } from "~/sagas/actions/commonAction";
import PredictionLimitModal from "./PredictionLimitModal";
import CustomToast from "../CustomToast";
import PredictionLoading from "~/screen/popup/PredictionLoading";
import PricePlanModal from "~/components/PricePlanModal";
import ReportLoginModal from "~/components/PricePlanModal/ReportLoginModal";
import styled from "styled-components";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

// import { appendScript } from "../../../common/NumberUtil";

const GET_MAP_BRAND_LIST = "naverMap/GET_MAP_BRAND";
const GET_MAP_BRANCH_LIST = "naverMap/GET_MAP_BRANCH";
const SET_STATE_BRAND_BRANCH_BY_MARKER =
  "naverMap/SET_STATE_BRAND_BRANCH_BY_MARKER";
const CHECKED_BRAND_ITEM = "naverMap/CHECKED_BRAND_ITEM";
const CHECKED_BRANCH_ITEM = "naverMap/CHECKED_BRANCH_ITEM";
const HIGHLIGHT_BRANCH_ITEM = "naverMap/HIGHLIGHT_BRANCH_ITEM";
const HANDLE_ASIDE_LAYER = "naverMap/HANDLE_ASIDE_LAYER";
const HANDLE_FIRST_LAYER = "naverMap/HANDLE_FIRST_LAYER";
const HANDLE_SECOND_LAYER = "naverMap/HANDLE_SECOND_LAYER";
const HANDLE_THIRD_LAYER = "naverMap/HANDLE_THIRD_LAYER";
const HANDLE_THIRD_BRANCH_DETAIL_POPUP =
  "naverMap/HANDLE_THIRD_BRANCH_DETAIL_POPUP";
const SORTING_BRAND = "naverMap/SORTING_BRAND";
const SORTING_BRANCH = "naverMap/SORTING_BRANCH";
const budongsanLevel = 17;

const initialState = {
  error: null,
  brand: null,
  branch: null,
  markerList: null,
  currentBrand: null,
  currentBranch: null,
  isActive: {
    all: true,
    firstLayer: true,
    secondLayer: false,
    thirdLayer: false,
    branchDetailPopup: false,
  },
};
//한글 가나다 순
function compString(a, b) {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
}

//한글 가나다 역순
function compStringReverse(a, b) {
  if (a > b) return -1;
  if (b > a) return 1;
  return 0;
}

function reducer(state, action) {
  // console.log(action);

  switch (action.type) {
    case GET_MAP_BRAND_LIST:
      return {
        ...state,
        brand: action.data.brandList,
        markerList: action.data.markerList,
      };
    case GET_MAP_BRANCH_LIST:
      const list = state.brand.filter(
        (brand) => brand.id === parseInt(action.data, 10)
      );
      return {
        ...state,
        branch: list && list.length > 0 ? list[0].branch : [],
      };
    case SET_STATE_BRAND_BRANCH_BY_MARKER:
      return {
        ...state,
        brand: state.brand.map((i) =>
          i.id === action.data.brandId
            ? { ...i, checked: true }
            : { ...i, checked: false }
        ),
        branch:
          state.branch &&
          state.branch.map((i) =>
            i.id == action.data.branchId
              ? { ...i, checked: true }
              : { ...i, checked: false }
          ),
        currentBrand:
          state.brand.filter((i) => i.id === action.data.brandId).length > 0
            ? state.brand.filter((i) => i.id === action.data.brandId)[0]
            : null,
        currentBranch:
          state.brand.filter((i) => i.id === action.data.branchId).length > 0
            ? state.brand.filter((i) => i.id === action.data.branchId)[0]
            : null,
        isActive: {
          all: true,
          firstLayer: true,
          secondLayer: true,
          thirdLayer: true,
          branchDetailPopup: false,
        },
      };
    case CHECKED_BRAND_ITEM:
      return {
        ...state,
        brand: state.brand.map((i) =>
          i.id === parseInt(action.data, 10)
            ? { ...i, checked: true }
            : { ...i, checked: false }
        ),
        currentBrand:
          state.brand.filter((i) => i.id === parseInt(action.data, 10)).length >
          0
            ? state.brand.filter((i) => i.id === parseInt(action.data, 10))[0]
            : null,
        isActive: {
          ...state.isActive,
          branchDetailPopup: false,
        },
      };
    case CHECKED_BRANCH_ITEM:
      return {
        ...state,
        branch: state.branch.map((i) =>
          i.id === parseInt(action.data, 10)
            ? { ...i, checked: true }
            : { ...i, checked: false }
        ),
        currentBranch:
          state.branch.filter((i) => i.id === parseInt(action.data, 10))
            .length > 0
            ? state.branch.filter((i) => i.id === parseInt(action.data, 10))[0]
            : null,
        isActive: {
          ...state.isActive,
          branchDetailPopup: false,
        },
      };
    case HIGHLIGHT_BRANCH_ITEM:
      return {
        ...state,
        branch: state.branch.map((i) =>
          i.id === parseInt(action.data, 10)
            ? { ...i, highlight: true }
            : { ...i, highlight: false }
        ),
        currentBranch:
          state.branch.filter((i) => i.id === parseInt(action.data, 10))
            .length > 0
            ? state.branch.filter((i) => i.id === parseInt(action.data, 10))[0]
            : null,
        isActive: {
          ...state.isActive,
          branchDetailPopup: false,
        },
      };
    case HANDLE_ASIDE_LAYER:
      return {
        ...state,
        isActive: {
          ...state.isActive,
          all: !state.isActive.all,
        },
      };
    case HANDLE_FIRST_LAYER:
      return {
        ...state,
        isActive: {
          ...state.isActive,
          firstLayer: true,
          secondLayer: false,
          thirdLayer: false,
          branchDetailPopup: false,
        },
      };

    case HANDLE_SECOND_LAYER:
      return {
        ...state,
        isActive: {
          ...state.isActive,
          firstLayer: true,
          secondLayer: true,
          thirdLayer: false,
          branchDetailPopup: false,
        },
      };

    case HANDLE_THIRD_LAYER:
      return {
        ...state,
        isActive: {
          ...state.isActive,
          firstLayer: true,
          secondLayer: true,
          thirdLayer: true,
          branchDetailPopup: false,
        },
      };
    case HANDLE_THIRD_BRANCH_DETAIL_POPUP:
      return {
        ...state,
        isActive: {
          ...state.isActive,
          branchDetailPopup: !state.isActive.branchDetailPopup,
        },
      };
    case SORTING_BRAND:
      return {
        ...state,
        brand:
          state.brand &&
          state.brand.sort((a, b) => {
            if (action.data === "BRANCHES") {
              return b.branch.length - a.branch.length;
            } else if (action.data === "AREA") {
              return b.startUpCost &&
                b.startUpCost.baseArea &&
                a.startUpCost &&
                a.startUpCost.baseArea
                ? b.startUpCost.baseArea - a.startUpCost.baseArea
                : 0;
            } else {
              return b && a && b.total && a.total ? b.total - a.total : 0;
            }
          }),
      };
    case SORTING_BRANCH:
      return {
        ...state,
        branch: state.branch.sort((a, b) => {
          if (action.data === "AREA_BIG") {
            return b.area - a.area;
          } else if (action.data === "AREA_SMALL") {
            return a.area - b.area;
          } else if (action.data === "NAME_DESC") {
            return compStringReverse(a.name, b.name);
          } else if (action.data === "NAME_ASC") {
            return compString(a.name, b.name);
          } else if (action.data === "OPEN_PERIOD_PAST") {
            return a.openPeriod - b.openPeriod;
          } else if (action.data === "OPEN_PERIOD_CURRENT") {
            return b.openPeriod - a.openPeriod;
          } else {
            return (
              (b.salesOfBranchList && b.salesOfBranchList.length > 0
                ? b.salesOfBranchList[0].salesAmount
                : 0) -
              (a.salesOfBranchList && a.salesOfBranchList.length > 0
                ? a.salesOfBranchList[0].salesAmount
                : 0)
            );
          }
        }),
      };
    default:
      return state;
  }
}

function NaverMap({ params, form }) {
  const history = useHistory();
  const zoomRef = useRef(14);
  const infoWindowParent = useRef(null);
  const markerBranch = useRef(null);
  const markerClicked = useRef(false);
  const infoWindow = useRef(null);
  const salesInfoWindowGroup = useRef([]);
  const contentString = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [map, setMap] = useState(null);
  const [reloadButtonIsHidden, setReloadButtonIsHidden] = useState(false);
  const [sortBrand, setSortBrand] = useState("BRANCHES");
  const [sortBranch, setSortBranch] = useState("AREA_BIG");
  const [recruitType, setRecruitType] = useState("ALL");

  const [isOpenPredictionLoading, setIsOpenPredictionLoading] = useState(false);

  const [isOpenPricePlanModal, setIsOpenPricePlanModal] = useState(false);

  const [addressMapCenter, setAddressMapCenter] = useState(null); //맵 중심 좌표의 행정동 주소
  const [paramBrandId, setParamBrandId] = useState(null); //브랜드 디테일에서 지도보기로 온 경우, 세팅 됨
  const [paramBranchId, setParamBranchId] = useState(null); //브랜드 디테일에서 지도보기로 온 경우, 세팅 됨
  const [count, setCount] = useState(0); //브랜드 디테일에서 지도보기로 온 경우, 사용 1일 때만 처리

  const [state, dispatch] = useReducer(reducer, initialState);
  const { brand, branch, currentBrand, currentBranch, isActive, markerList } =
    state;
  const [markerStatus, setMarkerStatus] = useState(false); //마커변경 상태

  const clickMode = useRef(""); //클릭 모드
  const modeForMapRef = useRef(false); //매출예측 토글
  const choiceBrandRef = useRef(null); //매출 예측 선택한 브랜드
  const [choiceBrand, setChoiceBrand] = useState(null); //매출 예측 선택한 브랜드 목록 팝업
  const [choiceBrandListPopup, setChoiceBrandListPopup] = useState(false); //매출 예측 선택한 브랜드 목록 팝업

  const [showZoomToast, setShowZoomToast] = useState(false);
  const [showMarkerZoomToast, setShowMarkerZoomToast] = useState(false);

  // 여기에 창업하기
  const [consultingModalOpen, setConsultingModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [consultingLocation, setConsultingLocation] = useState("");
  const [consultingCoord, setConsultingCoord] = useState(null);

  // 비회원 매출예측 제한
  const [predictionLimitModalOpen, setPredictionLimitModalOpen] =
    useState(false);
  // 비회원 레포트 신청 제한
  const [reportLoginModalOpen, setReportLoginModalOpen] = useState(false);
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));
  const loginDispatch = useDispatch();

  const [hotBrandList, setHotBrandList] = useState([
    { name: "60계치킨", id: 52509 },
    { name: "자담치킨", id: 52088 },
    { name: "컴포즈커피", id: 53248 },
    { name: "메가커피", id: 51131 },
    { name: "도미노피자", id: 58305 },
    { name: "청년피자", id: 50270 },
    { name: "한신포차", id: 48906 },
    { name: "하남돼지집", id: 54052 },
    { name: "씨유(CU)", id: 50307 },
    { name: "GS25", id: 52867 },
  ]);

  let circleListeners = []; //매출예측 circle dblclick listener

  const {
    selectedTheme,
    selectedBadge,
    selectedCategory,
    fromMonth,
    toMonth,
    isCheckedMonth,
    fromStartUpCost,
    toStartUpCost,
    isCheckedStartUpCost,
    fromArea,
    toArea,
    isCheckedArea,
    selectedOrder,
  } = form;

  const { folding } = useSelector(({ filter }) => ({
    folding: filter.folding,
  }));

  const [currentSalesMarker, setCurrentSalesMarker] = useState(null); //매출확인용 마커 중심좌표
  const generalRectangle = useRef(null); //pin 주변에 보여지는 사각형(임시)
  let generalMarkerArr = useRef([]); //매출확인을 위한 pin
  let generalCircleArr = useRef([]); //pin 주변에 보여지는 원
  let tempMap = null; //초기화 할때 map 정보 담아서 사용.

  //하이라이트 배경색 제거
  const clearHighlight = useCallback(() => {
    const element = document.getElementsByClassName("highlight");
    if (element != null && element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        const elem = element[i];
        elem.classList.remove("highlight");
      }
    }
    // eslint-disable-next-line
  }, [branch]);

  //하이라이트 배경색 추가
  const changeMarkerHighlightColor = useCallback(
    (dom) => {
      // console.log(dom);
      // window.ele = dom;
      let domId;
      if (!dom || !dom.length) return;
      if (dom.length === 16) {
        domId = dom[0].getElementsByClassName("map__marker-section")[0].id;
      }

      clearHighlight();

      const elmnt = document.getElementById(domId);
      // console.log(elmnt);
      if (elmnt) {
        elmnt.classList.add("highlight");
      }
      // eslint-disable-next-line
    },
    [branch]
  );

  //선택 배경색 제거
  const clearClicked = useCallback(() => {
    const element = document.getElementsByClassName("clicked");
    if (element != null && element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        const elem = element[i];
        elem.classList.remove("clicked");
      }
    }
    // eslint-disable-next-line
  }, [branch]);

  //선택 배경색 추가
  const changeMarkerClickedColor = useCallback(
    (domId) => {
      clearClicked();

      const elmnt = document.getElementById(domId);
      if (elmnt) {
        elmnt.classList.add("clicked");
      }
      // eslint-disable-next-line
    },
    [branch]
  );

  //move scroll to brand
  const changeScrollToBrand = useCallback(
    (brandId) => {
      var elmnt = document.getElementById(`item${brandId}`);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
      // eslint-disable-next-line
    },
    [brand]
  );
  //move scroll to branch
  const changeScrollToBranch = useCallback(
    (branchId) => {
      var elmnt = document.getElementById(`item${branchId}`);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
      // eslint-disable-next-line
    },
    [branch]
  );

  // //네이버 리버스 지오코딩 호출하여 주소 정보 받기
  const callGetAddress = (x, y) => {
    // console.log(x, y);
    getAddress(`${x},${y}`).then((res) => {
      if (res.status === 200) {
        if (
          res.data &&
          res.data.results[0] !== undefined &&
          res.data.results[0] !== "undefined" &&
          res.data.results[0].region !== null
        ) {
          const region = res.data.results[0].region;
          setAddressMapCenter(
            (region.area1 && region.area1.name) +
              " " +
              (region.area2 && region.area2.name) +
              " " +
              (region.area3 && region.area3.name) +
              " " +
              (region.area4 && region.area4.name)
          );
        }
      }
    });
  };

  let timer;

  const eventList = useCallback(
    (brandId, branchId) => {
      // console.log(brandId, branchId);
      unstable_batchedUpdates(() => {
        getMapBranchList(brandId); //2단 브렌치 목록 세팅
        setStateBrandBranchByMarker(brandId, branchId); //1단 해당 브랜드와 2단 해당 브랜치를 색상 데이타 처리(chedked 상태) / 1단 2단 레이어 활성화
        handleThirdLayer();

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          changeScrollToBrand(brandId); //1단 브랜드 색상 변경
          changeScrollToBranch(branchId); //2단 브랜치 색상 변경
          checkedBranchItem(branchId);
        }, 500);
      });
    },
    [brand, branch]
  );

  window.eventList = eventList;

  const onClickMarker = useCallback(
    async (brandOne, branchOne, e, marker) => {
      //moveToCenterAtMap(latitude, longitude);
      // console.log(brandId, branchId, e.coord);
      // console.log(branch); //branchListCount > 1

      if (zoomRef.current < 14) {
        let markerIndexNumber = 0;

        for (let i = 0; i < markerList.length; i++) {
          const markerItem = markerList[i];

          let isFindBranchId = false;

          for (let j = 0; j < markerItem.branches.length; j++) {
            const branchItem = markerItem.branches[j];
            if (branchItem.id === branchOne.id) {
              markerIndexNumber = i; //마커 목록에서 관련 브랜치 정보의 index를 가져옴.
              isFindBranchId = true;
              break;
            }
          }

          if (isFindBranchId) {
            break;
          }
        }

        // console.log(markerList[markerIndexNumber].branches);
        // console.log(markerList[markerIndexNumber].branches.length);

        if (markerList[markerIndexNumber].branches.length > 1) {
          // console.log(e.domEvent.path[4].firstChild.nextElementSibling.id);
          infoWindow.current
            .setContent(`<div class="marker__branch-list active">
        ${
          markerList[markerIndexNumber].branches &&
          markerList[markerIndexNumber].branches
            .map(
              (item) => `<button class="inner" onclick="eventList(${
                brand.filter((ii) => ii.id === item.tempBrandId)[0].id
              },${item.id})">
                            <div class="image__container">
                              <img class="image" alt="LOGO" src=${
                                brand.filter(
                                  (ii) => ii.id === item.tempBrandId
                                )[0].logoImageUrlThumb
                                  ? brand.filter(
                                      (ii) => ii.id === item.tempBrandId
                                    )[0].logoImageUrlThumb
                                  : "/placeholder_logo.png"
                              }></img>
                            </div>
                            <div class="brand-name">
                              <span>${
                                brand.filter(
                                  (ii) => ii.id === item.tempBrandId
                                )[0].name
                              }</span>
                              <span>${item.name}</span>
                            </div>                                                  
                        </button>`
            )
            .join("")
        }
        </div>`);
        }

        //선택한 마커가 다르면 리스트 닫고 초기화
        if (markerBranch.current != branchOne) {
          infoWindow.current.close();
          markerClicked.current = false;
        }

        markerBranch.current = branchOne;
        markerClicked.current = !markerClicked.current;

        if (markerClicked.current) {
          infoWindow.current.open(map, marker);
        } else {
          infoWindow.current.close();
        }

        if (markerList[markerIndexNumber].branches.length === 1) {
          infoWindow.current.close();
          eventList(brandOne.id, branchOne.id);
        }
      } else {
        eventList(brandOne.id, branchOne.id);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [brand, branch]
  );

  const setStateBrandBranchByMarker = useCallback(
    (brandId, branchId) => {
      dispatch({
        type: SET_STATE_BRAND_BRANCH_BY_MARKER,
        data: { brandId: brandId, branchId: branchId },
      });

      //changeColor(branchId);

      // const brand2 = brand && brand.filter((item) => item.id === brandId);
      // const branch = brand && brand.branch && brand.branch.filter((item) => item.id === branchId)[0];
      // const axis = window.naver.maps.LatLng(branch.latitude, branch.longitude);
      // // mapRef.current.setCenter(axis); //1안
      // mapRef.current.map.panTo(axis); //2안
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brand, branch]
  );

  //브랜치 목록 조회
  const getMapBranchList = useCallback(
    (id) => {
      dispatch({
        type: GET_MAP_BRANCH_LIST,
        data: id,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [branch]
  );

  //브랜드 체크
  const checkedBrandItem = useCallback(
    (id) => {
      logEvent(analytics, "lefttab-select-brand");
      dispatch({ type: CHECKED_BRAND_ITEM, data: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brand]
  );

  const checkedBranchItem = useCallback(
    (id) => {
      logEvent(analytics, "lefttab-select-branch");
      dispatch({ type: CHECKED_BRANCH_ITEM, data: id });
      changeMarkerClickedColor(`marker${id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [branch]
  );
  const highlightBranchItem = useCallback(
    (id) => {
      //markerList && markerList.map(i => !!i.branches && i.branches.map(j=> console.log(j) ) ); // FIXME 1회만 찍힌다.

      //dispatch({ type: HIGHLIGHT_BRANCH_ITEM, data: id });
      changeMarkerHighlightColor(`marker${id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [branch]
  );

  const handleAsideLayer = () => dispatch({ type: HANDLE_ASIDE_LAYER });
  const handleFirstLayer = () => dispatch({ type: HANDLE_FIRST_LAYER });
  const handleSecondLayer = () => dispatch({ type: HANDLE_SECOND_LAYER });
  const handleThirdLayer = () => dispatch({ type: HANDLE_THIRD_LAYER });
  const handleThirdBranchDetailPopup = () =>
    dispatch({ type: HANDLE_THIRD_BRANCH_DETAIL_POPUP });
  // eslint-disable-next-line
  const getAddressByLatLng = (e) => {
    const latlng = e.coord;

    window.naver.maps.Service.reverseGeocode(
      {
        coords: latlng,
        orders: [
          window.naver.maps.Service.OrderType.ADDR,
          window.naver.maps.Service.OrderType.ROAD_ADDR,
        ].join(","),
      },
      function (status, response) {
        if (status === window.naver.maps.Service.Status.ERROR) {
          if (!latlng) {
            return alert("ReverseGeocode Error, Please check latlng");
          }
          if (latlng.toString) {
            return alert("ReverseGeocode Error, latlng:" + latlng.toString());
          }
          if (latlng.x && latlng.y) {
            return alert(
              "ReverseGeocode Error, x:" + latlng.x + ", y:" + latlng.y
            );
          }
          return alert("ReverseGeocode Error, Please check latlng");
        }

        var address = response.v2.address,
          htmlAddresses = [];

        if (address.jibunAddress !== "") {
          htmlAddresses.push("[지번 주소] " + address.jibunAddress);
        }

        if (address.roadAddress !== "") {
          htmlAddresses.push("[도로명 주소] " + address.roadAddress);
        }

        // console.log(htmlAddresses);
      }
    );
  };

  //move to center at map
  const moveToCenterAtMap = async (latitude, longitude) => {
    if (map) {
      //중앙 표시를 살짝 우측으로 보여지도록 수정.
      const bounds = map.getBounds();
      const minLng = bounds._min._lng;
      const maxLng = bounds._max._lng;
      const quater = (maxLng - minLng) / 4; //반의 반\
      longitude = longitude - quater;
    }

    const axis = window.naver.maps.LatLng(latitude, longitude);
    // await map.setZoom(18, true); // 네이버맵처럼 마커 선택 시에도 줌레벨 변경 않음
    // mapRef.current.setCenter(axis); //1안

    await map.panTo(axis); //2안

    callGetAddress(map.getCenter().x, map.getCenter().y);
  };

  const sortingBrandEvent = (value) => {
    dispatch({ type: SORTING_BRAND, data: value });
    setSortBrand(value);
  };

  const sortingBranchEvent = (value) => {
    dispatch({ type: SORTING_BRANCH, data: value });
    setSortBranch(value);
  };
  const sortingBranchRecruitEvent = (value) => {
    setRecruitType(value);
  };

  const callGetBrandAndBranch = useCallback(() => {
    const bounds = map
      ? map.getBounds()
      : tempMap
      ? tempMap.getBounds()
      : { _min: { _lat: 0, _lng: 0 }, _max: { _lat: 0, _lng: 0 } };
    let minLat = bounds._min._lat; //x
    let minLng = bounds._min._lng; //y
    let maxLat = bounds._max._lat; //x
    let maxLng = bounds._max._lng; //y

    /////////////////////////////////
    // console.log("전 : ", minLng, minLat, maxLng, maxLat);

    let bufferX = (maxLng - minLng) * 0.5;
    let bufferY = (maxLat - minLat) * 0.5;

    // console.log("버퍼50% : ", bufferX, bufferY);

    minLng = minLng - bufferX;
    minLat = minLat - bufferY;
    maxLng = maxLng + bufferX;
    maxLat = maxLat + bufferY;

    // console.log("후 : ", minLng, minLat, maxLng, maxLat);
    ///////////////////////////

    const selectedCategory2 = selectedCategory
      ? [].concat(
          selectedCategory &&
            selectedCategory
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [1];
    const selectedBadge2 = selectedBadge
      ? [].concat(
          selectedBadge &&
            selectedBadge
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [];
    const selectedTheme2 = selectedTheme
      ? [].concat(
          selectedTheme &&
            selectedTheme
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [];

    let url = `/api/brands_by_axis?category=${selectedCategory2}&badge=${selectedBadge2}&theme=${selectedTheme2}&fromMonth=${fromMonth}&toMonth=${toMonth}&isCheckedMonth=${isCheckedMonth}&fromStartUpCost=${fromStartUpCost}&toStartUpCost=${toStartUpCost}&isCheckedStartUpCost=${isCheckedStartUpCost}&fromArea=${fromArea}&toArea=${toArea}&isCheckedArea=${isCheckedArea}&order=${selectedOrder}&minLat=${minLat}&minLng=${minLng}&maxLat=${maxLat}&maxLng=${maxLng}`;

    if (count === 0 && params && params.categoryId) {
      url = `/api/brands_by_axis?category=${[
        params.categoryId,
      ]}&badge=${selectedBadge2}&theme=${selectedTheme2}&fromMonth=${fromMonth}&toMonth=${toMonth}&isCheckedMonth=${isCheckedMonth}&fromStartUpCost=${fromStartUpCost}&toStartUpCost=${toStartUpCost}&isCheckedStartUpCost=${isCheckedStartUpCost}&fromArea=${fromArea}&toArea=${toArea}&isCheckedArea=${isCheckedArea}&order=${selectedOrder}&minLat=${minLat}&minLng=${minLng}&maxLat=${maxLat}&maxLng=${maxLng}`;
    } else {
      url = `/api/brands_by_axis?category=${selectedCategory2}&badge=${selectedBadge2}&theme=${selectedTheme2}&fromMonth=${fromMonth}&toMonth=${toMonth}&isCheckedMonth=${isCheckedMonth}&fromStartUpCost=${fromStartUpCost}&toStartUpCost=${toStartUpCost}&isCheckedStartUpCost=${isCheckedStartUpCost}&fromArea=${fromArea}&toArea=${toArea}&isCheckedArea=${isCheckedArea}&order=${selectedOrder}&minLat=${minLat}&minLng=${minLng}&maxLat=${maxLat}&maxLng=${maxLng}`;
    }

    client
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          //console.log(response.data);
          unstable_batchedUpdates(() => {
            dispatch({
              type: GET_MAP_BRAND_LIST,
              data: {
                brandList: response.data.brandList,
                markerList: response.data.markerList,
              },
            });
            sortingBrandEvent(sortBrand);
            setReloadButtonIsHidden(true);
            handleFirstLayer();
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    selectedCategory,
    selectedBadge,
    selectedTheme,
    fromMonth,
    toMonth,
    isCheckedMonth,
    fromStartUpCost,
    toStartUpCost,
    isCheckedStartUpCost,
    isCheckedArea,
    toArea,
    fromMonth,
  ]);

  const callGetBrandAndBranchClurstering = useCallback(async () => {
    const bounds = map
      ? map.getBounds()
      : tempMap
      ? tempMap.getBounds()
      : { _min: { _lat: 0, _lng: 0 }, _max: { _lat: 0, _lng: 0 } };

    let minLng = bounds._min._lng; //min x
    let minLat = bounds._min._lat; //min y
    let maxLng = bounds._max._lng; //max x
    let maxLat = bounds._max._lat; //max y

    /////////////////////////////////
    // console.log("전 : ", minLng, minLat, maxLng, maxLat);

    let bufferX = (maxLng - minLng) * 0.5;
    let bufferY = (maxLat - minLat) * 0.5;

    // console.log("버퍼50% : ", bufferX, bufferY);

    minLng = minLng - bufferX;
    minLat = minLat - bufferY;
    maxLng = maxLng + bufferX;
    maxLat = maxLat + bufferY;

    // console.log("후 : ", minLng, minLat, maxLng, maxLat);
    ///////////////////////////

    const selectedCategory2 = selectedCategory
      ? [].concat(
          selectedCategory &&
            selectedCategory
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [1];
    const selectedBadge2 = selectedBadge
      ? [].concat(
          selectedBadge &&
            selectedBadge
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [];
    const selectedTheme2 = selectedTheme
      ? [].concat(
          selectedTheme &&
            selectedTheme
              .filter((item) => item.checked === true)
              .map((item) => item.id)
        )
      : [];

    //console.log(selectedCategory2);

    const url = `/api/getBranchAndBrandByAxis?category=${selectedCategory2}&badge=${selectedBadge2}&theme=${selectedTheme2}&fromMonth=${fromMonth}&toMonth=${toMonth}&isCheckedMonth=${isCheckedMonth}&fromStartUpCost=${fromStartUpCost}&toStartUpCost=${toStartUpCost}&isCheckedStartUpCost=${isCheckedStartUpCost}&fromArea=${fromArea}&toArea=${toArea}&isCheckedArea=${isCheckedArea}&order=${selectedOrder}&minLat=${minLat}&minLng=${minLng}&maxLat=${maxLat}&maxLng=${maxLng}&zoomLevel=${zoomRef.current}`;

    await client
      .get(url)
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          // console.log(response.data);
          unstable_batchedUpdates(() => {
            dispatch({
              type: GET_MAP_BRAND_LIST,
              data: {
                brandList: response.data.brandList,
                markerList: response.data.markerList,
              },
            });
            sortingBrandEvent(sortBrand);
            setReloadButtonIsHidden(true);
            handleFirstLayer();
            if (infoWindow) {
              infoWindow.current.close();
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    selectedCategory,
    selectedBadge,
    selectedTheme,
    fromMonth,
    toMonth,
    isCheckedMonth,
    fromStartUpCost,
    toStartUpCost,
    isCheckedStartUpCost,
    isCheckedArea,
    toArea,
    fromMonth,
  ]);

  const initialize = useCallback(() => {
    if (zoomRef.current < budongsanLevel) {
      setShowMarkerZoomToast(true);
      unstable_batchedUpdates(() => {
        dispatch({
          type: GET_MAP_BRAND_LIST,
          data: {
            brandList: [],
            markerList: [],
          },
        });
        sortingBrandEvent(sortBrand);
        setReloadButtonIsHidden(true);
        handleFirstLayer();
      });
      return;
    }
    setShowMarkerZoomToast(false);
    callGetBrandAndBranch();
  }, [
    selectedCategory,
    selectedBadge,
    selectedTheme,
    fromMonth,
    toMonth,
    isCheckedMonth,
    fromStartUpCost,
    toStartUpCost,
    isCheckedStartUpCost,
    isCheckedArea,
    toArea,
    fromMonth,
  ]);

  const onZoomChanged = useCallback(
    (zoomLevel) => {
      // console.log("onZoomChanged");
      setReloadButtonIsHidden(false);

      initialize();

      callGetAddress(map.getCenter().x, map.getCenter().y);

      // eslint-disable-next-line
    },
    [
      reloadButtonIsHidden,
      selectedCategory,
      selectedBadge,
      selectedTheme,
      fromMonth,
      toMonth,
      isCheckedMonth,
      fromStartUpCost,
      toStartUpCost,
      isCheckedStartUpCost,
      isCheckedArea,
      toArea,
      fromMonth,
    ]
  );

  const onDragend = useCallback(
    (e) => {
      // console.log("onDragend");
      setReloadButtonIsHidden(false);
      // console.log(e.coord);

      initialize();

      //네이버 리버스 지오코딩 호출하여 주소 정보 받기
      callGetAddress(e.coord.x, e.coord.y);

      //getAddressByLatLng(e); //21 08 29 안쓰이고 있는 것 같아 주석
      // eslint-disable-next-line
    },
    [
      reloadButtonIsHidden,
      selectedCategory,
      selectedBadge,
      selectedTheme,
      fromMonth,
      toMonth,
      isCheckedMonth,
      fromStartUpCost,
      toStartUpCost,
      isCheckedStartUpCost,
      isCheckedArea,
      toArea,
      fromMonth,
    ]
  );

  const setNullMarkers = (markers) => {
    if (markers) {
      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];
        if (marker) {
          marker.setMap(null);
        }
      }
    }
  };

  const closeConfirm = useCallback(
    (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    ),
    [closeSnackbar]
  );

  const makeFlagAndCircle = (position) => {
    var marker = new window.naver.maps.Marker({
      position: position,
      map: map,
    });

    generalMarkerArr.current.push(marker);
    // if ( generalMarkerArr.length > 1 ) {
    //   let  removeMarker = generalMarkerArr.shift();
    //   removeMarker.setMap(null);
    //   removeMarker = null;
    // }

    var circle = new window.naver.maps.Circle({
      strokeColor: "#ff662a",
      strokeOpacity: 0.1,
      strokeWeight: 1,
      fillColor: "#ff662a",
      fillOpacity: 0.35,
      center: new window.naver.maps.LatLng(position.y, position.x),
      radius: 1000, //반경 1km
      zIndex: 100,
      clickable: true,
      map: map,
    });

    let circleListener = new window.naver.maps.Event.addListener(
      circle,
      "dblclick",
      function (e) {
        if (map.getZoom() <= 20) {
          const axis = window.naver.maps.LatLng(e._lat, e._lng);
          //tempMap.updateBy(axis, tempMap.getZoom()+1);
          map.setZoom(map.getZoom() + 1, true);
          map.setCenter(axis);
        }
      }
    );

    circleListeners.push(circleListener);

    generalCircleArr.current.push(circle);
    // if ( generalCircleArr.length > 1 ) {
    //   let  removeCircle = generalCircleArr.shift();
    //   removeCircle.setMap(null);
    //   removeCircle = null;
    // }

    // setCurrentSalesMarker(marker);
  };

  //원, 플래그, 매출예측 정보 지우기
  const closeFlagAndCircle = () => {
    if (generalMarkerArr.current.length > 0) {
      for (let i = 0; i < generalMarkerArr.current.length; i++) {
        let flag = generalMarkerArr.current[i];
        flag.setMap(null);
        flag = null;
      }
      generalMarkerArr.current = [];
    }

    if (generalCircleArr.current.length > 0) {
      for (let i = 0; i < generalCircleArr.current.length; i++) {
        let circle = generalCircleArr.current[i];
        circle.setMap(null);
        circle = null;
      }
      generalCircleArr.current = [];
    }

    if (salesInfoWindowGroup.current.length > 0) {
      for (let i = 0; i < salesInfoWindowGroup.current.length; i++) {
        let salesInfoWindow = salesInfoWindowGroup.current[i];

        if (salesInfoWindow && salesInfoWindow.getMap()) {
          salesInfoWindow.setMap(null);
          salesInfoWindow = null;
        }
      }
      salesInfoWindowGroup.current = [];
    }

    if (generalRectangle.current && generalRectangle.current.getMap()) {
      generalRectangle.current.setMap(null);
      generalRectangle.current = null;
    }
  };

  useEffect(() => {
    // console.log('setMap start');

    let latitude = 37.5197739; // TODO 똑사 본사 위치로 변경(현재 네이버 본사위치)
    let longitude = 127.0384867;

    if (
      params.brandId &&
      params.branchId &&
      params.categoryId &&
      params.latitude &&
      params.longitude &&
      params.latitude !== "0" &&
      params.longitude !== "0"
    ) {
      latitude = params.latitude;
      longitude = params.longitude;
    }

    if (
      params.brandId &&
      params.branchId &&
      params.categoryId &&
      params.latitude === "0" &&
      params.longitude === "0"
    ) {
      enqueueSnackbar("위치 정보가 등록되어 있지 않습니다.", {
        className: "snackbar warning",
        autoHideDuration: 5000,
        action: closeConfirm,
      });
    }

    if (!window || !window.naver || !window.naver.maps) return;

    tempMap = new window.naver.maps.Map("naver__map", {
      center: new window.naver.maps.LatLng(latitude, longitude),
      zoom: zoomRef.current,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.RIGHT_TOP,
      },
    });

    setMap((prev) => tempMap);
    callGetAddress(longitude, latitude);

    contentString.current = [
      `<div class="marker__branch-list active">`,
      `</div>`,
    ].join("");

    if (!window || !window.naver || !window.naver.maps) return;
    infoWindow.current = new window.naver.maps.InfoWindow({
      content: contentString.current.replaceAll(",", ""),
      backgroundColor: "#ffffff",
      borderColor: "#eeeeee",
      borderWidth: 1,
      disableAnchor: true,
      // anchorSkew: true,
      // anchorColor: "#ffffff",
      //pixelOffset: new window.naver.maps.Point(20, -20)
      pixelOffset: new window.naver.maps.Point(0, -20),
    });

    let markerOnMapClickListener2 = window.naver.maps.Event.addListener(
      tempMap,
      "dblclick",
      function (e) {
        clickMode.current = e.type;
      }
    );

    //브랜드 디테일에서 지도보기로 들어온 경우 처리
    if (params.brandId && params.branchId) {
      setParamBrandId((prev) => params.brandId);
      setParamBranchId((prev) => params.branchId);
      setRecruitType("RECRUITING"); //2단 레이어 모집중만 보이도록
      setCount((prev) => prev + 1);
    }

    return () => {
      // console.log('setMap End');

      if (
        !window ||
        !window.naver ||
        !window.naver.maps ||
        !window.naver.maps.Event
      )
        return;
      setMap(null);
      // console.log(generalMakerArr);
      window.naver.maps.Event.removeListener(markerOnMapClickListener2);
      markerOnMapClickListener2 = null;
    };
  }, []);

  useEffect(() => {
    if (map == null) return;

    if (
      !window ||
      !window.naver ||
      !window.naver.maps ||
      !window.naver.maps.Event
    )
      return;
    let markerOnMapClickListener = window.naver.maps.Event.addListener(
      map,
      "click",
      function (e) {
        clickMode.current = e.type;

        if (modeForMapRef.current === true) {
          //flag 만들고 원 그리고 저장, 리액트 상태에도 저장(react에서 사용함)
          setTimeout(() => {
            if (clickMode.current == "click") {
              logEvent(analytics, "prediction-click-map");
              if (sessionStorage.getItem("hasPrediction")) {
                clickSalesInCircle({
                  position: e.coord,
                });
              } else {
                sessionStorage.setItem("hasPrediction", "true");
                setIsOpenPredictionLoading(true);
                setTimeout(() => {
                  clickSalesInCircle({
                    position: e.coord,
                  });
                  setIsOpenPredictionLoading(false);
                }, 2000);
              }
            }
          }, 300);
        }
      }
    );

    return () => {
      if (
        !window ||
        !window.naver ||
        !window.naver.maps ||
        !window.naver.maps.Event
      )
        return;
      window.naver.maps.Event.removeListener(markerOnMapClickListener);
      markerOnMapClickListener = null;
    };
  }, [map, userInfo]);

  const zoomInPrediction = (predictionPosition) => {
    if (map.getZoom() < 15) {
      setShowZoomToast(true);
      map.morph(predictionPosition, 15);
    } else {
      map.panTo(predictionPosition);
    }
  };

  useEffect(() => {
    // console.log('데이타조회 start');

    initialize();

    //console.log(selectedTheme, selectedBadge, selectedCategory, fromMonth, toMonth, isCheckedMonth, fromStartUpCost, toStartUpCost, isCheckedStartUpCost, fromArea, toArea, isCheckedArea, selectedOrder);

    return () => {
      // console.log('데이타조회 end');
    };
    // eslint-disable-next-line
  }, [
    selectedTheme,
    selectedBadge,
    selectedCategory,
    fromMonth,
    toMonth,
    isCheckedMonth,
    fromStartUpCost,
    toStartUpCost,
    isCheckedStartUpCost,
    fromArea,
    toArea,
    isCheckedArea,
    selectedOrder,
  ]);

  //매출 확인을 위한 팝업창 띄운다.
  const openChoiceBrandList = () => {
    logEvent(analytics, "prediction-start");
    setChoiceBrandListPopup((prev) => !prev);
  };

  const popupChoiceBrand = (selectedBrand) => {
    modeForMapRef.current = true;
    const brand = {
      name: selectedBrand.name,
      id: selectedBrand.id,
      logoImageUrl: selectedBrand.logoImageUrl,
    };
    choiceBrandRef.current = brand;
    setChoiceBrand(brand);
    setChoiceBrandListPopup(false);
  };

  //api 호출해서 매출예측 데이타 가져온다.
  const clickSalesInCircle = async (currentSalesMarker) => {
    if (currentSalesMarker) {
      let regionName = ""; //지역명 서울,전북,전라,제주도, 인천 등등

      getAddress(
        `${currentSalesMarker.position.x},${currentSalesMarker.position.y}`
      )
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            if (
              res.data &&
              res.data.results[0] !== undefined &&
              res.data.results[0] !== "undefined" &&
              res.data.results[0].region !== null
            ) {
              if (res.data) {
                const region = res.data.results[0].region;
                regionName = region.area1.alias;
                if (process.env.NODE_ENV != "development") {
                  if (regionName !== "서울") {
                    enqueueSnackbar(
                      "해당 지역의 매출 확인 기능은 현재 서비스하고 있지 않습니다.",
                      {
                        className: "snackbar alert",
                        autoHideDuration: 10000,
                        action: closeConfirm,
                      }
                    );

                    return;
                  }
                }

                getSalesInCircle(
                  choiceBrandRef.current.id,
                  regionName,
                  currentSalesMarker.position.y,
                  currentSalesMarker.position.x
                )
                  .then((res) => {
                    // 비회원 매출예측 제한
                    if (userInfo.email == "") {
                      logEvent("prediction-not-login");
                      let predictionCount =
                        Cookies.get("prediction_count") || 0;
                      predictionCount = parseInt(predictionCount, 10);

                      if (predictionCount < 2) {
                        const midnight = new Date();
                        midnight.setHours(24, 0, 0, 0);
                        Cookies.set("prediction_count", predictionCount + 1, {
                          expires: midnight,
                        });
                      } else {
                        setPredictionLimitModalOpen(true);

                        return;
                      }
                    }
                    zoomInPrediction(currentSalesMarker.position);

                    makeFlagAndCircle(currentSalesMarker.position);

                    const data = res.data;
                    const minX = data.minX;
                    const minY = data.minY;
                    const maxX = data.maxX;
                    const maxY = data.maxY;

                    let salesInfoWindow = new window.naver.maps.Marker({
                      position: new window.naver.maps.LatLng(
                        currentSalesMarker.position.y,
                        currentSalesMarker.position.x
                      ),
                      map: map,
                      icon: {
                        // content: contentHtml,
                        content: PredictionMarker(
                          data,
                          salesInfoWindowGroup.current.length + 1
                        ),
                        anchor: new window.naver.maps.Point(208, 0),
                      },
                      clickable: true,
                    });

                    setConsultingLocation(
                      `${region.area1.name} ${region.area2.name} ${region.area3.name}`
                    );
                    setConsultingCoord({
                      x: currentSalesMarker.position.x,
                      y: currentSalesMarker.position.y,
                    });
                    setSelectedBrand(data.brand);

                    document
                      .getElementById(
                        `btn-${data.brand.id}-detail-${
                          salesInfoWindowGroup.current.length + 1
                        }`
                      )
                      .addEventListener("click", (e) => {
                        window.open(`/brand/${data.brand.id}`);
                      });

                    document
                      .getElementById(
                        `btn-${data.brand.id}-startup-${
                          salesInfoWindowGroup.current.length + 1
                        }`
                      )
                      .addEventListener("click", (e) => {
                        logEvent(analytics, "prediction-click-startup-place");
                        setConsultingModalOpen(true);
                      });

                    document
                      .getElementById(
                        `btn-${data.brand.id}-report-${
                          salesInfoWindowGroup.current.length + 1
                        }`
                      )
                      .addEventListener("click", (e) => {
                        logEvent(analytics, "prediction-click-report");
                        setIsOpenPricePlanModal(true);
                      });
                    salesInfoWindowGroup.current.push(salesInfoWindow);

                    //salesInfoWindow.open(tempMap, currentSalesMarker);

                    // if (
                    //   generalRectangle.current &&
                    //   generalRectangle.current.getMap()
                    // ) {
                    //   // window.naver.maps.Event.removeListener(rectangle);
                    //   generalRectangle.current.setMap(null);
                    // }

                    // const rectangle = new window.naver.maps.Rectangle({
                    //   strokeColor: "#ff00ff",
                    //   strokeOpacity: 0.8,
                    //   strokeWeight: 2,
                    //   fillColor: "#ff00ff",
                    //   fillOpacity: 0.35,
                    //   bounds: new window.naver.maps.LatLngBounds(
                    //     new window.naver.maps.LatLng(maxY, minX), //왼쪽 위
                    //     new window.naver.maps.LatLng(minY, maxX)
                    //   ), //오른쪽 아래
                    //   clickable: true,
                    //   map: map,
                    // });

                    // generalRectangle.current = rectangle;
                  })
                  .catch((e) => {
                    console.log(e);
                    enqueueSnackbar("매출확인이 불가한 지역입니다.", {
                      className: "snackbar alert",
                      autoHideDuration: 5000,
                      action: closeConfirm,
                    });
                  });
              } else {
                console.log("주소 데이타 정보 없음");
              }
            }
          } else {
            console.log(res);
          }
        })
        .catch((e) => {
          console.log(e);
          return;
        });
    } else {
      console.log("clickSalesInCircle 값이 없다");
    }
  };

  useEffect(() => {
    // console.log('marker start');

    let markers = [];
    let markerClustering = null;
    let markerClickListeners = [];
    let markerMouseOverListeners = [];
    let markerMouseOutListeners = [];
    let markerDragEndListener;
    let markerZoomChangedListener;

    setNullMarkers(markers);

    if (markerClustering) {
      markerClustering.onRemove();
    }

    //initialize(mapRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //console.log(brand);

    if (markerList) {
      //console.log(markerList.length);
      for (let i = 0; i < markerList.length; i++) {
        let currentMarker = markerList[i];

        const branchOne = currentMarker.branches[0];
        const badgeCount = currentMarker.branches.length;

        const filteredBrandList = brand.filter(
          // (brand) => brand.id === branchOne.brandId, // 48914 빽다방
          (brand) => brand.id === branchOne.tempBrandId // 48914 빽다방
        );
        const brandOne = filteredBrandList[0];

        if (brandOne) {
          if (!window || !window.naver) return;
          const latlng = new window.naver.maps.LatLng(
            branchOne.latitude,
            branchOne.longitude
          );

          const marker = new window.naver.maps.Marker({
            position: latlng,
            icon: {
              //url : "https://navermaps.github.io/maps.js/docs/img/logo.png",
              // <div id="marker-branch-list${branchOne.id}" class="${cn('marker__branch-list')}">
              //                 <div>리스트1</div>
              //                 <div>리스트2</div>
              //                 <div>리스트3</div>
              //             </div>
              content: `<div>
                          <div id="marker${branchOne.id}" class="${cn(
                "map__marker-section",
                {
                  checked: branchOne.checked,
                  highlight: branchOne.highlight,
                  bigger: markerStatus,
                }
              )}">
                            <div class="${cn("inner")}">
                              <div class="${cn("image__container")}">
                                <img class="image" alt="LOGO" src=${
                                  brandOne.logoImageUrlThumb
                                    ? brandOne.logoImageUrlThumb
                                    : "/placeholder_logo.png"
                                }></img>
                              </div>
                              <div class="${cn("brand-name")}">
                                <span>${brandOne.name}</span>
                                <span>${branchOne.name}</span>
                              </div>
                              <div class="${cn("count-badge", {
                                active: badgeCount > 1,
                              })}">+${badgeCount - 1}</div>  
                              <div class="triangle"><div>
                            </div>
                          </div>
              </div>`,
              // size: new window.naver.maps.Size(173, 69),
              origin: new window.naver.maps.Point(0, 0),
              anchor: new window.naver.maps.Point(86, 79), // 14,10
            },
            draggable: false,
            map: map,
          });

          //좌측 패널 열리고 가운데 위치로 마커 옮기기
          const markerClickListener = window.naver.maps.Event.addListener(
            marker,
            "click",
            function (e) {
              onClickMarker(brandOne, branchOne, e, marker);
            }
          );
          const markerMouseOverListener = window.naver.maps.Event.addListener(
            marker,
            "mouseover",
            function (e) {
              //const markerElement = document.getElementById(e.domEvent.path[0].id);
              changeMarkerHighlightColor(e.domEvent.path);
            }
          );

          const markerMouseOutListener = window.naver.maps.Event.addListener(
            marker,
            "mouseout",
            function (e) {
              //const markerElement = document.getElementById(e.domEvent.path[0].id);
              //console.log(markerElement);
              clearHighlight();
            }
          );

          markerClickListeners.push(markerClickListener);
          markerMouseOverListeners.push(markerMouseOverListener);
          markerMouseOutListeners.push(markerMouseOutListener);

          markers.push(marker);
        } else {
          // console.log('No brandOne');

          const latlng = new window.naver.maps.LatLng(
            branchOne.latitude,
            branchOne.longitude
          );

          const marker = new window.naver.maps.Marker({
            position: latlng,
            icon: {
              content: `<div>${badgeCount}</div>`,
              origin: new window.naver.maps.Point(0, 0),
              anchor: new window.naver.maps.Point(86, 79),
            },
            draggable: false,
            map: map,
          });

          markers.push(marker);
        }
      }

      markerDragEndListener = window.naver.maps.Event.addListener(
        map,
        "dragend",
        function (e) {
          onDragend(e);
          infoWindow.current.close();
        }
      );

      markerZoomChangedListener = window.naver.maps.Event.addListener(
        map,
        "zoom_changed",
        function (e) {
          // console.log(e);
          zoomRef.current = e;
          onZoomChanged(e);
          infoWindow.current.close();
        }
      );

      //console.log(markers);

      // //eslint-disable-next-line no-unused-vars
      // markerClustering = new MarkerClustering({
      //   minClusterSize: 2,
      //   maxZoom: 15, //숫자가 커질수록 확대됨. 14부터 1?까지 적용 됨.[1-16]
      //   map: map,
      //   markers: markers,
      //   disableClickZoom: false,
      //   gridSize: 200,
      //   icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5, htmlMarker6, htmlMarker7], // TODO 내용처리를 어떻게 해야 하나.
      //   indexGenerator: [1, 50, 100, 200, 500, 1000, 3000],
      //   stylingFunction: function (clusterMarker, count) {
      //     // console.log(clusterMarker, count);
      //     // console.log(clusterMarker.getElement());
      //     // console.log(clusterMarker.getElement().getElementsByClassName("count-badge"));

      //     if ( clusterMarker.getElement().getElementsByClassName("count-badge") ) {
      //       const el = clusterMarker.getElement().getElementsByClassName("count-badge")[0];
      //       // console.log(el);
      //       el.innerText = "+" + count;
      //     }
      //     // clusterMarker.getElement().firstChild.innerHTML = count;
      //     // clusterMarker.getElement().firstChild.firstChild.lastChild.innerHTML = count
      //     // $(clusterMarker.getElement()).find('div:first-child').text(count);
      //   },
      // });

      //브랜드 디테일에서 지도보기로 들어온 경우 처리
      if (count === 1) {
        // setParamBrandId(prev => null);
        // setParamBranchId(prev => null);
        eventList(params.brandId, params.branchId);

        if (params.latitude && params.logitude) {
          moveToCenterAtMap(params.latitude, params.logitude);
        }

        setCount((prev) => prev + 1);
      }
    } else {
      //console.log("브랜드 리스트 없다");
    }

    return () => {
      // console.log('marker end');

      if (
        !window ||
        !window.naver ||
        !window.naver.maps ||
        !window.naver.maps.Event
      )
        return;
      setNullMarkers(markers);
      if (markerClustering) {
        markerClustering.onRemove();
      }
      markerClustering = null;

      if (markerClickListeners) {
        for (let i = 0; i < markerClickListeners.length; i++) {
          const markerClickListener = markerClickListeners[i];
          if (markerClickListener) {
            window.naver.maps.Event.removeListener(markerDragEndListener);
          }
        }
      }
      if (markerMouseOverListeners) {
        for (let i = 0; i < markerMouseOverListeners.length; i++) {
          const markerMouseOverListener = markerMouseOverListeners[i];
          if (markerMouseOverListener) {
            window.naver.maps.Event.removeListener(markerMouseOverListener);
          }
        }
      }

      if (markerMouseOutListeners) {
        for (let i = 0; i < markerMouseOutListeners.length; i++) {
          const markerMouseOutListener = markerMouseOutListeners[i];
          if (markerMouseOutListener) {
            window.naver.maps.Event.removeListener(markerMouseOutListener);
          }
        }
      }

      if (circleListeners) {
        for (let i = 0; i < circleListeners.length; i++) {
          const circleListener = circleListeners[i];
          if (circleListener) {
            window.naver.maps.Event.removeListener(circleListener);
          }
        }
      }

      window.naver.maps.Event.removeListener(markerDragEndListener);
      window.naver.maps.Event.removeListener(markerZoomChangedListener);

      markerClickListeners = null;
      markerMouseOverListeners = null;
      markerMouseOutListeners = null;
      markerDragEndListener = null;
      markerZoomChangedListener = null;
      circleListeners = [];
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  return (
    <div
      className={cn("navermap-container", { expanded: folding.foldingStatus })}
    >
      <div id="naver__map"></div>
      <MapAsideContainer
        currentBrand={currentBrand}
        currentBranch={currentBranch}
        brandList={brand}
        branchList={branch}
        addressMapCenter={addressMapCenter}
        getMapBranchList={getMapBranchList}
        checkedBrandItem={checkedBrandItem}
        checkedBranchItem={checkedBranchItem}
        highlightBranchItem={highlightBranchItem}
        handleAsideLayer={handleAsideLayer}
        handleFirstLayer={handleFirstLayer}
        handleSecondLayer={handleSecondLayer}
        handleThirdLayer={handleThirdLayer}
        handleThirdBranchDetailPopup={handleThirdBranchDetailPopup}
        isActive={isActive}
        moveToCenterAtMap={moveToCenterAtMap}
        sortBrand={sortBrand}
        sortBranch={sortBranch}
        sortingBrandEvent={sortingBrandEvent}
        sortingBranchEvent={sortingBranchEvent}
        initialize={initialize}
        recruitType={recruitType}
        sortingBranchRecruitEvent={sortingBranchRecruitEvent}
        callGetAddress={callGetAddress}
        showMarkerZoomToast={showMarkerZoomToast}
      />

      {false && !reloadButtonIsHidden && (
        <div className="btn-reload">
          <Button
            variant="contained"
            onClick={initialize}
            className="btn-color"
          >
            현재 위치에서 검색
          </Button>
        </div>
      )}

      {
        <div className="marker__flag">
          <Button
            variant="contained"
            //onClick={clickSalesInCircle}
            onClick={openChoiceBrandList}
            className={cn("btn-color", { on: modeForMapRef.current })}
          >
            매출예측
          </Button>
        </div>
      }
      {choiceBrand && choiceBrandRef.current && modeForMapRef.current && (
        <div className="selected-brand-for-sales">
          <img
            src={`https://ttoksa-brand-logo.s3.ap-northeast-2.amazonaws.com/logo/${choiceBrand.id}/${choiceBrand.id}_logo.jpg`}
            alt="current prediction brand logo"
          />
          <div>
            <div>{choiceBrandRef.current.name}</div>
            <div>매출 예측 중</div>
          </div>
          <i
            className="selected-brand-for-sales__close-btn"
            onClick={() => {
              modeForMapRef.current = false;
              closeFlagAndCircle();
              setChoiceBrand(null);
            }}
          >
            닫기
          </i>
        </div>
      )}

      <Modal
        open={choiceBrandListPopup}
        onClose={() => setChoiceBrandListPopup(false)}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <BrandSelectModal
          isOpen={choiceBrandListPopup}
          onClose={() => setChoiceBrandListPopup(false)}
          hotBrandList={hotBrandList}
          onClickBrand={(brand) => {
            popupChoiceBrand(brand);
            logEvent(analytics, "prediction-select-brand");
          }}
        />
      </Modal>
      <Modal
        open={consultingModalOpen}
        onClose={() => setConsultingModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ConsultingModal
          isOpen={consultingModalOpen}
          onClose={() => setConsultingModalOpen(false)}
          brand={selectedBrand}
          consultingLocation={consultingLocation}
          coord={consultingCoord}
        />
      </Modal>
      <Modal
        open={predictionLimitModalOpen}
        onClose={() => setPredictionLimitModalOpen(false)}
        BackdropProps={{
          style: {
            background: "none",
          },
        }}
      >
        <PredictionLimitModal
          open={predictionLimitModalOpen}
          onClose={() => setPredictionLimitModalOpen(false)}
          onClickLogin={() => {
            setPredictionLimitModalOpen(false);
            loginDispatch(
              popup({
                login: true,
              })
            );
          }}
        />
      </Modal>
      {Cookies.get("onboarding_next_time") != "false" &&
        sessionStorage.getItem("onboarding") != "false" && (
          <OnBoardingModal map={map} />
        )}
      {showZoomToast && (
        <CustomToast
          showZoomToast={showZoomToast}
          onClose={() => setShowZoomToast(false)}
        >
          <div>💡꿀팁 : 매출 예측 기능은 지도를 확대할수록 더 정확해요.</div>
        </CustomToast>
      )}

      {showMarkerZoomToast && (
        <MarkerZoomToastContainer>
          💡 지점 정보를 확인하기 위해 지도를 확대해주시기 바랍니다{" "}
          <button
            onClick={() => {
              if (!map) return;
              map.setZoom(budongsanLevel, true);
              logEvent(analytics, "map-click-zoom");
            }}
          >
            지도 확대하기
          </button>
        </MarkerZoomToastContainer>
      )}
      <PricePlanModal
        open={isOpenPricePlanModal}
        onClose={() => {
          setIsOpenPricePlanModal(false);
        }}
        brand={selectedBrand}
        consultingLocation={consultingLocation}
        onClickRequest={() => {
          if (userInfo.email == "") {
            setReportLoginModalOpen(true);
            return;
          } else {
            window.open("http://pf.kakao.com/_Xxmavxj/chat", "target=_blank");
          }
        }}
      />

      <Modal
        open={reportLoginModalOpen}
        onClose={() => setReportLoginModalOpen(false)}
        BackdropProps={{
          style: {
            background: "none",
          },
        }}
      >
        <ReportLoginModal
          open={reportLoginModalOpen}
          onClose={() => setReportLoginModalOpen(false)}
          onClickLogin={() => {
            setReportLoginModalOpen(false);
            loginDispatch(
              popup({
                login: true,
              })
            );
          }}
        />
      </Modal>
      <PredictionLoading isOpen={isOpenPredictionLoading} />
    </div>
  );
}
const MarkerZoomToastContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%, -50%);
  padding: 12px 16px;

  background: #ed703e;
  border-radius: 4px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;

  button {
    color: #ed703e;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    background-color: white;
    border-radius: 4px;

    padding: 4px 8px;
  }
`;

export default React.memo(NaverMap);
