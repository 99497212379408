import client from "./client";


//창업 문의
export const requestStartupConsultation = (body, tokenVal) => client.post(
    "/api/startupConsultationApplication",
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: "Bearer " + tokenVal,
        },
    }
);



