import cn from 'classnames';
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '~/assets/scss/brand.scss';
import Footer from '~/components/layout/Footer';
import { app } from '~/sagas/actions/commonAction';
import { changeFilterFoldingBtnHide, changeFilterFoldingInit, filterForm, getSearchBrand } from '~/sagas/actions/filterAction';
import { getBrandList } from '~/sagas/actions/listAction';
import { getBrandsByKeyword } from '~/sagas/api/brand';
import MapSearchResultText from '../maps/MapSearchResultText';
import BrandListContainer from './BrandListContainer';
import BrandListMoreBtn from './BrandListMoreBtn';

let searchResult = [];
let lastValue;

const BrandScreen = () => {
  let inDebounce;
  
  const [searchKeyword, setSearchKeyword] = useState(''); //검색시 키워드
  const [resultBrandList, setResultBrandList] = useState([]); //검색시 brand List에서 검색어에 해당되는 브랜드 목록들

  const dispatch = useDispatch();
  const {  form } = useSelector(({ filter }) => ({
    form: filter.form,
  }), shallowEqual);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onBlurAutoComplete = useCallback((e) => {
      
      setResultBrandList([]);
      setSearchKeyword('');
      

      dispatch(
        app({
          dimmed: {
            isActive: false,
          },
          class: null,
        }),
      );
    },
    [dispatch],
  );

  //검색 된 브랜드 클릭 이벤트
  const onClickResultBrand = (brandId) => {
    
    onBlurAutoComplete(); //검색 기능 초기화

    
  };

  //검색중 ESC 누르면 닫힘.
  const handleKeyDown = (e) => {
    if ( e.keyCode === 27 || e.which === 27 ) {
      onBlurAutoComplete();
    }
  };



  const debounce = (func, delay)=> {
    if ( inDebounce ) {
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout( func(), delay);
  }

  //브랜드 검색 키 입력 이벤트
  const onChangeBrandSearch = (e) => {
    const { value } = e.target;
    // console.log("텍스트필드 값: " + value);
    lastValue = value;
    setSearchKeyword(value);
    
    debounce( () => { //redux saga에서

      if ( value ) {
        getBrandsByKeyword(value).then(res => { //이 부분 비동기인데 '크'가 '크린'보다 더 늦게 response 오면서 문제를 발생시킴. -> lastValue / arr로 해결한 로직
          if ( res.status === 200 ) {
            const data = res.data;
            //console.log("검색된 : " + value + ", 받은 것 : " + searchResult);

            searchResult.push(data);

            if ( searchResult.length > 10 ) {
              searchResult.shift();
            }

            searchResult.map( item => {
              if ( item.keyword === lastValue ) {
                //console.log('setting 된 : ' +lastValue);
                setResultBrandList(item.brandList);
              }

              return item;
            });
          }
        });
      } else {
        setResultBrandList([]);
      }
    }, 1000);
  };

 
  useLayoutEffect(() => {
    dispatch(changeFilterFoldingBtnHide());
    

    return () => {
      dispatch(changeFilterFoldingInit());
      dispatch(
        getBrandList(
          {
            ...form,
            more:false,
            pageIndex: 0,
            selectedCategory: form.selectedCategory
              .filter((i) => i.checked)
              .map((i) => i.id)
              .join(),
            selectedBadge: form.selectedBadge
              .filter((i) => i.checked)
              .map((i) => i.id)
              .join(),
            selectedTheme: form.selectedTheme
              .filter((i) => i.checked)
              .map((i) => i.id)
              .join(),
          },
          
        ),
      );
      //window.scrollTo(0, 0);
    };

  }, []);

  return (
    <>
      {/* <FilterContainer /> */}
      <div className="brand-container">
        <form onSubmit={onSubmit}>

          <div className="search-brand">
            <div className="inner">
              <div className="search">

                <div className="search-box">
                  <button type="submit" className="ic-search"></button>
                  <div className="search-input">
                    <input type="text" 
                      value={searchKeyword} 
                      onChange={onChangeBrandSearch} 
                      onKeyDown={handleKeyDown}  
                      placeholder="어떤 브랜드를 찾으시나요?" />
                  </div>
                </div>
                

                <div className={cn("result-list-group", {on: resultBrandList && resultBrandList.length > 0})}>
                  { 
                    resultBrandList ? resultBrandList.map( ( item ) => 
                      <Link key={item.id} to={`/brand/${item.id}`} target="_blank">
                        <div className="brand-item" onClick={() => onClickResultBrand(item.id)}>
                          <span className="brand-name">
                            <div className="thumb-logo">
                              <img src={item.logoImageUrlThumb ? item.logoImageUrlThumb : "/placeholder_logo.png"} alt="LOGO" />
                            </div>
                            <MapSearchResultText text={item.name} searchKeyword={searchKeyword} />
                            
                          </span>
                          <div className="category-name">{item.categoryName}</div>
                        </div>
                      </Link>
                    )
                  : null
                  }
                </div>

                <div className={cn("btn-group", {on: resultBrandList && resultBrandList.length > 0})}>
                  
                  <div className="close-btn" onClick={onBlurAutoComplete}>
                    <i className="close-img">close</i>
                    <button className="search-brand-close-btn">
                      창닫기
                    </button>
                  </div>
                  
                </div>
                

              </div>
            </div>
          </div>
        </form>

        <div className="inner">
          <BrandListContainer />
          <BrandListMoreBtn />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default React.memo(BrandScreen);
