import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OnBoardingBrand from "./OnBoardingBrand";
import OnBoardingEnd from "./OnBoardingEnd";
import OnBoardingMap from "./OnBoardingMap";
import OnBoardingPrediction from "./OnBoardingPrediction";
import OnBoardingStart from "./OnBoardingStart";
import Cookies from "js-cookie";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

const MODAL_STATE = {
  START: 0,
  CLICK_PREDICTION: 1,
  CLICK_BRAND: 2,
  CLICK_MAP: 3,
  END: 4,
};

export default function OnBoardingModal({ map }) {
  //
  const [modalState, setModalState] = useState(MODAL_STATE.START);
  const [modalOpen, setModalOpen] = useState(true);

  const setSessionSkip = () => {
    sessionStorage.setItem("onboarding", "false");
  };

  const setSkipNextTimeCookie = () => {
    logEvent(analytics, "tutorial-skip-forever");

    const date = new Date();
    date.setMonth(date.getMonth() + 12);
    Cookies.set("onboarding_next_time", "false", {
      expires: date,
    });
    closeModal();
  };

  const closeModal = () => {
    localStorage.setItem("onboarding_shown", "true");
    setModalOpen(false);
  };

  return (
    <Modal open={modalOpen} hideBackdrop={modalState !== MODAL_STATE.START}>
      {modalState === MODAL_STATE.START ? (
        <OnBoardingStart
          onClickNext={() => {
            setSessionSkip();
            setModalState(MODAL_STATE.CLICK_PREDICTION);
          }}
          onClickSkip={() => {
            setSessionSkip();
            setModalState(MODAL_STATE.START);
            setModalOpen(false);
            logEvent(analytics, "tutorial-skip");
          }}
          onClickSkipNextTime={setSkipNextTimeCookie}
        />
      ) : modalState === MODAL_STATE.CLICK_PREDICTION ? (
        <OnBoardingPrediction
          onClickNext={() => {
            setModalState(MODAL_STATE.CLICK_BRAND);
          }}
        />
      ) : modalState === MODAL_STATE.CLICK_BRAND ? (
        <OnBoardingBrand
          onClickNext={() => {
            setModalState(MODAL_STATE.CLICK_MAP);
          }}
        />
      ) : modalState === MODAL_STATE.CLICK_MAP ? (
        <OnBoardingMap
          onClickNext={() => {
            setModalState(MODAL_STATE.END);
          }}
        />
      ) : (
        <OnBoardingEnd
          onClickNext={() => {
            setModalOpen(false);
            logEvent(analytics, "tutorial-finish");
          }}
          map={map}
        />
      )}
    </Modal>
  );
}
