import cn from "classnames";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { chageFilterListCategoryShowOnFilter } from "~/sagas/actions/filterAction";
import CategoryAddModal from "../CategoryAddModal";
import PopOver from "../container/Popover";
import { logEvent } from "firebase/analytics";
import { analytics } from "~/App";

const CategoryListItem = ({ item, onChange }) => {
  const { name, id, checked, deletable } = item;

  const dispatch = useDispatch();
  const { selectedCategory, hiddenCategory } = useSelector(
    ({ filter }) => ({
      selectedCategory: filter.form.selectedCategory,
      hiddenCategory: filter.form.hiddenCategory,
    }),
    shallowEqual
  );

  const removeCategory = (id) => {
    const restoreList = selectedCategory
      .filter((i) => i.id === id)
      .map((e) => {
        if (e.id === id) {
          e.showOnFilter = false; //보이는 카테고리 목록에서 뺀다.
          e.checked = false; //선택된것 초기화
          return e;
        } else {
          return e;
        }
      });

    //보여지고 있는 카테고리에 checked 카테고리가 없으면 true
    const list = selectedCategory.filter(
      (e) => e.id !== id && e.checked === true
    );
    let listClear = true;
    if (list && list.length < 1) {
      listClear = true;
    } else {
      listClear = false;
    }

    //show on filter 상태를 false로 변경, 되돌려 놓을 때 리스트 순서 정렬
    dispatch(
      chageFilterListCategoryShowOnFilter({
        a: selectedCategory
          .filter((e) => e.id !== id)
          .map((i) => {
            if (i.id === 0 && listClear) {
              i.checked = true;
              return i;
            } else {
              return i;
            }
          }),
        b: hiddenCategory.concat(restoreList).sort((a, b) => a.id - b.id),
      })
    );
  };

  return (
    <label className={cn("checkbox check-outlined", { deletable: deletable })}>
      <input
        checked={checked}
        onChange={(e) => {
          logEvent(analytics, "map-click-filter-category");
          onChange(e, id);
        }}
        name="selectedCategory"
        value={id}
        type="checkbox"
      />
      <span>{name}</span>
      {deletable && (
        <button
          className="deletable-btn"
          onClick={() => removeCategory(item.id)}
        >
          &nbsp;
        </button>
      )}
    </label>
  );
};

const CategoryList = ({ data, onChange }) => {
  const closePopover = () => {
    //지우면 창 안닫힘.
  };

  return (
    <dl className="desc-primary row">
      <dt>업종</dt>
      <dd>
        {data &&
          data
            .filter((item) => item.showOnFilter === true)
            .map((i) => (
              <CategoryListItem key={i.id} item={i} onChange={onChange} />
            ))}
        <PopOver
          onClickConfirm={closePopover}
          title="업종추가"
          value=""
          buttonStyle="btn-outlined-add"
          containerClass="cate-add"
        >
          <CategoryAddModal />
        </PopOver>
      </dd>
    </dl>
  );
};

export default React.memo(CategoryList);
