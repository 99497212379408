import "~/assets/scss/coin.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "@material-ui/core";
import MyCoin from "./MyCoin";
import cn from "classnames";
import CoinListItem from "~/components/payment/CoinListItem";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { formattedNum, numberWithCommas } from "~/common/NumberUtil";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "~/App";

export const coinText = "코인";

const initTab = [
  {
    id: "COIN",
    isActive: true,
  },
  {
    id: "BUY",
    isActive: false,
  },
  {
    id: "USED",
    isActive: false,
  },
];

function Coin() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [coinCnt, setCoinCnt] = useState(null);
  const [chargePopup, setChargePopup] = useState(false);

  const [tab, setTab] = useState(initTab);

  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  const changeTab = (id) => {
    setTab((prev) =>
      prev.map((i) => {
        if (i.id === id) {
          i.isActive = true;
        } else {
          i.isActive = false;
        }
        return i;
      })
    );
  };

  const popupOpen = () => {
    setChargePopup(true);
  };

  const popupClose = () => {
    setChargePopup(false);
  };

  const [coinList, setCoinList] = useState([]);

  const [buyList, setbuyList] = useState([
    // {
    //   id: 19,
    //   cnt: 300,
    //   label: "30,000원",
    //   date: "21.11.09 15:38",
    // },
  ]);

  const [useList, setUseList] = useState([
    // {
    //   id: 7,
    //   cnt: 300,
    //   date: "21.11.09 15:38",
    // },
  ]);

  // customized
  const actionCloseChangePasswordPopup = useCallback(
    (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    ),
    [closeSnackbar]
  );

  useEffect(() => {
    getCoinList();
    getUserCoin();
    getBuyList();
    getUseList();
  }, [userInfo]);

  const getCoinList = () => {
    getDocs(collection(firestore, "coin"))
      .then((querySnapshot) => {
        const coinList = [];
        querySnapshot.forEach((doc) => {
          coinList.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        coinList.sort((a, b) => {
          return a.count - b.count;
        });
        setCoinList(coinList);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const getUserCoin = () => {
    if (!userInfo.id) return;
    const queryRef = query(
      collection(firestore, "wallet"),
      where("userId", "==", userInfo.id)
    );

    getDocs(queryRef)
      .then((querySnapshot) => {
        setCoinCnt(querySnapshot.empty ? 0 : querySnapshot.docs[0].data().coin);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const getBuyList = () => {
    setbuyList(
      userInfo && userInfo.coinBuyHistoryList
        ? userInfo.coinBuyHistoryList
        : null
    );
  };

  const getUseList = () => {
    setUseList(
      userInfo && userInfo.coinUsedHistoryList
        ? userInfo.coinUsedHistoryList
        : null
    );
  };

  return (
    <>
      <div className="coin__container" onClick={popupOpen}>
        <div className="left-group">
          <i className="coin-img">{coinText}이미지</i>
          <span className="coin-label">{coinText}</span>
          {coinCnt ? (
            <span className="coin-cnt">{coinCnt} 개</span>
          ) : (
            <span className="coin-cnt">없음</span>
          )}
        </div>
        <div className="right-group">
          <span className="charge-text">충전하기</span>
        </div>
      </div>
      <Modal open={chargePopup} className="coin-modal-popup">
        <div className="coin-tap__container">
          <div className="coin-section">
            <div className="title-group">
              <div className="title">충전하기</div>
              <div className="close-popup" onClick={popupClose}>
                닫기
              </div>
            </div>

            <div className="tab-group">
              <div
                className={cn("tab", {
                  active:
                    tab.filter((i) => i.id === "COIN" && i.isActive === true)
                      .length > 0,
                })}
                onClick={() => changeTab(initTab[0].id)}
              >
                {coinText}구매
              </div>
              <div
                className={cn("tab", {
                  active:
                    tab.filter((i) => i.id === "BUY" && i.isActive === true)
                      .length > 0,
                })}
                onClick={() => changeTab(initTab[1].id)}
              >
                구매내역
              </div>
              <div
                className={cn("tab", {
                  active:
                    tab.filter((i) => i.id === "USED" && i.isActive === true)
                      .length > 0,
                })}
                onClick={() => changeTab(initTab[2].id)}
              >
                사용내역
              </div>
            </div>

            <div
              className={cn("tab__container", {
                active:
                  tab.filter((i) => i.id === "COIN" && i.isActive === true)
                    .length > 0,
              })}
            >
              <MyCoin />
              {coinList &&
                coinList.map((item) => (
                  <div key={item.id} className="buy-coin__container">
                    <div className="left-group">
                      <i className="coin-img">{coinText}이미지</i>
                      <span className="coin-label">{coinText}</span>
                      <span className="coin-cnt">
                        {numberWithCommas(item.count)}개
                      </span>
                    </div>
                    <div className="right-group">
                      <CoinListItem userInfo={userInfo} coinData={item} />
                    </div>
                  </div>
                ))}
            </div>

            <div
              className={cn("tab__container", {
                active:
                  tab.filter((i) => i.id === "BUY" && i.isActive === true)
                    .length > 0,
              })}
            >
              <MyCoin />
              {buyList ? (
                buyList.map((item) => (
                  <div key={item.id} className="my-coin__container">
                    <span className="date">
                      {item &&
                        item.paymentDateTime &&
                        item.paymentDateTime.replace("T", " ").substring(0, 16)}
                    </span>
                    <div className="text-group pading-left30">
                      <span className="title">구매</span>
                      <span className="value">{item && item.coinCnt}개</span>
                    </div>
                    <div className="text-group">
                      <span className="title">금액</span>
                      <span className="value">
                        {item &&
                          item.coinCnt &&
                          formattedNum(item.coinCnt * 100)}
                        원
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data__container">구매내역이 없습니다.</div>
              )}
            </div>

            <div
              className={cn("tab__container", {
                active:
                  tab.filter((i) => i.id === "USED" && i.isActive === true)
                    .length > 0,
              })}
            >
              <MyCoin />
              {useList ? (
                useList.map((item) => (
                  <div key={item.id} className="use-coin__container">
                    <span className="date">
                      {item &&
                        item.paymentDateTime &&
                        item.paymentDateTime.replace("T", " ").substring(0, 16)}
                    </span>
                    <div className="text-group">
                      <span className="title">코인</span>
                      <span className="value">{item && item.cnt}개</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data__container">사용내역이 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(Coin);
