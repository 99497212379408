import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { chageFilterListCategoryShowOnFilter } from '~/sagas/actions/filterAction';



const CategoryAddModal = ({value, handleConfirm}) => {
  const dispatch = useDispatch();
  const [selectedParent, setSelectedParent] = useState(''); //선택된 부모 카테고리
  const [selectedChild, setSelectedChild] = useState(''); //선택된 자식 카테고리

  const [distinctParentList, setDistinctParentList] = useState(null); //중복제거된 부모 카테고리 리스트

  const {selectedCategory, hiddenCategory} = useSelector(({filter}) => ({
    form: filter.form,
    selectedCategory: filter.form.selectedCategory,
    hiddenCategory: filter.form.hiddenCategory
  }), shallowEqual);

  // 부모 카테고리 변경
  const changeParentCategory = useCallback((e) => {
    setSelectedParent(e.target.value);

    //부모 카테고리 변경 시 자식 첫번째 세팅.
    let firstCategory = hiddenCategory && hiddenCategory.filter((v)  => v.category === e.target.value)[0];
    setSelectedChild(firstCategory.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent]);

  // 자식 카테고리 변경
  const changeChildCategory = useCallback((e) => {
    setSelectedChild(e.target.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChild]);

  // 추가 버튼 이벤트
  const addEvent = useCallback(() => {    

    const addList = hiddenCategory.filter(i => i.id === Number(selectedChild)).map( e => {
      if (e.id === Number(selectedChild)) {
        e.showOnFilter = true; //보이는 카테고리 목록에서 뺀다.
        e.checked = true; //선택된 것 활성화
        return e;
      } else {
        return e;
      }
    });

    //show on filter 상태를 false로 변경
    dispatch(
      chageFilterListCategoryShowOnFilter({
        a:selectedCategory.map(i => {
          i.checked = false; //선택되어 있던 것들 전부 해제
          return i;
        }).concat(addList),
        b:hiddenCategory.filter(e=> e.id !== Number(selectedChild))
      }
      )
    );

    handleConfirm();
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, selectedCategory, hiddenCategory, selectedParent, selectedChild, changeChildCategory]);

  //최초 조회시 부모 1번째와 자식 첫번째 세팅
  useEffect(() => {
    //부모 카테고리 목록 중복제거
    const categoryList = hiddenCategory && hiddenCategory.map(e=> e.category);
    let firstCategory = [];
    const distinctedCategory = categoryList && categoryList.filter((v,i,a)=>a.indexOf(v)===i); //중복제거

    if ( categoryList && categoryList.length > 0 ) {
      firstCategory = hiddenCategory[0];
    }

    setDistinctParentList(distinctedCategory);
    //첫번째 부모의 첫번째 자식
    setSelectedParent(firstCategory.category);
    setSelectedChild(firstCategory.id);
  

  }, [selectedCategory, hiddenCategory]);

  return (
    <div className="modal-container">
      <div className="modal-content">
        <select name="" className="select outlined" value={selectedParent} onChange={changeParentCategory}>
          {distinctParentList && distinctParentList.map( (e, idx) => <option key={idx} value={e}> {e}</option> )}
        </select>
        <select name="" className="select outlined" value={selectedChild} onChange={changeChildCategory} >
          {hiddenCategory && 
          hiddenCategory
            .filter((f) => f.category === selectedParent)
            .map( (e) => <option key={e.id} value={e.id}> {e.name}</option> )}
        </select>
      </div>

      <div className="modal-footer">
        <div className="alignR">
          <Button variant="outlined" className="btn-modal-ok" onClick={addEvent}>
              추가
            </Button>
        </div>
      </div>
    </div>
  );
};

export default CategoryAddModal;
