import cn from "classnames";
import {
  doc,
  getDoc,
  query as firestoreQuery,
  collection,
  where,
  getDocs,
  setDoc,
  increment,
  addDoc,
} from "firebase/firestore";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { firestore } from "~/App";
import { numberWithCommas } from "~/common/NumberUtil";
import useQuery from "~/common/useQuery";
import Header from "~/components/layout/Header";
import { getBankName } from "~/constants/payment_bank";

export default function PaymentSuccessScreen() {
  const query = useQuery();
  const history = useHistory();

  const [currentOrder, setCurrentOrder] = useState();
  const [currentCoin, setCurrentCoin] = useState();

  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  const [virtualAccountInfo, setVirtualAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userInfo.id) return;
    const orderId = query.get("orderId");
    const amount = query.get("amount");
    const paymentKey = query.get("paymentKey");

    if (!orderId || !amount || !paymentKey) {
      alert("잘못된 접근입니다.");
      history.replace("/");
      return;
    }

    // query
    const paymentRef = collection(firestore, "payment");
    const queryRef = firestoreQuery(
      paymentRef,
      where("orderId", "==", orderId)
    );

    getDocs(queryRef)
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          alert("결제 정보가 없습니다.");
          history.replace("/");
          return;
        }
        const docRef = querySnapshot.docs[0];
        const savedPaymentData = {
          id: docRef.id,
          ...docRef.data(),
        };

        setCurrentOrder(savedPaymentData);
        if (savedPaymentData.state === "paid") {
          alert("이미 결제가 완료된 주문입니다.");
          history.replace("/");
          return;
        }

        if (savedPaymentData.state === "cancel") {
          alert("결제가 취소된 주문입니다.");
          history.replace("/");
          return;
        }

        if (savedPaymentData.state === "fail") {
          alert("결제가 실패된 주문입니다.");
          history.replace("/");
          return;
        }

        if (savedPaymentData.amount == amount) {
          try {
            const coinRef = doc(firestore, "coin", savedPaymentData.coinId);

            const coinDoc = await getDoc(coinRef);
            const coin = {
              id: coinDoc.id,
              ...coinDoc.data(),
            };
            setCurrentCoin(coin);

            fetch("https://api.tosspayments.com/v1/payments/confirm", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // Authorization: `Basic dGVzdF9za19PUnpkTWFxTjN3bzlYbmRsb0tCMzVBa1lYUUd3Og==`,
                Authorization: `Basic bGl2ZV9za19HZVBXdnlKbnJLYko2UFA5bE9SVmdMek45N0VvOg==`,
              },
              body: JSON.stringify({
                orderId,
                amount,
                paymentKey,
              }),
            })
              .then((res) => res.json())
              .then(async (res) => {
                if (res.code) {
                  alert("결제 승인에 실패했습니다. 관리자에게 문의해주세요.");
                  console.log(res);
                  return;
                }
                try {
                  await setDoc(
                    doc(firestore, "payment", savedPaymentData.id),
                    {
                      state: "paid",
                      updatedAt: new Date(),
                      paymentKey,
                    },
                    { merge: true }
                  );

                  if (res.method === "가상계좌") {
                    setVirtualAccountInfo({
                      number: res.virtualAccount.accountNumber,
                      bank: res.virtualAccount.bankCode,
                      expireAt: res.virtualAccount.dueDate,
                      customerName: res.virtualAccount.customerName,
                    });
                  } else {
                    const walletQueryRef = firestoreQuery(
                      collection(firestore, "wallet"),
                      where("userId", "==", userInfo.id)
                    );

                    const walletQuerySnapshot = await getDocs(walletQueryRef);

                    if (walletQuerySnapshot.empty) {
                      await addDoc(collection(firestore, "wallet"), {
                        userId: userInfo.id,
                        coin: coin.count,
                      });
                    } else {
                      await setDoc(
                        doc(
                          firestore,
                          "wallet",
                          walletQuerySnapshot.docs[0].id
                        ),
                        {
                          coin: increment(coin.count),
                        },
                        { merge: true }
                      );
                    }
                  }
                  setIsLoading(false);
                } catch (error) {
                  alert("결제 승인에 실패했습니다. 관리자에게 문의해주세요.");
                  console.log(error);
                }
              })
              .catch((err) => {
                alert("결제 승인에 실패했습니다. 관리자에게 문의해주세요.");
                console.log(err);
              });
          } catch (error) {
            alert("결제 승인에 실패했습니다. 관리자에게 문의해주세요.");
            console.log(error);
          }
        }
      })
      .catch((err) => {
        alert("결제 승인에 실패했습니다. 관리자에게 문의해주세요.");
        console.log(err);
      });
  }, [userInfo]);

  return (
    <div
      id="wrapper"
      className={cn(app.class, { dimmed: app.dimmed.isActive })}
    >
      <Header />
      {!isLoading && (
        <Container>
          <div style={{ textAlign: "center" }}>
            <Kudo>🎉</Kudo>
            <Title>
              {virtualAccountInfo
                ? "가상 계좌에 입금하여 구매를 완료하세요!"
                : "구매 완료!"}
            </Title>
          </div>
          <ReciptContainer>
            <ReciptRow>
              <div>주문 번호</div>
              <div>{currentOrder && currentOrder.orderId}</div>
            </ReciptRow>
            <ReciptRow>
              <div>결제 상품</div>
              <div>
                코인 {currentCoin && numberWithCommas(currentCoin.count)}개
              </div>
            </ReciptRow>
            <ReciptRow>
              <div>결제 금액</div>
              <div>
                {currentOrder && numberWithCommas(currentOrder.amount)}원
              </div>
            </ReciptRow>
            {virtualAccountInfo && (
              <>
                <ReciptRow>
                  <div>은행</div>
                  <div>{getBankName(virtualAccountInfo.bank)}</div>
                </ReciptRow>
                <ReciptRow>
                  <div>계좌번호</div>
                  <div>{virtualAccountInfo.number}</div>
                </ReciptRow>
                <ReciptRow>
                  <div>입금자명</div>
                  <div>{virtualAccountInfo.customerName}</div>
                </ReciptRow>
                <ReciptRow>
                  <div>입금기한</div>
                  <div>
                    {moment(virtualAccountInfo.expireAt).format(
                      "YYYY.MM.DD HH:mm:ss"
                    )}
                  </div>
                </ReciptRow>
              </>
            )}
          </ReciptContainer>

          <BackButton
            onClick={() => {
              history.replace("/");
            }}
          >
            홈으로
          </BackButton>
        </Container>
      )}
    </div>
  );
}

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 24px;
`;

const Kudo = styled.div`
  font-size: 150px;
`;

const Title = styled.h1`
  font-size: large;
  font-weight: bold;
`;

const ReciptContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReciptRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BackButton = styled.button`
  padding: 30px;
  background-color: #ff662a;
  border-radius: 10px;
  max-width: 500px;
  margin: 30px auto;

  width: 100%;
  display: flex;
  justify-content: center;

  color: white;
  font-weight: bold;
  font-size: large;
`;
