import React from "react";
import { amountUnit } from "~/App.js";
import { formattedNum } from "../../common/NumberUtil.js";
import ManagerComment from "./ManagerComment.js";

function StartUpCostOfBrand({ startUpCost, logo, comment, hideComment }) {
  if (startUpCost == null) {
    return null;
  }

  return (
    <div className="brand-detail__start-up-costs">
      {!hideComment && (
        <span className="brand-detail__title">가맹점 창업비용</span>
      )}

      <span className="brand-detail__sub-title">
        {startUpCost.baseYear}년 정보공개서 기준
      </span>

      {startUpCost && startUpCost.baseArea != 0 && (
        <span className="brand-detail__base-area">
          {parseInt(startUpCost.baseArea * 3.3, 10)}㎡({startUpCost.baseArea}평)
          기준
        </span>
      )}

      <div className="brand-detail__table-group">
        <div>
          <span>보증금</span>
          <span>
            {startUpCost.deposit
              ? `${formattedNum(startUpCost.deposit * amountUnit)}원`
              : "정보없음"}
          </span>
        </div>
        <div>
          <span>교육비</span>
          <span>
            {startUpCost.educationExpenses
              ? `${formattedNum(startUpCost.educationExpenses * amountUnit)}원`
              : "정보없음"}
          </span>
        </div>
        <div>
          <span>가맹비</span>
          <span>
            {startUpCost.membershipFee
              ? `${formattedNum(startUpCost.membershipFee * amountUnit)}원`
              : "정보없음"}
          </span>
        </div>
        <div>
          <span>기타</span>
          <span>
            {startUpCost.otherCosts
              ? `${formattedNum(
                  (startUpCost.otherCosts - startUpCost.interiorCost) *
                    amountUnit
                )}원`
              : "정보없음"}
          </span>
        </div>
        <div>
          <span>인테리어</span>
          <span>
            {startUpCost.interiorCost
              ? `${formattedNum(startUpCost.interiorCost * amountUnit)}원`
              : "정보없음"}
          </span>
        </div>
        <div>
          <span>단위면적당 인테리어 비용</span>
          <span>
            {startUpCost.interiorCostPerBaseArea > 0
              ? `${formattedNum(
                  startUpCost.interiorCostPerBaseArea * amountUnit
                )}원`
              : startUpCost.interiorCostPerBaseArea == 0
              ? "0원"
              : "정보없음"}
          </span>
        </div>
      </div>
      <div className="brand-detail__table_total">
        <span>전체</span>
        <span>
          {startUpCost.total
            ? `${formattedNum(
                (startUpCost.total - startUpCost.interiorCost) * amountUnit
              )}원`
            : "정보없음"}
        </span>
      </div>

      {!hideComment && comment && comment.franchiseStartUpCostComment && (
        <ManagerComment
          key="franchiseStartUpCostComment"
          logo={logo}
          comment={comment.franchiseStartUpCostComment}
          name={comment.userName}
          modifyAt={comment.modifyAt}
        />
      )}
    </div>
  );
}

export default React.memo(StartUpCostOfBrand);
