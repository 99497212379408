import { createAction, handleActions } from 'redux-actions';
import * as type from '../types/filterType';

export const changeFilterFolding = createAction(type.CHANGE_FILTER_FOLDING);
export const changeFilterFoldingInit = createAction(type.CHANGE_FILTER_FOLDING_INIT);
export const changeFilterFoldingBtnHide = createAction(type.CHANGE_FILTER_FOLDING_BTN_HIDE);
export const getFilterListBadge = createAction(type.GET_FILTER_BADGE);
export const getFilterListCategory = createAction(type.GET_FILTER_CATEGORY);
export const chageFilterListCategoryShowOnFilter = createAction(type.CHANGE_FILTER_CATEGORY_SHOW_ON_FILTER);
export const getFilterListTheme = createAction(type.GET_FILTER_THEME);
export const getSearchBrand = createAction(type.GET_SEARCH_BRAND);
export const filterForm = createAction(type.FILTER_FORM);
export const autocompleteInit = createAction(type.AUTOCOMPLETE_INIT);

const initialState = {
  badge: null,    //초기값들
  category: null, //초기값들
  theme: null,    //초기값들
  form: {
    selectedCategory: [], //브랜드 필터 메인에 보여짐. 조회될 때 사용되기도 함.
    hiddenCategory: [],  //브랜드 필터 업종추가 창에 보여짐.
    selectedBadge: [],
    selectedTheme: [],
    fromStartUpCost: 0,
    toStartUpCost: 500000000,
    isCheckedStartUpCost: false, //창업비용
    fromMonth: 0,
    toMonth: 500000000,
    isCheckedMonth: false,  //매출액   
    fromArea: 0,
    toArea: 156,
    isCheckedArea: false, //매장면적
    selectedOrder: '',
    pageIndex: 0,
  },
  folding: {//필터 영역 접고 펴기
    showOnFolding: true, //폴딩 버튼 보이고 안보이고
    foldingStatus: true, //버튼의 상태 true: 펼치진 상태
  }, 
  autocomplete: [],
  error: {
    GET_FILTER_BADGE: null,
    GET_FILTER_CATEGORY: null,
    GET_FILTER_THEME: null,
  },
};

const filter = handleActions(
  {
    [type.CHANGE_FILTER_FOLDING]: (state) => ({
      ...state,
      folding: {
        showOnFolding: true,
        foldingStatus: !state.folding.foldingStatus
      },
    }),
    [type.CHANGE_FILTER_FOLDING_INIT]: (state) => ({
      ...state,
      folding: {
        showOnFolding: true,
        foldingStatus: true,
      },
    }),
    [type.CHANGE_FILTER_FOLDING_BTN_HIDE]: (state) => ({
      ...state,
      folding: {
        showOnFolding: false,
        foldingStatus: true,
      },
    }),
    [type.GET_FILTER_BADGE_SUCCESS]: (state, { payload }) => ({
      ...state,
      badge: payload,
      form: {
        ...state.form,
        selectedBadge: payload.filter((i) => i.active).map((i) => ({
          id: i.id,
          name: i.name,
          checked: false,
        })),
      },
    }),
    [type.GET_FILTER_BADGE_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_FILTER_BADGE: payload,
      },
    }),
    [type.CHANGE_FILTER_CATEGORY_SHOW_ON_FILTER]: (state, {payload}) => ({
      ...state,
      form: {
        ...state.form,
        selectedCategory: payload.a,
        hiddenCategory: payload.b,
      },
    }),
    [type.GET_FILTER_CATEGORY_SUCCESS]: (state, { req, payload }) => ({
      ...state,
      category: payload,
      form: {
        ...state.form,
        selectedCategory: payload.filter(i=> i.showOnFilter===true).map((i) => ({
                            id: i.id,
                            category: i.category,
                            name: i.name,
                            showOnFilter: i.showOnFilter,
                            deletable: i.showOnFilter ? false: true, 
                            checked: false,
                          })),
        hiddenCategory: payload.filter(i=> i.showOnFilter===false).map((i) => ({
                          id: i.id,
                          category: i.category,
                          name: i.name,
                          showOnFilter: i.showOnFilter,
                          deletable: i.showOnFilter ? false: true, 
                          checked: false,
                        })),
      },
    }),
    [type.GET_FILTER_CATEGORY_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_FILTER_CATEGORY: payload,
      },
    }),

    [type.GET_FILTER_THEME_SUCCESS]: (state, { req, payload }) => ({
      ...state,
      theme: payload,
      form: {
        ...state.form,
        selectedTheme: payload.filter((i) => i.active).map((i) => ({
          id: i.id,
          name: i.shortName,
          checked: false,
        })),
      },
    }),
    [type.GET_FILTER_THEME_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_FILTER_THEME: payload,
      },
    }),

    [type.GET_SEARCH_BRAND_SUCCESS]: (state, { req, payload }) => ({
      ...state,
      autocomplete: payload,
    }),
    [type.GET_SEARCH_BRAND_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_SEARCH_BRAND: payload,
      },
    }),

    [type.AUTOCOMPLETE_INIT]: (state) => ({
      ...state,
      autocomplete: [],
    }),
    [type.FILTER_FORM]: (state, { payload }) => ({
      ...state,
      form: payload,
    }),
  },
  initialState,
);

export default filter;
