import React, { useCallback } from 'react';
import { Modal } from '@material-ui/core';
import '~/assets/scss/find_password_admin.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { popup } from '~/sagas/actions/commonAction';


function FindPasswordModal() {

  const dispatch = useDispatch();

  const { findPasswordAdmin } = useSelector(({ common }) => ({
    findPasswordAdmin: common.popup.findPasswordAdmin,
  }), shallowEqual);

  const closeFindPassword = useCallback(() => {
      dispatch(
          popup({
            findPasswordAdmin: false,
          }),
      );
  }, [dispatch]);

  return (
    <Modal
          open={findPasswordAdmin}
      >
          <div className="find-password-admin__container">
              <div className="find-password__title-group">
                <div className="find-password__title">비밀번호 찾기</div>
                <button className="find-password__close-btn" onClick={closeFindPassword}>close</button>
              </div>

              <div className="find-password__content-group">
                <div className="find-password__content-content">가입 시 사용한 이메일 주소를 입력해주시면<br/>비밀번호 재설정 링크를 발송해 드립니다.</div>
                <input type="text" className="find-password__input-email" placeholder="가입한 이메일 주소"/>
                <div className="find-password__content-btn">비밀번호 재설정 링크받기</div>
              </div>
          </div>
      </Modal>
  );
}

export default React.memo(FindPasswordModal);