import cn from "classnames";
import React, { useState } from "react";
import styled from "styled-components";
import HighLightSvg from "../../assets/images/onboarding/onboarding_brand_hightlight.svg";
import BubbleSvg from "../../assets/images/onboarding/onboarding_prediction_bubble.svg";
import BrandSelectModal from "~/components/container/map/BrandSelectModal";

const OnBoardingBrand = React.forwardRef(({ onClickNext }, ref) => {
  const [hotBrandList, setHotBrandList] = useState([
    { name: "60계치킨", id: 52509 },
    { name: "자담치킨", id: 52088 },
    { name: "컴포즈커피", id: 53248 },
    { name: "메가커피", id: 51131 },
    { name: "도미노피자", id: 58305 },
    { name: "청년피자", id: 50270 },
    { name: "한신포차", id: 48906 },
    { name: "하남돼지집", id: 54052 },
    { name: "씨유(CU)", id: 50307 },
    { name: "GS25", id: 52867 },
  ]);
  return (
    <Container ref={ref}>
      <TopContainer></TopContainer>
      <MiddleContainer>
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            zIndex: -1,
          }}
        >
          <BrandSelectModal
            isOpen={true}
            onClose={() => {}}
            hotBrandList={hotBrandList}
            onClickBrand={(brandName, brandId) => {}}
          />
        </div>
        <MiddleBlock />
        <HighLight>
          <img src={HighLightSvg} alt="" />
          <HotBrandItemContainer onClick={onClickNext}>
            <HotBrandName>컴포즈커피</HotBrandName>
          </HotBrandItemContainer>
          <BubbleContainer style={{ backgroundImage: `url(${BubbleSvg})` }}>
            <BubbleTitle>
              이미 똑똑한 사장{" "}
              <span style={{ fontWeight: 400 }}>from 똑사</span>
            </BubbleTitle>
            <BubbleContents>
              궁금한 브랜드를 검색할 수 있어요
              <br />
              오늘은{" "}
              <span style={{ fontWeight: 600, color: "#ed703e" }}>
                인기있는 브랜드
              </span>
              로 선택해볼게요
            </BubbleContents>
          </BubbleContainer>
        </HighLight>
        <MiddleBlock />
      </MiddleContainer>
      <BottomContainer />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  min-height: calc(25vh + 176px);
  background-color: rgba(0, 0, 0, 0.5);
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MiddleBlock = styled.div`
  flex: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
`;

const BottomContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HighLight = styled.div`
  position: relative;
  margin-right: -1px;
`;

const BubbleContainer = styled.div`
  position: absolute;
  right: 130px;
  bottom: -15px;

  width: 416px;

  height: 125px;
  filter: drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25));

  padding: 24px 32px;
`;

const BubbleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #686868;
`;

const BubbleContents = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 8px;
`;

const HotBrandItemContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 11px;
  width: 90px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 0;
  background: #ffffff;
  border: 1px solid #ed703e;
  border-radius: 4px;
  cursor: pointer;
`;

const HotBrandName = styled.span`
  max-width: 90px;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ed703e;
  overflow: hidden;
  white-space: nowrap;
`;

export default OnBoardingBrand;
