export default function PrivacyScreen() {
  return (
    <div style={{ display: "flex" }}>
      <iframe
        style={{ width: "100%", height: "100vh" }}
        src="https://plip.kr/pcc/c5ace902-4740-41ac-aae2-c7d11552b73f/privacy/1.html"
      />
    </div>
  );
}
