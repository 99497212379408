import client from "../client";

//가맹점 관리 정보 조회
export const getBrandInfo = (brandId, tokenVal) => client.get(
    `/admin/brands/${brandId}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//가맹점 관리 정보 수정
export const modifyBrandInfo = (body, tokenVal) => client.post(
    `/admin/brands/modify`,
    body,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//댓글 정보들 조회
export const getBrandComments = (brandId, tokenVal) => client.get(
    `/admin/comments/${brandId}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//댓글 정보 저장
export const saveCommentData = (franchiseBusinessStatusId, data, tokenVal) => client.post(
    `/admin/comments/${franchiseBusinessStatusId}`,
    JSON.stringify(data),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);


//커스텀 섹션 목록 조회
export const getCustomSectionList = (brandId, tokenVal) => client.get(
    `/admin/customSection/list/${brandId}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//커스텀 섹션 추가 버튼
export const addSectionData = (body, tokenVal) => client.post(
    `/admin/customSection/list/add`,
    JSON.stringify(body),
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//커스텀 섹션 상세 저장
export const saveCustomSectionDetails = (id, title, contents, tokenVal) => client.post(
    `/admin/customSection/detail/add`,
    {
        id: id,
        title: title,
        contens: contents
    },
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//커스텀 섹션 삭제 버튼
export const deleteSectionData = (id, tokenVal) => client.delete(
    `/admin/customSection/list/${id}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);