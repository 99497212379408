import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { areaJson } from "~/screen/login/UserSetting";

const initStartUpType = [
  {
    id: 1,
    name: "3개월 이내",
    value: "UNDER_THREE_MONTH",
    checked: false,
  },
  {
    id: 2,
    name: "6개월 이내",
    value: "UNDER_SIX_MONTH",
    checked: false,
  },
  {
    id: 3,
    name: "1년 이내",
    value: "UNDER_ONE_YEAR",
    checked: false,
  },
  {
    id: 4,
    name: "구체적 계획 없음",
    value: "NONE",
    checked: false,
  },
];

export default function StartUpTimeStep({
  times,
  parentLocation,
  childLocation,
  onClickPrevious,
  onClickNext,
}) {
  const [selectedTime, setSelectedTime] = useState(times);
  const [selectedParentLocation, setSelectedParentLocation] =
    useState(parentLocation);
  const [selectedChildLocation, setSelectedChildLocation] =
    useState(childLocation);

  // 부모 카테고리 변경
  const changeParentCategory = useCallback(
    (e) => {
      setSelectedParentLocation(e.target.value);

      setSelectedChildLocation("전체");

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedParentLocation]
  );

  // 자식 카테고리 변경
  const changeChildCategory = useCallback(
    (e) => {
      setSelectedChildLocation(e.target.value);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedChildLocation]
  );
  return (
    <Container>
      <TitleContainer>
        <Title>원하시는 창업 지역과 시점을 선택하세요</Title>
      </TitleContainer>
      <TimeContainer>
        <TimeSubTitle>창업 지역</TimeSubTitle>
        <TimeList>
          <select
            name="sido"
            value={selectedParentLocation}
            className="select outlined"
            onChange={changeParentCategory}
          >
            {areaJson &&
              areaJson.map((e, idx) => (
                <option key={e.sido} value={e.sido}>
                  {" "}
                  {e.sido}
                </option>
              ))}
          </select>
          <select
            name="gu"
            value={selectedChildLocation}
            className="select outlined"
            onChange={changeChildCategory}
          >
            {areaJson &&
              areaJson
                .filter((f) => f.sido === selectedParentLocation)
                .map((e, idx) =>
                  e.gu.map((k) => (
                    <option key={k} value={k}>
                      {" "}
                      {k}
                    </option>
                  ))
                )}
          </select>
        </TimeList>
        <TimeSubTitle style={{ marginTop: "16px" }}>창업 시점</TimeSubTitle>
        <TimeList>
          {initStartUpType.map((ele) => (
            <TimeItem
              key={ele.id}
              onClick={() => {
                setSelectedTime(ele.value);
              }}
              isSelected={selectedTime == ele.value}
            >
              {ele.name}
            </TimeItem>
          ))}
        </TimeList>
      </TimeContainer>
      <UtilContainer>
        <PreviousButton onClick={onClickPrevious}>뒤로</PreviousButton>
        <NextButton
          disabled={
            selectedTime == -1 ||
            selectedParentLocation == "" ||
            selectedParentLocation == "선택하세요."
          }
          onClick={() =>
            onClickNext(
              selectedTime,
              selectedParentLocation,
              selectedChildLocation
            )
          }
        >
          다음
        </NextButton>
      </UtilContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const TitleContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  color: #000000;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 24px 12px 0;
`;

const TimeSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(50, 48, 48, 0.78);
`;

const TimeList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  margin-top: 3px;
`;

const TimeItem = styled.div`
  padding: 0px 10px;

  border: ${(props) =>
    props.isSelected
      ? "1px solid #fec581"
      : "1px solid rgba(101, 101, 101, 0.25);"};
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: #000000;
  cursor: pointer;
`;

const UtilContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-self: flex-end;
  gap: 16px;
  width: calc(100% - 32px);
  margin: auto auto 0;
`;

const PreviousButton = styled.button`
  width: 100px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(101, 101, 101, 0.25);
  border-radius: 4px;
  color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const NextButton = styled.button`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled ? "rgba(101, 101, 101, 0.25)" : "#ed703e"};
  border-radius: 4px;
  color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
