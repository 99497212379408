import { createAction, handleActions } from 'redux-actions';
import * as type from '../types/listType';

export const getBrandList = createAction(type.GET_BRAND_LIST, (form, type) => form);
export const getThemeList = createAction(type.GET_THEME_LIST, (form, type) => form);

const initialState = {
  BrandList: {
    list: null,
    hasNext: false,
  },
  ThemeList: {
    free: [],
    region: [],
    startup: [],
  },
  error: {
    GET_BRAND_LIST: false,
  },
};

const list = handleActions(
  {
    [type.GET_BRAND_LIST_SUCCESS]: (state, { req, payload }) => ({
      ...state,
      BrandList: {
        ...state.BrandList,
        list: !req.more ? payload.brandList : state.BrandList.list.concat(payload.brandList),
        hasNext: payload.hasNext,
      },
    }),
    [type.GET_BRAND_LIST_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_BRAND_LIST: payload,
      },
    }),

    [type.GET_THEME_LIST_SUCCESS]: (state, { req, payload }) => ({
      ...state,
      ThemeList: {
        ...state.ThemeList,
        [req.name]: payload,
      },
    }),
    [type.GET_THEME_LIST_FAILURE]: (state, { payload }) => ({
      ...state,
      error: {
        ...state.error,
        GET_THEME_LIST: payload,
      },
    }),
  },
  initialState,
);

export default list;
