import React, { useEffect, useState } from "react";
import { formattedNum } from "../../common/NumberUtil.js";
import {Bar} from "react-chartjs-2";
import cn from "classnames";
import { amountUnit } from "~/App.js";


const initChartData = {
  labels: [2016, 2017, 2018, 2019, 2020, 2021],
  datasets: [
    {
      label: '월평균매출',
      backgroundColor: 'rgb(108, 209, 130)',
      barThickness: 12,
      data: [1, 1, 1, 1, 1, 1],
      
    }
  ],
};

const initChartOptions = {
  maintainAspectRatio: true,
  scales: {
    x: {
      grid:{
        display:false
      },
      // title: {
      //   text: '축 이름'
      // },
      ticks: {
        major: {
          enabled: true
        },
        color: (context) => '#FF662A',
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 400
            };
          }
        },
      }
    },
    y: {
      ticks: {
        align:'right',
        major: {
          enabled: true
        },
        color: (context) => {
          return "#B3B3B3";
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 100
            };
          }
        },
        callback: function(tickValue, index, ticks) {
          //console.log(tickValue);
          return tickValue === 0 ?  0 + '개' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
        // index, dataset, point, nearest(defalut), x, y
        mode: 'index',
        intersect: true,
        titleColor: '#333333',
        titleFont: {weight: 500},
        bodyColor: '#333333',
        bodyFont: {weight: 400},
        backgroundColor: '#ffffff',
        borderColor: '#d3dfe2',
        borderWidth: 1,
        usePointStyle: true,
        callbacks: {
          title: function(data) {
            //console.log(data);
            return data[0].label;
          },
          label: function(context) { 
            //console.log(context);
              var label = context.dataset.label || '';

              if (label) {
                  label += ' ';
              }
              if (context.parsed.y !== null) {
                  if ( context.datasetIndex === 3 ) {
                    label += context.parsed.y + '%';
                  } else {
                    const tickValue = context.parsed.y;
                    label += tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
                  }                  
              }
              return label;
          },
          labelColor: function(context) { //바디 젤 앞 이미지
            //console.log(context.datasetIndex);
            return {
                //borderColor: 'rgb(0, 0, 255)',
                backgroundColor: context.datasetIndex === 0 ? "rgb(108, 209, 130)" : context.datasetIndex === 1 ? "#FF662A" : "#0000ee",
                borderWidth: 2,
                borderDash: [2, 2],
                borderRadius: 2,
            };
          },
          labelPointStyle: function(context) { //바디 젤 앞 이미지 모양
            return {
              pointStyle: 'circle',
              rotation: 0
            };
          }
        }
  
    }
  },
};

function FranchiseAvgMonthlySalesItem({ salesOfBrandList, franchiseStartDate }) {
  // console.log(salesOfBrandList);
  // console.log(franchiseStartDate);
  const [data, setData] = useState(initChartData);
  const [options, setOptions] = useState(initChartOptions);

  const handleDatas = () => {
    
    const date = new Date();
    let currentYear = date.getFullYear();
    let existDataYearArr = [];

    let list = salesOfBrandList;

    const openStore = list.filter( (item) => item.year > currentYear-10);
    //console.log(list)
    openStore.map(item => {
      if ( existDataYearArr.indexOf(item.year) === -1) {
        existDataYearArr.push(item.year);
      }
      return item;
    });

    existDataYearArr.sort((a,b) => a-b);

    ////데이타가 있는 것중에 제일 낮은 년도 2013
    const yearArr = salesOfBrandList.map(i => i.year);

    let emptyYears = [];

    //데이타가 없는 년도 뽑아내기
    for(let i=0; i<10; i++ ) {
      if ( existDataYearArr.indexOf(currentYear) === -1) {
        emptyYears.push(currentYear);
      }
      currentYear--;
    }

    //데이타가 없는 년도는 껍데기 데이타 구성하기.
    if ( emptyYears ) {
      emptyYears.map( year => {

        list = list.concat([{
          year: year,
          avgMonthlySales: 0
        }]);
        return year;
      })
    }


    const year = franchiseStartDate && franchiseStartDate.substring(0,4); //사업개시일

    list.sort((a,b) => a.year - b.year); //내림차순 정렬

    setData({
      labels: list.map((i) => i.year === Number(year) ? [i.year, '사업개시']: i.year),
      datasets: [
          {
            label: '월평균매출',
            backgroundColor: 'rgb(108, 209, 130)',
            barThickness: 12,
            data: list.map(item => item.avgMonthlySales*amountUnit),
            stack: 'Stack 0'
          }
        ]
    });

    setOptions({
      ...initChartOptions,
      scales: {
        ...initChartOptions.scales,
        x: {
          grid:{
            display:false
          },
          ticks: {
            major: {
              enabled: true
            },
            color: (context) => {
              if ( emptyYears && emptyYears.filter( i => context.tick.label === i ).length > 0 ) {
                return '#E5E5E5';
              } else {
                return '#B3B3B3';
              }
            },
            font: function(context) {
              return {weight: 400};
            },
          }
        },
        y: {
          stacked: true,
          ticks: {
            major: {
              enabled: true
            },
            color: (context) => {
              return "#B3B3B3";
            },
            font: function(context) {
              if (context.tick && context.tick.major) {
                return {
                  weight: 100
                };
              }
            },
            callback: function(tickValue, index, ticks) {
              //console.log(ticks);
              return tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
            }
          }
        }
      }
    });
  };


  useEffect(() => {
    handleDatas();

    // eslint-disable-next-line
  }, [salesOfBrandList]);


  return (
    <div>
      <div className="brand-detail__head-quater-chart">
        <div className="brand-detail__head-quater-chart-content">
          <Bar height={100} data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(FranchiseAvgMonthlySalesItem);
