import client from './client';
export const getList = ({
  selectedCategory,
  selectedBadge,
  selectedTheme,
  fromStartUpCost,
  toStartUpCost,
  isCheckedStartUpCost,
  fromMonth,
  toMonth,
  isCheckedMonth,
  fromArea,
  toArea,
  isCheckedArea,
  selectedOrder,
  pageIndex,
}) =>
  client.get(
    `/api/brands?category=${selectedCategory}&badge=${selectedBadge}&theme=${selectedTheme}&fromStartUpCost=${fromStartUpCost}&toStartUpCost=${toStartUpCost}&isCheckedStartUpCost=${isCheckedStartUpCost}&fromMonth=${fromMonth}&toMonth=${toMonth}&isCheckedMonth=${isCheckedMonth}&fromArea=${fromArea}&toArea=${toArea}&isCheckedArea=${isCheckedArea}&order=${selectedOrder}&page=${pageIndex}`,
  );
