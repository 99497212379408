import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import WaitingBranchCard from './WaitingBranchCard';

function WaitingBranchContainer({ brand }) {
  const sliderRef = useRef(null);
  const [list, setList] = useState();
  
  const settings = {
    dots: false,
    draggable: true, 
    arrows: false,
    infinite: true,
    centerMode: false,
    autoplaySpeed: 3000,
    autoplay:false,
    variableWidth: false,
    appendDots: (dots) => (
      <div className="slick-dots">
        <ul className="paging-container">{dots}</ul>
      </div>
    ),
  };

  //모집중인 것 3개만 보여준다.
  const division = useCallback((n) => {
    let arr = null;
    if ( brand && brand.branch && brand.branch.length > 0 ) {
      arr = brand.branch.filter((e) => e.isRecruit != null && e.isRecruit === "RECRUITING");
    }

    let len = arr != null ? arr.length : 0;
    let cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    let tmp = [];

    for(let i = 0 ; i< cnt; i++ ) {
      tmp.push(arr.splice(0, n));
    }

    //console.log(tmp);

    return tmp;
  }, [brand]);

  useEffect( () => {
    setList(division(3));

    return () => {
      setList(null);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onClickPrev = (e) => {
    sliderRef.current.slickPrev();
  };
  const onClickNext = (e) => {
    sliderRef.current.slickNext();
  };
  return (
    <div className={cn("brand-detail__recruit", {active : (brand && brand.branch && brand.branch.length > 0 && brand.branch.filter((e) => e.isRecruit != null && e.isRecruit === "RECRUITING").length > 0)})}>
      {brand.branch != null && brand.branch.length > 0 ? (
        brand.branch.filter((e) => e.isRecruit != null && e.isRecruit === "RECRUITING").length > 0 ? (
          <div className="brand-detail__recruit-title-group">
            <div className="brand-detail__recruit-title-group-left">
              <span>가맹점 모집 중</span>
              <span>{brand.branch.filter((e) => e.isRecruit != null && e.isRecruit === "RECRUITING").length}개</span>
            </div>
            <div className="brand-detail__recruit-title-group-right">
              <button className="brand-detail__prev-arrow" onClick={onClickPrev}>&lt;</button>
              <button className="brand-detail__next-arrow" onClick={onClickNext}>&gt;</button>
            </div>
          </div>
        ) : null
      ) : null}

      <Slider ref={sliderRef} {...settings}>
          {list && 
            list.map((i, idx) => (
                  <div key={idx} className="brand-detail__recruit-card-group">
                    {i.map( (e) => 
                      <WaitingBranchCard
                        key={e.id}
                        displayAddress={e.displayAddress}
                        eventDetail={e.eventDetail}
                        brand={brand}
                        branch={e}
                      />
                    )}
                  </div>
            )
          )}     
      </Slider>
    </div>
  );
}

export default WaitingBranchContainer;
