import React from "react";
import styled from "styled-components";

const PredictionLimitModal = React.forwardRef(
  ({ open, onClose, onClickLogin }, ref) => {
    return (
      <Container>
        <ModalContainer>
          <Title>
            지금 <TitleHighlight>로그인</TitleHighlight>
            하면
            <br />
            <TitleHighlight>매출 예측</TitleHighlight>이{" "}
            <TitleHighlight>무제한!</TitleHighlight>
          </Title>
          <SubTitle>
            똑사의 소중한 정보에 대한 무분별한 수집을 방지하고자
            <br />
            <SubTitleHighlight>비회원</SubTitleHighlight>의 매출 예측 기능은{" "}
            <SubTitleHighlight>1일 2회</SubTitleHighlight>로 제한돼요
          </SubTitle>
          <UtilContainer>
            <CloseButton onClick={onClose}>닫기</CloseButton>
            <LoginButton onClick={onClickLogin}>로그인 하기</LoginButton>
          </UtilContainer>
        </ModalContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  padding: 36px 24px 28px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1c1c1c;
  text-align: center;
`;

const TitleHighlight = styled.span`
  font-weight: 600;
  color: #ed703e;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;

  margin-top: 20px;
`;
const SubTitleHighlight = styled.span`
  color: #ed703e;
`;

const UtilContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 36px;
  padding: 0 3.5px;
  gap: 16px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ed703e;

  background: #fec581;
  border-radius: 4px;
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;

  background: #ed703e;
  border-radius: 4px;
`;

export default PredictionLimitModal;
