import client from "./client";


//비교 브랜드 목록 조회
export const getBrandsWithComparison = (tokenVal) => client.get(
    `/api/brands_with_comparison`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//비교 브랜드 추가
export const addBrandsWithComparison = (brandId, tokenVal) => client.post(
    `/api/comparisons/${brandId}`,
    null,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//비교 브랜드 제거
export const removeBrandsWithComparison = (brandId, tokenVal) => client.delete(
    `/api/comparisons/${brandId}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);


//관심 브랜드 목록 조회
export const getBrandsWithInterests = (tokenVal) => client.get(
    `/api/brands_with_interests`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//관심 브랜드 추가
export const addBrandsWithInterests = (brandId, tokenVal) => client.post(
    `/api/interests/${brandId}`,
    null,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);

//관심 브랜드 제거
export const removeBrandsWithInterests = (brandId, tokenVal) => client.delete(
    `/api/interests/${brandId}`,
    {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + tokenVal,
        },
    }
);