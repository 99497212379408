import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import '~/assets/scss/user_info_accept_rule.scss';
import {popup} from '~/sagas/actions/commonAction';

function UserInfoAcceptRule() {

  const dispatch = useDispatch();
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.popup.userInfo,
  }), shallowEqual);


  const closePopup = useCallback(() => {
    dispatch(
      popup({
        userInfo: false,
      }),
    );
  }, [dispatch]);

  return (
    <Modal
          open={userInfo}
      >
          <div className="user-info-accept-rule__container">
              <div className="user-info-accept-rule__title-group">
                <div className="user-info-accept-rule__title">개인정보처리방침</div>
                <button className="user-info-accept-rule__close-btn" onClick={closePopup}>close</button>
              </div>

              <iframe title={"개인정보처리방침"} src={"/term/privacy"} width={"100%"} height={600}/>

  {/*            <div className="user-info-accept-rule__content-group">*/}
  {/*              <div className="user-info-accept-rule__content-content">개인정보 보호법에 따라 마이프차에 회원 가입하는 분들의 개인정보 수집 및 이용내역에 대해 안내해 드리니 아래 내용을 자세히 살펴보신 후 동의해 주시기 바랍니다.</div>*/}
  {/*              <h1>회원가입 시 수집 정보</h1>*/}

  {/*              <h2>수집 정보</h2>*/}
  {/*              <h3>- 일반회원: 이름, 이메일 주소</h3>*/}
  {/*              <h3>- 기업 고객 회원: 이름, 휴대전화번호, 이메일 주소</h3>*/}

  {/*              <h2>수집∙이용 목적</h2>*/}
  {/*              <h3>- 회원가입, 중복확인, 서비스 제공 및 상담, 부정이용 확인 및 방지, 만족도 조사</h3>*/}
  {/*           */}
  {/*              <h2>보유 및 이용 기간</h2>*/}
  {/*              <h3>- 회원탈퇴 시 즉시 삭제*/}
  {/*(단, 이용자에게 개인정보 보관에 대한 별도 동의를 얻은 경우나 법령에 따라 일정 기간 정보보관 의무가 있을 경우 해당 기간 동안 개인정보를 안전하게 보관합니다.)</h3>*/}
  {/*           */}
  {/*              <br/>*/}
  {/*              <br/>*/}
  {/*              <h1>서비스 이용 시 수집 정보</h1>*/}

  {/*              <h2>수집 정보</h2>*/}
  {/*              <h3>- IP주소, 기기정보, 쿠키, 서비스 이용 기록, 쿠키, 자료(서비스에 게재를 목적으로 회사에 제공한 정보 등)</h3>*/}

  {/*              <h2>수집∙이용 목적</h2>*/}
  {/*              <h3>- 서비스 제공 및 상담, 부정이용 방지 및 확인, 만족도 조사</h3>*/}

  {/*              <h2>보유 및 이용 기간</h2>*/}
  {/*              <h3>- 서비스 제공 목적 달성 시까지*/}
  {/*  (단, 이용자에게 개인정보 보관에 대한 별도 동의를 얻은 경우나 법령에 따라 일정 기간 정보보관 의무가 있을 경우 해당 기간 동안 개인정보를 안전하게 보관합니다.)</h3>*/}

  {/*              <div>*/}
  {/*              이용자는 개인정보 수집 및 이용을 거부할 권리가 있습니다. 다만 필수정보 수집을 거부하실 경우 회원가입이 어려울 수 있습니다.*/}
  {/*더 자세한 내용은 개인정보처리방침을 확인해 주세요.*/}
  {/*              </div>*/}

  {/*            </div>*/}
          </div>
      </Modal>
  );
}

export default React.memo(UserInfoAcceptRule);
