// import { takeLatest, takeEvery } from "redux-saga/effects";
// import createRequestSaga from "./modules/createRequestSaga";
// import * as api from "../api/common";
// import * as type from "./types/commonType";

// const postLoginSaga = createRequestSaga(type.LOGIN, api.postLogin);
// const postLogoutSaga = createRequestSaga(type.LOGOUT, api.postLogout);
// const getUserInfoSaga = createRequestSaga(type.GET_USERINFO, api.getUserInfo);

export default function* commonSaga() {
  // yield takeLatest(type.LOGIN, postLoginSaga);
  // yield takeLatest(type.LOGOUT, postLogoutSaga);
  // yield takeLatest(type.GET_USERINFO, getUserInfoSaga);
}
