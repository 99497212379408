import "~/assets/scss/my_page.scss";
import cn from "classnames";
import { useHistory } from "react-router";
import Header from "~/components/layout/Header";
import Footer from "~/components/layout/Footer";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSetting from "./UserSetting";
import UnitNormalList from "~/components/listitems/UnitNormalList";
import { getBrandsWithInterests } from "~/sagas/api/favorite";
import { Button, CircularProgress, Modal } from "@material-ui/core";
import { removeUser, updateUser, updateUserMarketing } from "~/sagas/api/user";
import { login } from "~/sagas/actions/commonAction";
import { useSnackbar } from "notistack";
import Coin from "./Coin";

function MyPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const agreeInfomationSaveInput = useRef();
  const changePasswordInput1 = useRef();
  const changePasswordInput2 = useRef();
  const [tab, setTab] = useState(0);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  const [changePassword, setChangePassword] = useState(false); //비밀번호 변경 팝업
  const [secession, setSecession] = useState(false); //탈퇴 팝업
  const [secessionComplete, setSecessionComplete] = useState(false); //탈퇴 완료 팝업
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    password1: "",
    password2: "",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const changeTab = useCallback(
    (tabIndex) => {
      setTab(tabIndex);
      //eslint-disable-next-line
    },
    [tab]
  );

  const onChangeCheckbox = useCallback(
    (e) => {
      setAgreeCheckbox(!agreeCheckbox);
    },
    [agreeCheckbox]
  );

  // customized
  const actionCloseChangePasswordPopup = useCallback(
    (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        닫기
      </Button>
    ),
    [closeSnackbar]
  );

  const closeChangePasswordPopup = useCallback(() => {
    setChangePassword(false);
  }, []);

  const openChangePasswordPopup = useCallback(() => {
    setChangePassword(true);
  }, []);

  const onChangeField = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const modifyUser = useCallback(async () => {
    await updateUser(
      userInfo.id,
      { password1: inputs.password1 },
      localStorage.getItem("jwtToken")
    );
  }, [userInfo, inputs]);

  const modifyUserMarketing = useCallback(async () => {
    await updateUserMarketing(
      userInfo.id,
      { marketing: agreeCheckbox },
      localStorage.getItem("jwtToken")
    );
    dispatch(
      login({
        markettingAgree: agreeCheckbox,
      })
    );

    enqueueSnackbar("저장 되었습니다.", {
      className: "snackbar success",
      autoHideDuration: 3000,
    });
  }, [dispatch, enqueueSnackbar, userInfo, agreeCheckbox]);

  const deleteUser = useCallback(async () => {
    await removeUser(userInfo.id, localStorage.getItem("jwtToken"));
    localStorage.removeItem("jwtToken");
    dispatch(
      login({
        name: "",
        email: "",
        provider: "",
        providerId: "",
      })
    );
  }, [dispatch, userInfo]);

  const saveChangePassword = useCallback(async () => {
    const { password1, password2 } = inputs;

    if (password1.trim().length < 6) {
      enqueueSnackbar("6자리 이상 입력해 주세요.", {
        className: "snackbar warning",
        autoHideDuration: 3000,
      });
    } else if (password1.trim() !== password2.trim()) {
      enqueueSnackbar("비밀번호가 서로 다릅니다. 다시 입력 해주세요.", {
        className: "snackbar warning",
        autoHideDuration: 3000,
      });
    } else {
      setLoading(true);

      await modifyUser();

      setLoading(false);

      closeChangePasswordPopup();

      enqueueSnackbar("비밀번호가 변경되었습니다.", {
        className: "snackbar success",
        autoHideDuration: 3000,
        action: actionCloseChangePasswordPopup,
      });

      // setInputs({
      //     password1: "",
      //     password2: ""
      // }); //비밀번호 초기화
    }
  }, [
    actionCloseChangePasswordPopup,
    closeChangePasswordPopup,
    enqueueSnackbar,
    modifyUser,
    inputs,
  ]);

  const openSecessionPopup = useCallback(() => {
    setSecession(true);
  }, []);

  const closeSecessionPopup = useCallback(() => {
    setSecession(false);
  }, []);

  const openSecessionCompletePopup = useCallback(async () => {
    await deleteUser();
    setSecession(false);
    setSecessionComplete(true);
  }, [deleteUser]);

  const goHome = useCallback(() => {
    history.replace("/map");
  }, [history]);

  const postEventAdd = useCallback(() => {
    getBrandsWithInterests(localStorage.getItem("jwtToken")).then(
      (response) => {
        dispatch(
          login({
            brands: response.data,
          })
        );
      }
    );
  }, [dispatch]);

  const postEventDelete = useCallback(() => {
    getBrandsWithInterests(localStorage.getItem("jwtToken")).then(
      (response) => {
        dispatch(
          login({
            brands: response.data,
          })
        );
      }
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    if (localStorage.getItem("jwtToken") == "test") {
      history.push("/joincomplete");
    }
    getBrandsWithInterests(localStorage.getItem("jwtToken")).then(
      (response) => {
        dispatch(
          login({
            brands: response.data,
          })
        );
      }
    );
  }, [dispatch]);

  useEffect(() => {
    // if ( userInfo.id === '' || localStorage.getItem("jwtToken") === null) {
    //     localStorage.resetItem("jwtToken");
    //     goHome();
    // }
    // setAgreeCheckbox(userInfo.markettingAgree);
    // return () => {
    // };
  }, [goHome, userInfo.id, userInfo.markettingAgree, setAgreeCheckbox]);

  if (!userInfo) return null;

  return (
    <div>
      <Header />
      <div className={cn("my-page__container")}>
        <span className="my-page__user-name">{userInfo.name} 님</span>
        <span className="my-page__user-email">{userInfo.email}</span>
        <div className="my-page__tab-group">
          <button
            className={cn({ on: tab === 0 })}
            onClick={() => changeTab(0)}
          >
            창업 프로필
          </button>
          <button
            className={cn({ on: tab === 1 })}
            onClick={() => changeTab(1)}
          >
            관심 브랜드
          </button>
          <button
            className={cn({ on: tab === 2 })}
            onClick={() => changeTab(2)}
          >
            계정정보
          </button>
        </div>

        <div className={cn("my-page__section")}>
          {/* first tab */}
          <div className={cn("section", { active: tab === 0 })}>
            <UserSetting />
          </div>

          {/* second tab */}
          <div className={cn("section", { active: tab === 1 })}>
            <div className={cn("brand__list-container", { active: tab === 1 })}>
              {userInfo.brands && userInfo.brands.length > 0 ? (
                <UnitNormalList
                  items={userInfo.brands}
                  postEventAdd={postEventAdd}
                  postEventDelete={postEventDelete}
                />
              ) : (
                <div className="brands-no-data">데이타가 없습니다.</div>
              )}
            </div>
          </div>

          {/* third tab */}
          <div className={cn("section", { active: tab === 2 })}>
            <Coin />
            <span className="sub-title">계정정보</span>
            <div className="divider"></div>

            <div className="main-group">
              <div className="group">
                <input
                  type="text"
                  className="input-box"
                  disabled
                  defaultValue={userInfo.name}
                />
                <span className="input-title">이름</span>
              </div>
              <div className="group">
                <input
                  type="text"
                  className="input-box"
                  disabled
                  defaultValue={userInfo.email}
                />
                <span className="input-title">이메일 주소</span>
              </div>
            </div>

            <button
              className="my-page__change-btn"
              onClick={openChangePasswordPopup}
            >
              비밀번호 변경하기
            </button>

            <span className="sub-title">마케팅 동의</span>
            <div className="divider"></div>
            <span className="sub-contents">
              수신 동의서 이벤트 및 각종 행사 등의 정보를 알려드립니다.
            </span>

            <div className="my-page__private">
              <label className="checkbox check-primary">
                <input
                  type="checkbox"
                  name="agreeInfomationSave"
                  onChange={onChangeCheckbox}
                  defaultChecked={agreeCheckbox}
                  ref={agreeInfomationSaveInput}
                />
                <i></i>
                <span>마케팅 정보 수신에 동의합니다.</span>
              </label>
            </div>

            <button className="my-page__save-btn" onClick={modifyUserMarketing}>
              저장
            </button>
            <button className="delete-user" onClick={openSecessionPopup}>
              탈퇴하기
            </button>
          </div>
        </div>

        <Modal open={changePassword}>
          <div className="password-change__container">
            <div className="password-change__title-group">
              <div className="password-change__title">비밀번호 변경하기</div>
              <button
                className="password-change__close-btn"
                onClick={closeChangePasswordPopup}
              >
                close
              </button>
            </div>

            <div className="password-change__content-group">
              <div className="main-group">
                <div className="group">
                  <input
                    type="password"
                    name="password1"
                    className="input-box"
                    onChange={onChangeField}
                    ref={changePasswordInput1}
                  />
                  <span className="input-title">새 비밀번호</span>
                </div>
                <div className="group">
                  <input
                    type="password"
                    name="password2"
                    className="input-box"
                    onChange={onChangeField}
                    ref={changePasswordInput2}
                  />
                  <span className="input-title">새 비밀번호 확인</span>
                </div>
              </div>
              <div className="btn-and-progress-group">
                <Button
                  className="password-change__content-btn"
                  disabled={loading}
                  onClick={saveChangePassword}
                >
                  {loading ? (
                    <div className="circular-progress-box">
                      <CircularProgress
                        className="circular-progress"
                        size={20}
                      />
                    </div>
                  ) : (
                    "비밀번호 변경하기"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={secession}>
          <div className="password-change__container">
            <div className="password-change__title-group">
              <div className="password-change__title">탈퇴하기</div>
              <button
                className="password-change__close-btn"
                onClick={closeSecessionPopup}
              >
                close
              </button>
            </div>

            <div className="password-change__content-group">
              <div className="password-change__content-content">
                탈퇴하시겠습니까?
              </div>
              <div className="password-change__content-btn-group">
                <div
                  className="password-change__content-btn grey"
                  onClick={closeSecessionPopup}
                >
                  취소
                </div>
                <div
                  className="password-change__content-btn"
                  onClick={openSecessionCompletePopup}
                >
                  탈퇴하기
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={secessionComplete}>
          <div className="password-change__container">
            <div className="password-change__title-group">
              <div className="password-change__title">탈퇴 완료</div>
              <button className="password-change__close-btn" onClick={goHome}>
                close
              </button>
            </div>

            <div className="password-change__content-group">
              <div className="password-change__content-content">
                정상적으로 탈퇴 처리되었습니다
              </div>
              <div className="password-change__content-btn" onClick={goHome}>
                확인
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default MyPage;
