import { NoEncryption } from '@material-ui/icons';
import React, { useRef, useState } from 'react';

function JoinSelectedBrand( {item, removeBrand} ) {

    const { brandId, companyRegistrationNumber, name, headQuaterName } = item;

    const [businessFile, setBusinessFile] = useState(null);

    const fileInput = useRef(null);

    const onClickSelectFile = () => {
        fileInput.current.click();
    };

    const changeBusinessNumberFile = (e) => {
        console.log(e.target.value);
        setBusinessFile(e.target.value);
        item.businessFileUrl = e.target.value;
    };

    return (
        <div className="join__brand-group">
            <button className="join__brand-group-close-btn" onClick={() => removeBrand(brandId)}>X</button>
            <div className="join__brand-group-row">
                <div className="join__brand-group-body">
                    <span className="join__title-header">브랜드명</span>
                    <span className="join__title-data underline">{name}</span>
                </div>
            </div>
            <div className="join__brand-group-row">
                <div className="join__brand-group-body">
                    <span className="join__title-header">사업자번호</span>
                    <span className="join__title-data">
                        { companyRegistrationNumber && companyRegistrationNumber.length === 10 ?
                            `${companyRegistrationNumber.substring(0,3)}-${companyRegistrationNumber.substring(3,5)}-${companyRegistrationNumber.substring(5,10)}`
                        : companyRegistrationNumber 
                        }</span>
                </div>
            </div>
            <div className="join__brand-group-row">
                <div className="join__brand-group-body">
                    <span className="join__title-header">본사명</span>
                    <span className="join__title-data">{headQuaterName}</span>
                </div>
            </div>
            <div className="join__brand-group-row">
                <div className="join__brand-group-body">
                    <span className="join__title-header">사업자 등록증</span>
                    <input type="file" className="join__title-data upload-name" name={`file_${brandId}`} accept="image/png,image/jpeg" ref={fileInput} onChange={changeBusinessNumberFile} style={{display:"none"}}/> 
                    <div>{businessFile ? businessFile.substring(businessFile.lastIndexOf("\\") + 1) : "이미지 파일을 선택하세요."}</div>

                </div>
                <div className="join__title-tailing filebox">
                    <button onClick={onClickSelectFile}>선택</button>
                </div>
            </div>
        </div>
    );
}

export default React.memo(JoinSelectedBrand);