import classNames from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import '~/assets/scss/admin_branch_branch_management.scss';

function BranchManagementBranchView({ brand, item, regionList }) {
    
    const [branch, setBranch] = useState(null);

    useEffect(() => {
        
        setBranch({
            ...item,
            brandName:brand.name, 
            manageType: item.manageType === "" ? "" 
                        : item.manageType === "FRANCHISE" ? "가맹점" 
                        : item.manageType === "DIRECT" ? "직영점" 
                        : "",
            regionName : item.region ? regionList.filter(region => region.id === item.region.id)[0].name : '',
            isRecruit : item.isRecruit === "" ? "" :
                        item.isRecruit === "OPERATING" ? "운영중" :
                        item.isRecruit === "DUE_OPEN" ? "오픈예정" :
                        item.isRecruit === "CLOSE_DOWN" ? "폐점" :
                        item.isRecruit === "RECRUITING" ? "모집중" 
                        : "",
        });
    }, [brand, item, regionList]);

    if ( branch == null ) return null;

    return (
        <div className="branch-management-right-group">

            {/* 뷰 모드 */}
            {
                <div className={classNames("main-group", { show: branch })}>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.brandName}</div>
                        <span className={classNames("title", { disabled: branch })} >브랜드 이름</span>
                    </div>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.year}</div>
                        <span className={classNames("title", { disabled: branch })} >기준 연도</span>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.manageType}</div>
                            <span className={classNames("title", { disabled: branch })}>가맹구분</span>
                        </div>
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.regionName}</div>
                            <span className={classNames("title", { disabled: branch })}>지역</span>
                        </div>
                    </div>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.name}</div>
                        <span className={classNames("title", { disabled: branch })}>지점 이름</span>
                    </div>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.address}</div>
                        <span className={classNames("title", { disabled: branch })}>주소</span>
                    </div>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.addressDetail}</div>
                        <span className={classNames("title", { disabled: branch })}>상세 주소</span>
                    </div>
                    <div className="field full-width">
                        <div className={classNames("input-box", { disabled: branch })} >{branch.fullAddress}</div>
                        <span className={classNames("title", { disabled: branch })}>전체 주소</span>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.latitude}</div>
                            <span className={classNames("title", {disabled: branch})}>위도</span>
                        </div>
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.longitude}</div>
                            <span className={classNames("title", { disabled: branch })}>경도</span>
                        </div>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.isRecruit}</div>
                            <span className={classNames("title", { disabled: branch })}>운영상태</span>
                        </div>
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.openPeriod}</div>
                            <span className={classNames("title", {disabled: branch})}>개업일</span>
                        </div>
                    </div>
                    { item.isRecruit === "RECRUITING" &&
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.displayAddress}</div>
                            <span className={classNames("title", { disabled: branch })}>모집중 주소</span>
                        </div>
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.eventDetail}</div>
                            <span className={classNames("title", { disabled: branch })}>모집중 이벤트명</span>
                        </div>
                    </div>
                    }
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.area}</div>
                            <span className={classNames("title", { disabled: branch })}>면적 (평)</span>
                        </div>
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.monthlyEstimatedSales}</div>
                            <span className={classNames("title", {disabled: branch})}>추정 월평균 매출액</span>
                        </div>
                    </div>
                    <div className="row-group">
                        <div className="field">
                            <div className={classNames("input-box", { disabled: branch })} >{branch.phoneNumber}</div>
                            <span className={classNames("title", { disabled: branch })}>전화번호</span>
                        </div>
                    </div>

                </div>
            }



        </div>
    );
}

export default React.memo(BranchManagementBranchView);
