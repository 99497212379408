import client from "./client";

//브랜드 상세 정보 조회
export const getBrandDetail = (brandId) => client.get(`/api/brands/${brandId}`);

export const getBrandsByKeyword = (keyword) =>
  client.get(`/api/search/${keyword}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

export const getBranchSales = (brandId, branchId) =>
  client.post(
    `/api/branch/getYearlyEstimatedSales`,
    {
      brandId: brandId,
      branchId: branchId,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
