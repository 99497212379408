import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_branch.scss';
import { Box, Button, CircularProgress, Modal, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from "prop-types";
import AdminHeader from './AdminHeader';
import { useHistory } from 'react-router';
import { addBranchData, deleteBranchAll, deleteBranchData, downloadBranchData, getBranchList, getRegionList, modifyBranchData, uploadBranchExcelFile } from '~/sagas/api/admin/branch_management';
import { useSnackbar } from 'notistack';
import BranchManagementBranchView from './BranchManagementBranchView';
import BranchManagementBranchModify from './BranchManagementBranchModify';
import FileSaver from 'file-saver';
import { API_HOST } from '~/constants';
import { unstable_batchedUpdates } from 'react-dom';
import {getTodayYYYYMMDD} from '../../common/DateUtil';
import { startLoading, finishLoading } from '~/sagas/actions/loadingAction';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function BranchManagement() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const { app } = useSelector(({ common }) => ({
        app: common.app,
    }));
    const { corpUserInfo } = useSelector(({ common }) => ({
        corpUserInfo: common.auth.corpUserInfo,
    }));
    const dispatch = useDispatch();

    //const [brand, setBrand] = useState(null);//헤더에 선택된 브랜드 

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [addBranch, setAddBranch] = useState(false);
    const [modifyBranch, setModifyBranch] = useState(false);

    const [branchList, setBranchList] = useState(null);//좌측 브렌치 목록
    const [selectedBranch, setSelectedBranch] = useState(null); //선택된 브랜치
    const [deletePopup, setDeletePopup] = useState(false); //삭제 팝업
    const [deleteAllPopup, setDeleteAllPopup] = useState(false); //지점 전체 삭제 팝업
    const [deletePopupComplete, setDeletePopupComplete] = useState(false); //삭제 완료 팝업
    const [excelUploading, setExcelUploading] = useState(false); //엑셀 수행중 체크.

    const [inputFields, setInputFields] = useState({
        branchId: '',
        branchName: '',
        area: '', //평수
        region: '',//지역
        type: '',
        address: '',
        addressDetail: '',
        fullAddress: '',
        operationStatus: '',
        year: '',
        openPeriod: '',
        phoneNumber: '',
        monthlyEstimatedSales: '',
        displayAddress: '',
        eventDetail: '',
    }); //input field
    
    const [regionList, setRegionList] = useState(null);//지역 목록

    const excelFileElement = useRef(null);
    const excelUploadInput = useRef(null);


    
    const closeConfirm = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
            닫기
        </Button>
    ), [closeSnackbar]);

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    //지점 추가하기 팝업 열기
    const openAddBranch = () => {
        setInputFields({
            branchId: '',
            branchName: '',
            area: '',//평수
            region: '',//지역
            type: '',
            address: '',
            addressDetail: '',
            fullAddress: '',
            operationStatus: '',
            year: '',
            openPeriod: '',
            phoneNumber: '',
            monthlyEstimatedSales: '',
            latitude: '',
            longitude: '',
            displayAddress: '',
            eventDetail: '',
            brandId: corpUserInfo.selectedBrand.brandId
        });
        setAddBranch(true);
    };

    //지점 추가하기 팝업 닫기
    const closeAddBranch = () => {
        setAddBranch(false);
    };

    //지점 수정하기 팝업 열기
    const openModifyBranch = () => {
        setModifyBranch(true);
    };
    //지점 수정하기 팝업 닫기
    const closeModifyBranch = () => {
        setModifyBranch(false);
    };

    //필드 입력시 상태 변경
    const onChangeInputFields = (e) => {
        const { name, value } = e.target;

        setInputFields({
            ...inputFields,
            [name]: value
        });
    };

    const setXY = (y, x) => {
        setInputFields({
            ...inputFields,
            "latitude": y,
            "longitude": x
        });
    };


    //지점 목록 조회
    const callGetBranchList = async ()  => {
        //브랜치 목록
        await getBranchList(corpUserInfo.selectedBrand.brandId, localStorage.getItem("jwtTokenAdmin"))
            .then(response => {

                if (response.status === 200) {
                    const data = response.data;
                    setBranchList(data); //목록

                    if ( data && data.length > 0 ) {
                        setSelectedBranch(data[0]); //첫번째 지점 선택
                    }

                }
            });
    }

    //지역 목록 조회
    const callGetRegionList = async () => {
        await getRegionList().then(response => {

            if (response.status === 200) {
                const data = response.data;
                setRegionList(data); //목록
            }
        });
    };

    //왼쪽 지점 메뉴 선택
    const onClickBranch = (id) => {
        const list = branchList.filter(item => item.id === id);

        const item = list[0];

        console.log(item);

        setSelectedBranch({
            ...item,
            year: item.year ? item.year : '',
            region: item.region ? item.region : '',
            address : item.address ? item.address : '',
            addressDetail : item.addressDetail ? item.addressDetail : '',
            fullAddress : item.fullAddress ? item.fullAddress : '',
            latitude : item.latitude ? item.latitude : '',
            longitude : item.longitude ? item.longitude : '',
            monthlyEstimatedSales : item.monthlyEstimatedSales ? item.monthlyEstimatedSales : '',
            area : item.area ? item.area : '',
            phoneNumber : item.phoneNumber ? item.phoneNumber : '',
            openPeriod : item.openPeriod ? item.openPeriod : '',
            displayAddress: item.displayAddress ? item.displayAddress : '',
            eventDetail: item.eventDetail ? item.eventDetail : '',
        });
    };

    //지점 삭제 메세지 팝업 열기
    const openDeletePopup = () => {
        setDeletePopup(true);
    };

    //지점 삭제 메세지 팝업 닫기
    const closeDeletePopup = () => {
        setDeletePopup(false);
    };

    //지점 전체 삭제 메세지 팝업 열기
    const openDeleteAllPopup = () => {
        setDeleteAllPopup(true);
    };

    //지점 전체 삭제 메세지 팝업 닫기
    const closeDeleteAllPopup = () => {
        setDeleteAllPopup(false);
    };

    //지점 엑셀 다운로드 
    const branchDataExcelDownload = () => {
        if ( branchList && branchList.length > 0 ) {
            FileSaver.saveAs(`${API_HOST}admin/downloadBranchData/${corpUserInfo.selectedBrand.brandId}`, `${corpUserInfo.selectedBrand.name}_${getTodayYYYYMMDD()}.xlsx`);
        } else {
            enqueueSnackbar("다운로드 할 데이타가 없습니다.", {
                className: "snackbar warning",
                autoHideDuration: 3000,
                action: closeConfirm
            });
        }
        
    };

    //수정 화면 띄우기
    const onClickModify = () => {
        setInputFields({
            brandId: corpUserInfo.selectedBrand.brandId,
            branchId: selectedBranch.id,
            branchName: selectedBranch.name,
            year: selectedBranch.year,
            area: selectedBranch.area,//평수
            region: selectedBranch.region ? selectedBranch.region.id : '',//지역
            type: selectedBranch.manageType,
            address: selectedBranch.address,
            addressDetail: selectedBranch.addressDetail,
            fullAddress: selectedBranch.fullAddress,
            operationStatus: selectedBranch.isRecruit,
            openPeriod: selectedBranch.openPeriod,
            phoneNumber: selectedBranch.phoneNumber,
            monthlyEstimatedSales: selectedBranch.monthlyEstimatedSales,
            latitude: selectedBranch.latitude, //위도   -90~90
            longitude: selectedBranch.longitude, //경도 -180~180
            displayAddress: selectedBranch.displayAddress,
            eventDetail: selectedBranch.eventDetail,
        });

        openModifyBranch();
    };

    //개별 저장 // 수정
    const onClickSaveOneBranch = async (buttonType) => {
        if ( inputFields.operationStatus === "RECRUITING" ) {
              if ( !inputFields.displayAddress || inputFields.displayAddress == '' || inputFields.displayAddress.trim().length === 0 ) {
                enqueueSnackbar("모집중 주소를 입력해야 합니다.", {
                    className: "snackbar warning",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });
                return;
              }

              if ( !inputFields.eventDetail || inputFields.eventDetail == '' || inputFields.eventDetail.trim().length === 0 ) {
                enqueueSnackbar("모집중 이벤트명을 입력해야 합니다.", {
                    className: "snackbar warning",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });
                return;
              }

        }
            

        dispatch(startLoading());
        
        try {
            let res = null;
            
            const reqData = {
                ...inputFields,
                region : inputFields.region ? parseInt(inputFields.region, 10) : '',
                openPeriod : inputFields.openPeriod ? parseInt(inputFields.openPeriod, 10) : '',
                monthlyEstimatedSales : inputFields.monthlyEstimatedSales ? parseInt(inputFields.monthlyEstimatedSales, 10) : '',
                area : inputFields.area ? parseInt(inputFields.area, 10) : '',
                year : inputFields.year ? parseInt(inputFields.year, 10) : '',
            };

            if (buttonType === 'ADD') {
                res = await addBranchData(reqData, localStorage.getItem("jwtTokenAdmin"));

            } else {
                res = await modifyBranchData(reqData, localStorage.getItem("jwtTokenAdmin"));
            }

            if (res.status === 200) {
                const data = res.data;

                if (buttonType === 'ADD') {
                    if (data && data.id === -1) {
                        enqueueSnackbar("해당 사업자번호를 가진 지점이 이미 존재합니다.", {
                            className: "snackbar warning",
                            autoHideDuration: 3000,
                            action: closeConfirm
                        });
                        return;
                    }
                    setSelectedBranch(data);
                    setBranchList(branchList ? branchList.concat(data) : [data]);
                    closeAddBranch();
                } else {
                    setSelectedBranch(data);
                    setBranchList(branchList.map(item => {
                        if (item.id === data.id) {
                            return data;
                        } else {
                            return item;
                        }
                    }));
                    closeModifyBranch();
                }
                enqueueSnackbar("저장 되었습니다.", {
                    className: "snackbar success",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });

            }
        } catch (e) {

            let msg = "통신에 문제가 발생되었습니다. 관리자에게 문의하세요.";

            if ( e.response && e.response.data) {
                msg = e.response.data.message;
            }
            
            enqueueSnackbar(msg, {
                className: "snackbar warning",
                autoHideDuration: 3000,
                action: closeConfirm
            });

        }

        dispatch(finishLoading());

    };

    //지점 삭제 하기
    const openDeletePopupComplete = () => {

        if (selectedBranch) {
            deleteBranchData(selectedBranch.id, localStorage.getItem("jwtTokenAdmin")).then(res => {

                if (res.status === 200) {

                    setBranchList(branchList ? branchList.filter(item => item.id !== selectedBranch.id) : null);
                    setSelectedBranch(branchList ? branchList.filter(item => item.id !== selectedBranch.id)[0] : null);

                    setDeletePopup(false);
                    setDeletePopupComplete(true);
                }
            });
        }

    };

     //지점 전체 삭제 하기
     const openDeleteAllPopupComplete = async () => {
        dispatch(startLoading());
        
        await deleteBranchAll(corpUserInfo.selectedBrand.brandId, localStorage.getItem("jwtTokenAdmin")).then((res) => {
            
            setDeleteAllPopup(false);

            if ( res.status === 200) {
                setSelectedBranch(null);
                setBranchList(null);
                enqueueSnackbar("삭제 완료되었습니다.", {
                    className: "snackbar success",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });
            }
            
        });

        dispatch((finishLoading()));

    };

    //섹션삭제 완료 메세지 팝업 닫기
    const closeDeletePopupComplete = () => {
        setDeletePopup(false);
        setDeletePopupComplete(false);
    };

    const downloadExcelFile = () => {
        console.log(`${API_HOST}admin/static/files/branch_template.xlsx`, "branch_template.xlsx");
        FileSaver.saveAs(`${API_HOST}admin/static/files/branch_template.xlsx`, "branch_template.xlsx");
        //FileSaver.saveAs(`${API_HOST}admin/static/files/파리바게트.xlsx`, "파리바게트.xlsx");
        // downloadExcelFileAPI("파리바게트.xlsx", localStorage.getItem("jwtTokenAdmin")).then(res =>{

        // });
    };

    const selectExcelFile = () => {

        if ( (excelUploadInput.current.value === undefined || excelUploadInput.current.value === "") ) {
            enqueueSnackbar(`파일을 먼저 선택하세요.`, {
                className: "snackbar warning",
                autoHideDuration: 10000,
                action: closeConfirm
            });
            excelUploadInput.current.focus();
            return ;
        }

        unstable_batchedUpdates(async () => {
            setExcelUploading(true); //엑셀 업로드 버튼 로딩 시작
            // console.log(e.target);
            // console.log(excelFileElement.current);
            const data = new FormData(excelFileElement.current);
            // console.log(data);
            // console.log(corpUserInfo.selectedBrand);

            try {
    
                await uploadBranchExcelFile(corpUserInfo.selectedBrand.brandId, data, localStorage.getItem("jwtTokenAdmin")).then(res => {
                    // console.log(res);
    
                    if (res.status === 200) {
                        callGetBranchList();
    
                        enqueueSnackbar(`저장 완료되었습니다.`, {
                            className: "snackbar success",
                            autoHideDuration: 3000,
                            action: closeConfirm
                        });
                        closeAddBranch();
                    } else if (res.status === 206) {
                        enqueueSnackbar(`입력 권한이 없는 사업자번호(${res.data})가 포함되어 있습니다.`, {
                            className: "snackbar warning",
                            autoHideDuration: 10000,
                            action: closeConfirm
                        });
                    } else if (res.status === 204) {
                        //선택한 파일 없음. 정상.
                    }
                });
    
            } catch (e) {
                console.log(e);
                enqueueSnackbar("통신에 문제가 발생되었습니다. 관리자에게 문의하세요.", {
                    className: "snackbar warning",
                    autoHideDuration: 3000,
                    action: closeConfirm
                });
            }
    
            setExcelUploading(false); //엑셀 업로드 버튼 로딩 종료
        });
        

    };




    useEffect( async () =>  {
        dispatch(startLoading());
        //const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

        if (corpUserInfo && corpUserInfo.selectedBrand) {
            // setBrand(brandList[0]);
            await callGetBranchList(); //지점 목록 조회
            await callGetRegionList(); //지역 목록 조회
        }
        
        dispatch(finishLoading());

    }, [dispatch, corpUserInfo.id, history]);

    return (
        <div id="wrapper" className={cn(app.class, { dimmed: app.dimmed.isActive })}>
            <AdminHeader />
            <div className="main-container">
                <div className="admin-branch__container">
                    <div className="info-tab__container">
                        <div className="left-group">
                            <div className="inner-main">
                                {
                                    branchList &&
                                    branchList.map(item => <span key={item.id} className={cn("custom-section-btn", {selected : item.id === selectedBranch && selectedBranch.id, warning : item.latitude === null || item.longitude === null || item.latitude === 0 || item.longitude === 0})} onClick={() => onClickBranch(item.id)} >{item.name}</span>)
                                }
                            </div>
                            <button className="inner-bottom mb10" onClick={openAddBranch}>+ 추가하기</button>
                            <button className="inner-bottom mb10" onClick={openDeleteAllPopup}>지점 전체삭제</button>
                            <button className="inner-bottom" onClick={branchDataExcelDownload}>엑셀 다운로드</button>
                            <div>전체 지점 수 : {branchList && branchList.length} 개</div>
                            <div>이상 지점 수 : {branchList && branchList.filter(item=> item.latitude === null || item.longitude === null || item.latitude === 0 || item.longitude === 0).length} 개</div>
                        </div>

                        {
                            selectedBranch &&
                            <div className="right-group">
                                <div className="header-group">
                                    <div className="button-group">
                                        <button className="action-btn" onClick={onClickModify}>수정하기</button>
                                        <button className="action-btn" onClick={openDeletePopup}>삭제하기</button>
                                    </div>
                                </div>
                                {
                                    corpUserInfo.selectedBrand && selectedBranch && regionList && 
                                    <BranchManagementBranchView brand={corpUserInfo.selectedBrand} item={selectedBranch} regionList={regionList}/>
                                }
                            </div>
                        }


                    </div>
                </div>
            </div>
            <Modal open={modifyBranch}
                className="branch-management-popup"
            >
                <div className="admin-branch-add-branch__container">
                    <div className="admin-branch-add-branch__title-group">
                        <div className="admin-branch-add-branch__title">가맹점 저장</div>
                        <button className="admin-branch-add-branch__close-btn" onClick={closeModifyBranch}>close</button>
                    </div>

                    <div className="admin-branch-add-branch__content-group">
                        <BranchManagementBranchModify brand={corpUserInfo.selectedBrand} regionList={regionList} inputs={inputFields} onChangeInputFields={onChangeInputFields} setXY={setXY}/>

                        <div className="admin-branch-add-branch__content-btn-group">
                            <div className="admin-branch-add-branch__content-btn" onClick={closeModifyBranch}>취소</div>
                            <div className="admin-branch-add-branch__content-btn" onClick={() => onClickSaveOneBranch('MODIFY')}>저장하기</div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={addBranch}
                className="branch-management-popup"
            >
                <div className="admin-branch-add-branch__container">
                    <div className="admin-branch-add-branch__title-group">
                        <div className="admin-branch-add-branch__title">가맹점 저장</div>
                        <button className="admin-branch-add-branch__close-btn" onClick={closeAddBranch}>close</button>
                    </div>

                    <div className="admin-branch-add-branch__content-group">
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" aria-label="tabs">
                            <Tab label="개별 입력" {...a11yProps(0)} />
                            <Tab label="엑셀로 일괄 입력" {...a11yProps(1)} />
                        </Tabs>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                {/* 가맹등록 */}
                                <BranchManagementBranchModify brand={corpUserInfo.selectedBrand} regionList={regionList} inputs={inputFields} onChangeInputFields={onChangeInputFields} />

                                <div className="admin-branch-add-branch__content-btn-group">
                                    <div className="admin-branch-add-branch__content-btn" onClick={closeAddBranch}>취소</div>
                                    <div className="admin-branch-add-branch__content-btn" onClick={() => onClickSaveOneBranch('ADD')}>저장하기</div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                {/* 엑셀로 일괄 등록 */}
                                <form id="excelFileUpload" method="POST" encType="multipart/form-data" ref={excelFileElement}>
                                    <div className="header-group">
                                        <div className="button-group">

                                            <span className="action-btn" onClick={downloadExcelFile}>양식 다운로드</span>

                                            
                                            <div className="upload-group">
                                                <input ref={excelUploadInput} type="file" name="excelUpload" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /* style={{ display: "none" }} */  />
                                                <span className="action-btn flex" onClick={selectExcelFile}>
                                                    엑셀양식 업로드
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="excel-upload-body-group">
                                        엑셀 파일로 한번에 등록하세요.
                                    </div>

                                </form>

                                {/* <div className="admin-branch-add-branch__content-btn-group">
                                    <div className="admin-branch-add-branch__content-btn" onClick={closeAddBranch}>취소</div>
                                    <div className="admin-branch-add-branch__content-btn" onClick={closeAddBranch}>저장하기</div>
                                </div> */}
                            </TabPanel>
                        </SwipeableViews>
                    </div>

                </div>
            </Modal>

            <Modal
                open={deletePopup}
            >
                <div className="password-change__container">
                    <div className="password-change__title-group">
                        <div className="password-change__title">지점 삭제하기</div>
                        <button className="password-change__close-btn" onClick={closeDeletePopup}>close</button>
                    </div>

                    <div className="password-change__content-group">

                        <div className="password-change__content-content">
                            삭제하시겠습니까?
                        </div>
                        <div className="password-change__content-btn-group">
                            <div className="password-change__content-btn grey" onClick={closeDeletePopup}>취소</div>
                            <div className="password-change__content-btn" onClick={openDeletePopupComplete}>삭제하기</div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={deleteAllPopup}
            >
                <div className="password-change__container">
                    <div className="password-change__title-group">
                        <div className="password-change__title">지점 전체 삭제하기</div>
                        <button className="password-change__close-btn" onClick={closeDeleteAllPopup}>close</button>
                    </div>

                    <div className="password-change__content-group">

                        <div className="password-change__content-content">
                            삭제하시겠습니까?
                        </div>
                        <div className="password-change__content-btn-group">
                            <div className="password-change__content-btn grey" onClick={closeDeleteAllPopup}>취소</div>
                            <div className="password-change__content-btn" onClick={openDeleteAllPopupComplete}>삭제하기</div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={deletePopupComplete}
            >
                <div className="password-change__container">
                    <div className="password-change__title-group">
                        <div className="password-change__title">삭제 완료</div>
                        <button className="password-change__close-btn" onClick={closeDeletePopupComplete}>close</button>
                    </div>

                    <div className="password-change__content-group">

                        <div className="password-change__content-content">
                            정상적으로 삭제 처리되었습니다
                        </div>
                        <div className="password-change__content-btn" onClick={closeDeletePopupComplete}>확인</div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={excelUploading}
                className="admin-branch__modal"
            >
                <div>
                    <div className="circular-progress-box">
                        <CircularProgress className="circular-progress" size={20} />
                        <div className="upload-text">업로드 중 입니다.</div>
                    </div>
                </div>
            </Modal>

        </div>
    );
}

export default React.memo(BranchManagement);