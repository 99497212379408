import React from "react";
import styled from "styled-components";
import HighLightSvg from "../../assets/images/onboarding/onboarding_map_hightlight.svg";
import BubbleSvg from "../../assets/images/onboarding/onboarding_prediction_bubble.svg";

const OnBoardingMap = React.forwardRef(({ onClickNext }, ref) => {
  return (
    <Container ref={ref}>
      <TopContainer></TopContainer>
      <MiddleContainer>
        <MiddleBlock />
        <HighLight>
          <img
            src={HighLightSvg}
            alt=""
            onClick={onClickNext}
            style={{ cursor: "pointer" }}
          />
          <BubbleContainer style={{ backgroundImage: `url(${BubbleSvg})` }}>
            <BubbleTitle>
              이미 똑똑한 사장{" "}
              <span style={{ fontWeight: 400 }}>from 똑사</span>
            </BubbleTitle>
            <BubbleContents>
              <span style={{ fontWeight: 600, color: "#ed703e" }}>
                지도 위를 클릭
              </span>
              해보세요
              <br />
              <span style={{ color: "#ed703e" }}>
                이 지역 예상 매출 💸 이 금방 나타날거에요!
              </span>
            </BubbleContents>
          </BubbleContainer>
        </HighLight>
        <MiddleBlock />
      </MiddleContainer>
      <BottomContainer />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  min-height: 37vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MiddleBlock = styled.div`
  flex: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BottomContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HighLight = styled.div`
  position: relative;
  width: 320px;
  height: 320px;
`;

const BubbleContainer = styled.div`
  position: absolute;
  right: 330px;
  bottom: 120px;

  width: 416px;

  height: 125px;
  filter: drop-shadow(0px 6px 10px rgba(101, 101, 101, 0.25));

  padding: 24px 32px;
`;

const BubbleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #686868;
`;

const BubbleContents = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 8px;
`;

export default OnBoardingMap;
