import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

export default function CustomToast({ children, showZoomToast, onClose }) {
  const [isVisible, setIsVisible] = useState(showZoomToast);
  const [toastHide, setToastHide] = useState(false);

  useEffect(() => {
    if (showZoomToast) {
      setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 3000);
      setIsVisible(true);
      setToastHide(false);
    }
  }, [showZoomToast]);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setToastHide(true);
      }, 1000);
    }
  }, [isVisible]);

  return (
    <Container
      isVisible={isVisible}
      style={{ display: toastHide ? "none" : "block" }}
    >
      {children}
    </Container>
  );
}

const float = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
`;

const fall = keyframes`
  0% {
    transform: translateY(0);
    opacity: 100%;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
`;

const animation = (props) => css`
  ${props.isVisible ? float : fall}
`;

const Container = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%, -50%);
  padding: 16px;

  background: #ed703e;
  border-radius: 4px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
  animation: ${animation} 1s ease-out;
`;
