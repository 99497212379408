import { Modal } from "@material-ui/core";
import styled from "styled-components";
import { useEffect, useState } from "react";
import CloseSvg from "../../assets/images/icon/icon_onboarding_close.svg";
import priceData from "./price_data.json";
import { numberWithCommas } from "~/common/NumberUtil";
import { getBrandDetail } from "~/sagas/api/brand";
import CheckedSvg from "../../assets/images/icon/priceplan_check_checked.svg";
import UnCheckedSvg from "../../assets/images/icon/priceplan_check_unchecked.svg";
import AlertSvg from "../../assets/images/icon/priceplan_alert.svg";

const tabs = ["SIMPLE", "BASIC", "PREMIUM"];

export default function PricePlanModal({
  open,
  onClose,
  brand,
  consultingLocation,
  onClickRequest,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPriceData, setSelectedPriceData] = useState(priceData.simple);
  const [brandDetail, setBrandDetail] = useState(null);

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setSelectedPriceData(priceData.simple);
        break;
      case 1:
        setSelectedPriceData(priceData.basic);
        break;
      case 2:
        setSelectedPriceData(priceData.premium);
        break;
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!brand) return;
    getBrandDetail(brand.id)
      .then((res) => {
        if (res.status == 200 && res.data) {
          setBrandDetail(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [brand]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <Container>
        <CloseButton onClick={onClose}>
          <img src={CloseSvg} alt="close" />
        </CloseButton>
        <TabList>
          {tabs.map((tab, index) => (
            <TabItem
              selected={selectedTab == index}
              key={index}
              onClick={() => setSelectedTab(index)}
            >
              {tab}
            </TabItem>
          ))}
        </TabList>
        <ContentsWrapper>
          {selectedPriceData.salePercent > 0 && (
            <SaleContainer>
              <span>{numberWithCommas(selectedPriceData.originPrice)}</span>
              <span>{selectedPriceData.salePercent * 100}%</span>
            </SaleContainer>
          )}
          <PriceContainer>
            {numberWithCommas(selectedPriceData.salePrice)}원
          </PriceContainer>
          <PredictionTargetContainer>
            {brandDetail && brandDetail.category
              ? `${brandDetail.category.name} · `
              : ""}
            {brandDetail && brandDetail.name}
            {selectedTab != 0 &&
              consultingLocation &&
              " · " + consultingLocation.split(" ")[2]}
          </PredictionTargetContainer>
          <Description>{selectedPriceData.description}</Description>
          <Divider />
          {selectedPriceData.checkList.map((ele, index) => (
            <CheckListItem
              key={ele.name}
              style={{ marginTop: index == 0 ? 0 : "8px" }}
            >
              <CheckItemName>{ele.name}</CheckItemName>
              {ele.isChecked ? (
                <img src={CheckedSvg} />
              ) : (
                <img src={UnCheckedSvg} />
              )}
            </CheckListItem>
          ))}
          <CheckListItem style={{ marginTop: "8px" }}>
            <CheckItemName>경쟁 브랜드 비교</CheckItemName>
            {selectedPriceData.compare.isChecked ? (
              <CheckItemTextValue style={{ fontWeight: 500, color: "#ED703E" }}>
                3개
              </CheckItemTextValue>
            ) : (
              <img src={UnCheckedSvg} />
            )}
          </CheckListItem>
          <CheckListItem style={{ marginLeft: "9px" }}>
            <CheckItemName>ㄴ AI 추천 브랜드 비교</CheckItemName>
            {selectedPriceData.compare.isChecked ? (
              <CheckItemTextValue
                style={{
                  fontWeight: 500,
                  color: "#ED703E",
                  fontSize: "10px",
                  marginRight: "5px",
                }}
              >
                o
              </CheckItemTextValue>
            ) : (
              <CheckItemTextValue
                style={{
                  fontWeight: 500,
                  color: "#D8D8D8",
                  fontSize: "10px",
                  marginRight: "5px",
                }}
              >
                -
              </CheckItemTextValue>
            )}
          </CheckListItem>
          <CheckListItem style={{ marginTop: "8px" }}>
            <CheckItemName>작업일</CheckItemName>
            <CheckItemTextValue>
              {selectedPriceData.workingDay}일
            </CheckItemTextValue>
          </CheckListItem>
          <CheckListItem style={{ marginTop: "8px" }}>
            <CheckItemName>분량</CheckItemName>
            <CheckItemTextValue>
              {selectedPriceData.quantity}장
            </CheckItemTextValue>
          </CheckListItem>
        </ContentsWrapper>
        <RequestButton onClick={onClickRequest}>레포트 신청하기</RequestButton>
        <AlertContainer>
          <img src={AlertSvg} />
          <div style={{ maxWidth: "337px" }}>
            신뢰도 높은 예측을 위한 자체 보유 데이터가 충분하지 않으면 전문가
            검토 후 신청이 반려될 수 있어요
          </div>
        </AlertContainer>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(101, 101, 101, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
  width: fit-content;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseButton = styled.button`
  position: absolute;
  top: -35px;
  right: 0px;
`;

const TabList = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;
  padding: 0 16px;
  border: 1px solid rgba(101, 101, 101, 0.25);
  border-radius: 12px;
`;

const TabItem = styled.button`
  position: relative;
  padding: 13px 0;

  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;

  width: 103.33px;
  text-align: center;
  color: ${(props) => (props.selected ? "#ED703E;" : "rgba(68, 67, 67, 0.55)")};

  ::after {
    content: "";
    display: ${(props) => (props.selected ? "block" : "none")};
    width: 97px;
    height: 2.5px;
    background-color: #ed703e;

    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ContentsWrapper = styled.div`
  padding: 14px 22px;
  margin-top: 10px;
  border: 1px solid rgba(101, 101, 101, 0.25);
  border-radius: 12px;
`;

const SaleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.25px;

  margin-bottom: 7px;

  span:nth-child(1) {
    text-decoration-line: line-through;
    color: rgba(68, 67, 67, 0.55);
  }
  span:nth-child(2) {
    font-weight: 700;
    color: #ed3e3e;
  }
`;

const PriceContainer = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.25px;

  color: #323030;
`;

const PredictionTargetContainer = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  margin-top: 7px;

  letter-spacing: 0.25px;

  color: #ed703e;
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  letter-spacing: 0.25px;

  color: #323030;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(125, 121, 121, 0.5);

  margin: 8px 0 10px;
`;

const CheckListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
`;

const CheckItemName = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  letter-spacing: 0.25px;

  color: #323030;
`;

const CheckItemTextValue = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  letter-spacing: 0.25px;

  color: #000000;
`;

const RequestButton = styled.button`
  margin: 10px 0;
  background: #ed703e;
  border-radius: 4px;
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #ffffff;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  letter-spacing: 0.25px;

  color: #bebcbc;
`;
