import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Backdrop, Modal } from '@material-ui/core';
import '~/assets/scss/comparison_popup.scss';
import { Bar } from 'react-chartjs-2';
import { formattedNum } from '~/common/NumberUtil';
import { Link } from 'react-router-dom';
import { login, popup } from '~/sagas/actions/commonAction';
import { getBrandsWithComparison, addBrandsWithComparison, addBrandsWithInterests, removeBrandsWithComparison, removeBrandsWithInterests, getBrandsWithInterests } from '~/sagas/api/favorite';
import PopupStartUpConsultationApplication from '../brand/PopupStartUpConsultationApplication';
import { selectUser } from "~/sagas/api/user";
import {amountUnit} from '~/App';

const initChartData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green'],
  datasets: [
    {
      backgroundColor:['#00B06E', '#FBA942', '#36C0FF', '#C46800', '#FF662A'],
      barThickness: 12,
      data: [12, 19, 3, 5],
    },
  ],
};


const initChartOptionsLength = {
  maintainAspectRatio: true,
  scales: {
    x: {
      grid:{
        display:false
      },
      // title: {
      //   text: '축 이름'
      // },
      ticks: {
        major: {
          enabled: true
        },
        color: (context) => '#333333',
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 400
            };
          }
        },
      }
    },
    y: {
      ticks: {
        align:'right',
        major: {
          enabled: true
        },
        color: (context) => {
          return "#333333";
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 100
            };
          }
        },
        callback: function(tickValue, index, ticks) {
          // console.log(index);
          // console.log(tickValue);
          // console.log(ticks);
          return tickValue === 0 ?  '0개' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
        // index, dataset, point, nearest(defalut), x, y
        //mode: 'index',
        enabled: true,
        intersect: true,
        titleColor: '#333333',
        titleFont: {weight: 500},
        bodyColor: '#333333',
        bodyFont: {weight: 400},
        backgroundColor: '#ffffff',
        borderColor: '#d3dfe2',
        borderWidth: 1,
        usePointStyle: true,
        callbacks: {
          title: function(data) {
            //console.log(data);
            return data[0].label;
          },
          label: function(context) { 
            //console.log(context);
              var label = context.dataset.label || '';

              if (label) {
                  label += ' ';
              }
              if (context.parsed.y !== null) {
                  if ( context.datasetIndex === 3 ) {
                    label += context.parsed.y + '%';
                  } else {
                    const tickValue = context.parsed.y;
                    label += tickValue === 0 ?  0 + '개' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
                  }                  
              }
              
              return label;
          },
          labelColor: function(context) { //바디 젤 앞 이미지
            //console.log(context.datasetIndex);
            return {
                //borderColor: 'rgb(0, 0, 255)',
                backgroundColor: context.datasetIndex === 0 ? "rgb(108, 209, 130)" 
                                  : context.datasetIndex === 1 ? "rgb(220, 223, 226)"
                                  : context.datasetIndex === 2 ? "rgb(25, 58, 115)"
                                  : context.datasetIndex === 3 ? "#FF662A"
                                  : "#FF662A",
                borderWidth: 2,
                borderDash: [2, 2],
                borderRadius: 2,
            };
          },
          labelPointStyle: function(context) { //바디 젤 앞 이미지 모양
            return {
              pointStyle: 'circle',
              rotation: 0
            };
          }
        }
  
    }
  },
};

const initChartOptions = {
  maintainAspectRatio: true,
  scales: {
    x: {
      grid:{
        display:false
      },
      // title: {
      //   text: '축 이름'
      // },
      ticks: {
        major: {
          enabled: true
        },
        color: (context) => '#333333',
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 400
            };
          }
        },
      }
    },
    y: {
      ticks: {
        align:'right',
        major: {
          enabled: true
        },
        color: (context) => {
          return "#333333";
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 100
            };
          }
        },
        callback: function(tickValue, index, ticks) {
          // console.log(index);
          // console.log(tickValue);
          // console.log(ticks);
          return tickValue === 0 ?  '0원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
        // index, dataset, point, nearest(defalut), x, y
        //mode: 'index',
        enabled: true,
        intersect: true,
        titleColor: '#333333',
        titleFont: {weight: 500},
        bodyColor: '#333333',
        bodyFont: {weight: 400},
        backgroundColor: '#ffffff',
        borderColor: '#d3dfe2',
        borderWidth: 1,
        usePointStyle: true,
        callbacks: {
          title: function(data) {
            //console.log(data);
            return data[0].label;
          },
          label: function(context) { 
            //console.log(context);
              var label = context.dataset.label || '';

              if (label) {
                  label += ' ';
              }
              if (context.parsed.y !== null) {
                  if ( context.datasetIndex === 3 ) {
                    label += context.parsed.y + '%';
                  } else {
                    const tickValue = context.parsed.y;
                    label += tickValue === 0 ?  0 + '원' : tickValue > 0 ? formattedNum(tickValue) : '-'+formattedNum(tickValue*-1);
                  }                  
              }
              
              return label;
          },
          labelColor: function(context) { //바디 젤 앞 이미지
            //console.log(context.datasetIndex);
            return {
                //borderColor: 'rgb(0, 0, 255)',
                backgroundColor: context.datasetIndex === 0 ? "rgb(108, 209, 130)" 
                                  : context.datasetIndex === 1 ? "rgb(220, 223, 226)"
                                  : context.datasetIndex === 2 ? "rgb(25, 58, 115)"
                                  : context.datasetIndex === 3 ? "#FF662A"
                                  : "#FF662A",
                borderWidth: 2,
                borderDash: [2, 2],
                borderRadius: 2,
            };
          },
          labelPointStyle: function(context) { //바디 젤 앞 이미지 모양
            return {
              pointStyle: 'circle',
              rotation: 0
            };
          }
        }
  
    }
  },
};

const BrandItem = React.memo(function BrandItem({item, idx, changeBrands}) {
  const {id, logoImageUrlThumb, category, name} = item;

  const [favoriteState, setFavoriteState] = useState(false);
  const [comparisonState, setComparisonState] = useState(false);

  const [open, setOpen] = useState([false,false,false,false,false]);

  const dispatch = useDispatch();

  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));
  

  const handleOpen = useCallback((index) => {
    setOpen(prev => {
      //console.log(prev.map( (i,idx) => idx === index ? true : i));
      return prev.map( (i,idx) => idx === index ? true : i);
    });

    // eslint-disable-next-line
  },[open]);

  
  const handleClose = useCallback((index) => {
    setOpen(prev => {
      // console.log(prev.map( (i,idx) => idx === index ? false : i));
      return prev.map( (i,idx) => idx === index ? false : i);
    });
    // eslint-disable-next-line
  }, [open]);


  //로그인 팝업 띄우기
  const openLoginMessagePopup = useCallback(() => {
    dispatch(
      popup({
        loginMessage: true,
      }),
    );
  }, [dispatch]);

  //비교하기 등록/삭제 기능
  const onClickComparison = useCallback(async (e) => {
    //로그인 여부 확인 후 
    if ( !userInfo || !userInfo.email ) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();
    
    } else {//로그인 되어 있으면
      //비교하기 등록/삭제 기능
      setComparisonState(prev => !prev);

      if ( comparisonState ) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithComparison(id, localStorage.getItem("jwtToken"));
        changeBrands(id);
        
      } else {
        await addBrandsWithComparison(id, localStorage.getItem("jwtToken"));
      }
    }

  }, [changeBrands, id, openLoginMessagePopup, userInfo, comparisonState]);



  //관심 브랜드 등록/삭제 기능
  const onClickFavorite = useCallback( async (e) => {
    //로그인 여부 확인 후 
    if ( !userInfo || !userInfo.email ) {
      //openLoginMessagePopup 호출 정하고
      e.preventDefault();
      openLoginMessagePopup();

    } else {//로그인 되어 있으면
      //관심 브랜드 등록/삭제 기능
      setFavoriteState(prev => !prev);

      if ( favoriteState ) { //이전 상태값이 true
        //false 가 되었고 db 에서 제거.
        await removeBrandsWithInterests(id, localStorage.getItem("jwtToken"));

      } else {
        await addBrandsWithInterests(id, localStorage.getItem("jwtToken"));

      }
    }
    
  }, [id, openLoginMessagePopup, userInfo, favoriteState]);


  useEffect(() => {
    // console.log(userInfo);

    setComparisonState(userInfo.comparisonTargetList && userInfo.comparisonTargetList.indexOf(id) !== -1 ? true : false);
    setFavoriteState(userInfo.brandOfInterestList && userInfo.brandOfInterestList.indexOf(id) !== -1 ? true : false);
    
      // eslint-disable-next-line
  }, [userInfo]);


  return (
        <div className="camparison-popup__list-item">
          <div className="camparison-popup__brand-img__container">
            <img className="camparison-popup__brand_img" src={logoImageUrlThumb ? logoImageUrlThumb : "/placeholder_logo.png"} alt="BRAND" />
          </div>
          <div className="camparison-popup__item-right">
            <div className="camparison-popup__info-and-event">
              <div className="camparison-popup__brand_info">
                  <span className="camparison-popup__brand_category">{category.name}</span>
                  <span className="camparison-popup__brand-name">{name}</span>
              </div>
              <div className="camparison-popup__right-group">
                <div className="util">
                  <label className="hart" onClick={onClickFavorite}>
                    <i className={cn("favorite", {on: favoriteState})}></i>
                  </label>
                  <span className="close-btn" onClick={onClickComparison}>X</span>
                </div>
                <div className="camparison-popup__brand-intro-ask">
                  <Link to={`/brand/${id}`} target="_blank">
                  <button className="camparison-popup__brand-intro">브랜드 소개</button>
                  </Link>
                  <button className="camparison-popup__brand-ask" onClick={() => handleOpen(idx)}>가맹문의</button>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={open[idx]}
          >
            <PopupStartUpConsultationApplication
              brand={item}
              buttonName="비교하기"
              close={() => handleClose(idx)}
            />
          </Modal>
        </div>
  )
});

function ComparisonBrand() {
  const [brands, setBrands] = useState([]);
  const [data1, setData1] = useState(initChartData);
  const [data2, setData2] = useState(initChartData);
  const [data3, setData3] = useState(initChartData);
  const [data4, setData4] = useState(initChartData);
  // eslint-disable-next-line no-unused-vars
  const [options1, setOptions1] = useState(initChartOptionsLength);
  // eslint-disable-next-line no-unused-vars
  const [options2, setOptions2] = useState(initChartOptions);
  // eslint-disable-next-line no-unused-vars
  const [options3, setOptions3] = useState(initChartOptions);
  // eslint-disable-next-line no-unused-vars
  const [options4, setOptions4] = useState(initChartOptions);

  const dispatch = useDispatch();
    
  const { comparison } = useSelector(({ common }) => ({
    comparison: common.popup.comparison,
  }), shallowEqual);

  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
}));
  
  
  const closePopup = useCallback(async () => {

    // let res = await selectUser(localStorage.getItem("jwtToken"));
    // // console.log(res.data); 
    // dispatch(login(res.data));

    dispatch(
      popup({
        comparison: false,
      }),
    );

    // getBrandsWithInterests(localStorage.getItem("jwtToken")).then( response =>{
    //   const responseData = response.data;
    //   dispatch(
    //     login({
    //       brands: responseData,
    //     }),
    //   );
    // });
    
        
  }, [dispatch]);


  const changeBrands = useCallback( (id) => {
    setBrands(prev => prev.filter( item => item.id !== id) );
  }, []);
  
  useEffect(()=> {

    const token = localStorage.getItem("jwtToken");

    if ( token && token != 'null' ) {

      getBrandsWithComparison(localStorage.getItem("jwtToken")).then( response =>{
        
        if ( response.status === 200 ) {

          const responseData = response.data;

          //console.log(responseData);

          setBrands(responseData);
          setData1({
            labels: responseData && responseData.map(i=> i.name),
            datasets: [
                {
                  label : '매장 수',
                  backgroundColor:['#00B06E', '#FBA942', '#36C0FF', '#C46800', '#FF662A'],
                  barThickness: 12,
                  fill: false,
                  data: responseData && responseData.map(i=> (i.directRegionOfBrandList[0] ? i.directRegionOfBrandList[0].total : 0) + (i.franchiseRegionOfBrandList[0] ? i.franchiseRegionOfBrandList[0].total : 0))
                }
              ]
          });
          setData2({
            labels: responseData && responseData.map(i=> i.name),
            datasets: [
                {
                  label : '월 평균 매출액',
                  backgroundColor:['#00B06E', '#FBA942', '#36C0FF', '#C46800', '#FF662A'],
                  barThickness: 12,
                  fill: false,
                  data: responseData && responseData.map(i=> i.salesOfBrandList ? i.salesOfBrandList.map(j => j.avgMonthlySales*amountUnit) : 0)
                }
              ]
          });
          setData3({
            labels: responseData && responseData.map(i=> i.name),
            datasets: [
                {
                  label : '3.3제곱미터당 월 평균 매출액',
                  backgroundColor:['#00B06E', '#FBA942', '#36C0FF', '#C46800', '#FF662A'],
                  barThickness: 12,
                  fill: false,
                  data: responseData && responseData.map(i=> i.salesOfBrandList ? i.salesOfBrandList.map(j => j.avgMonthlySalesArea*amountUnit) : 0)
                }
              ]
          });
          setData4({
            labels: responseData && responseData.map(i=> i.name),
            datasets: [
                {
                  label : '창업비용',
                  backgroundColor:['#00B06E', '#FBA942', '#36C0FF', '#C46800', '#FF662A'],
                  barThickness: 12,
                  fill: false,
                  data: responseData && responseData.map(i=> i.startUpCost ? i.startUpCost.total*amountUnit : 0)
                }
              ]
          });
        }
        
        
      });
    }

    return () => {
      setBrands(null);
    }
    // eslint-disable-next-line
  },[userInfo]);

  if ( brands == null ) return null;

  return (
      <>
        <Modal
          open={comparison}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <div className={cn("camparison-popup__container")} >
          <div className="camparison-popup__section">

            {/* 좌측 영역 */}
            <div className="camparison-popup__left">
              <div className="camparison-popup__left-title-group">
                <span className="camparison-popup__left-title">비교하기</span>
                <span className="camparison-popup__count">{brands.length}/4</span>
              </div>
              <div className="divider"></div>

              {
                brands.map( (i, idx)=> (
                  <BrandItem key={i.id} item={i} idx={idx} changeBrands={changeBrands}/>
                ))
              }

            </div>
              


            {/* 우측 영역 */}
            <div className="camparison-popup__right">
                <div className="camparison-popup_right-inner">
                  <span className="close-btn" onClick={closePopup}>X</span>
                </div>
                <div className="chart-group">
                  {
                    localStorage.getItem("jwtToken") && 
                    <>
                      <span>매장 수</span>
                      <Bar className="chart" height={100} data={data1} options={options1} />
                      <span>월 평균 매출액</span>
                      <Bar className="chart" height={100} data={data2} options={options2} />
                      <span>3.3m<sup>2</sup>(1평) 당 월 평균 매출액</span>
                      <Bar className="chart" height={100} data={data3} options={options3} />
                      <span>창업비용</span>
                      <Bar className="chart" height={100} data={data4} options={options4} />
                    </>
                  }
                  
                </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(ComparisonBrand);