import { createAction, handleActions } from "redux-actions";
import * as type from "../types/avgMonthlySalesType";

export const getAvgMonthlySales = createAction(type.GET_AVG_MONTHLY_SALES);
export const setAvgMonthlySales = createAction(type.SET_AVG_MONTHLY_SALES, ({from, to, isChecked}) => ({from, to, isChecked}));


const initialState = {
  from: 0,
  to: 500000000,
  isChecked: false,
};

const avgMonthlySalesAction = handleActions(
  {
    [type.SET_AVG_MONTHLY_SALES]: (state, { req, payload }) => ({
      ...state,
    }),
    [type.SET_AVG_MONTHLY_SALES]: (state, { req, payload }) => ({
      ...state,
      from: payload.from,
      to: payload.to,
      isChecked: payload.isChecked,
    }),
  },
  initialState
);

export default avgMonthlySalesAction;
