import cn from "classnames";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { firestore } from "~/App";
import Header from "~/components/layout/Header";
import { useHistory } from "react-router-dom";
import { numberWithCommas, uuidv4 } from "~/common/NumberUtil";
import CoinImagePng from "../../assets/images/icon/icon_coin.png";

export const clientKey = "live_ck_E92LAa5PVbp5Y971N6J87YmpXyJj"; // 테스트용 클라이언트 키

export default function PaymentScreen() {
  const history = useHistory();
  const searhParams = new URLSearchParams(window.location.search);

  const [currentCoin, setCurrentCoin] = useState();

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const { app } = useSelector(({ common }) => ({
    app: common.app,
  }));
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));

  useEffect(() => {
    getCurrentCoin();
  }, []);

  useEffect(() => {
    if (!currentCoin || !userInfo.id) return;

    const paymentWidget = window.PaymentWidget(clientKey, userInfo.id);

    const paymentMethodWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      {
        value: currentCoin.price,
        currency: "KRW",
        country: "KR",
      }
    );

    paymentWidget.renderAgreement("#agreement");
    paymentWidgetRef.current = paymentWidget;
    paymentMethodsWidgetRef.current = paymentMethodWidget;
  }, [currentCoin, userInfo]);

  const getCurrentCoin = () => {
    const coinId = searhParams.get("coinId");
    if (!coinId) {
      alert("잘못된 접근입니다.");
      history.goBack();
      return;
    }
    getDoc(doc(firestore, "coin", coinId))
      .then((doc) => {
        if (doc.exists()) {
          setCurrentCoin({ id: doc.id, ...doc.data() });
        } else {
          alert("잘못된 접근입니다.");
          history.goBack();
        }
      })
      .catch((error) => {
        alert("잘못된 접근입니다.");
        history.goBack();
        console.log(error);
      });
  };

  const onClickPaymentTest = async () => {
    if (!currentCoin) return;
    const paymentWidget = paymentWidgetRef.current;

    let paymentDocRef;
    try {
      const orderId = uuidv4();

      const currentDate = new Date();

      paymentDocRef = await addDoc(collection(firestore, "payment"), {
        orderId,
        userId: userInfo.id,
        coinId: currentCoin.id,
        amount: currentCoin.price,
        state: "ready",
        createdAt: currentDate,
        updatedAt: currentDate,
      });

      await paymentWidget?.requestPayment({
        orderId,
        orderName: `코인 ${numberWithCommas(currentCoin.count)}개`,
        customerName: userInfo.name,
        customerEmail: userInfo.email,
        successUrl: `${window.location.origin}/payment/success`,
        failUrl: `${window.location.origin}/payment/fail`,
      });
    } catch (error) {
      // 에러 처리하기
      if (error.code && error.code === "USER_CANCEL" && paymentDocRef) {
        setDoc(
          doc(firestore, "payment", paymentDocRef.id),
          {
            state: "cancel",
          },
          { merge: true }
        );
      } else {
        console.error(error);
        alert("일시적인 오류가 발생했습니다. 다시 시도해주세요.");
        return;
      }
    }
  };
  return (
    <div
      id="wrapper"
      className={cn(app.class, { dimmed: app.dimmed.isActive })}
    >
      <Header />
      <Container>
        <Title>상품</Title>
        <ProductContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CoinImage
              style={{
                backgroundImage: `url(${CoinImagePng})`,
              }}
            />
            <div>
              코인 {currentCoin && numberWithCommas(currentCoin.count)} 개
            </div>
          </div>
          <div>{currentCoin && numberWithCommas(currentCoin.price)}원</div>
        </ProductContainer>
        <Title style={{ marginTop: "24px", marginBottom: "8px" }}>
          취소/환불 정책
        </Title>
        <span style={{ fontWeight: 500 }}>코인 환불규정</span>
        <br />
        사용하지 않은 코인은 환불이 가능합니다. 결제수수료 및 코인발행수수료를
        제한 금액이 환불됩니다. 코인은 결제 후 1년간 사용이 가능합니다.
        <br />
        <span style={{ fontWeight: 500 }}>보고서 환불규정</span>
        <br />
        보고서는 주문제작 제품으로 주문과 결제 동시에 접수완료가 되어 환불이
        불가합니다. 주문 후 수정 및 취소가 불가하오니 꼼꼼히 확인하시고
        주문하시기 바랍니다.
        <div id="payment-widget" />
        <div id="agreement" />
        <PaymentButton onClick={onClickPaymentTest}>구매하기</PaymentButton>
      </Container>
    </div>
  );
}

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 24px;
`;

const Title = styled.h1`
  font-size: large;
  font-weight: bold;
`;

const ProductContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CoinImage = styled.i`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  text-indent: -9999px;
  margin-right: 10px;
`;

const PaymentButton = styled.button`
  padding: 30px;
  background-color: #ff662a;
  border-radius: 10px;
  width: 100%;

  color: white;
  font-weight: bold;
  font-size: large;
`;
