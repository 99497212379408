import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import cn from 'classnames'
import '~/assets/scss/admin_brand.scss';
import { Modal } from '@material-ui/core';
import { addSectionData, deleteSectionData, getCustomSectionList } from '~/sagas/api/admin/brand_management';
import { Button } from '@material-ui/core';
import {saveCustomSectionDetails} from '~/sagas/api/admin/brand_management';

import { Editor } from '@tinymce/tinymce-react';

function BrandManagementCustom () {
    const editorRef = useRef(null);
    const { corpUserInfo } = useSelector(({ common }) => ({
        corpUserInfo: common.auth.corpUserInfo,
    }));
    const [addSection, setAddSection] = useState(false);


    ////커스텀 섹션 정보
    const [customSections, setCustomSections] = useState(null); //section list
    const [selectedSection, setSelectedSection] = useState({
        id:0,
        seq:0,
        title:'',
        contents:'',
        isVisible:true
    });// 선택된 섹션
    const [sectionTitle, setSectionTitle] = useState(''); //섹션 추가시 입력 값
    const [deletePopup, setDeletePopup] = useState(false); //삭제 팝업
    const [deletePopupComplete, setDeletePopupComplete] = useState(false); //삭제 완료 팝업
    const [isWriteMode, setIsWriteMode] = useState(false); //view / modify 모드
    const [editorData, setEditorData] = useState(null); //에디터 내용.

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   
    const closeConfirm = useCallback(key => (
        <Button onClick={() => { closeSnackbar(key); }}>
          닫기
        </Button>
      ), [closeSnackbar]);
      

    //커스텀 섹션 좌측 메뉴 선택
    const onClickSection = (id) => {
        const list = customSections.filter(item => item.id === id);

        setSelectedSection(list[0]);
        setIsWriteMode(false);
    };

    //섹션추가 팝업 열기
    const openAddSection = () => {
        setAddSection(true);
    };
    //섹션추가 팝업 닫기
    const closeAddSection = () => {
        setSectionTitle(null);
        setAddSection(false);
    };

    //섹션추가 추가 버튼
    const onClickAddSection = () => {
        // const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

        if ( corpUserInfo && corpUserInfo.selectedBrand ) {
            addSectionData({brandId:corpUserInfo.selectedBrand.brandId, title:sectionTitle}, localStorage.getItem("jwtTokenAdmin")).then(res=>{
    
                if ( res.status === 200 ) {
                    closeAddSection();
                    setSelectedSection(res.data);
                    setCustomSections(customSections ? customSections.concat(res.data) : [res.data]);
                }
            });
        }
    };

    //섹션추가 입력 값
    const onChangeSectionTitle = (e) => {
        setSectionTitle(e.target.value);
    };

    //수정 모드에서 타이틀 제목 바꾸면 세팅 해줌.
    const onChangeSectionTitleModifyMode = (e) => {
        setSelectedSection({
            ...selectedSection,
            title:e.target.value
        });
    };

    

    
    
    //에디터 저장
    const saveEditor = (id) => {
        if ( selectedSection.title == null || selectedSection.title === "" || selectedSection.title.length === 0 ) {
            enqueueSnackbar("섹션 이름을 입력해 주세요.", {
                className: "snackbar warning",
                autoHideDuration: 10000,
                action: closeConfirm
            });
            return;
        }
        
        setEditorData(editorRef.current);

        const content = editorRef.current;
        // post 저장. editorData
        saveCustomSectionDetails(selectedSection.id, selectedSection.title, content, localStorage.getItem("jwtTokenAdmin")).then(res => {

            if ( res.status === 200 ) {
                const list = customSections.map(item => {
                    if ( item.id === id ) {
                        item.title = selectedSection.title;
                        item.contents = content;
                    }

                    return item;

                }).filter(item => item.id === id);

                setSelectedSection(list[0]);
                setIsWriteMode(false);
                setEditorData(content);
        
                enqueueSnackbar("저장되었습니다..", {
                    className: "snackbar success",
                    autoHideDuration: 5000,
                    action: closeConfirm
                });
                
            }
        });

    };

    //화면 수정
    const goModifyView = () => {
        setIsWriteMode(true);
    };
    //섹션삭제 메세지 팝업 열기
    const openDeletePopup = () => {
        setDeletePopup(true);
    };

    //섹션삭제 메세지 팝업 닫기
    const closeDeletePopup = () => {
        setDeletePopup(false);
    };
    
    //섹션 삭제 하기
    const openDeletePopupComplete = () => {

        if ( selectedSection ) {
            deleteSectionData(selectedSection.id, localStorage.getItem("jwtTokenAdmin")).then(res=>{

                if ( res.status === 200 ) {
    
                    setCustomSections(customSections ? customSections.filter(item=> item.id !== selectedSection.id) : null);
                    setSelectedSection(customSections ? customSections.filter(item=> item.id !== selectedSection.id)[0] : null);
    
                    setDeletePopup(false);
                    setDeletePopupComplete(true);
                }
            });
        }
        
    };

    //섹션삭제 완료 메세지 팝업 닫기
    const closeDeletePopupComplete = () => {
        setDeletePopup(false);
        setDeletePopupComplete(false);
    };

    //editor init
    const editorInit = (evt, editor) => {
        editorRef.current = editor;
    }

    //editor change
    const handleEditorChange = (e) => {
        editorRef.current = e;
      };
      
    
    //최초 조회시 부모 1번째와 자식 첫번째 세팅
    useEffect(() => {
        try {
            // const brandList = corpUserInfo && corpUserInfo.brandList && corpUserInfo.brandList.filter(i=> i.isActive);

            if ( corpUserInfo && corpUserInfo.selectedBrand ) {
                
                //커스텀 섹션 조회
                getCustomSectionList(corpUserInfo.selectedBrand.brandId, localStorage.getItem("jwtTokenAdmin")).then(res=> {
                    
                    if ( res.status === 200 ) {
                        const data = res.data;
                        setCustomSections(data);
                        setSelectedSection(data[0]);
                    }
                });
            }
            
        } catch (e) {
            alert(e);
        }

    }, [corpUserInfo]);

    if (!customSections) return null;
    
    return (
        <div className="info-tab__container">
            <div className="left-group">
                <div className="inner-main">
                    {
                        customSections &&
                        customSections.map(item => <span key={item.id} className={cn("custom-section-btn", {selected : item.id === (selectedSection ? selectedSection.id : item.id)})} onClick={() => onClickSection(item.id)}>{item.title}</span>)
                    }
                </div>
                <button className="inner-bottom" onClick={openAddSection}>+ 추가하기</button>
            </div>
            <div className="right-group">
                {
                    selectedSection &&
                    <>
                        <div className="header-group">
                            { isWriteMode === false && <span className="title">{selectedSection && selectedSection.title}</span> }
                            { isWriteMode && <input type="text" className="title" placeholder="섹션 이름" value={selectedSection.title} onChange={onChangeSectionTitleModifyMode} /> }
                            <div className="button-group">
                                { isWriteMode && <button className="modify-btn mg8" onClick={() => saveEditor(selectedSection.id)}>저장</button> }
                                { isWriteMode === false && <button className="modify-btn mg8" onClick={goModifyView}>수정</button>}
                                <button className="delete-btn" onClick={openDeletePopup}>삭제</button>
                            </div>
                        </div>
                        <div className="main-group ckeditor-root">
                            { isWriteMode &&
                                <>
                                    <Editor
                                        onInit={editorInit}
                                        apiKey="zm0ui7tul373hc2nc1poa1kmm7p6219rr518p0vqlljm42vg"
                                        initialValue={selectedSection.contents}
                                        init={{
                                            height: 600,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen insertdatetime media table code'
                                            ],
                                            toolbar: 'code | formatselect | fontsizeselect | forecolor backcolor  | bold italic  |' +
                                                ' alignleft aligncenter alignright alignjustify | bullist numlist outdent indent '  +
                                                ' | table | image  media  | link |  undo redo',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            
                                        }}
                                        onEditorChange={handleEditorChange}
                                        //onChange={handleEditorChange}
                                        
                                    />
                                    {/* <button onClick={log}>Log editor content</button> */}
                                </>
                                
                            }
                            {isWriteMode === false && selectedSection && 
                                <div dangerouslySetInnerHTML={{ __html: selectedSection.contents }}></div>
                            }
                        </div>
                    </>
                }
                
            </div>

            <Modal
                    open={addSection}
                >
                <div className="admin-brand-add-section__container">
                    <div className="admin-brand-add-section__title-group">
                        <div className="admin-brand-add-section__title">섹션 추가</div>
                        <button className="admin-brand-add-section__close-btn" onClick={closeAddSection}>close</button>
                    </div>

                    <div className="admin-brand-add-section__content-group">
                        <div className="admin-brand-add-section__content-content">추가할 섹션 이름을 입력하세요.</div>    
                        <input type="text" className="admin-brand-add-section__input-email" placeholder="섹션 이름" value={sectionTitle} onChange={onChangeSectionTitle} />

                        <div className="admin-brand-add-section__content-btn-group">            
                            <div className="admin-brand-add-section__content-btn" onClick={closeAddSection}>취소</div>
                            <div className="admin-brand-add-section__content-btn" onClick={onClickAddSection}>확인</div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                        open={deletePopup}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">섹션 삭제하기</div>
                            <button className="password-change__close-btn" onClick={closeDeletePopup}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="password-change__content-content">
                                 삭제하시겠습니까?
                            </div>
                            <div className="password-change__content-btn-group">
                                <div className="password-change__content-btn grey" onClick={closeDeletePopup}>취소</div>
                                <div className="password-change__content-btn" onClick={openDeletePopupComplete}>삭제하기</div>
                            </div>       
                        </div>
                    </div>
                </Modal>

                <Modal
                        open={deletePopupComplete}
                >
                    <div className="password-change__container">
                        <div className="password-change__title-group">
                            <div className="password-change__title">삭제 완료</div>
                            <button className="password-change__close-btn" onClick={closeDeletePopupComplete}>close</button>
                        </div>

                        <div className="password-change__content-group">
                            
                            <div className="password-change__content-content">
                                정상적으로 삭제 처리되었습니다
                            </div>                
                            <div className="password-change__content-btn" onClick={closeDeletePopupComplete}>확인</div>
                        </div>
                    </div>
                </Modal>
        </div>
            
    );
}

export default React.memo(BrandManagementCustom);