import React from "react";
import cn from "classnames";
import MapSearchResultText from "./MapSearchResultText";

function MapFirstSearchBrand({isBackgroundLayerActive, resultBrandList, resultAddressList, onSubmit, onChangeBrandSearch, handleKeyDown, onFocusAutoComplete, onBlurAutoComplete, searchKeyword, handleFirstLayer, moveToCenterAtMap, sortingBrand, initialize, seacrhCloseBtn, onClickBrandOne, callGetAddress}) {

    // console.log(resultAddressList);
  
    //검색 된 브랜드 클릭 이벤트
    const onClickResultBrand = (brandId) => {
      onClickBrandOne(brandId);
      // handleFirstLayer();
      
      // const brands = resultBrandList.filter( (item) => item.id === brandId);
      // if ( brands ) {
      //   const brand = brands[0];
      //   if ( brand.branch ) {
      //     const branch = brand.branch[0]; 
      //     moveToCenterAtMap(branch.latitude, branch.longitude); //move to center at map
      //     // callGetAddress(branch.longitude, branch.latitude); //지도 위치 주소 검색
      //   }
      // }


      onBlurAutoComplete(); //검색 기능 초기화

      
    };
  
    let timer;

    //검색 된 지역 클릭 이벤트
    const onClickResultAddress = async (item) => {
      console.log("onClickResultAddress");
      handleFirstLayer();
      
      await moveToCenterAtMap(item.latitude, item.longitude); //해당 위치로 이동

      onBlurAutoComplete(); //검색 기능 초기화

      if ( timer ) clearTimeout(timer);

      timer = setTimeout(() => {
        initialize(); //현재 위치에서 검색
        callGetAddress(item.longitude, item.latitude);
      }, 1000);

    };
    
    return (
      <>
        <form onSubmit={onSubmit}>
          <div className="search-brand">
            <div className="inner">
              <div className="search">
                <button type="submit" className="ic-search"></button>
                <div className="search-input">
                    <input type="text" value={searchKeyword} onChange={onChangeBrandSearch} onKeyDown={handleKeyDown} onClick={onFocusAutoComplete}  placeholder="브랜드 또는 지역 이름을 검색해보세요." />
                  </div>
                <div className={cn("map__search-brand-close", {active: seacrhCloseBtn})} onClick={onBlurAutoComplete}>
                  창닫기
                </div>
                
              </div>
            </div>
          </div>
        </form>
        
        <div className={cn("map__background-layer", { "backgroundLayerActive": isBackgroundLayerActive })}>
            <div className={cn("map__brand-search-result", { existResult: ((resultBrandList == null || resultBrandList.length === 0) && (resultAddressList == null || resultAddressList.length === 0))})}>
              <span className="map__search-no-result">검색된 결과가 없습니다.</span>
            </div>
            <div className={cn("map__brand-search-result", { existResult: ((resultBrandList !== null && resultBrandList.length > 0) || (resultAddressList !== null && resultAddressList.length > 0))})}>
              
              { 
                resultBrandList ? resultBrandList.map( ( item ) => 
                  <div key={item.id} className="map__brand-search-group" onClick={() => onClickResultBrand(item.id)}>
                    <div>
                      <div className="map__brand-search-thumb-logo">
                        <img src={item.logoImageUrlThumb ? item.logoImageUrlThumb : "/placeholder_logo.png"} alt="LOGO" />
                      </div>
                      <span className="map__brand-search-name">
                        <MapSearchResultText text={item.name} searchKeyword={searchKeyword} />
                      </span>
                    </div>
                    <div className="map__brand-search-category-name">{item.category && item.category.name}</div>
                  </div>
                )
              : null
              }
              {
                (resultBrandList.length > 0 && (resultAddressList !== null && resultAddressList.length > 0)) &&
                <div className="divider"></div>
              }
              { 
                resultAddressList ? resultAddressList.map( ( item ) => 
                  <div key={item.id+item.address} className="map__brand-search-group" onClick={() => onClickResultAddress(item)}>
                    <div>
                      <div className="map__brand-search-thumb-gps">
                        <i className="gps">i</i>
                      </div>
                      <span className="map__brand-search-name">
                        <MapSearchResultText text={item.address} searchKeyword={searchKeyword} />
                      </span>
                    </div>
                  </div>
                )
              : null
              }
            </div>
            
        </div>
      </>
    );
  }

  export default React.memo(MapFirstSearchBrand);