import { Popover } from "@material-ui/core";
import cn from "classnames";
import React, { useCallback } from "react";
import LoginNaver from "react-login-by-naver";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { login, popup } from "~/sagas/actions/commonAction";
import LoginKakao from "react-kakao-login";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(({ common }) => ({
    userInfo: common.auth.userInfo,
  }));
  const { corpUserInfo } = useSelector(({ common }) => ({
    corpUserInfo: common.auth.corpUserInfo,
  }));

  const openLoginScreen = () => {
    dispatch(
      popup({
        login: true,
      })
    );
  };
  const openLoginScreenCorp = useCallback(() => {
    dispatch(
      popup({
        login: true,
        loginUserType: false,
        loginView: true,
      })
    );
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDropdownEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem("jwtToken");
    //console.log('logouted');
    dispatch(
      login({
        name: "",
        email: "",
        provider: "",
        providerId: "",
      })
    );

    handleClose();

    history.replace("/map");
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-info-popover" : undefined;

  const goCorpMemberView = useCallback(async () => {
    openLoginScreenCorp();
  }, [openLoginScreenCorp]);

  return (
    <div id="header">
      <header>
        <div className="inner">
          <h1 className="logo">
            <Link to="/map">
              <i className="ttoksa">똑똑한사장로고</i>
            </Link>
          </h1>
          <div id="nav" className="clfix">
            <nav>
              {/* <NavLink exact to="/" activeClassName="active">
                홈
              </NavLink> */}

              <NavLink to="/map" activeClassName="active">
                지도
              </NavLink>
              <NavLink to="/brand" activeClassName="active">
                브랜드
              </NavLink>
              {/*<NavLink to="/map" activeClassName="active">
                지도
              </NavLink> */}
              <NavLink
                style={{
                  float: "right",
                  marginRight: "250px",
                }}
                to="/introduce"
                activeClassName="active"
              >
                똑똑한 보고서란?
              </NavLink>
            </nav>
          </div>
          <div className={cn("member", { on: !userInfo || !userInfo.name })}>
            <span
              onClick={
                localStorage.getItem("jwtToken") == "test"
                  ? () => {
                      history.push("/mypage");
                    }
                  : openLoginScreen
              }
            >
              로그인
            </span>
          </div>

          {userInfo && userInfo.name && (
            <div className={cn("member", { on: userInfo.name })}>
              <div className="header__user-info">
                <button onClick={openDropdownEvent} className="login-user-name">
                  {userInfo.name} 님
                  {open ? (
                    <KeyboardArrowUpIcon
                      className="folding"
                      sx={{ fontSize: 22 }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className="folding"
                      sx={{ fontSize: 22 }}
                    />
                  )}
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div id="header__order-group-select-box-container">
                    <span className="user-name">{userInfo.name} 님</span>
                    <span className="user-type">
                      {userInfo.level === "STANDARD_USER"
                        ? "예비 창업자"
                        : "기업 고객"}
                    </span>
                    <span className="user-email">{userInfo.email}</span>
                    <div className="divier"></div>
                    <Link to="/mypage">
                      <span
                        className={cn("header__order-group-select-box-item")}
                      >
                        마이 페이지
                      </span>
                    </Link>
                    {userInfo.provider === "naver" ? (
                      <LoginNaver
                        clientId="tLlfi8fqnCKlsdh6j3RA"
                        // callbackUrl="http://localhost:3000/login"
                        // onSuccess={(response) => LoginResponse(response, 'naver')}
                        onLogout={true}
                        onFailure={(error) => console.error(error)}
                        render={(props) => (
                          <span
                            className="header__order-group-select-box-item"
                            onClick={onLogout}
                          >
                            로그아웃
                          </span>
                        )}
                      >
                        로그아웃
                      </LoginNaver>
                    ) : userInfo.provider === "google" ? (
                      <span
                        className="header__order-group-select-box-item"
                        onClick={onLogout}
                      >
                        로그아웃
                      </span>
                    ) : userInfo.provider === "kakao" ? (
                      <LoginKakao
                        token="419a57acd98d5949e2fa39b1d7b8243a"
                        // onSuccess={(response) => LoginResponse(response, 'kakao')}
                        onFail={(error) => console.error(error)}
                        onLogout={true}
                        render={(props) => (
                          <span
                            className="header__order-group-select-box-item"
                            onClick={onLogout}
                          >
                            로그아웃
                          </span>
                        )}
                      >
                        로그아웃
                      </LoginKakao>
                    ) : (
                      <span
                        className="header__order-group-select-box-item"
                        onClick={onLogout}
                      >
                        로그아웃
                      </span>
                    )}
                  </div>
                </Popover>
              </div>
            </div>
          )}
          {corpUserInfo && corpUserInfo.id ? (
            <Link to="/corp/brand" target="_blank" className="corp-member">
              기업 고객
            </Link>
          ) : (
            <div className="corp-member" onClick={goCorpMemberView}>
              기업 고객
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
